import {Link} from 'react-router-dom';
import { Home,ShoppingBag,Settings,Power, Lock, User, Heart, MapPin} from 'react-feather';
function Sidebar(){
    return (
            <ul className="navbar-nav flex-column w-100">
                <li className="nav-divider">
                    ----                
                </li>
                <li className="nav-item">
                    <Link to="/customer/dashboard" className="nav-link" aria-current="true"><Home/> Dashboard</Link>
                </li>
                <li className="nav-item">
                    <Link to="/customer/orders" className="nav-link" aria-current="true"><ShoppingBag/> Orders</Link>
                </li>
                <li className="nav-item">
                    <Link to="/customer/wishlist" className="nav-link" aria-current="true"><Heart/> Wishlist</Link>
                </li>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                        <li className="nav-item accordion-button collapsed text-blue" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            <Link to="/seller/reports" className="nav-link" aria-current="true"><Settings/> Settings</Link>
                        </li>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <li className="nav-item">
                                    <Link to="/customer/profile" className="nav-link" aria-current="true"><User/> Profile</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/customer/reset-link" className="nav-link" aria-current="true"><Lock/> Change Password</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/customer/logout" className="nav-link" aria-current="true"><Power/> Logout</Link>
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </ul> 

        
    )
}

export default Sidebar;