import { Link } from 'react-router-dom';
import googleLogo from '../../google.png';
import fbLogo from '../../fb.png';
import axios from "axios";
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'; // For getting the current route
import api from '../api';

function SellerLogin(props) {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [formError, setFormError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [loginFormData, setLoginFormData] = useState({
        username: '',
        password: '',
    });
    
    const inputHandler = (event) => {
        setLoginFormData({
            ...loginFormData,
            [event.target.name]: event.target.value,
        });
    };
    
    const submitHandler = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = {
            username: loginFormData.username,
            password: loginFormData.password,
        };
    
        try {
            const response = await axios.post(baseUrl + '/vendor/login/', formData);
    
            if (response.data.bool) {
                const { access_token, refresh_token, user, id } = response.data;
    
                // Store secure details in local storage
                localStorage.setItem('access_token', access_token);
                localStorage.setItem('refresh_token', refresh_token);
                localStorage.setItem('vendor_username', user);
                localStorage.setItem('vendor_id', id);
                localStorage.setItem('vendor_login', true);
                setLoading(false);
    
                // Redirect to the dashboard
                window.location.href = '/seller/dashboard';
            } else {
                setFormError(true);
                setLoading(false);
                setErrorMsg(response.data.message || 'Login failed');
            }
        } catch (error) {
            //console.error('Login error:', error);
            setFormError(true);
            setLoading(false);
            setErrorMsg('An error occurred. Please try again.');
        }
    };
    
    const location = useLocation(); // Get the current route
    
    useEffect(() => {
        const token = localStorage.getItem('access_token');
    
        if (!token && location.pathname !== '/seller/login') {
            // Redirect to login page if no token and not already on the login page
            window.location.href = '/seller/login';
        }
    
        if (token) {
            api
                .get(baseUrl + '/vendor/validate-token/', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    if (!response.data.valid) {
                        // Invalid token
                        localStorage.clear();
                        window.location.href = '/seller/login';
                    }
                })
                .catch(() => {
                    // Clear storage on error
                    localStorage.clear();
                    window.location.href = '/seller/login';
                });
        }
    }, [location.pathname]); // Only re-run if the route changes

    const buttonEnabled = loginFormData.username !== '' && loginFormData.password !== '';

    return (
        <section className="container mtopcon normal-mt-sctn">
            <div className="row">
                <div className="col-12 col-lg-4 reglogincon">
                    <div className="card shadow-none">
                        <div className="card-body">
                            <h2 className='mb-3 text-center'><b>Welcome back</b></h2>
                            {formError && <p className="text-danger text-center">{errorMsg}</p>}
                            <div className="mb-3">
                                <label htmlhtmlfor="lnm" className="form-label mb-0 font9 grey-text">Business Name</label>
                                <input
                                    type="text"
                                    name="username"
                                    value={loginFormData.username}
                                    onChange={inputHandler}
                                    className="form-control"
                                    id="lnm"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlhtmlfor="InputPassword1" className="form-label mb-0 font9 grey-text">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={loginFormData.password}
                                    onChange={inputHandler}
                                    className="form-control"
                                    id="InputPassword1"
                                />
                            </div>
                            <div className="mb-3 font9">
                                <p><Link to="/seller/reset-link">Forgot Password?</Link></p>
                            </div>
                            <button
                                type="button"
                                disabled={!buttonEnabled}
                                onClick={submitHandler}
                                className="col-12 btn btn-primary"
                            >
                                { loading === true ? <>Loading...</> : <>Continue</>}
                            </button>
                            
                            <div className="mb-3 font9 mt-3">
                                <p>Don't have an account? <Link to="/seller/register">Sign Up</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SellerLogin;
