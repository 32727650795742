import { useState} from 'react';
import Sidebar from './sidebar';
import { Copy } from 'react-feather';
import useStoreSetupCheck from './useStoreSetupCheck';

function Aiprompts(){
  const setupStatus = useStoreSetupCheck();
    const [copiedIndex, setCopiedIndex] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('All');

    const handleCopy = (value, index) => {
        const tempInput = document.createElement('input');
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        setCopiedIndex(index);
        setTimeout(() => setCopiedIndex(null), 2000);
    };

    const items = [
        {
          "value": "My business is called [Your Business Name], and we specialize in [what you do/products or services you offer]. Our target market is [describe your ideal customers, e.g., busy professionals, small business owners, parents, etc.], and we help them by [how you solve their problems or meet their needs]. What makes us unique is [your unique value proposition or what sets you apart from competitors]. Right now, we’re focusing on [your current goals, e.g., growing sales, improving customer retention, launching a new product], but we’re facing challenges like [describe your top challenges]. Our brand is all about [describe your brand identity, tone, and visuals], and we’ve received feedback from customers that [share customer feedback or insights]. When it comes to competition, we see [describe your main competitors and how you compare]. I’d love your help with [specific areas you want advice on, e.g., marketing, branding, financial strategies] to take our business to the next level!",
          "title": "Introduction Prompt",
          "category": "Business Development"
        },
        {
          "value": "Who are our ideal customers, and what are their key pain points? How can we tailor our offerings to better meet their needs?",
          "title": "Customer Persona Deep Dive",
          "category": "Business Development"
        },
        {
          "value": "What does the end-to-end customer journey look like, and where are the friction points? How can we improve the experience?",
          "title": "Customer Journey Mapping",
          "category": "Business Development"
        },
        {
          "value": "What are our customers saying about us through reviews, surveys, or social media? What recurring themes or suggestions can we act on?",
          "title": "Feedback Loop Analysis",
          "category": "Business Development"
        },
        {
          "value": "What makes our brand unique compared to competitors? How can we communicate our value proposition more effectively?",
          "title": "Brand Positioning Clarity",
          "category": "Business Development"
        },
        {
          "value": "Are there untapped markets, niches, or customer segments we can target? What strategies can we use to enter these markets?",
          "title": "Market Expansion Opportunities",
          "category": "Business Development"
        },
        {
          "value": "Who are potential partners (businesses, influencers, or organizations) that align with our brand values and can help us reach new audiences?",
          "title": "Partnership & Collaboration Ideas",
          "category": "Business Development"
        },
        {
          "value": "What new products, services, or features can we develop to address unmet customer needs or stay ahead of industry trends?",
          "title": "Product/Service Innovation",
          "category": "Business Development"
        },
        {
          "value": "How can we increase customer lifetime value? What loyalty programs, personalized offers, or engagement strategies can we implement?",
          "title": "Customer Retention Strategies",
          "category": "Business Development"
        },
        {
          "value": "What emotions do we want our brand to evoke in customers? Are we consistently delivering on this through our messaging and visuals?",
          "title": "Emotional Connection Audit",
          "category": "Marketing"
        },
        {
          "value": "Is our brand identity (logo, tone, colors, messaging) consistent across all touchpoints? How can we ensure a cohesive brand experience?",
          "title": "Consistency Check",
          "category": "Marketing"
        },
        {
          "value": "What channels (social media, email, SEO, etc.) are driving the most engagement and conversions? How can we optimize our marketing mix?",
          "title": "Marketing Channel Optimization",
          "category": "Marketing"
        },
        {
          "value": "What content formats (blogs, videos, infographics, etc.) resonate most with our audience? How can we create more of what works?",
          "title": "Content Strategy Refinement",
          "category": "Marketing"
        },
        {
          "value": "How can we leverage data and analytics to measure the ROI of our marketing campaigns and make data-driven decisions?",
          "title": "Data-Driven Marketing Decisions",
          "category": "Marketing"
        },
        {
          "value": "What are the latest trends in our industry, and how can we adapt our marketing strategies to stay ahead of the curve?",
          "title": "Trend Analysis & Adaptation",
          "category": "Marketing"
        },
        {
          "value": "What is our current cash flow situation, and how can we improve it to ensure sustainable growth?",
          "title": "Cash Flow Management",
          "category": "Finance"
        },
        {
          "value": "What are our key financial metrics (e.g., profit margins, ROI, burn rate), and how can we optimize them for better performance?",
          "title": "Financial Metrics Optimization",
          "category": "Finance"
        },
        {
          "value": "What cost-saving measures can we implement without compromising the quality of our products or services?",
          "title": "Cost Reduction Strategies",
          "category": "Finance"
        },
        {
          "value": "What funding options (e.g., loans, investors, grants) are available to us, and how can we secure the necessary capital for growth?",
          "title": "Funding & Capital Acquisition",
          "category": "Finance"
        }
      ];
    
    const filteredItems = selectedCategory === 'All' ? items : items.filter(item => item.category === selectedCategory);
    const [copySuccess, setCopySuccess] = useState(false);
    return (
        <section className="container mtopcon dashb">
            <div className="row">
                    <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max">
                        <div className="row mb-3">
                            <div className="col-12 pt-2">
                                <h6 className="pageheader-title mb-2"><b>AI For Business</b></h6>
                                <p>Boost your business with these tailored prompts. Copy, edit, and use on your favorite AI platform.</p>
                            </div>
                        </div>
                        <div className='row'>
                        <div>
                                    <div className="mb-3">
                                        <select className="form-select" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                                        <option value="All">All</option>
                                        {[...new Set(items.map(item => item.category))].map(category => (
                                            <option key={category} value={category}>{category}</option>
                                        ))}
                                        </select>
                                    </div>
                                    <div className="row">
                                        {filteredItems.map((item, index) => (
                                        <div className='col-12 col-lg-3' key={index}>
                                            <div className='card'>
                                                <p className='p-3 pb-0 font9 mh100 text-grey'>{item.value}</p>
                                                <div className='card-footer pt-0 pb-0 pr-0'>
                                                    <div className='row'>
                                                    <div className='col-8 font9'><p className='mt-2'>{item.title}</p></div>
                                                    <div className='col-4 text-right'>
                                                        {copiedIndex === index ? (
                                                        <button className='btn text-green'><b>Copied</b></button>
                                                        ) : (
                                                        <button className='btn btn-default' onClick={() => handleCopy(item.value, index)}><Copy /></button>
                                                        )}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                </div>                            
                        </div>
                    </div>
            </div>
        </section>
    )
}

export default Aiprompts;