function Help(){
   
    return (
        <main className="mtopcon normal-mt-sctn">
            <div className="container">
                
            <div className="mb-4 section-title-con"><span className='section-ttl'>Help Center</span></div>
                <div className="row mb-4 p-0">
                    <div className="accordion" id="accordionExample">
                        
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="true" aria-controls="faq1">
                                How do I create an online store on Kadhr?
                            </button>
                            </h2>
                            <div id="faq1" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                To create an online store, sign up for a Kadhr account, navigate to the dashboard, and click on "Create Store." Follow the guided steps to set up your store profile, upload products, and customize your store’s appearance.
                            </div>
                            </div>
                        </div>
                        
                        
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="false" aria-controls="faq2">
                                What payment methods does Kadhr support?
                            </button>
                            </h2>
                            <div id="faq2" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Kadhr supports multiple payment methods, including M-Pesa, PayPal, credit/debit cards, and bank transfers. You can configure your preferred payment options in the store settings.
                            </div>
                            </div>
                        </div>
                        
                        
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                                How do I track my inventory?
                            </button>
                            </h2>
                            <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Kadhr offers a built-in inventory management tool. Navigate to the "Inventory" tab in your dashboard to view stock levels, update quantities, and set restock alerts.
                            </div>
                            </div>
                        </div>

                        
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">
                                Can I customize my store’s appearance?
                            </button>
                            </h2>
                            <div id="faq4" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Yes, Kadhr allows you to customize your store’s logo, colors, banners, and layouts. Visit the "Customization" section in your dashboard to apply changes.
                            </div>
                            </div>
                        </div>

                        
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="faq5">
                                How does the loyalty program work?
                            </button>
                            </h2>
                            <div id="faq5" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Kadhr’s loyalty program rewards customers based on their spending. You can set up tiers and offer vouchers or discounts that are automatically sent to customers via SMS.
                            </div>
                            </div>
                        </div>
                        
                        
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq7" aria-expanded="false" aria-controls="faq7">
                                Is Kadhr mobile-friendly?
                            </button>
                            </h2>
                            <div id="faq7" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Yes, Kadhr is optimized for mobile use. Customers and store owners can access the platform and manage stores on smartphones and tablets seamlessly.
                            </div>
                            </div>
                        </div>
                        
                        
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq8" aria-expanded="false" aria-controls="faq8">
                                What analytics does Kadhr provide?
                            </button>
                            </h2>
                            <div id="faq8" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Kadhr provides detailed analytics, including sales trends, customer behavior, product performance, and revenue insights. Access these in the "Analytics" tab.
                            </div>
                            </div>
                        </div>

                        
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq9" aria-expanded="false" aria-controls="faq9">
                                How do I attract more customers to my store?
                            </button>
                            </h2>
                            <div id="faq9" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Kadhr offers marketing tools like promotional codes, SMS campaigns, and social media integrations to help you reach more customers.
                            </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq10" aria-expanded="false" aria-controls="faq10">
                                Is my data secure on Kadhr?
                            </button>
                            </h2>
                            <div id="faq10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Yes, Kadhr uses encryption and other security measures to protect your data. Your business information and customer details are stored securely.
                            </div>
                            </div>
                        </div>
                        
                        
                        </div>

                </div>
            </div>
        </main>
    )
}

export default Help;
