export const fetchExchangeRates = async (baseCurrency) => {
  try {
      if(baseCurrency==""){baseCurrency="KES";}
      const response = await fetch(`https://api.exchangerate-api.com/v4/latest/${baseCurrency}`);
      const data = await response.json();
      
      if (data.rates) {
          localStorage.setItem("exchangeRates", JSON.stringify(data.rates));
          localStorage.setItem("baseCurrency", baseCurrency);
      }

      return data.rates;
  } catch (error) {
      //console.error("Error fetching exchange rates:", error);
      return null;
  }
};

export const getStoredExchangeRates = () => {
  const rates = localStorage.getItem("exchangeRates");
  return rates ? JSON.parse(rates) : null;
};

export const getStoredBaseCurrency = () => {
  return localStorage.getItem("baseCurrency"); // Default to USD
};
