import {Link} from 'react-router-dom';
import { Plus} from 'react-feather';
import Sidebar from './sidebar';
import { useState,useEffect,useContext } from 'react';
import api from "../api";
function BizdocsProformaInvoiceList(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [quotations, setQuotations] = useState([]);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);


    const fetchQuotations = async () => {
        try {
          const response = await api.get(baseUrl+`/proformainvoices/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          setQuotations(response.data.quotations);
        } catch (error) {
          //console.error("Error fetching products:", error.toJSON());
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchQuotations();
    }, []);

    if (error) {
        return <div>{error}</div>;
    }

    // Filter quotations based on the search term
    const filteredQuotations = quotations.filter((quotation) => {
        const search = searchTerm.toLowerCase();
        return (
            (quotation.customer_name && quotation.customer_name.toLowerCase().includes(search)) ||
            (quotation.company_name && quotation.company_name.toLowerCase().includes(search)) ||
            (quotation.phone_number && String(quotation.phone_number).toLowerCase().includes(search))
        );
    });
    
    const getVendorCurrency = () => {
        return localStorage.getItem("VendorCurrency");
    };
    
    return (
        <section className="container mtopcon dashb">
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max">                        
                        <div className="row mb-3 ph-header-con">
                            <div className="col-8 pt-2"><h6 className="pageheader-title mb-3">Proforma Invoices Overview </h6></div>
                            <div className="col-4"><Link to="/bizdocs/proforma"><button className="btn btn-primary f85 float-right h40 lh40"><Plus width='20px'/></button></Link></div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <input
                                    className="filter2 border me-2 w-100"
                                    type="search"
                                    placeholder="Search by Customer Name/Company Name"
                                    aria-label="Search"
                                    onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on keyup
                                />
                            </div>
                        </div>

                        <div className="mb-4">                       

                        {filteredQuotations.length === 0 ? (
                            loading === true ? <p>Loading...</p> : <p>No proforma invoices found.</p>
                        ) : (
                            <>
                                {filteredQuotations
                                    .sort((a, b) => b.quote_id - a.quote_id) // Sort by id in descending order
                                    .map((quotation) => (
                                        <Link
                                            to={`/bizdocs/getproforma/${quotation.uniqid}`}
                                            className="text-dark"
                                            key={quotation.uniqid}
                                        >
                                            <div className="row ord-bg invc-ord-bg bg-white p-2 hvr bx-shadow font8">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-12 text-dark">{quotation.customer_name}</div>
                                                        <div className="col-12">{quotation.company_name}</div>
                                                        <div className="col-12 grey-text">{quotation.phone_number}</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <div className="row">
                                                        <div className="col-12 text-dark">PI-{quotation.quote_id}</div>
                                                        <div className="col-12">Date: {quotation.date}</div>
                                                        <div className="col-12">
                                                            {quotation.status === "Paid" && <><span className="invcsts0 p-1 me-2 bg-success">{quotation.status}</span></>}
                                                            {quotation.status === "Unpaid" && <><span className="invcsts0 p-1 me-2 bg-danger">{quotation.status}</span></>}
                                                            <b>{getVendorCurrency()} {quotation.total_amount}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                            </>
                        )}
                            

                        </div>
                </div>
            </div>
        </section>
    )
}

export default BizdocsProformaInvoiceList;