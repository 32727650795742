import Sidebar from './sidebar';
import { useState,useEffect } from 'react';
import { ToggleRight } from "react-feather";
import api from "../api";
import { PaystackButton } from 'react-paystack';
function SellerSubscription(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY; 
    const [UsernameError,setUsernameError]=useState(false);
    const [PackageAmount,setPackageAmount]=useState(false);
    const [TotalPackageAmount,setTotalPackageAmount]=useState(false);
    const [PackageAnnualAmount,setPackageAnnualAmount]=useState(false);
    const [PackageName,setPackageName]=useState(false);
    const [selectedPaymentMode, setSelectedPaymentMode] = useState('0');
    const [selectedPaymentModeName, setSelectedPaymentModeName] = useState('Monthly');
    const [oneYearFromToday, setOneYearFromToday] = useState('');
    const [oneMonthFromToday, setOneMonthFromToday] = useState('');
    const [ExpDate, setExpDate] = useState('');
    const [packageDetails, setPackageDetails] = useState(null);
    
    const [EmailError,setEmailError]=useState(false);
    const [UsernameError0,setUsernameError0]=useState('0');
    const [EmailError0,setEmailError0]=useState('');
    const [PayError, SetPayError]=useState('');

    const token = localStorage.getItem("access_token");  

    const [transactions, setTransactions] = useState([]);

    const [currency, setcurrency] = useState("");
    const [plan1, setplan1] = useState("");
    const [plan2, setplan2] = useState("");
    const [plan3, setplan3] = useState("");
    const [plan10, setplan10] = useState("");
    const [plan20, setplan20] = useState("");
    const [plan30, setplan30] = useState("");
    const [ProfileData,setProfileData]=useState({  
        "user_id":'', 
        "first_name":'',
        "last_name":'',
        "username":'',
        "customer_email":'',
        "mobile":0,
        "address":'',
        "p_image":'',
    });

    const inputHandler =(event) => {
        setProfileData({
            ...ProfileData,
            [event.target.name]:event.target.value
        });
    };

    async function getLocationAndSetPricing() {
        try {
            const response = await fetch("https://ipapi.co/json/");
            const data = await response.json();
            const countryCode = data.country_code;
    
            let prices, prices0, currency;
    
            if (countryCode === "KE") {
                prices = [299, 949, 4499];
                prices0 = [3000, 9499, 42999];
                currency = "KES";
            } else {
                prices = [5, 20, 40];
                prices0 = [49, 199, 399];
                currency = "USD";
            }
            setplan1(prices[0]);
            setplan2(prices[1]);
            setplan3(prices[2]);  
            setplan10(prices0[0]);
            setplan20(prices0[1]);
            setplan30(prices0[2]); 
            setcurrency(currency);          

        } catch (error) {
            //console.error("Error fetching location:", error);
        }
    }
    


    useEffect(()=>{
        // Call function on page load
        getLocationAndSetPricing();
        const fetchTransactions = async () => {
            try {
                const token = localStorage.getItem("access_token"); // Get authentication token
                const response = await api.get(baseUrl+"/vendor-transactions/", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setTransactions(response.data);
            } catch (error) {
                //console.error("Error fetching transactions:", error);
            }
        };
        const fetchPackageDetails = async () => {
            try {
              const token = localStorage.getItem("access_token"); // Get authentication token
              const response = await api.get(baseUrl+"/vendor-package/", {
                headers: { Authorization: `Bearer ${token}` },
              });
              setPackageDetails(response.data);
            } catch (error) {
              //console.error("Error fetching package details:", error);
            }
        };
      
        fetchPackageDetails();
    
        fetchTransactions();
        fetchData();
        const formatDate = (date) => {
            return date.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
            });
        };

        const today = new Date();
        const oneYearLater = new Date();
        oneYearLater.setFullYear(today.getFullYear() + 1);

        const oneMonthLater = new Date();
        oneMonthLater.setMonth(today.getMonth() + 1);

        setOneYearFromToday(formatDate(oneYearLater));
        setOneMonthFromToday(formatDate(oneMonthLater));
        setExpDate(formatDate(oneMonthLater));
    },[]);

    const fetchData = async () => {
        try {
            const response = await api.get("/vendor/", {
            headers: {
                Authorization: `Bearer ${token})}`,
            },
            });
            //setSelectedPaymentMode(response.data.package);

        } catch (error) {
            //console.error("Error fetching products:", err.toJSON());
        }
    }

    function StepOne(amount,name,annual){
        setPackageAmount(amount);
        setPackageName(name);
        setPackageAnnualAmount(annual);
        setSelectedPaymentMode('0');
        setTotalPackageAmount(amount);
        setSelectedPaymentModeName("Monthly");
        setExpDate(oneMonthFromToday);
    }
    const handleRadioChange = async (event) => {
        const newPaymentMode = event.target.value;
        setSelectedPaymentMode(newPaymentMode);
        if(newPaymentMode === 0){
            setSelectedPaymentModeName("Monthly");
            setExpDate(oneMonthFromToday);
            setTotalPackageAmount(PackageAmount);
        }else{
            setSelectedPaymentModeName("Annualy");
            setExpDate(oneYearFromToday);
            setTotalPackageAmount(PackageAnnualAmount);
        }
    };

    if (!packageDetails) {
        return <p>Loading package details...</p>;
    }
    
    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && !emailPattern.test(email)) {
            setEmailError(true);setEmailError0('0');
        } else {
            setEmailError(false);setEmailError0('1');
        }
    };
    const validateUsername = (username) => {
        if(username.length<3){
            setUsernameError(true);setUsernameError0('0'); return;
        }else{
            setUsernameError(false);setUsernameError0('1');
        } 
    };

    const handlePaymentSuccess = async (response) => {
        var smsRefill = 0;
        if(PackageName == 'Personal'){
            smsRefill = 10;
        }else if(PackageName == 'Premium'){
            smsRefill = 100;
        }else if(PackageName == 'Advanced'){
            smsRefill = 250;
        }

        const payload = {
            email: ProfileData.email,
            total_amount:TotalPackageAmount,
            trxref: response.trxref,
            trans: response.trans,
            package: PackageName,
            tdateto: ExpDate,
            sms_refill: smsRefill,
        };
    
        try {
            const res = await api.post(baseUrl+`/subscription-checkout/`, payload, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
              }); 
            if(res.data.success){
                window.location.href = '/seller/subscription';
            } 
        } catch (error) {
            //console.error('Failed to update order status:', error.response?.data || error.message);
            //alert('Failed to update order status.');
        }
    };

    const handlePaymentClose = async () => {    
        SetPayError('Transaction was not completed.');
    };
    
    const onSuccess = (response) => {
        handlePaymentSuccess(response);
    };

    const onClose = () => {
        //alert('Transaction was not completed, window closed.');
        //console.error('Transaction was not completed, window closed.');
        handlePaymentClose();
    };

    function formatDate0(dateString) {
        const date = new Date(dateString);
        const month = date.toLocaleDateString('en-US', { month: 'short' }); // "Nov"
        const day = date.getDate(); // 1
        const year = date.getFullYear();
        const time = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        }).toLowerCase(); // "11:01 pm"
    
        return `${month} ${day}, ${year}`; 
    }
    return (
        <section className="container mtopcon dashb">            
            <div className="row">
                
                    <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max orderscontainer">
                        <div className="row mb-3 ph-header-con">
                            <div className="col-12 pt-2"><h6 className="pageheader-title mb-3">Subscription Overview </h6></div>
                        </div>

                        <div className="mb-4">

                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
                                    <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
                                        <div className="section-block">
                                            <h6 className="mb-3">Current Package </h6>
                                        </div>
                                    </div>  
                                    <div className="card mb-3">
                                        {packageDetails.package_name == "0" && <><p className='p-3'>No active subscription found. Upgrade to a package to access premium features.</p></>}
                                        {packageDetails.package_name != "0" && <>                                        
                                        <div className="row p-3 m-0">
                                            <div className="row ordbgrw pb-2">
                                                <div className="col-6 ord-sz1 pt-2">
                                                    <div className="row p-0 m-0">
                                                    <div className="col-12 grey-text font9">Package Name</div>
                                                    <div className="col-12 font-bold">{packageDetails.package_name}</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 ord-sz1 pt-2">
                                                    <div className="row p-0 m-0">
                                                    <div className="col-12 grey-text font9">Status</div>
                                                    <div className="col-12 font-bold">
                                                        <span className={packageDetails.status === "Active" ? "text-success" : "text-danger"}>
                                                        {packageDetails.status}
                                                        </span>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 ord-sz1 pt-2">
                                                    <div className="row p-0 m-0">
                                                    <div className="col-12 grey-text font9">Publish Date</div>
                                                    <div className="col-12 font-bold">{formatDate0(packageDetails.publish_date)}</div>
                                                    </div>
                                                </div>
                                                <div className="col-6 ord-sz1 pt-2">
                                                    <div className="row p-0 m-0">
                                                    <div className="col-12 grey-text font9">Ends</div>
                                                    <div className="col-12 font-bold">{formatDate0(packageDetails.end_date)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>}
                                    </div>
                                </div>
                                
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                    <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12">
                                        <div className="section-block">
                                            <h6 className="mb-3">Select New Package</h6>
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                                <div className="card">
                                                    <span className="yrprc br-0 price2yr-bg text-white text-center">{currency}. {parseFloat(plan10).toLocaleString()}/year Save 15%</span>
                                                    <div className="card-header h-auto bg-white text-center p-4 ">
                                                        <h6 className="mb-1">PERSONAL</h6>
                                                        <h1 className="mb-1"><span className="font-24">{currency}</span><sub className="display-4 fbold">{parseFloat(plan1).toLocaleString()}</sub></h1>
                                                        <p>per month</p>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#selectPackage" className="btn btn-outline-primary mb-2 gtst bgpkg w-100" onClick={()=>StepOne(plan1,'Personal',plan10)}>Get Started</a>
                                                    </div>
                                                    <div className="card-body">
                                                        <ul className="list-unstyled grey-text0 font9 mb-0">
                                                            <li className="mb-2">Online Storefront</li>
                                                            <li className="mb-2">1 User Account</li>
                                                            <li className="mb-2">A Link to use in your Bio</li>
                                                            <li className="mb-2">Upto 5 Products</li>
                                                            <li className="mb-2">Order Notification SMS Alerts</li>
                                                            <li className="mb-2">Card and Mobile payments (3%)</li>
                                                            <li className="mb-2">Manual payments (0%)</li>
                                                            <li className="mb-2">10 SMS Credits</li>
                                                            <li className="mb-2">Help Center</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                                <div className="card">
                                                    <span className="yrprc br-0 price2yr-bg text-white text-center">{currency}. {parseFloat(plan20).toLocaleString()}/year Save 15%, Get Free Domain</span>
                                                    <div className="card-header h-auto bg-white text-center p-4 ">
                                                        <h6 className="mb-1">PREMIUM</h6>
                                                        <h1 className=" mb-1"><span className="font-24">{currency}</span><sub className="display-4 fbold">{parseFloat(plan2).toLocaleString()}</sub></h1>
                                                        <p>per month</p>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#selectPackage" className="btn btn-primary mb-2 gtst sdhstl w-100" onClick={()=>StepOne(plan2,'Premium',plan20)}>Get Started</a>
                                                    </div>
                                                    <div className="card-body">
                                                        <ul className="list-unstyled grey-text0 font9 mb-0">
                                                            <li className="mb-2"><b>Everything in Personal</b></li>
                                                            <li className="mb-2">Unlimited Products</li>
                                                            <li className="mb-2">Advanced Analytics</li>
                                                            <li className="mb-2">Customer Reviews</li>
                                                            <li className="mb-2">Customer Loyalty Program</li>
                                                            <li className="mb-2">100 SMS Credits</li>
                                                            <li className="mb-2">Email Support</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                                <div className="card">
                                                    <span className="yrprc br-0 price2yr-bg text-white text-center">{currency}. {parseFloat(plan30).toLocaleString()}/year Save 15%, Get Free Domain</span>
                                                    <div className="card-header h-auto bg-white text-center p-4 ">
                                                        <h6 className="mb-1">ADVANCED</h6>
                                                        <h1 className="mb-1"><span className="font-24">{currency}</span><sub className="display-4 fbold">{parseFloat(plan3).toLocaleString()}</sub></h1>
                                                        <p>per month</p>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#selectPackage" className="btn btn-outline-primary mb-2 gtst smepkg w-100" onClick={()=>StepOne(plan3,'Advanced',plan30)}>Get Started</a>
                                                    </div>
                                                    <div className="card-body">
                                                        <ul className="list-unstyled grey-text0 font9 mb-0">
                                                            <li className="mb-2"><b>Everything in Premium</b></li>
                                                            <li className="mb-2">Kadhr logo removal</li>
                                                            <li className="mb-2">Multiple stores and staff accounts</li>
                                                            <li className="mb-2">250 SMS Credits</li>
                                                            <li className="mb-2">Mobile Point Of Sale</li> 
                                                            <li className="mb-2">Custom domain</li> 
                                                            <li className="mb-2">Priority support</li>                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <hr className="hddsktp0"></hr>
                            <h6 className="pageheader-title mb-3">Subscription History </h6>
                            <div>
                                    {transactions.length > 0 ? (
                                        transactions.map((transaction, index) => (
                                        <div key={transaction.id} className="row ord-bg bg-white p-2 pointer hvr bx-shadow">
                                            <div className="w-auto me-3 rownumsn p-0">
                                            <div className="pt-2 pl-2 font8">{index + 1}</div>
                                            </div>
                                            <div className="col-10 pl-0 pr-0">
                                            <div className="row">
                                                <div className="col-7 col-lg-3 ord-sz">{transaction.user_identification}</div>
                                                <div className="col-5 col-lg-3 ord-sz text-primary">{transaction.status}
                                                    {transaction.status === false && (
                                                        <span className="badge rounded-pill text-bg-warning">Processing</span>
                                                    )}
                                                    {transaction.status === true && (
                                                        <span className="badge rounded-pill text-bg-success">Complete</span>
                                                    )}
                                                </div>
                                                <div className="col-7 col-lg-3 ord-sz grey-text">
                                                {transaction.package} - Ksh. {transaction.total_amount}
                                                </div>
                                                <div className="col-5 col-lg-3 ord-sz grey-text">{formatDate0(transaction.created_at)}</div>
                                            </div>
                                            </div>
                                        </div>
                                        ))
                                    ) : (
                                        <p>No transactions found.</p>
                                    )}
                            </div>
                            
                        </div>


                </div>
            </div>

            <div className="modal fade" id="selectPackage" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mt70">
                    <div className="modal-content">
                        <div className="modal-body pb-2">                                  
                            <h6><ToggleRight width={'18px'} className="rwdlogo" /> {PackageName}</h6>
                            <div className="mb-4">
                                <div className="grey-text font9 mb-3">Select your preferred payment plan.</div>
                                <hr/>                               
                                    
                                <div className="row">
                                            <div className="col-6">
                                                <div className="card border-top-secondary btp2 mb-0">
                                                    <div className="card-body">
                                                        <div className="font9"><b>Monthly</b></div>
                                                            <p className="font9">{currency} {parseFloat(PackageAmount).toLocaleString()}</p>
                                                        <div className="metric-value d-inline-block">
                                                            <input
                                                                type="radio"
                                                                value="0"
                                                                checked={selectedPaymentMode === '0'}
                                                                onChange={handleRadioChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div className="col-6">
                                                <div className="card border-top-secondary btp3 mb-0">
                                                    <div className="card-body">
                                                        <div className="font9"><b>Annualy</b></div>
                                                            <p className="font9">{currency} {parseFloat(PackageAnnualAmount).toLocaleString()}</p>
                                                        <div className="metric-value d-inline-block">
                                                            <input
                                                                type="radio"
                                                                value="1"
                                                                checked={selectedPaymentMode === '1'}
                                                                onChange={handleRadioChange}
                                                            />
                                                        </div>
                                                        <div className="metric-label d-inline-block float-right text-success font-weight-bold">
                                                            <span className="ml-1 font8"><b>SAVE 15%</b></span><br/>
                                                            <span className="ml-1 font8"><b>FREE Domain</b></span>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                </div>                                
                                <button type="button"className="col-12 btn btn-primary mt-4" data-bs-toggle="modal" data-bs-target="#selectPackagePay">Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="selectPackagePay" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mt70 mw400">
                    <div className="modal-content">
                        <div className="modal-body pb-2">                                  
                            <h6><ToggleRight width={'18px'} className="rwdlogo" /> {PackageName}</h6>
                            <div className="mb-4">
                                <div className="row ordbgrw">
                                    <div className="col-4 ord-sz1 pt-2"><font className="grey-text">Total</font><br/> 
                                        <span>KES {new Intl.NumberFormat().format(TotalPackageAmount)}</span>
                                    </div>
                                    <div className="col-8 ord-sz1 pt-2"><font className="grey-text">Payment Mode:</font><br/> {selectedPaymentModeName}</div>
                                    <div className="col-8 ord-sz1 pt-2"><font className="grey-text">Valid Till:</font><br/> {ExpDate}</div>
                                </div> 
                                <hr/>     
                                <div className="tab-content pymt pb-2" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                    <>
                                            <div className="mb-3 mt-3">
                                                    <label htmlFor="lnm" className="grey-text font9 mb-0 pb-0">Username</label>
                                                    <input type="text" className="form-control" id="lnm" name='username' onBlur={(e) => validateUsername(e.target.value)} onChange={inputHandler} value={ProfileData.username} />                                                        
                                                    {UsernameError && <small className="text-danger font8">Confirm username</small>}
                                            </div>
                                            <div className="mb-3 mt-3">
                                                    <label htmlFor="lnm" className="grey-text font9 mb-0 pb-0">Email</label>
                                                    <input type="email" className="form-control" id="lnm" name='email' onBlur={(e) => validateEmail(e.target.value)}  onChange={inputHandler} value={ProfileData.email} />                                                        
                                                    {EmailError && <small className="text-danger font8">Invalid email format. Kindly enter a valid email.</small>}
                                            </div>
                                            { UsernameError0 == '1' && EmailError0 == '1' ? <>
                                                    <PaystackButton 
                                                        className='btn col-12 text-light bg-dark mt-3'
                                                        text="Complete"
                                                        currency="KES"
                                                        publicKey={publicKey}
                                                        email={ProfileData.email}
                                                        amount={TotalPackageAmount * 100}
                                                        onSuccess={onSuccess}
                                                        onClose={onClose}
                                                    />                                                    
                                            </> : <>
                                                <button className='btn col-12 text-light bg-dark mt-3' disabled>Complete</button>
                                            </>} 
                                        </>  
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default SellerSubscription;