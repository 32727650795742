import {Link} from 'react-router-dom';
import Sidebar from './sidebar';
import { ArrowLeft, Copy, Download, Edit, Grid, Mail, Phone, Trash} from 'react-feather';
import { useState,useEffect,useRef } from 'react';
import { useParams } from "react-router-dom";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import api from "../api";

function PurchaseOrderBizDocsPdf(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl0 = process.env.REACT_APP_BASE_URL_IMG;
    const { uniqid } = useParams(); // Get uniqid from URL
    const [quotation, setQuotation] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [VendorData, setVendorData] = useState({
        signature: "",
        profile_img:"",
    });
    
    const [showModal, setShowModal] = useState(false);
    const [selectedQuotation, setSelectedQuotation] = useState(null);

    const token = localStorage.getItem("access_token");
    const fetchData = async () => {
        try {
          const response = await api.get(baseUrl+`/vendor-info/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = response.data.vendor;
            setVendorData({
                profile_img_preview: data.profile_img,
                signature_preview: data.signature,
                first_name:data.user.first_name,    
                username:data.user.username,
                email:data.user.email,
                mobile: data.mobile,    
                address: data.address,
                bpin: data.bpin,
                bnum: data.bnum,
                bcat: data.bcat,
                bpymt: data.bpymt,
            });
        } catch (error) {            
            //console.error("Error fetching products:", error.toJSON());
        }
    };
    const fetchQuotation = async () => {
        try {
            const response = await api.get(baseUrl+`/getpurchaseorder/${uniqid}/`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            setQuotation(response.data.quotation);
        } catch (err) {
            //setErrorMsg('Failed to fetch quotation. Please try again.');
        }
    };

    useEffect(() => {
        fetchData();
        fetchQuotation();
    }, []);

    const editQuotation = async () => {
        try {
            const response = await api.get(baseUrl+`/getpurchaseorder/${uniqid}/`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            if (response.status === 200) {
                const { customer, terms, charges, products } = response.data.quotation;

                localStorage.removeItem('purchaseordersCharges');
                localStorage.removeItem('purchaseorderProducts');
                localStorage.removeItem('purchaseorderselectedCustomer');
                localStorage.removeItem('purchaseordersselectedTerms');

                // Populate local storage
                localStorage.setItem('purchaseorderselectedCustomer', JSON.stringify(customer));
                localStorage.setItem('purchaseordersselectedTerms', JSON.stringify(terms));
                localStorage.setItem('purchaseordersCharges', JSON.stringify(charges));
                localStorage.setItem('purchaseorderProducts', JSON.stringify(products));

                window.location.href = '/bizdocs/editpurchaseorder/'+uniqid;
            } else {
                
            }
        } catch (error) {
            
        }
    };

    const duplicate = async () => {
        try {
            const response = await api.get(baseUrl+`/getpurchaseorder/${uniqid}/`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            if (response.status === 200) {
                const { customer, terms, charges, products } = response.data.quotation;

                localStorage.removeItem('purchaseordersCharges');
                localStorage.removeItem('purchaseorderProducts');
                localStorage.removeItem('purchaseorderselectedCustomer');
                localStorage.removeItem('purchaseordersselectedTerms');

                // Populate local storage
                localStorage.setItem('purchaseorderselectedCustomer', JSON.stringify(customer));
                localStorage.setItem('purchaseordersselectedTerms', JSON.stringify(terms));
                localStorage.setItem('purchaseordersCharges', JSON.stringify(charges));
                localStorage.setItem('purchaseorderProducts', JSON.stringify(products));

                window.location.href = '/bizdocs/purchaseorder/';
            } else {
                
            }
        } catch (error) {
            
        }
    };

    const deleteQuotation = async (uniqid) => {    
        try {
            const response = await api.delete(`${baseUrl}/purchaseorder/delete/${uniqid}/`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                alert(response.data.message);
                localStorage.removeItem('purchaseordersCharges');
                localStorage.removeItem('purchaseorderProducts');
                localStorage.removeItem('purchaseorderselectedCustomer');
                localStorage.removeItem('purchaseordersselectedTerms');

                window.location.href = '/bizdocs/purchaseorders';
            } else {
                setErrorMsg("Failed to delete.");
            }
        } catch (error) {
            setErrorMsg("An error occurred while deleting.");
        }
    };
    
    // Usage
    // Pass the uniqid and when the delete button is clicked
    const handleDeleteQuotation = (uniqid) => {
        deleteQuotation(uniqid);
    };    

    const pdfRef = useRef();

    const downloadPDF = async () => {
        setLoadingDownload(true);
        const input = pdfRef.current;

        pdfRef.current.style.display = 'block';

        if (!input) {
            setLoadingDownload(false);
            alert("Unable to generate PDF.");
            return;
        }

        try {
            // Ensure images are preloaded
            const images = input.querySelectorAll("img");
            await Promise.all(
                Array.from(images).map((img) => {
                    return new Promise((resolve, reject) => {
                        if (img.complete) {
                            resolve();
                        } else {
                            img.onload = resolve;
                            img.onerror = reject;
                        }
                    });
                })
            );

            // Generate PDF
            const canvas = await html2canvas(input, {
                scale: 2,
                useCORS: true, // Enable CORS for external images
            });

            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");

            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft > 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save(`PO_${quotation.id}.pdf`);
            pdfRef.current.style.display = 'none';
            setLoadingDownload(false);
        } catch (error) {
            setLoadingDownload(false);
            //console.error("Error generating PDF:", error);
            alert("An error occurred while generating the PDF.");
        }
    };

    if (errorMsg) {
        return <div>{errorMsg}</div>;
    }

    if (!quotation) {
        return <div>Loading...</div>;
    }
    var shw_date="";
    const date = new Date(quotation.created_at);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    shw_date =  `${day}/${month}/${year}`;

    const getVendorCurrency = () => {
        return localStorage.getItem("VendorCurrency");
    };
    return (
        <section className="container mtopcon dashb">           
            <div className="row">                
                    <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max">
                        <div className="row mb-3 ph-header-con">
                            <div className="col-8 pt-2"><h6 className="pageheader-title mb-3">Purchase Order</h6></div>
                            <div className="col-4"><Link to="/bizdocs/quotations"><button className="btn btn-primary f85 float-right"><ArrowLeft/></button></Link></div>
                        </div>
                        {errorMsg && <p className="d-flex justify-content-center text-danger"><b>{errorMsg}</b></p> }  
                        <div className="pdfcon d-flex justify-content-center">
                            <div className="dsktpbtn-pdf-con text-center font9 p-2">
                                <div className={`row`}>
                                        <div className='col-3 p-0 btmnavcon dshbrd' onClick={editQuotation}>
                                            <div className='col-12'><Edit/></div>
                                            <div className='col-12 pt-1'>Edit</div>
                                        </div>
                                        <div className='col-3 p-0 btmnavcon' onClick={downloadPDF}>
                                            <div className='col-12'><Download/></div>
                                            <div className='col-12 pt-1'>{ loadingDownload === true ? <>Loading...</> : <>Download</>}</div>
                                        </div>
                                        <div className='col-3 p-0 btmnavcon' onClick={duplicate}>
                                            <div className='col-12'>
                                                    <Copy/>
                                            </div>
                                            <div className='col-12 pt-1'>Duplicate</div>
                                        </div>
                                        <div className='col-3 p-0 btmnavcon' data-bs-toggle="modal" data-bs-target="#more-btn">
                                            <div className='col-12'><Grid/></div>
                                            <div className='col-12 pt-1'>More</div>
                                        </div>
                                </div>
                            </div>
                            <div className="a4-container a4-container0">
                                <div className="row p-3">

                                    <div className="col-3">
                                        <div className="mt-2 p-0" style={{ height: 80, width: '100%'}}>
                                            {VendorData.profile_img_preview && (
                                                <img
                                                src={baseUrl0+'/'+VendorData.profile_img_preview}
                                                alt="Profile Preview"
                                                style={{ width: "100%", height: "100%" }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-6 text-center">
                                        <ul>
                                            <li className="tc"><b>{VendorData.username}</b></li>
                                            <li>{VendorData.first_name}</li>
                                            <li className="tcone">{VendorData.address}</li>
                                            <li><Phone width="18px" height="18px"/> {VendorData.mobile} <Mail width="18px" height="18px"/> {VendorData.email}</li>
                                            <li>{VendorData.bpin} <b>{VendorData.bnum}</b></li>
                                        </ul>
                                    </div>
                                    <div className="col-3 text-right">
                                        <b className="font2em">
                                            Purchase<br/>Order
                                        </b>
                                    </div>
                                    <hr/>

                                    <div className="col-4">
                                        <ul className="pl-0">
                                            <li><b>BILL TO</b></li>
                                            <li><b>{quotation.customer.name}</b></li>
                                            <li>{quotation.customer.company}</li>
                                            <li><Phone width="15px" height="15px"/> {quotation.customer.mobile}</li>
                                            <li><Mail width="15px" height="15px" /> {quotation.customer.email}</li>
                                        </ul>
                                    </div>
                                    <div className="col-4">
                                    </div>
                                    <div className="col-4 text-right">
                                        <ul>
                                            <li><b className="me-3">PO#</b> PO-{quotation.id}</li>
                                            <li><b className="me-3">Date:</b> {shw_date}</li>
                                        </ul>
                                    </div>

                                    <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table align-middle table-nowrap table-centered mb-0 invcpdf">
                                                        <thead>
                                                            <tr className="bg-grey">
                                                                <th><div className="thmin">#</div></th>
                                                                <th><div className="thmin">DESCRIPTION</div></th>
                                                                <th className="text-end"><div className="thmin">QTY</div></th>
                                                                <th className="text-end"><div className="thmin">PRICE</div></th>
                                                                <th className="text-end"><div className="thmin">TAX</div></th>
                                                                <th className="text-end" ><div className="thmin">TOTAL</div></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                                {quotation.products.map((product, index) => {
                                                                    const totalTax = (product.tax / 100) * product.price * product.qty;
                                                                    const totalPrice = parseFloat(product.price) * parseFloat(product.qty) + totalTax;

                                                                    return (
                                                                        <tr key={product.productId}>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>
                                                                                <b>{product.title}</b><br />
                                                                                {product.desc}
                                                                            </td>
                                                                            <td className="text-end">
                                                                                {product.qty}
                                                                                <br />
                                                                                {product.unit}(s)
                                                                            </td>
                                                                            <td className="text-end">{getVendorCurrency()} {parseFloat(product.price).toLocaleString()}</td>
                                                                            <td className="text-end">
                                                                                {getVendorCurrency()} {totalTax.toFixed(2)}
                                                                                <br />
                                                                                {product.tax}%
                                                                            </td>
                                                                            <td className="text-end">{getVendorCurrency()} {totalPrice.toFixed(2)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                                <tr>
                                                                    <td colSpan="3" className="border-bottom-none"></td>
                                                                    <td className="text-left b1pxgrey" colSpan="2">SUB TOTAL<br/>TAX<br/>
                                                                            {quotation.charges.map((product, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <li key={index}>{product.charge_label}</li>
                                                                                        <span className="grey-text">({product.tax}%)</span>                                                                                    
                                                                                    </>
                                                                                );
                                                                            })}                                                                            
                                                                    </td>
                                                                    <td className="text-end b1pxgrey">
                                                                            {(() => {
                                                                                // Calculate total tax and total price for all products
                                                                                const totals = quotation.products.reduce(
                                                                                    (acc, product) => {
                                                                                        const productTax = (product.tax / 100) * product.price * product.qty;
                                                                                        const productPrice = parseFloat(product.price) * parseFloat(product.qty);

                                                                                        return {
                                                                                            totalTax: acc.totalTax + productTax,
                                                                                            totalPrice: acc.totalPrice + productPrice,
                                                                                        };
                                                                                    },
                                                                                    { totalTax: 0, totalPrice: 0} // Initial values
                                                                                );

                                                                                return (
                                                                                    <>
                                                                                        <>{getVendorCurrency()} {totals.totalPrice.toFixed(2)}<br/> {getVendorCurrency()} {totals.totalTax.toFixed(2)}</>                                                                                        
                                                                                    </>
                                                                                );
                                                                            })()}
                                                                            <br/>
                                                                            {quotation.charges.map((product, index) => {
                                                                                const chargeTax = (product.amount*product.tax)/100;
                                                                                return (
                                                                                    <>
                                                                                        <li>{getVendorCurrency()} {product.amount.toFixed(2)}</li>
                                                                                        <span className="grey-text">{getVendorCurrency()} {chargeTax.toFixed(2)}</span>
                                                                                    </>
                                                                                );
                                                                            })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="3" className="border-bottom-none"></td>
                                                                    <td className="text-left b2pxgrey bg-grey" colSpan="2">GRAND TOTAL</td>
                                                                    <td className="text-end b2pxgrey bg-grey">
                                                                    {(() => {
                                                                        // Calculate total tax, total price for products, and total charges
                                                                        const productTotals = quotation.products.reduce(
                                                                            (acc, product) => {
                                                                                const productTax = (product.tax / 100) * product.price * product.qty;
                                                                                const productPrice = parseFloat(product.price) * parseFloat(product.qty);

                                                                                return {
                                                                                    totalTax: acc.totalTax + productTax,
                                                                                    totalPrice: acc.totalPrice + productPrice,
                                                                                };
                                                                            },
                                                                            { totalTax: 0, totalPrice: 0 } // Initial values
                                                                        );

                                                                        const chargesTotal = quotation.charges.reduce((acc, charge) => {
                                                                            const chargeTax = (charge.amount * charge.tax) / 100;
                                                                            return {
                                                                                totalCharge: acc.totalCharge + charge.amount,
                                                                                totalChargeTax: acc.totalChargeTax + chargeTax,
                                                                            };
                                                                        }, { totalCharge: 0, totalChargeTax: 0 }); // Initial values

                                                                        const overallTotal =
                                                                            productTotals.totalPrice +
                                                                            productTotals.totalTax +
                                                                            chargesTotal.totalCharge +
                                                                            chargesTotal.totalChargeTax;

                                                                        return (
                                                                            <>
                                                                                <>{getVendorCurrency()} {overallTotal.toFixed(2)}</>
                                                                            </>
                                                                        );
                                                                    })()}

                                                                    </td>
                                                                </tr>

                                                        </tbody>
                                                </table>
                                            </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="row">
                                            <div className="col-8">
                                                    <p className="mb-1"><b>Terms & Conditions</b></p>
                                                    <ul className="trmslist">
                                                        {quotation.terms.map((product, index) => {
                                                            return (
                                                                <li>{product.title}</li>
                                                            );
                                                        })}
                                                    </ul>
                                            </div>
                                            <div className="col-4 text-right">
                                                    <p className="mb-1"><b>Payment Instructions</b></p>
                                                    {VendorData.bpymt}
                                            </div>
                                            <div className="col-8"></div>
                                            <div className="col-4 text-right mt-3">
                                                    <p className="mb-3"><b>For, <span className="tc">{VendorData.username}</span></b></p>
                                                        <div className="mb-3 mt-2" style={{ height: 70, width: '100%'}}>
                                                            {VendorData.signature_preview && (
                                                                <img
                                                                src={baseUrl0+'/'+VendorData.signature_preview}
                                                                style={{ width: "100%", height: "100%" }}
                                                                />
                                                            )}
                                                        </div>
                                                    <p>AUTHORIZED SIGNATURE</p>
                                            </div>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>
                            <div className="a4-container5" ref={pdfRef}  style={{display: 'none'}}>
                                <div className="row p-3">

                                    <div className="col-3">
                                        <div className="mt-2 p-0" style={{ height: 80, width: '100%'}}>
                                            {VendorData.profile_img_preview && (
                                                <img
                                                src={baseUrl0+'/'+VendorData.profile_img_preview}
                                                alt="Profile Preview"
                                                style={{ width: "100%", height: "100%" }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-6 text-center">
                                        <ul>
                                            <li className="tc"><b>{VendorData.username}</b></li>
                                            <li>{VendorData.first_name}</li>
                                            <li className="tcone">{VendorData.address}</li>
                                            <li><Phone width="18px" height="18px"/> {VendorData.mobile} <Mail width="18px" height="18px"/> {VendorData.email}</li>
                                            <li>{VendorData.bpin} <b>{VendorData.bnum}</b></li>
                                        </ul>
                                    </div>
                                    <div className="col-3 text-right">
                                        <b className="font2em">
                                            Purchase<br/>Order
                                        </b>
                                    </div>
                                    <hr/>

                                    <div className="col-4">
                                        <ul className="pl-0">
                                            <li><b>BILL TO</b></li>
                                            <li><b>{quotation.customer.name}</b></li>
                                            <li>{quotation.customer.company}</li>
                                            <li><Phone width="15px" height="15px"/> {quotation.customer.mobile}</li>
                                            <li><Mail width="15px" height="15px" /> {quotation.customer.email}</li>
                                        </ul>
                                    </div>
                                    <div className="col-4">
                                    </div>
                                    <div className="col-4 text-right">
                                        <ul>
                                            <li><b className="me-3">PO#</b> PO-{quotation.id}</li>
                                            <li><b className="me-3">Date:</b> {shw_date}</li>
                                        </ul>
                                    </div>

                                    <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table align-middle table-nowrap table-centered mb-0 invcpdf">
                                                        <thead>
                                                            <tr className="bg-grey">
                                                                <th><div className="thmin">#</div></th>
                                                                <th><div className="thmin">DESCRIPTION</div></th>
                                                                <th className="text-end"><div className="thmin">QTY</div></th>
                                                                <th className="text-end"><div className="thmin">PRICE</div></th>
                                                                <th className="text-end"><div className="thmin">TAX</div></th>
                                                                <th className="text-end" ><div className="thmin">TOTAL</div></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                                {quotation.products.map((product, index) => {
                                                                    const totalTax = (product.tax / 100) * product.price * product.qty;
                                                                    const totalPrice = parseFloat(product.price) * parseFloat(product.qty) + totalTax;

                                                                    return (
                                                                        <tr key={product.productId}>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>
                                                                                <b>{product.title}</b><br />
                                                                                {product.desc}
                                                                            </td>
                                                                            <td className="text-end">
                                                                                {product.qty}
                                                                                <br />
                                                                                {product.unit}(s)
                                                                            </td>
                                                                            <td className="text-end">{getVendorCurrency()} {parseFloat(product.price).toLocaleString()}</td>
                                                                            <td className="text-end">
                                                                                {getVendorCurrency()} {totalTax.toFixed(2)}
                                                                                <br />
                                                                                {product.tax}%
                                                                            </td>
                                                                            <td className="text-end">{getVendorCurrency()} {totalPrice.toFixed(2)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                                <tr>
                                                                    <td colSpan="3" className="border-bottom-none"></td>
                                                                    <td className="text-left b1pxgrey" colSpan="2">SUB TOTAL<br/>TAX<br/>
                                                                            {quotation.charges.map((product, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <li key={index}>{product.charge_label}</li>
                                                                                        <span className="grey-text">({product.tax}%)</span>                                                                                    
                                                                                    </>
                                                                                );
                                                                            })}                                                                            
                                                                    </td>
                                                                    <td className="text-end b1pxgrey">
                                                                            {(() => {
                                                                                // Calculate total tax and total price for all products
                                                                                const totals = quotation.products.reduce(
                                                                                    (acc, product) => {
                                                                                        const productTax = (product.tax / 100) * product.price * product.qty;
                                                                                        const productPrice = parseFloat(product.price) * parseFloat(product.qty);

                                                                                        return {
                                                                                            totalTax: acc.totalTax + productTax,
                                                                                            totalPrice: acc.totalPrice + productPrice,
                                                                                        };
                                                                                    },
                                                                                    { totalTax: 0, totalPrice: 0} // Initial values
                                                                                );

                                                                                return (
                                                                                    <>
                                                                                        <>{getVendorCurrency()} {totals.totalPrice.toFixed(2)}<br/> {getVendorCurrency()} {totals.totalTax.toFixed(2)}</>                                                                                        
                                                                                    </>
                                                                                );
                                                                            })()}
                                                                            <br/>
                                                                            {quotation.charges.map((product, index) => {
                                                                                const chargeTax = (product.amount*product.tax)/100;
                                                                                return (
                                                                                    <>
                                                                                        <li>{getVendorCurrency()} {product.amount.toFixed(2)}</li>
                                                                                        <span className="grey-text">{getVendorCurrency()} {chargeTax.toFixed(2)}</span>
                                                                                    </>
                                                                                );
                                                                            })}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="3" className="border-bottom-none"></td>
                                                                    <td className="text-left b2pxgrey bg-grey" colSpan="2">GRAND TOTAL</td>
                                                                    <td className="text-end b2pxgrey bg-grey">
                                                                    {(() => {
                                                                        // Calculate total tax, total price for products, and total charges
                                                                        const productTotals = quotation.products.reduce(
                                                                            (acc, product) => {
                                                                                const productTax = (product.tax / 100) * product.price * product.qty;
                                                                                const productPrice = parseFloat(product.price) * parseFloat(product.qty);

                                                                                return {
                                                                                    totalTax: acc.totalTax + productTax,
                                                                                    totalPrice: acc.totalPrice + productPrice,
                                                                                };
                                                                            },
                                                                            { totalTax: 0, totalPrice: 0 } // Initial values
                                                                        );

                                                                        const chargesTotal = quotation.charges.reduce((acc, charge) => {
                                                                            const chargeTax = (charge.amount * charge.tax) / 100;
                                                                            return {
                                                                                totalCharge: acc.totalCharge + charge.amount,
                                                                                totalChargeTax: acc.totalChargeTax + chargeTax,
                                                                            };
                                                                        }, { totalCharge: 0, totalChargeTax: 0 }); // Initial values

                                                                        const overallTotal =
                                                                            productTotals.totalPrice +
                                                                            productTotals.totalTax +
                                                                            chargesTotal.totalCharge +
                                                                            chargesTotal.totalChargeTax;

                                                                        return (
                                                                            <>
                                                                                <>{getVendorCurrency()} {overallTotal.toFixed(2)}</>
                                                                            </>
                                                                        );
                                                                    })()}

                                                                    </td>
                                                                </tr>

                                                        </tbody>
                                                </table>
                                            </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="row">
                                            <div className="col-8">
                                                    <p className="mb-1"><b>Terms & Conditions</b></p>
                                                    <ul className="trmslist">
                                                        {quotation.terms.map((product, index) => {
                                                            return (
                                                                <li>{product.title}</li>
                                                            );
                                                        })}
                                                    </ul>
                                            </div>
                                            <div className="col-4 text-right">
                                                    <p className="mb-1"><b>Payment Instructions</b></p>
                                                    {VendorData.bpymt}
                                            </div>
                                            <div className="col-8"></div>
                                            <div className="col-4 text-right mt-3">
                                                    <p className="mb-3"><b>For, <span className="tc">{VendorData.username}</span></b></p>
                                                        <div className="mb-3 mt-2" style={{ height: 70, width: '100%'}}>
                                                            {VendorData.signature_preview && (
                                                                <img
                                                                src={baseUrl0+'/'+VendorData.signature_preview}
                                                                style={{ width: "100%", height: "100%" }}
                                                                />
                                                            )}
                                                        </div>
                                                    <p>AUTHORIZED SIGNATURE</p>
                                            </div>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>
                        </div>


                </div>
            </div>

            <div className="modal fade" id="confirmdelModal" tabIndex="-1" aria-labelledby="confirmdelModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header p-2">
                        <h1 className="modal-title fs-5" id="confirmdelModalLabel">Confirm</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        Are you sure you want to delete this?
                    </div>
                    <div className="modal-footer p-2">
                        <button type="button" className="btn btn-danger" onClick={() => handleDeleteQuotation(quotation.uniqid)}>Delete</button>
                    </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="more-btn" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mt70">
                    <div className="modal-content">
                        <div className="modal-body pb-4 text-center">                            
                                <div className={`row`}>
                                        <div className='col-3 p-0 btmnavcon' data-bs-toggle="modal" data-bs-target="#confirmdelModal">
                                            <div className='col-12'><Trash/></div>
                                            <div className='col-12 pt-1 font9'>Delete</div>
                                        </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default PurchaseOrderBizDocsPdf;