import { useState, useEffect } from "react";
import { ArrowLeft } from 'react-feather';
import Sidebar from './sidebar';
import { Link } from "react-router-dom";
import api from "../api";

function BizdocsAddProduct() {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState("");
    const [ProductData, setProductData] = useState({
        "vendor": '',
        "title": '',
        "detail": '',
        "unit": '',
        "price": '',
        "tax": '',
        "hsn": '',
        "currency":'',
    });
    const token = localStorage.getItem("access_token");

    useEffect(() => {
         // Retrieve customer data from localStorage when the component mounts
         const storedCurrency = JSON.parse(localStorage.getItem('selectedCurrency'));
         if (storedCurrency) {
             setSelectedCurrency(storedCurrency);
         }
    }, []);

    const inputHandler = (event) => {
        setProductData({
            ...ProductData,
            [event.target.name]: event.target.value
        });
    };

    const submitHandler = async (event) => {
        event.preventDefault(); // Prevent form from reloading page
        const formData = new FormData();
        formData.append('title', ProductData.title);
        formData.append('detail', ProductData.detail);
        formData.append('unit', ProductData.unit);
        formData.append('price', ProductData.price);
        formData.append('tax', ProductData.tax);
        formData.append('hsn', ProductData.hsn);
        formData.append('currency', selectedCurrency.currency);
    
        try {
            const response = await api.post(`${baseUrl}/bizproproduct/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Correct content type for FormData
                    Authorization: `Bearer ${token}`
                },
            });
            //console.log(response.status)
            if (response.status == '201') {
                setSuccessMsg('Product Added Successfully');
                setErrorMsg('');
                setProductData({
                    title: '',
                    detail: '',
                    unit: '',
                    price: '',
                    tax: '',
                    hsn: '',
                    currency: '',
                });
            } else {
                setErrorMsg('There was a problem adding product. Kindly try again.');
                setSuccessMsg('');
            }
        } catch (error) {
            //console.error(error);
            setErrorMsg('An error occurred while adding product. Please try again.');
            setSuccessMsg('');
        }
    };
    

    const buttonEnable = ProductData.title && ProductData.detail && ProductData.unit && ProductData.price && ProductData.tax

    return (
        <section className="container mtopcon dashb">
            <div className="row">
            <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">
                    
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Add New Product</h6></div>
                            <div className="col-5"><Link to="/bizdocs/products"><button className="btn btn-primary f85 float-right"><ArrowLeft/></button></Link></div>
                        </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-4">
                            <div className="card shadow-sm border border-secondary border-opacity-10">                               
                               
                            <div className="card-body"> 

                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Product Name</label>
                                                <input type="text" onChange={inputHandler} value={ProductData.title} name="title"  className="form-control addprobginpt" id="fnm"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="lnm" className="form-label font9 mb-0">Price</label>
                                                <input type="number" pattern="^\d*\.?\d*$" onChange={inputHandler} value={ProductData.price} name="price" className="form-control addprobginpt" id="lnm"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Unit Of Measure (Kg/g, CM, ml/l etc)</label>
                                                <input type="email" onChange={inputHandler} value={ProductData.unit} name="unit"  className="form-control addprobginpt" id="fnm"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Tax (%)</label>
                                                <input type="text" onChange={inputHandler} value={ProductData.tax} name="tax"  className="form-control addprobginpt" id="fnm"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Description</label>
                                                <textarea type="text" onChange={inputHandler} value={ProductData.detail} name="detail" className="form-control addprobginpt" id="lnm"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">HSN</label>
                                                <input type="text" onChange={inputHandler} value={ProductData.hsn} name="hsn"  className="form-control addprobginpt" id="fnm"/>
                                            </div>
                                        </div>
                                        <button type="button" disabled={!buttonEnable} onClick={submitHandler} className="col-12 btn btn-primary mt-3">Add Product</button>
                                        
                                        {successMsg && <p className="text-success text-center mt-4"><b>{successMsg}</b></p> } 
                                        {errorMsg && <p className="text-danger text-center mt-4"><b>{errorMsg}</b></p> }   

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BizdocsAddProduct;