import {Link} from 'react-router-dom';
import Sidebar from './sidebar';
import { useState,useEffect } from 'react';
import OrderRow from "./orderrows";
import { Calendar, Clipboard, Copy, Heart, Package} from 'react-feather';
import api from "../api0";
function CustomerDashboard(){    
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const customerId = localStorage.getItem('customer_id');
    const [OrderItems,setOrderItems]=useState([]);
    const [copiedIndex, setCopiedIndex] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [CountList,setCountList]=useState({
        'totalWishlist':0,
        'totalOrders':0,
    });
    const currentDate = new Date();
    var UserName=localStorage.getItem('customer_username');
    const token = localStorage.getItem("access_token");
    useEffect(()=>{
        fetchData();
        fetchOrders();
    },[]);
    const fetchData = async () => {
        try {
          const response = await api.get(`${baseUrl}/customer/dashboard/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          if(response.data.bool == true){
            setCountList({
                'totalWishlist':response.data.totalWishlist,
                'totalOrders':response.data.totalOrders,
            });
          }else{localStorage.clear();window.location.href='/customer/login'}
        } catch (error) {
          //console.error("Error fetching products:", err.toJSON());
        }
    };
    const fetchOrders = async () => {
        try {
          const response = await api.get(`${baseUrl}/customer-orderitems/?fetch_limit=7`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          setOrderItems(response.data.results);
        } catch (error) {
          //console.error("Error fetching products:", err.toJSON());
        }
    };
    const formatDate = (date) => {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        const dayName = days[date.getDay()];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
    
        const getOrdinalSuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };
    
        return `${dayName}, ${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
    };
    const handleCopy = (value, index) => {
        const tempInput = document.createElement('input');
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        setCopiedIndex(index);
        setTimeout(() => setCopiedIndex(null), 2000);
    };

    const items = [
        {
          "value": "I have Ksh. [amount] and I want to start a profitable business in Kenya. What are some realistic ideas with high potential?",
          "title": "Business Ideas for Kenya",
          "category": "Entrepreneurship"
        },
        {
          "value": "What are the top habits of successful entrepreneurs, and how can I apply them to my daily routine?",
          "title": "Success Habits of Entrepreneurs",
          "category": "Self-Improvement"
        },
        {
          "value": "I want to improve my financial discipline. Can you give me a step-by-step guide to budgeting and saving money effectively?",
          "title": "Personal Finance 101",
          "category": "Finance"
        },
        {
          "value": "How do I use AI tools to improve my business operations and marketing strategies?",
          "title": "AI for Business Growth",
          "category": "Technology"
        },
        {
          "value": "Can you break down the concept of passive income and give me practical ways to start earning it?",
          "title": "Building Passive Income",
          "category": "Wealth Creation"
        },
        {
          "value": "What are the top digital marketing strategies for small businesses looking to increase their online visibility?",
          "title": "Digital Marketing Guide",
          "category": "Marketing"
        },
        {
          "value": "How can I use social media to build a strong personal brand and attract business opportunities?",
          "title": "Personal Branding on Social Media",
          "category": "Marketing"
        },
        {
          "value": "What are some proven negotiation tactics that can help me close better deals in business?",
          "title": "Mastering Negotiation Skills",
          "category": "Business Development"
        },
        {
          "value": "How can I develop a powerful morning routine that boosts productivity and focus?",
          "title": "Optimizing Your Morning Routine",
          "category": "Self-Improvement"
        },
        {
          "value": "What are the most common psychological tricks used in sales and marketing, and how can I use them ethically?",
          "title": "Psychology of Sales & Marketing",
          "category": "Marketing"
        },
        {
          "value": "I want to learn how to invest my money wisely. Can you explain different investment options and their risks?",
          "title": "Investment Strategies",
          "category": "Finance"
        },
        {
          "value": "How can I become more productive without feeling overwhelmed or burned out?",
          "title": "Productivity Hacks",
          "category": "Self-Improvement"
        },
        {
          "value": "What are the best side hustles I can start while working a full-time job?",
          "title": "Side Hustle Ideas",
          "category": "Entrepreneurship"
        },
        {
          "value": "How can I improve my public speaking skills and become a more confident communicator?",
          "title": "Mastering Public Speaking",
          "category": "Personal Development"
        },
        {
          "value": "Can you give me a simple breakdown of cryptocurrency, blockchain, and how I can safely invest?",
          "title": "Crypto & Blockchain Explained",
          "category": "Finance & Technology"
        },
        {
          "value": "What are the top mistakes new entrepreneurs make, and how can I avoid them?",
          "title": "Entrepreneurship Mistakes to Avoid",
          "category": "Business Development"
        },
        {
          "value": "How do I create an online store and start selling products with little to no capital?",
          "title": "Starting an E-commerce Business",
          "category": "E-commerce"
        },
        {
          "value": "What are some powerful networking strategies that can help me connect with influential people in my industry?",
          "title": "Networking Like a Pro",
          "category": "Business & Career"
        },
        {
          "value": "How can I develop a millionaire mindset and start thinking like a successful entrepreneur?",
          "title": "Developing a Wealth Mindset",
          "category": "Mindset & Success"
        },
        {
          "value": "What are some practical ways to turn my passion into a profitable business?",
          "title": "Monetizing Your Passion",
          "category": "Entrepreneurship"
        }
    ];
      
      const filteredItems = selectedCategory === 'All' ? items : items.filter(item => item.category === selectedCategory);
      const [copySuccess, setCopySuccess] = useState(false);

    return (
        <section className="container mtopcon dashb">
            <div className="row">                
                <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div> 

                <div className="col-12 col-lg-10 dash-max">
                    <div className="row dash-top-row hdmobile">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 hdmobile">
                            <div className="row dash-top-row mb-4">
                                <ul className="h-100 p-0">
                                    <li className='float-right h-100 me-3 topdate'><Calendar/><p className='float-right'> {formatDate(currentDate)}</p></li>
                                </ul>                                
                                
                            </div>
                        </div>

                    </div>
                    <div className="row dashhome-rw">
                        <div className='col-lg-4 col-12'>
                            <div className="row containerzz mb-2">
                                <h3>Welcome Back, <span className='tcone'>{UserName}</span></h3>
                                <p className='font9'>Let's make your shopping effortless today.</p>
                                <div className="grid">
                                        <Link to="/customers/orders" className=''>
                                            <div className="card">
                                                <div className='row w-100'>
                                                    <div className='col-3 text-center text-white p-0'><Package className='navstaticon'/></div>
                                                    <div className='col-9 pr-0 plpt'><h4 className="text font90x mb-0 text-white">Orders</h4></div>
                                                    <div className='col-3 text-right text-white p-0'></div>
                                                    <div className='col-9'><h2 className="mb-1 text-white mb-0">{CountList.totalOrders}</h2></div>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/customer/wishlist" className=''>
                                            <div className="card">
                                                <div className='row w-100'>
                                                    <div className='col-3 text-center text-white p-0'><Heart className='navstaticon'/></div>
                                                    <div className='col-9 pr-0 plpt'><h4 className="text font90x mb-0 text-white">Wishlist</h4></div>
                                                    <div className='col-3 text-right text-white p-0'></div>
                                                    <div className='col-9'><h2 className="mb-1 text-white mb-0">{CountList.totalWishlist}</h2></div>
                                                </div>
                                            </div>
                                        </Link>
                                </div>
                            </div>
                            
                                <div className="col-12 mt-3">
                                    <div className="card dsktp-ord">
                                        <div className="card-header bg-default">
                                            <div className='row p-0 m-0'>
                                                <p className='pl-3 m-0 col-7 ordhdr'>Latest Orders </p>
                                                <div className='col-5 text-right'><Link to="/customer/orders" className='fgreen font9'><button className='btn btn-dark p-1 w-auto'><Clipboard/> View all</button></Link></div>
                                            </div>
                                        </div>  
                                        <div className="card-body bg-default">
                                                {
                                                    OrderItems.map((item,index)=>{ 
                                                        return <OrderRow item={item} key={index} index={index} />
                                                    })
                                                }
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className='col-lg-8 col-12'>
                            <div className='row'>
                                <h6 className='mb-3'>Cool AI Prompts to test out</h6>
                                <div className="mb-3">
                                        <select className="form-select" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                                        <option value="All">All</option>
                                        {[...new Set(items.map(item => item.category))].map(category => (
                                            <option key={category} value={category}>{category}</option>
                                        ))}
                                        </select>
                                    </div>
                                        {filteredItems.map((item, index) => (
                                        <div className='col-12 col-lg-4' key={index}>
                                            <div className='card'>
                                                <p className='p-3 pb-0 font9 mh100 text-grey'>{item.value}</p>
                                                <div className='card-footer pt-0 pb-0 pr-0'>
                                                    <div className='row'>
                                                    <div className='col-8 font9'><p className='mt-2'>{item.title}</p></div>
                                                    <div className='col-4 text-right'>
                                                        {copiedIndex === index ? (
                                                        <button className='btn text-green'><b>Copied</b></button>
                                                        ) : (
                                                        <button className='btn btn-default' onClick={() => handleCopy(item.value, index)}><Copy /></button>
                                                        )}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </section>
    )
}

export default CustomerDashboard;