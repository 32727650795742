import axios from 'axios';
import logo from '../logo.svg';
import logo2 from '../logo2.png';
import sellerlogo from '../user.jpg';
import userlogo from '../user.jpg';
import userlong from '../userlong.png';
import {Link} from 'react-router-dom';
import { ShoppingCart, Home, Grid, User, X, HelpCircle, Power, Heart, CreditCard, LogIn, Monitor, Youtube, PhoneCall, Briefcase, Shield, Phone, Share2, Gift, Activity, Sliders, Smartphone, Users, AlignLeft, FileText} from 'react-feather';
import { useContext, useState, useEffect } from 'react';
import { UserContext, CartContext, VendorContext } from '../context';
import { useLocation } from 'react-router-dom';
import Sidebar from './seller/sidebar';
import Sidebar0 from './customer/sidebar';
import Country from './country';
import Sidebar_bizdocs from './bizdocs/sidebar';
import { ExchangeRateContext } from '../ExchangeRateContext';
import api from "./api";
function Header(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl0 = process.env.REACT_APP_BASE_URL_IMG;
    const { exchangeRates, baseCurrency } = useContext(ExchangeRateContext);
    const userContext=useContext(UserContext);
    const vendorContext=useContext(VendorContext);  
    const {cartData,setCartData}=useContext(CartContext);    
    const [categories,setCategories]=useState([]);
    const [showSideCategories, setShowSideCategories] = useState(false);
    const [showSideCategories_dark, setShowSideCategories_dark] = useState(false);
    const [loadingCat, setLoadingCat] = useState(false);
    const location = useLocation();
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [VendorData, setVendorData] = useState(() => {
        const savedData = localStorage.getItem('VendorData');
        return savedData ? JSON.parse(savedData) : {
            'totalProducts': 0,
            'totalOrders': 0,
            'totalCustomers': 0,
            'name': '',
            'loc': '',
            'bal': '',
            'totalTraffic': '',
            'payMode': '',
            'payMode1': '',
            'payMode2': '',
        };
    });

    var UserName=localStorage.getItem('customer_username');
    var VendorName=localStorage.getItem('vendor_username');

    if(cartData == null){
        var cartItems=0;
    }else{
        var cartItems=cartData.length;
    }
    
    const isCustPage = location.pathname.includes('/seller/' && !['/seller/login', '/seller/register']);  

    const hideLogo = [
        //'/customer/' ,
        '/categories' ,
        '/seller/' ,
        '/seller/login' ,
        '/seller/register' ,
        //'/customer/login' ,
        //'/customer/register',
    ];

    const isLogoPage = hideLogo.some(
        path =>
            path === location.pathname 
    );
    
    const fetchData0 = async () => {
        try {
          const response = await api.get(`${baseUrl}/vendor-dashboard/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          setVendorData(response.data);
        } catch (err) {
          //console.error("Error fetching products:", err.toJSON());
        }
    };

     // Update localStorage whenever VendorData changes
     useEffect(() => {
        localStorage.setItem('VendorData', JSON.stringify(VendorData));
    }, [VendorData]);

    useEffect(() => {    
         
        if(isCustPage && localStorage.getItem("access_token") != null){
            fetchData0();
        }
        fetchData(baseUrl+'/categories/');
    }, []);
    
    useEffect(() => {
        const fetchSuggestions = async () => {
          if (query.trim().length > 0) {
            const activeVendorName = localStorage.getItem('active_vendor_name');
    
            try {
              const response = await axios.get(
                `${baseUrl}/search/`,
                {
                    params: {
                      q: query,
                      vendor: activeVendorName || '',
                    },
                    headers: {
                      "Content-Type": "application/json",
                    },
                }
              );
              setSuggestions(response.data.products);
            } catch (error) {
              //console.error('Error fetching suggestions:', error);
              setSuggestions([]);
            }
          } else {
            setSuggestions([]);
          }
        };
    
        // Debounce API call (wait 300ms after typing stops)
        const timer = setTimeout(() => fetchSuggestions(), 300);
    
        // Cleanup on unmount or when query changes
        return () => clearTimeout(timer);
      }, [query]);
    
    useEffect(() => {
        const fetchCategories = async () => {
            setLoadingCat(true);
            const storedData = localStorage.getItem('categoriesData');
            const lastUpdated = localStorage.getItem('categoriesLastUpdated');

            // Check if data is already stored and it's less than 24 hours old
            if (storedData && lastUpdated) {
                const now = new Date().getTime();
                if (now - lastUpdated < 24 * 60 * 60 * 1000) {
                    setCategories(JSON.parse(storedData));
                    setLoadingCat(false);
                    return; // Use stored data without making a new fetch
                }
            }

            // Fetch new data if 24 hours have passed or data is not available
            try {
                const response = await api.get(baseUrl+'/categories', {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                  });
                const data = response.data.results;

                // Save data and timestamp to localStorage
                localStorage.setItem('categoriesData', JSON.stringify(data));
                localStorage.setItem('categoriesLastUpdated', new Date().getTime());

                setLoadingCat(false);
                setCategories(data);
            } catch (error) {
                setLoadingCat(false);
                //console.error('Error fetching categories:', error);
            }
        };

        
        if(isCustPage && localStorage.getItem("access_token") != null){
            fetchCategories();
        }
        pagereload();
    }, [location]);

    function pagereload(){
        const reloadCount = sessionStorage.getItem('reloadCount');
        if(reloadCount < 1) {
          sessionStorage.setItem('reloadCount', String(reloadCount + 1));
          window.location.reload();
        } else {
          sessionStorage.removeItem('reloadCount');
        }
      }

    function fetchData(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setCategories(data.results);
        });
    }
    function close(){
        setShowSideCategories(!showSideCategories);
        setShowSideCategories_dark(!showSideCategories_dark);
    };
    function open(){
        setShowSideCategories(!showSideCategories);
        setShowSideCategories_dark(!showSideCategories_dark);
    };
    var hideClassA = '';var hideClassB = '';
    if(vendorContext == 'true' || userContext == 'true') {
        hideClassA="hidenav0";
        hideClassB="";
    } else {
        hideClassA="";
        hideClassB="hidenav0";
    }
    var btnmnavvar="";
    const isHomePage = location.pathname === '/' || location.pathname === '';
    const isBizDocsPage = location.pathname.includes('bizdocs');   
    const isBizDocsPage0 = location.pathname.includes('/get');  
    if (isBizDocsPage === true) {btnmnavvar="bizdocs";}    
    if (isBizDocsPage0 === true) {btnmnavvar="getquotation";}
    var strnm = localStorage.getItem('active_vendor_name');
    var strid = localStorage.getItem('active_vendor');
    var homeroute = `categories`;
    if(strnm !== '' || strnm !== null){homeroute = `${strnm}`;}
    var homepage='bg-white border-nav';
    if(isHomePage){homepage="bg-blue border-0";}

    function newprice(price,currency){
        // Convert price from the product's original currency to the selected base currency
        const productExchangeRate = exchangeRates[currency] || 1;
        const baseExchangeRate = exchangeRates[baseCurrency] || 1;

        // Calculate the converted price
        const convertedPrice = ((price / productExchangeRate) * baseExchangeRate).toFixed(2);

        return convertedPrice;
    }

    return (
        <nav className={`navbar ${homepage} fixed-top navbar-expand-lg nav-con`}>
        {isHomePage ? (
            <>
                <div className="container">
                    <Link className="nav-link logo me-4" aria-current="page" to="/"><img src={logo2} className="card-img-top" alt="..."/></Link> 
                    <button className="navbar-toggler border-0 text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <AlignLeft className="text-white"/>
                    </button>
                    <div className="collapse navbar-collapse w-100" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown features">
                                <a className="nav-link dropdown-toggle bgbtna" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <b>Features</b>
                                </a>
                                <ul className="dropdown-menu navdropdowndiv p-4 navftrs" aria-labelledby="navbarDropdown">
                                    <div className='row'>
                                        <div className='col-12 col-lg-4 br-10'><a className="text-dark drktxt p-0" href="#payment-processing">
                                            <div className="row navftrspd">
                                                <div className="col-auto p-0" ><CreditCard/></div>
                                                <div className="col-10 text-left">
                                                    <h5 className='font9'><b>Finance Automation Tools</b></h5>
                                                <p className="font8 grey-text">Accept payments, transfer funds, and grow capital with Kadhr’s Finance Automation Tools.</p></div>
                                            </div>
                                        </a></div>
                                        <div className='col-12 col-lg-4 br-10'><a className="text-dark drktxt p-0" href="#loyalty">
                                            <div className="row navftrspd">
                                                <div className="col-auto p-0" ><Users/></div>
                                                <div className="col-10 text-left">
                                                    <h5 className='font9'><b>Loyalty Program</b></h5>
                                                <p className="font8 grey-text">Boost customer retention with our easy-to-use loyalty program.</p></div>
                                            </div>
                                        </a></div>
                                        <div className='col-12 col-lg-4 br-10'><a className="text-dark drktxt p-0" href="#analytics">
                                            <div className="row navftrspd">
                                                <div className="col-auto p-0" ><Activity/></div>
                                                <div className="col-10 text-left">
                                                    <h5 className='font9'><b>Actionable Analytics</b></h5>
                                                <p className="font8 grey-text">Track performance and sales trends with our powerful analytics.</p></div>
                                            </div>
                                        </a></div>
                                        <div className='col-12 col-lg-4 br-10'><a className="text-dark drktxt p-0" href="#order-management">
                                            <div className="row navftrspd">
                                                <div className="col-auto p-0" ><Sliders/></div>
                                                <div className="col-10 text-left">
                                                    <h5 className='font9'><b>Stock & Order Management</b></h5>
                                                <p className="font8 grey-text">Manage stock and orders effortlessly.</p></div>
                                            </div>
                                        </a></div>
                                        <div className='col-12 col-lg-4 br-10'><a className="text-dark drktxt p-0" href="#bizdocs">
                                            <div className="row navftrspd">
                                                <div className="col-auto p-0" ><FileText/></div>
                                                <div className="col-10 text-left">
                                                    <h5 className='font9'><b>BizDocs</b></h5>   
                                                    <p className="font8 grey-text">Generate invoices, quotes, receipts, and more—fast, easy, and professional.</p></div>
                                            </div>
                                        </a></div>
                                        <div className='col-12 col-lg-4 br-10'><a className="text-dark drktxt p-0" href="#online-store">
                                            <div className="row navftrspd">
                                                <div className="col-auto p-0" ><Smartphone/></div>
                                                <div className="col-10 text-left">
                                                    <h5 className='font9'><b>Online Store</b></h5>
                                                <p className="font8 grey-text">Launch your online store quickly and start receiving orders with ease.</p></div>
                                            </div>
                                        </a></div>
                                        <div className='col-12 col-lg-4 br-10'><a className="text-dark drktxt p-0" href="#pos">
                                            <div className="row navftrspd">
                                                <div className="col-auto p-0" ><Monitor/></div>
                                                <div className="col-10 text-left">
                                                    <h5 className='font9'><b>Point Of Sale App</b></h5>
                                                <p className="font8 grey-text">Manage in-store and online sales seamlessly using our simple POS App.</p></div>
                                            </div>
                                        </a></div>
                                    </div>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white0 bgbtna" href="#pricing"><b>Pricing</b></a>
                            </li>
                        </ul>
                        <form className="d-flex">
                            <Link to="/seller/login" className="text-white0"><button className="btn border-none me-2 text-white0" type="submit"><b>Log in</b></button></Link>
                            <Link to="/seller/register" className="text-white"><button className="btn btn-dark" type="submit">Sign up for free</button></Link>
                        </form>
                    </div>
                </div>
            </>
        ) : (
            <>
                <div className={`container ${hideClassA}`}>                       
                        {!isLogoPage && strnm !==null && <><Link className="nav-link logo1 pt-2" aria-current="page" to={homeroute}><span className="txtlogo font9">{strnm}</span></Link></>}
                        {!isLogoPage && strnm == null && <><Link className="nav-link logo" aria-current="page" to={homeroute}><img src={logo} className="card-img-top" alt="..."/></Link></>}
                        {isLogoPage && <><Link className="nav-link logo" aria-current="page" to={homeroute}><img src={logo} className="card-img-top" alt="..."/></Link></>}                  
                       
                    {btnmnavvar !== 'bizdocs' && 
                        <>
                            <input
                                type="search"
                                className="form-control me-2 mbl-srch"
                                placeholder="Search for products"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                aria-label="Search"
                            />
                            {/* Suggestions Dropdown */}
                            {suggestions.length > 0 && (
                                <ul className="suggestions-list hddsktp0">
                                {suggestions.map((product) => (
                                    <li key={product.id}>
                                    <a href={`/product/${product.title}/${product.id}`} target='_blank'>
                                        <img src={baseUrl0+product.image} alt={product.title} className="suggestion-image" />
                                        <div className="suggestion-details">
                                        <span className="suggestion-title">{product.title}</span>
                                        <span className="suggestion-price">                                                                                          
                                            <div className='row'>
                                                <div className='col-7'>{product.category}</div>
                                                <div className='col-5 p-0'>{baseCurrency} {newprice(product.price,product.currency)}</div>
                                            </div>
                                        </span>
                                        </div>
                                    </a>
                                    </li>
                                ))}
                                </ul>
                            )}
                        </>
                    }  
                    {btnmnavvar == 'bizdocs' && 
                        <><h6 className="pageheader-title m-0"><b>BizDocsPro</b></h6></>
                    }               

                    <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                        <ul className="navbar-nav ms-auto mb-4 mb-lg-0">
                            <form className="d-flex" role="search">
                                <input
                                    type="search"
                                    className="form-control me-2"
                                    placeholder="Search for products"
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                    aria-label="Search"
                                />
                                {/* Suggestions Dropdown */}
                                {suggestions.length > 0 && (
                                    <ul className="suggestions-list">
                                    {suggestions.map((product) => (
                                        <li key={product.id}>
                                        <a href={`/product/${product.title}/${product.id}`} target='_blank'>
                                            <img src={baseUrl0+product.image} alt={product.title} className="suggestion-image" />
                                            <div className="suggestion-details">
                                            <span className="suggestion-title">{product.title}</span>
                                            <span className="suggestion-price">                                                
                                                <div className='row'>
                                                    <div className='col-7'>{product.category}</div>
                                                    <div className='col-5 p-0'>{baseCurrency} {newprice(product.price,product.currency)}</div>
                                                </div>
                                            </span>
                                            </div>
                                        </a>
                                        </li>
                                    ))}
                                    </ul>
                                )}
                            </form>        
                            <li className="nav-item dropdown">
                                <a className="nav-link pointer" data-bs-toggle="modal" data-bs-target="#exampleModal" aria-expanded="false">Account</a>
                            </li>
                            <li className="nav-item">
                                <Link to="/checkout"><button type="button" className="btn position-relative">
                                    <ShoppingCart/>
                                    <span className="position-absolute top-0 mt-2 start-100 translate-middle badge rounded-pill bg-danger">        
                                        {cartItems }  
                                    </span>
                                </button></Link>
                            </li> 
                            <li className="nav-item pointer ms-2" onClick={open}>
                                <span className="nav-link"><Grid/></span>
                            </li>  
                        </ul>
                    </div>
                </div>

                {userContext == 'true' &&           
                
                    <div className={'container dashnav'}>
                        <Link className="nav-link logo" aria-current="page" to={homeroute}><img src={logo} className="card-img-top" alt="..."/></Link>       
                        {btnmnavvar !== 'bizdocs' && 
                            <><input className="form-control me-2 mbl-srch" type="search" placeholder="Search for products2" aria-label="Search"/></>
                        }  
                        {btnmnavvar == 'bizdocs' && 
                            <><h6 className="pageheader-title m-0"><b>BizDocsPro</b></h6></>
                        }

                        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                            <ul className="navbar-nav ms-auto mb-4 mb-lg-0">
                                <li className="nav-item dropdown dropdown-toggle pointer topnavusricon" data-bs-toggle="modal" data-bs-target="#exampleModal" aria-expanded="false">
                                    <img src={userlong} width='30' className='br-10 me-1'/>  <span className='tcone font8'>{UserName}</span>
                                </li>                                
                            </ul>
                        </div>
                    </div>
                }

                {vendorContext == 'true' &&           
                    
                    <div className={'container dashnav'}>
                            
                        {btnmnavvar !== 'bizdocs' && 
                            <>
                                <Link className="nav-link logo" aria-current="page" to="/seller/dashboard"><img src={logo} className="card-img-top" alt="..."/></Link>
                                <ul className="navbar-nav hddsktp0">
                                    <li className="nav-item dropdown dropdown-toggle pointer topnavusricon prfnamenav" data-bs-toggle="modal" data-bs-target="#exampleModal" aria-expanded="false">
                                        <img src={sellerlogo} width='28' className='br-10 me-1'/>
                                    </li>                                
                                </ul>
                            </>
                        }  
                        {btnmnavvar == 'bizdocs' && 
                            <>
                                <Link className="nav-link logo" aria-current="page" to="/bizdocs/dashboard"><img src={logo} className="card-img-top" alt="..."/></Link>
                                <h6 className="pageheader-title m-0 ms-3"><b>BizDocsPro</b></h6>
                            </>
                        }

                        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                            <ul className="navbar-nav ms-auto mb-4 mb-lg-0">
                                <li className="nav-item dropdown dropdown-toggle pointer topnavusricon" data-bs-toggle="modal" data-bs-target="#exampleModal" aria-expanded="false">
                                    <img src={sellerlogo} width='30' className='br-10 me-1'/> <span className='tcone'>{VendorName}</span>
                                </li>                                
                            </ul>
                        </div>
                    </div>
                }   

                
                {btnmnavvar !== 'bizdocs' &&
                    <>
                        <div className={`row btm-nav`}>
                                <div className='col-3 p-0 btmnavcon dshbrd'><Link to={homeroute}>
                                    <div className='col-12'><Home/></div>
                                    <div className='col-12 pt-1'>Home</div></Link>
                                </div>
                                <div className='col-3 p-0 btmnavcon ' data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <div className='col-12'><User/></div>
                                    <div className='col-12 pt-1'>Account</div>
                                </div>
                                <div className='col-3 p-0 btmnavcon shpngbg'><Link to="/checkout">
                                    <div className='col-12'>
                                            <ShoppingCart/>
                                            <span className="position-absolute top-0 mt-1 badge rounded-pill orange">        
                                                {cartItems }  
                                            </span>
                                    </div>
                                    <div className='col-12 pt-1'>Cart</div></Link>
                                </div>
                                <div className='col-3 p-0 btmnavcon ' onClick={open}>
                                    <div className='col-12'><Grid/></div>
                                    <div className='col-12 pt-1'>Browse</div>
                                </div>
                        </div>
                        {vendorContext == 'true' &&             
                        
                            <div className={`row btm-nav`}>
                                    <div className='col-3 p-0 btmnavcon dshbrd'><Link to="/seller/dashboard">
                                        <div className='col-12'><Home/></div>
                                        <div className='col-12 pt-1'>Home</div></Link>
                                    </div>
                                    <div className='col-3 p-0 btmnavcon' data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        <div className='col-12'><User/></div>
                                        <div className='col-12 pt-1'>Account</div>
                                    </div>
                                    <div className='col-3 p-0 btmnavcon ordrs'><Link to="/seller/orders">
                                        <div className='col-12'>
                                                <Gift/>
                                                <span className="position-absolute top-0 mt-1 badge rounded-pill orange">        
                                                    {VendorData.totalOrders}
                                                </span>
                                        </div>
                                        <div className='col-12 pt-1'>Orders</div></Link>
                                    </div>
                                    <div className='col-3 p-0 btmnavcon' onClick={open}>
                                        <div className='col-12'><Grid/></div>
                                        <div className='col-12 pt-1'>Browse</div>
                                    </div>
                            </div>
                        }
                    </>
                }
                {btnmnavvar === 'bizdocs' &&
                    <>
                        <div className={`row btm-nav`}>
                                <div className='col-3 p-0 btmnavcon dshbrd'><Link to="/bizdocs/dashboard">
                                    <div className='col-12'><Home/></div>
                                    <div className='col-12 pt-1'>Home</div></Link>
                                </div>
                                <div className='col-3 p-0 btmnavcon'><Link to="/bizdocs/profile">
                                    <div className='col-12'><Briefcase/></div>
                                    <div className='col-12 pt-1'>Business</div></Link>
                                </div>
                                <div className='col-3 p-0 btmnavcon'><Link to="https://wa.me/254794072134">
                                    <div className='col-12'><PhoneCall/></div>
                                    <div className='col-12 pt-1'>Contact Us</div></Link>
                                </div>
                                <div className='col-3 p-0 btmnavcon' onClick={open}>
                                    <div className='col-12'><Grid/></div>
                                    <div className='col-12 pt-1'>Browse</div>
                                </div>
                        </div>
                    </>
                }
                {btnmnavvar === 'getquotation' &&
                    <>
                        <div className={`row btm-nav`}>
                                
                        </div>
                    </>
                }
                

                {showSideCategories_dark && <div className='cover' onClick={close}></div>}
                {showSideCategories && <div className='sidebar-cat'>
                    <div className="col-12 cat_div"> 
                        <h3 className="mb-0">
                            <div className="row pt-2 pb-2 font-bold sidebar-header text-center"><div className="col-10"><span className={`${hideClassB}`}>Manage Shop</span><span className={`${hideClassA} pt-1`}>Sell Anything</span> </div><div className="col-2"  onClick={close}><X/></div></div> 
                        </h3>       
                        <div className={`con-responsive sidebarcon ${hideClassA}`}> 
                            { loadingCat === true ? <>Loading...</> : <>
                                {categories.map((category)=>{
                                    return (
                                            <Link to={`/`}><div className="row border-0"> 
                                                <div className="col-12 catdivside p-0"><img src={category.cat_img} className="w-100 h-100" alt="..."/></div> 
                                            </div></Link> 
                                        
                                    )
                                    
                                })}
                            </>}      
                        </div>    
                        <div className={`con-responsive sidebarcon ${hideClassB}`}>                       
                            <div className='mt-3'></div>
                            
                            {userContext === 'true' ? (
                                <Sidebar0 />
                            ) : vendorContext === true ? (
                                <Sidebar />
                            ) : btnmnavvar !== 'bizdocs' ? (
                                <Sidebar />
                            ) : (
                                <Sidebar_bizdocs />
                            )}

                            
                        </div>
                    </div>
                </div>}

                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog mt70 wusrmodal">
                        <div className="modal-content">
                            <div className="modal-body pb-4">  
                                <ul className='p-0'>
                                {btnmnavvar !== 'bizdocs' &&
                                    <>
                                                {userContext != 'true' && vendorContext != 'true' &&
                                                        <>
                                                                <Link to="/customer/register" className="text-dark"><li className='p-2 hvr pointer'><User className='me-2'/> Sign up</li></Link>
                                                                <Link to="/customer/login" className="text-dark"><li className='p-2 hvr pointer'><LogIn className='me-2'/> Log in</li></Link>
                                                                <hr/>
                                                                <Link to="https://www.youtube.com/@kadhrafrica" target='_blank' className="text-dark"><li className='p-2 hvr pointer'><Youtube className='me-2'/> Guide & Tutorials</li></Link>
                                                                <Link to="/help-center" className="text-dark"><li className='p-2 hvr pointer'><HelpCircle className='me-2'/> Help Center</li></Link>
                                                                <Link to="https://www.kadhr.com/" target='_blank' className="text-dark"><li className='p-2 hvr pointer'><CreditCard className='me-2'/> Start Selling</li> </Link> 
                                                                    
                                                        </>
                                                    }

                                                    {userContext == 'true' &&
                                                        <>           
                                                            <li>
                                                                <div className="row bg-white p-2 pointer" >
                                                                    <div className="col-1 col-lg-1 p-0"><div className="ord-img"><img src={userlogo} className='w-100 h-100'/></div></div>
                                                                    <div className="col-8 p-0 p-topnavcon">
                                                                        <p className='m-0 p-topnav'>{UserName}</p>
                                                                        <span className="badge rounded-pill text-bg-success">Online</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <hr/>
                                                            <Link to="/customer/dashboard" className="text-dark"><li className='p-2 hvr pointer'><Monitor className='me-2'/> Dashboard</li></Link>
                                                            <Link to="/customer/wishlist" className="text-dark"><li className='p-2 hvr pointer'><Heart className='me-2'/> Wishlist</li></Link>
                                                            <Link to="https://www.kadhr.com/" target='_blank' className="text-dark"><li className='p-2 hvr pointer'><CreditCard className='me-2'/> Start Selling</li> </Link> 
                                                            <Link to="https://www.youtube.com/@kadhrafrica" target='_blank' className="text-dark"><li className='p-2 hvr pointer'><Youtube className='me-2'/> Guide & Tutorials</li></Link>
                                                            <hr/>
                                                            <Link to="/help-center" className="text-dark"><li className='p-2 hvr pointer'><HelpCircle className='me-2'/> Help Center</li></Link>
                                                            <Link to="/customer/logout" className="text-dark"><li className='p-2 hvr pointer'><Power className='me-2'/> Logout</li></Link>
                                                        </>
                                                    }

                                                    {vendorContext == 'true' &&
                                                        <> 
                                                            <li>
                                                                <div className="row bg-white p-2 pointer" >
                                                                    <div className="col-1 col-lg-1 p-0"><div className="ord-img"><img src={sellerlogo} className='w-100 h-100'/></div></div>
                                                                    <div className="col-8 p-0 p-topnavcon">
                                                                        <p className='m-0 p-topnav'>{VendorName}</p>
                                                                        <span className="badge rounded-pill text-bg-success">Online</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <hr/>
                                                            <Link to="/seller/dashboard" className="text-dark"><li className='p-2 hvr pointer'><Monitor className='me-2'/> Dashboard</li></Link>    
                                                            <Link to="/seller/profile" className="text-dark"><li className='p-2 hvr pointer'><User className='me-2'/> Profile</li></Link>        
                                                            <Link to="https://wa.me/254794072134" className="text-dark"><li className='p-2 hvr pointer'><Phone className='me-2'/> Contact Us</li></Link>                                                                                                               
                                                            <hr/>
                                                            <Link to="https://www.youtube.com/@kadhrafrica" target='_blank' className="text-dark"><li className='p-2 hvr pointer'><Youtube className='me-2'/> Guide & Tutorials</li></Link>
                                                            <Link to="/seller/help-center" className="text-dark"><li className='p-2 hvr pointer'><HelpCircle className='me-2'/> Help Center</li></Link>
                                                            <Link to="/seller/logout" className="text-dark"><li className='p-2 hvr pointer'><Power className='me-2'/> Logout</li></Link>
                                                        </>
                                                    }
                                    </>
                                }
                                {btnmnavvar == 'bizdocs' &&
                                    <>
                                            <Link to="/bizdocs/dashboard" className="text-dark"><li className='p-2 hvr pointer'><Monitor className='me-2'/> Home</li></Link>
                                            <Link to="/bizdocs/profile" className="text-dark"><li className='p-2 hvr pointer'><Briefcase className='me-2'/> Business Profile</li></Link>
                                            <Link to="/bizdocs/terms" className="text-dark"><li className='p-2 hvr pointer'><Shield className='me-2'/> Terms</li></Link>
                                            <Link to="https://wa.me/254794072134" className="text-dark"><li className='p-2 hvr pointer'><Phone className='me-2'/> Contact Us</li></Link>
                                            <Link to="#" className="text-dark"><li className='p-2 hvr pointer'><Share2 className='me-2'/> Share</li></Link>
                                            <hr/>
                                            <Link to="https://www.youtube.com/@kadhrafrica" target='_blank' className="text-dark"><li className='p-2 hvr pointer'><Youtube className='me-2'/> Guide & Tutorials</li></Link>
                                            <Link to="/help-center" className="text-dark"><li className='p-2 hvr pointer'><HelpCircle className='me-2'/> Help Center</li></Link>
                                            <Link to="/seller/logout" className="text-dark"><li className='p-2 hvr pointer'><Power className='me-2'/> Logout</li></Link>
                                    </>
                                }
                                                    
                                                    <hr/>
                                                    <li className='p-2 pointer mt-3'>
                                                        <h6>Select Region/Country</h6> 
                                                        <Country />
                                                    </li>
                                    
                                </ul> 
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}

        </nav>      
    )
}

export default Header;