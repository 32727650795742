import logo from '../logo.svg';
import {Link} from 'react-router-dom';
import { useParams } from "react-router-dom";
import { CheckCircle} from 'react-feather';
import { useState,useEffect, useContext } from 'react';
import axios from 'axios';
import { ExchangeRateContext } from '../ExchangeRateContext';
function OrderSuccess(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl0 = process.env.REACT_APP_BASE_URL_IMG;
    const {order_id} = useParams();
    const [OrderItems,setOrderItems]=useState(false);
    const [products, setProducts] = useState();
    const { exchangeRates, baseCurrency } = useContext(ExchangeRateContext);

    useEffect(()=>{
        fetchData(baseUrl+'/itemsorder/'+order_id);
    },[]);

    function currencyChange(price,currency){
        // Convert price from the product's original currency to the selected base currency
        const productExchangeRate = exchangeRates[currency] || 1;
        const baseExchangeRate = exchangeRates[baseCurrency] || 1;

        // Calculate the converted price
        const convertedPrice = ((price / productExchangeRate) * baseExchangeRate).toFixed(2);
        
        return convertedPrice;
    }

    function fetchData(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            if(data.count>0){
                setOrderItems({
                    "orderid":data.results[0].order.id,
                    "date":data.results[0].order.order_time.split('T')[0],
                    "cust_name":data.results[0].order.cust_name,
                    "cust_phone":data.results[0].order.cust_phone,
                    "del_loc":data.results[0].order.delivery_loc,
                    "del_fee":data.results[0].order.delivery_fee,
                    "pay_sts":data.results[0].order.payment_status,
                    "ttl":data.results[0].order.total_amount,
                    "order_status":data.results[0].order.order_status,
                 });
                 setProducts(data.results);
            }else{ }
            
        });
    }
    return (
        <section className="container mtopcon normal-mt-sctn">
            <div className="row">
                <div className="col-12 col-lg-6 reglogincon" max-width='100px'>
                    <div className="border-0">
                        <div className="card-body mb-0">                        
                                <div className="row text-center">
                                    <div className="col-md-12 mb-4">
                                        <button className="btn btn-outline-success border-0"><CheckCircle width='100px' height='100px' /></button>
                                    </div>
                                    <div className="col-12">
                                        <h3><b>Thank you</b></h3>
                                        <p className='grey-text'>We have received your order, and we will start processing it immediately.</p>
                                        <p className='grey-text'>Your order number is <b>#{OrderItems.orderid}</b></p>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <h4 className='text-center'><b>Order Summary</b></h4>
                        <div className='cntr'>
                            <div className="row bg-white br-10 ord-div orddetailspopcon zindx">  
                                {products &&
                                    products.map((item,index)=>{ 
                                        return <>
                                        <div className="row ord-bg pt-2 pb-2">
                                            <div className="col-2 col-lg-3 p-0"><div className="ord-img br-50"><img src={`${baseUrl0}/${item.product.product_imgs[0].image}`} className='w-100 h-100'/></div></div>
                                            <div className="col-9 col-lg-9 pl-0 p-0">
                                                <div className="row">
                                                    <div className="col-7 ord-sz mb-1">{item.product.title}</div>
                                                    <div className="col-5 ord-sz mb-1 grey-text">Qty: {item.qty}</div>
                                                    <div className="col-7 ord-sz mb-1 grey-text"><span>{baseCurrency}  {new Intl.NumberFormat().format(currencyChange(item.price,item.currency))}</span></div>
                                                    <div className="col-5 ord-sz mb-1 grey-text">Size: {item.size}</div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    })
                                }
                            </div>  
                        </div>                  
                    </div>

                    <hr/>
                    <div className="row  mt-4 w-90">      
                        <div className='col-6'><Link to="/"><button className="btn btn-info text-white h40 w-100">Home</button></Link></div>    
                        <div className='col-6'><Link to="/customer/dashboard"><button className="btn btn-warning h40 w-100">Account</button></Link></div>   
                    </div>

                </div>
            </div>
        </section>
    )
}

export default OrderSuccess;