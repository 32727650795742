import logo from '../logo.svg';
import {Link} from 'react-router-dom';
import SingleProduct from './singleproduct';
import { useState,useEffect } from 'react';
import { useParams } from "react-router-dom";
import useStoreStatusCheck from './useStoreSetupCheck';

function CategoryProductsShop(){
    useStoreStatusCheck();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { shopname, categoryname, categoryid } = useParams();
    const [products, setProducts] = useState([]);
    const [sortOption, setSortOption] = useState("default"); 
  

    useEffect(() => {
        const fetchProducts = async () => {
          try {
            const response = await fetch(`${baseUrl}/shop/${shopname}/category/${categoryid}/products/`, {
              headers: {
                "Content-Type": "application/json",
              },
            });
            if (response.ok) {
              const data = await response.json();
              setProducts(data.products);
            } else {
              console.error('Failed to fetch products');
            }
          } catch (error) {
            console.error('Error fetching products:', error);
          }
        };
    
        fetchProducts();
    }, [shopname, categoryid]);

     // Filter and Sort Products
     const filteredAndSortedResults = products.sort((a, b) => {
          if (sortOption === "price_high_low") return b.price - a.price;
          if (sortOption === "price_low_high") return a.price - b.price;
          if (sortOption === "default") return b.id - a.id;
          return 0; // Default sorting (no change)
      });

      
    return (
        <section className="container mtopcon normal-mt-sctn">
            <div className="row mb-4 ph-header-con">
                <div className="col-7 col-lg-9 pt-2"><h6 className="pageheader-title mb-3 tcone">{categoryname}</h6></div>
                <div className="col-5 col-lg-3">
                  <select className="filter2 text-center border w-100" name='category' onChange={(e) => setSortOption(e.target.value)}>
                      <option value="default">Sort</option>
                      <option value="price_high_low">Price from High to Low</option>
                      <option value="price_low_high">Price from Low to High</option>
                  </select>
                </div>
            </div>
            <div className="row mb-4">
            {filteredAndSortedResults.length === 0 ? (
                <p>Products Loading...</p>
            ) : (
                <>
                    {filteredAndSortedResults.map((product) => (
                        <SingleProduct product={product} key={product.id} />
                    ))}
                </>
            )}

            </div>  
                
        </section>
    )
}

export default CategoryProductsShop;