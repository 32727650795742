function Privacy(){

    return (
        <main className="mtopcon normal-mt-sctn">
            <div className="container">
                <h2 className="text-center"><b>Privacy Policy</b></h2>
                <p className="text-center">Updated December 18, 2024</p> 

                <h3>1. Introduction</h3>
                <p>Welcome to Kadhr Africa. We value your privacy and are committed to protecting any personal data you share with us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.</p>

                <h3>2. Information We Collect</h3>
                <p>We may collect and process the following types of personal data:</p>
                <ul>
                <li><strong>Personal Identification Information:</strong> Name, email address, phone number, and other contact details.</li>
                <li><strong>Account Information:</strong> Login credentials, purchase history, and preferences.</li>
                <li><strong>Transaction Information:</strong> Payment details, order details, and billing addresses.</li>
                <li><strong>Technical Information:</strong> IP address, browser type, device identifiers, and cookies.</li>
                <li><strong>Usage Data:</strong> Interactions with our website, including pages visited and actions taken.</li>
                </ul>

                <h3>3. How We Use Your Information</h3>
                <p>We use the information collected for the following purposes:</p>
                <ul>
                <li>To provide and maintain our services.</li>
                <li>To process transactions and manage payments.</li>
                <li>To improve user experience and website functionality.</li>
                <li>To send marketing, promotional, or service-related communications.</li>
                <li>To prevent fraud, unauthorized transactions, and security threats.</li>
                <li>To comply with legal obligations and enforce our terms.</li>
                </ul>

                <h3>4. How We Share Your Information</h3>
                <p>We do not sell, trade, or rent users’ personal information. However, we may share your data with:</p>
                <ul>
                <li><strong>Service Providers:</strong> Third-party vendors who assist with payment processing, analytics, customer service, and marketing.</li>
                <li><strong>Legal Authorities:</strong> If required by law, court orders, or to prevent fraud and illegal activities.</li>
                <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred.</li>
                </ul>

                <h3>5. Data Security</h3>
                <p>We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, use, or disclosure. However, no internet-based service is completely secure, and we cannot guarantee absolute security.</p>

                <h3>6. Your Rights and Choices</h3>
                <p>You have the following rights regarding your personal data:</p>
                <ul>
                <li><strong>Access & Correction:</strong> You can request to access or update your personal information.</li>
                <li><strong>Deletion:</strong> You can request that we delete your data, subject to legal and business obligations.</li>
                <li><strong>Marketing Preferences:</strong> You can opt out of marketing emails at any time.</li>
                <li><strong>Data Portability:</strong> You may request a copy of your data in a structured format.</li>
                </ul>

                <h3>7. Cookies and Tracking Technologies</h3>
                <p>We use cookies and similar tracking technologies to improve user experience, analyze website traffic, and personalize content. You can manage your cookie preferences through your browser settings.</p>

                <h3>8. Third-Party Links</h3>
                <p>Our website may contain links to third-party sites. We are not responsible for their privacy practices and encourage you to review their policies before providing any personal information.</p>

                <h3>9. Children's Privacy</h3>
                <p>Our services are not intended for individuals under the age of 18. We do not knowingly collect personal data from minors. If you believe a minor has provided us with their data, please contact us to have it removed.</p>

                <h3>10. Changes to This Privacy Policy</h3>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices, legal requirements, or improvements in data protection. Any changes will be posted on this page with an updated "Last Updated" date.</p>

                <h3>11. Contact Us</h3>
                <p>If you have any questions or concerns about this Privacy Policy, you can contact us at:</p>
                <p><strong>Email:</strong> support@kadhr.com</p>
                <p><strong>Website:</strong> <a href="https://kadhr.com">https://kadhr.com</a></p>


            </div>            
        </main>
    )
}

export default Privacy;
