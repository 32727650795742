import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../api";

const useStoreSetupCheck = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [setupStatus, setSetupStatus] = useState({
        subscription: false,
        payment: false,
        delivery: false,
        product: false,
    });

    useEffect(() => {
        const fetchSetupStatus = async () => {
            try {
                const token = localStorage.getItem("access_token");
                const response = await api.get("/vendor/store-setup-status/", {
                    headers: { Authorization: `Bearer ${token}` },
                });

                setSetupStatus(response.data);

                // If any setup step is false and the user is not on the setup page, redirect them
                if (
                    (!response.data.payment || !response.data.delivery || !response.data.product || !response.data.subscription) &&
                    location.pathname !== "/seller/setup"
                ) {
                    navigate("/seller/setup");
                }
            } catch (error) {
                //console.error("Error fetching setup status:", error);
            }
        };

        fetchSetupStatus();
    }, [navigate, location]);

    return setupStatus;
};

export default useStoreSetupCheck;
