import {Link} from 'react-router-dom';
import orderlogo from '../../order.jpg';
import LineChart from './LineChart';
import { useState,useEffect,useContext } from 'react';
import { VendorContext } from '../../context';
import { Activity, Calendar, CreditCard, Gift, MoreVertical, Package, Plus, Users} from 'react-feather';
import Sidebar from './sidebar';
import api from "../api";
import { ExchangeRateContext } from '../../ExchangeRateContext';
import useStoreSetupCheck from './useStoreSetupCheck';
function SellerDashboard(){   
    const setupStatus = useStoreSetupCheck();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl0 = process.env.REACT_APP_BASE_URL_IMG;
    const vendorContext=useContext(VendorContext);
    const [OrderItems,setOrderItems]=useState([]);
    const [VendorCurrency,setVendorCurrency]=useState([]);    
    const [StockItems,setStockItems]=useState([]);
    const [ProductViewsData,setProductViewsData]=useState([]);
    const [VendorData, setVendorData] = useState(() => {
        const savedData = localStorage.getItem('VendorData');
        return savedData ? JSON.parse(savedData) : {
            'totalProducts': 0,
            'totalOrders': 0,
            'totalCustomers': 0,
            'name': '',
            'loc': '',
            'bal': '',
            'totalTraffic': '',
            'payMode': '',
            'payMode1': '',
            'payMode2': '',
        };
    });
    const { exchangeRates, baseCurrency } = useContext(ExchangeRateContext);
    const [stats, setStats] = useState({
        units_sold: 0,
        total_sales: 0,
        lifetime_revenue: 0,
    });
    const [filter, setFilter] = useState("week");

    const fetchData = async () => {
        try {
          const response = await api.get(`${baseUrl}/vendor-dashboard/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          setVendorData(response.data);
        } catch (err) {
          //console.error("Error fetching products:", err.toJSON());
        }
    };    

    const fetchOrders = async () => {
        try {
          const response = await api.get(`${baseUrl}/vendor-orderitems/?fetch_limit=6`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          setOrderItems(response.data.order_items);
          localStorage.setItem('VendorCurrency', response.data.vendor_currency);
        } catch (error) {
          //console.error("Error fetching products:", err.toJSON());
        }
    };

    const fetchProductViewsData = async () => {
        try {
          const response = await api.get(`${baseUrl}/most-viewed-products/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          setProductViewsData(response.data.most_viewed_products);
        } catch (error) {
          //console.error("Error fetching products:", err.toJSON());
        }
    };
    
    const fetchStock = async () => {
        try {
          const response = await api.get(`${baseUrl}/vendor-products/dash/products/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          setStockItems(response.data.products);
        } catch (error) {
          //console.error("Error fetching products:", err.toJSON());
        }
    };
    useEffect(() => {
        fetchStats(filter);
    }, [filter]);

    const fetchStats = async (filterOption) => {
        try {
            const response = await api.get(`/vendor-sales-stats-reports/?filter=${filterOption}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
            });
            setStats(response.data);
        } catch (error) {
            //console.error("Error fetching stats:", error);
        }
    };

    // Update localStorage whenever VendorData changes
    useEffect(() => {
        localStorage.setItem('VendorData', JSON.stringify(VendorData));
    }, [VendorData]);

    useEffect(() => {
        fetchData();
        fetchOrders();
        fetchProductViewsData();
        fetchStock();
    }, []);

    const formatDate = (date) => {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        const dayName = days[date.getDay()];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
    
        const getOrdinalSuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };
    
        return `${dayName}, ${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
    };
    const currentDate = new Date();
    var shop_level = "";var shop_level_val = "";
    if(VendorData.totalProducts<10){
        shop_level="Beginner";shop_level_val="10%";
    }else if(VendorData.totalProducts<20){shop_level="Bronze";shop_level_val="20%";}else if(VendorData.totalProducts<50){shop_level="Silver";shop_level_val="60%";}
    else if(VendorData.totalProducts<150){shop_level="Gold";shop_level_val="80%";}else if(VendorData.totalProducts<250){shop_level="Platinum";shop_level_val="100%";}
    function formatDate0(dateString) {
        const date = new Date(dateString);
        const month = date.toLocaleDateString('en-US', { month: 'short' }); // "Nov"
        const day = date.getDate(); // 1
        const time = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        }).toLowerCase(); // "11:01 pm"
    
        return `${month} ${day}, ${time.replace(':', '.')}`; // "Nov 01 11.01pm"
    }
    function newprice(price,currency){
        const productExchangeRate = exchangeRates[currency] || 1;
        const baseExchangeRate = exchangeRates[baseCurrency] || 1;
        return baseCurrency + " " + new Intl.NumberFormat().format(((price / productExchangeRate) * baseExchangeRate).toFixed(2));
        
    }
    const getVendorCurrency = () => {
        return localStorage.getItem("VendorCurrency");
    };
    
    return (
        <section className="container mtopcon dashb">
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">                    
                    <div className="row dash-top-row hdmobile">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 hdmobile">
                            <div className="row dash-top-row mb-4">
                                <ul className="h-100 p-0">
                                    <Link to="/seller/addproduct"><li className='float-right h-100'><button className='btn btn-primary h-100'><Plus className='me-2'/>New Product</button></li></Link>
                                    <li className='float-right h-100 me-3 topdate'><Calendar/><p className='float-right'> {formatDate(currentDate)}</p></li>
                                </ul>                                
                                
                            </div>
                        </div>

                    </div>
                    <div className="row dashhome-rw">
                        <div className='col-lg-4 col-12 '> 
                            <div className="row containerzz mb-2">
                                <h3>Welcome Back, <span className='tcone'>{VendorData.name}</span></h3>
                                <p className='font9'>Let's make today a success.</p>
                                <div className="grid pb-2">
                                        <Link to="/seller/orders" className=''>
                                            <div className="card">
                                                <div className='row w-100'>
                                                    <div className='col-3 text-center text-white p-0'><Gift className='navstaticon'/></div>
                                                    <div className='col-9 pr-0 plpt'><h4 className="text font90x mb-0 text-white">Orders</h4></div>
                                                    <div className='col-3 text-right text-white p-0'></div>
                                                    <div className='col-9'><h2 className="mb-1 text-white mb-0">{VendorData.totalOrders}</h2></div>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/seller/products" className=''>
                                            <div className="card">
                                                <div className='row w-100'>
                                                    <div className='col-3 text-center text-white p-0'><Package className='navstaticon'/></div>
                                                    <div className='col-9 pr-0 plpt'><h4 className="text font90x mb-0 text-white">Current Stock</h4></div>
                                                    <div className='col-3 text-right text-white p-0'></div>
                                                    <div className='col-9'><h2 className="mb-1 text-white mb-0">{VendorData.totalProducts}</h2></div>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/seller/reports" className=''>
                                            <div className="card">
                                                <div className='row w-100'>
                                                    <div className='col-3 text-center text-white p-0'><Users className='navstaticon'/></div>
                                                    <div className='col-9 pr-0 plpt'><h4 className="text font90x mb-0 text-white">Visits Today</h4></div>
                                                    <div className='col-3 text-right text-white p-0'></div>
                                                    <div className='col-9'><h2 className="mb-1 text-white mb-0">{VendorData.totalTraffic}</h2></div>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/seller/finance" className=''>
                                            <div className="card">
                                                <div className='row w-100'>
                                                    <div className='col-3 text-center text-white p-0'><CreditCard className='navstaticon'/></div>
                                                    <div className='col-9 pr-0 plpt'><h4 className="text font90x mb-0 text-white">Balance</h4></div>
                                                    <div className='col-3 text-right text-white p-0'></div>
                                                    <div className='col-9'><h2 className="mb-1 text-white mb-0">{VendorData.bal}</h2></div>
                                                </div>
                                            </div>
                                        </Link>
                                </div>
                            </div>  
                            <div className="row">  
                                <div className="col-12 mt-3">
                                    <div className="card">
                                        <div className="card-header font9">
                                            <div className='row p-0 m-0'>
                                                <p className='pl-3 m-0 col-7 fbold'>Realtime Shop Visits </p>
                                                <div className='col-5 text-right'><Link to="/seller/reports" className='fgreen'><button className='btn btn-dark p-1 w-auto'><Activity/> Analytics</button></Link></div>
                                            </div>
                                        </div>                                            
                                        <div className="card-body p-1">
                                            <LineChart chart_title={''} chart_categories={stats.shop_visits && stats.shop_visits.categories} chart_data={stats.shop_visits && stats.shop_visits.data}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            {OrderItems.length > 0 && (
                                <>
                                    <div className="col-12">
                                        <div className="card dsktp-ord">
                                            <div className="card-header font9 bgdefault-a">
                                                <div className='row p-0 m-0'>
                                                    <p className='pl-3 m-0 col-7 fbold'>Latest Orders </p>
                                                    <div className='col-5 text-right'><Link to="/seller/orders" className='fgreen'><button className='btn btn-dark p-1 w-auto'><Gift/> View all</button></Link></div>
                                                </div>
                                            </div> 
                                            <div className="card-body bg-default">
                                                    <>
                                                        {OrderItems.sort((a, b) => new Date(b.order.order_time) - new Date(a.order.order_time)).map((item,index)=>{ 
                                                            return <>
                                                            <Link to={`/seller/order/${item.order.order_id}`} className='text-dark'><div className="row ord-bg bg-white p-2 pointer hvr bx-shadow">
                                                                <div className="col-2 col-lg-2 p-0"><div className="ord-img br-50"><img src={orderlogo} className='w-100 h-100'/></div></div>
                                                                <div className="col-9 col-lg-8 pl-0 pr-0">
                                                                    <div className="row">
                                                                        <div className="col-7 ord-sz-dsk">{item.order.cust_name}</div>
                                                                        <div className="col-5 p-0 ord-sz-dsk text-primary">
                                                                            {item.order.order_status === false && (
                                                                                <span className="badge rounded-pill text-bg-warning">{item.order.payment_status}</span>
                                                                            )}
                                                                            {item.order.order_status === true && (
                                                                                <span className="badge rounded-pill text-bg-success">Complete</span>
                                                                            )}                                                                       
                                                                        </div>
                                                                        <div className="col-7 ord-sz grey-text">{formatDate0(item.order.order_time)}</div>
                                                                        <div className="col-5 ord-sz font9 grey-text">
                                                                            <span className='cart-pro-ttl'>{item.product.currency} {item.order.total_amount}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 col-lg-2 f8 text-black p-0 mt-1 text-right lh34"><MoreVertical/></div>
                                                            </div></Link>
                                                            </>
                                                        })}
                                                    </>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header font9">
                                        <div className='row p-0 m-0'>
                                            <p className='pl-3 m-0 col-7 fbold'>Revenue Overview </p>
                                            <div className='col-5 text-right'><Link to="/seller/finance" className='fgreen'><button className='btn btn-dark p-1 w-auto'><CreditCard/> View all</button></Link></div>
                                        </div>
                                    </div> 
                                    <div className="card-body p-1">
                                        <LineChart chart_title={''}  chart_categories={stats.revenue_overview && stats.revenue_overview.categories} chart_data={stats.revenue_overview && stats.revenue_overview.data}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                        {ProductViewsData.length > 0 && (
                            <>
                                <div className="col-12">
                                    <div className="card bg-default dsktp-ord">
                                        <p className='pl-3 mb-0 col-12 font9 fbold'>Most Viewed Products Today </p>
                                        <div className="card-body">
                                            {ProductViewsData.map((item,index)=>{ 
                                                return <>
                                                <div className="row ord-bg bg-white p-2 hvr bx-shadow">
                                                    <div className="col-2 p-0"><div className="ord-img br-10"><img src={`${baseUrl0}/${item.first_image_url}`} className='w-100 h-100'/></div></div>
                                                    <div className="col-9 ml-2 pl-0 pr-0">
                                                        <div className="row">
                                                            <div className="col-11 ord-sz-dsk">{item.product_name}</div>
                                                            <div className="col-6 ord-sz-dsk text-primary"><span className="badge rounded-pill text-bg-secondary">Visits: {item.view_count}</span></div>
                                                            <div className="col-6 ord-sz-dsk grey-text">                                                                            
                                                                <span className='cart-pro-ttl'>{getVendorCurrency()} {item.product_price}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                            })}                                                
                                        </div>
                                    </div>
                                </div>                                
                            </>
                        )}
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header font9">
                                        <div className='row p-0 m-0'>
                                            <p className='pl-3 m-0 col-7 fbold'>Stock Overview </p>
                                            <div className='col-5 text-right'><Link to="/seller/products" className='fgreen'><button className='btn btn-dark p-1 w-auto'><Package/> View all</button></Link></div>
                                        </div>
                                    </div> 
                                    <div className="card-body">
                                        <div className='row'>
                                            <ul className="ltst_stk col-12">  
                                                {StockItems.total_results === 0 ? (
                                                        <p>No products found.</p>
                                                    ) : (
                                                        <>
                                                            {StockItems.map((item,index)=>{ 
                                                                return <>
                                                                        <li><img src={`${baseUrl0}/${item.first_image_url}`} className="ltpro" /></li>
                                                                </>
                                                            })}
                                                        </>
                                                    )}
                                                <div className="Clear"></div>
                                            </ul>
                                            <div className='col-12 mt-3'>
                                                <div className="metric-value d-inline-block">
                                                    <h6 className="mb-1">Shop Level : {shop_level}</h6>
                                                </div>
                                                <div className="progress prgrss mb-3">
                                                    <div className="progress-bar" role="progressbar" aria-valuemin="0"  style={{ width: shop_level_val }} aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default SellerDashboard;