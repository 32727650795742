import {Link} from 'react-router-dom';
import pay1 from "../feature/pay1.gif";
import link0 from "../feature/link.gif";
import Loyalty from "../feature/Loyalty.gif";
import analytics from "../feature/analytics.gif";
import ord from "../feature/ord.gif";
import oshop from "../feature/oshop.gif";
import convv from "../feature/convv.gif";
import main from "../feature/main.gif";
import pos_gif from "../feature/pos_gif.gif";
import bizdocs_img from "../feature/bizdocs_img.gif";
import digitalocean from "../feature/digitalocean.png";
import africatalking from "../feature/africatalking.png";
import mpesa from "../feature/mpesa.png";
import coop from "../feature/coop.png";
import google from "../feature/google.png";
import user1 from "../user1.jpg";
import user3 from "../user3.jpg";
import user4 from "../user4.jpg";
import user5 from "../user5.jpg";
import { Gift, Phone, Watch, Youtube,} from 'react-feather';
import { useState, useEffect } from "react";
import { FaTshirt, FaHeart, FaMobileAlt, FaTicketAlt, FaSprayCan, FaLaptopCode, FaPizzaSlice } from 'react-icons/fa';
import api5 from './api5';

function SellHome(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [currency, setcurrency] = useState("");
    const [plan1, setplan1] = useState("");
    const [plan2, setplan2] = useState("");
    const [plan3, setplan3] = useState("");
    const [plan10, setplan10] = useState("");
    const [plan20, setplan20] = useState("");
    const [plan30, setplan30] = useState("");

    async function getLocationAndSetPricing() {
        try {
            const response = await fetch("https://ipapi.co/json/");
            const data = await response.json();
            const countryCode = data.country_code;
    
            let prices, prices0, currency;
    
            if (countryCode === "KE") {
                prices = [299, 949, 4499];
                prices0 = [3000, 9499, 42999];
                currency = "KES";
            } else {
                prices = [5, 20, 40];
                prices0 = [49, 199, 399];
                currency = "USD";
            }
            setplan1(prices[0]);
            setplan2(prices[1]);
            setplan3(prices[2]);  
            setplan10(prices0[0]);
            setplan20(prices0[1]);
            setplan30(prices0[2]); 
            setcurrency(currency);          

        } catch (error) {
            //console.error("Error fetching location:", error);
        }
    }
    

    useEffect(()=>{
        getLocationAndSetPricing();       
    },[]);

    
    const handleSubscribe = async () => {
        setLoading(true);
        if (!email) {
            setMessage("Kindly enter an email address.");
            return;
        }

        try {
            const response = await api5.post(baseUrl+"/subscribe/", { email });
            setMessage(response.data.message);
            setEmail(""); // Clear input
            setLoading(false);
        } catch (error) {
            setMessage("Subscription failed. Please try again.");
            setLoading(false);
        }
    };
    const year0 = new Date().getFullYear();
    const categories = [
        { id: 4, icon: <FaLaptopCode />, name: 'Electronics', col: 'col-5' },
        { id: 3, icon: <FaSprayCan />, name: 'Fragrances', col: 'col-5' },
        { id: 2, icon: <FaHeart />, name: 'Beauty Products', col: 'col-5' },
        { id: 1, icon: <Watch />, name: 'Accessories', col: 'col-5' },
        { id: 1, icon: <FaTshirt />, name: 'Fashion', col: 'col-6' },
        { id: 5, icon: <FaPizzaSlice />, name: 'Hotels', col: 'col-6' },
        { id: 6, icon: <FaTicketAlt />, name: 'Tickets/ Digital Products', col: 'col-12' },
        { id: 7, icon: <FaMobileAlt />, name: 'Tender Documents Generator', col: 'col-12' },
    ];
    const socialLinks = [
          {
            name: "Twitter",
            url: "https://twitter.com/kadhr_africa",
            color: "#1DA1F2", // Twitter Blue
            icon: <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />,
          },
          {
            name: "Instagram",
            url: "https://instagram.com/kadhrafrica",
            color: "#E4405F", // Instagram Pinkish Red
            icon: <path d="M7 2c-2.8 0-5 2.2-5 5v10c0 2.8 2.2 5 5 5h10c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5H7zm8 2a1 1 0 011 1v1a1 1 0 01-2 0V5a1 1 0 011-1zm-5 3a5 5 0 015 5 5 5 0 01-5 5 5 5 0 01-5-5 5 5 0 015-5zm0 2a3 3 0 100 6 3 3 0 000-6z" />,
          },
          {
            name: "LinkedIn",
            url: "https://www.linkedin.com/company/kadhrafrica",
            color: "#0077B5", // LinkedIn Blue
            icon: <path d="M16 8a6 6 0 00-6 6v6h4v-6a2 2 0 114 0v6h4v-6a6 6 0 00-6-6zM2 9h4v12H2V9zm2-5a2 2 0 110 4 2 2 0 010-4z" />,
          },
          {
            name: "YouTube",
            url: "https://www.youtube.com/@kadhrafrica",
            color: "#FF0000", // YouTube Red
            icon: <path d="M21.8 8s-.2-1.4-.8-2a3.7 3.7 0 00-2-1.4C16.7 4 12 4 12 4s-4.7 0-7 .6a3.7 3.7 0 00-2 1.4c-.6.6-.8 2-.8 2S2 9.7 2 11.5v1c0 1.8.2 3.5.2 3.5s.2 1.4.8 2a3.7 3.7 0 002 1.4c2.3.6 7 .6 7 .6s4.7 0 7-.6a3.7 3.7 0 002-1.4c.6-.6.8-2 .8-2s.2-1.7.2-3.5v-1c0-1.8-.2-3.5-.2-3.5zm-12 6.7V9.3l5.3 2.7-5.3 2.7z" />,
          },
      ];
    return (
        <main className="mtopcon0 ovrflwhdn" id="up">
            <a href="https://wa.me/254794072134" target='_blank' className="wappcomms btn-hover float-left">
                <Phone className='text-white'/>
            </a>
            <div className="row">
                <div className="col-12 text-center text-white pt-6indx bg-blue0">
                    <h2 className='hmintrotxt mb-3'><b>Business Automation<br/>For Modern Solopreneurs</b></h2>
                    <p className='mb-4 hmintrotxt0'>Streamline your finances, simplify order taking, and build customer loyalty—all in one place.</p>
                    <p>
                        <Link to="/seller/register" className="text-dark"><button className="btn btn-dark mb-2 mt-2 me-3">Sign up for free</button></Link>
                        <Link to="https://wa.me/254794072134" target='_blank' className="text-dark mb-2 mt-2"><button className="btn btn-outline-light">Contact Us</button></Link>
                    </p>

                    <div className="d-flex align-items-center justify-content-center mt-4">
                        {/* Text */}
                        <span className="text-light text-right font9 me-2">
                            Trusted by <br/><strong>100+ Businesses</strong>
                        </span>

                        {/* Profile Images */}
                        <div className="d-flex align-items-center">
                            <div class="position-relative me-1">
                                <img src={user1} alt="" class="rounded-circle"/>
                            </div>
                            <div class="position-relative mln10">
                                <img src={user4} alt="" class="rounded-circle"/>
                            </div>
                            <div class="position-relative mln10">
                                <img src={user5} alt="" class="rounded-circle"/>
                            </div>
                    
                            <div className="position-relative mln10">
                                <div
                                    className="rounded-circle d-flex align-items-center justify-content-center"
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        backgroundImage: `url(${user3})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        backdropFilter: 'blur(2px)',
                                        textShadow: '0 0 5px rgba(0, 0, 0, 0.7)',
                                        border: '2px solid #333',
                                    }}
                                >
                                    +
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-12'>                        
                        <div className="welcome-banner mt-5 br10">
                            <img src={main} className='w-100 h-100'/>
                        </div>
                    </div>
                </div>

                <div className="col-12 text-center mt-5 mb-2 pt-6indx0">
                    <div className='container hdmobile h-auto'>
                        <h2 className="mb-5 hmpg-header"><b>Businesess we serve</b></h2>                        
                        <div className="row">
                            {categories.map((category) => (
                                <div key={category.id} className={`${category.col} col-md-3 col-lg-3 h50 mb-2`}>
                                    <div className="card border-0 d-flex align-items-center justify-content-center h-100 p-2">
                                        <div className='row w-100'>
                                            <div className='col-3'>
                                                <div className="icon-wrapper d-flex align-items-center justify-content-center me-2">
                                                    <span style={{ fontSize: '20px', color: '#6c757d' }}>
                                                    {category.icon}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col-9 pt-2' style={{textAlign:'left'}}><h6 className="mb-0 font9">{category.name}</h6></div>
                                        </div>
                                        
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="container hddsktp0">
                        <h4 className="mb-4 hmpg-header"><b>Businesess we serve</b></h4>                        
                        <div className="d-flex flex-row flex-nowrap overflow-auto p-1">
                            {categories.map((category) => (
                                <div key={category.id} className={`${category.col} col-md-3 col-lg-3 h50 mb-3 me-3`} style={{ minWidth: '180px' }}>
                                    <div className="card border-0 d-flex align-items-center justify-content-center h-100 p-2">
                                        <div className='row w-100'>
                                            <div className='col-3'>
                                                <div className="icon-wrapper d-flex align-items-center justify-content-center">
                                                    <span style={{ fontSize: '20px', color: '#6c757d' }}>
                                                        {category.icon}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col-9' style={{ paddingTop: '0.4em', paddingLeft: '0', textAlign: 'left' }}>
                                                <h6 className="mb-0 font9">{category.name}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>                

                <div className="background0 mt-6 pt-6indx0">
                    <div className="content0">                        
                        <h2 className='mt-4'>100% FREE<br/> Business Documents Generator.</h2>
                        <p className='font8'>Generate invoices, quotes, receipts, and more— fast, easy, professional and completely free</p>
                        <div  className="row">
                            <div  className="col-12 col-md-12 text-center mb-4 mt-3">                                            
                                <Link className="main-btn btn-hover text-white bg-blue" to="/seller/register">Sign up for free</Link>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 text-center mt-6 pt-6indx0" id='features'>
                    <div className="col-12">
                        <div className='container'>
                            <h2 className="mb-5 text-center hmpg-header"><b>Core Features</b></h2>
                            <div className="row">
                                {/* Smart Business Management */}
                                <div className="col-lg-4 col-12 col-md-auto br10 bg-white" id="business-management">
                                    <div className='card p-3'>
                                        <div className="feature-imgcon"><img src={pay1} /></div>
                                        <div className="col-12 mt-3 text-left">
                                            <h5 className="mb-2"><b>Smart Business Management</b></h5>
                                            <p className='font9'>
                                                <p className='mb-2'>Automate your finances with <b>secure payments, sales tracking, and a savings wallet</b>.</p>  
                                                <p className='mb-2'>Stay in control with <b>Stock & Order Management</b> for inventory and orders.</p>
                                                <p className='mb-2'>Get a professional <b>Online Store</b> with customizable storefronts.</p>
                                                <p><b>BizDocs</b>: Generate invoices, quotes, receipts, and more—fast, easy, and professional.</p>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Customer Engagement & Growth */}
                                <div className="col-lg-4 col-12 col-md-auto" id="customer-engagement">
                                    <div className='card p-3'>
                                        <div className="feature-imgcon"><img src={Loyalty} /></div>
                                        <div className="col-12 mt-3 text-left">
                                            <h5 className="mb-2"><b>Customer Engagement & Growth</b></h5>
                                            <p className='font9'>
                                                <p className='mb-2'>Drive repeat sales with a <b>Loyalty Program</b> that enables you to reward and engage with customers.</p>  
                                                <p className='mb-2'>Enhance your online presence with <b>‘Link in Bio’</b>, making it easy for customers to shop from your social media pages.</p>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Data-Driven Insights & Optimization */}
                                <div className="col-lg-4 col-12 col-md-auto" id="analytics">
                                    <div className='card p-3'>
                                        <div className="feature-imgcon"><img src={analytics} /></div>
                                        <div className="col-12 mt-3 text-left">
                                            <h5 className="mb-2"><b>Data-Driven Insights</b></h5>
                                            <p className='font9'>
                                                Make <b>smarter decisions with Actionable Analytics</b>, tracking performance, sales trends, and customer behavior.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                
                <div className="col-12 text-center mt-5 pt-6indx0">
                    <div className='container'>
                        <h2 className="mb-5 hmpg-header"><b>We've partnered with the best</b></h2>                        
                        <div className="marquee">
                            <div className="marquee-content"> 
                                <div class="marquee-item"><a><img src={digitalocean} alt=""/></a></div>
                                
                                <div class="marquee-item"><a><img src={mpesa} alt=""/></a></div>

                                <div class="marquee-item"><a><img src={coop} alt=""/></a></div>
                                
                                <div class="marquee-item"><a><img src={google} alt=""/></a></div>
                                
                                <div class="marquee-item"><a><img src={africatalking} alt=""/></a></div>

                                <div class="marquee-item"><a><img src={digitalocean} alt=""/></a></div>
                                
                                <div class="marquee-item"><a><img src={mpesa} alt=""/></a></div>

                                <div class="marquee-item"><a><img src={coop} alt=""/></a></div>

                                <div class="marquee-item"><a><img src={google} alt=""/></a></div>

                                <div class="marquee-item"><a><img src={africatalking} alt=""/></a></div>
                                
                                <div class="marquee-item"><a><img src={digitalocean} alt=""/></a></div>
                                
                                <div class="marquee-item"><a><img src={mpesa} alt=""/></a></div>

                                <div class="marquee-item"><a><img src={coop} alt=""/></a></div>

                                <div class="marquee-item"><a><img src={google} alt=""/></a></div>

                                <div class="marquee-item"><a><img src={africatalking} alt=""/></a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mt-6 pt-6indx0" id='pricing'>
                    <div className='container'>
                        <h2 className="text-center hmpg-header"><b>Pricing</b></h2>
                        <p className='font9 grey-text text-center mb-5 pricingtext'><Gift/> Save 15% with yearly plan</p>
                        <div className="row">
                                <div className="col-lg-4 col-md-6 col-xs-12 mb-30 ">
                                    <div className="feature-content prc">
                                        <span className="yrprc savegreen">{currency}. {parseFloat(plan10).toLocaleString()}/year Save 15%</span>
                                        <h4 className="pmhd mt-4">PERSONAL</h4> 
                                        <p className="text-center font9">For hobbies & testing out new products</p>
                                        <p className="p-prc mb-5 mt-4"><span>{currency}.</span>{plan1}<span>/mo</span></p>
                                        <Link className="main-btn btn-hover border-btn mb-4 ctabtn" to="/seller/register">Sign up for free</Link>
                                        <hr/>
                                        <ul className="pl-0 font9">
                                            <li className="mb-2"><div className="lidivicn1">Online Storefront</div></li>
                                            <li className="mb-2"><div className="lidivicn1">1 User Account</div></li>
                                            <li className="mb-2"><div className="lidivicn1">A Link to use in your Bio</div></li>
                                            <li className="mb-2"><div className="lidivicn1">Upto 5 Products</div></li>
                                            <li className="mb-2"><div className="lidivicn1">Order Notification SMS Alerts</div></li>
                                            <li className="mb-2"><div className="lidivicn1">Card and Mobile payments (3%)</div></li>
                                            <li className="mb-2"><div className="lidivicn1">Manual payments (0%)</div></li>
                                            <li className="mb-2"><div className="lidivicn1">10 SMS Credits</div></li>
                                            <li className="mb-2"><div className="lidivicn1">Help Center</div></li>                                            
                                        </ul>               
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-xs-12 mb-30">
                                    <div className="feature-content prc">
                                        <span className="yrprc savegreen">{currency}. {parseFloat(plan20).toLocaleString()}/year Save 15%</span>
                                        <h4 className="mt-4 pmhd">PREMIUM</h4>
                                        <p className="text-center font9">For growing businesses</p>
                                        <p className="p-prc mb-5 mt-4"><span>{currency}.</span>{plan2}<span>/mo</span></p>
                                        <Link className="main-btn btn-hover border-btn mb-4 bg-blue text-white ctabtn" to="/seller/register">Sign up for free</Link>
                                        <hr/>
                                        <ul className="pl-0 font9">
                                            <li className="mb-2"><div className="lidivicn1"><p><b>Everything in Personal</b></p></div></li>
                                            <li className="mb-2"><div className="lidivicn1"><p>Unlimited Products</p></div></li>
                                            <li className="mb-2"><div className="lidivicn1"><p>Advanced Analytics</p></div></li>
                                            <li className="mb-2"><div className="lidivicn1"><p>Customer Reviews</p></div></li>
                                            <li className="mb-2"><div className="lidivicn1"><p>Customer Loyalty Program</p></div></li>
                                            <li className="mb-2"><div className="lidivicn1"><p>100 SMS Credits</p></div></li>
                                            <li className="mb-2"><div className="lidivicn1"><p>Email Support</p></div></li>
                                        </ul>               
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-xs-12 mb-30">
                                    <div className="feature-content prc">
                                        <span className="yrprc savegreen">{currency}. {parseFloat(plan30).toLocaleString()}/year Save 15%</span>
                                        <h4 className="pmhd mt-4">ADVANCED</h4>
                                        <p className="text-center font9">For established businesses</p>
                                        <p className="p-prc mb-5 mt-4"><span>{currency}.</span>{parseFloat(plan3).toLocaleString()}<span>/mo</span></p>
                                        <a href="account/" className="main-btn btn-hover border-btn mb-4 ctabtn">Sign up for free</a>
                                        <hr/>
                                        <ul className="pl-0 font9">
                                            <li className="mb-2"><div className="lidivicn1"><p><b>Everything in Premium</b></p></div></li>
                                            <li className="mb-2"><div className="lidivicn1"><p>Kadhr logo removal</p></div></li>
                                            <li className="mb-2"><div className="lidivicn1"><p>Multiple stores and staff accounts</p></div></li>
                                            <li className="mb-2"><div className="lidivicn1"><p>250 SMS Credits</p></div></li>
                                            <li className="mb-2"><div className="lidivicn1"><p>Mobile Point Of Sale</p></div></li>
                                            <li className="mb-2"><div className="lidivicn1"><p>Custom domain</p></div></li>
                                            <li className="mb-2"><div className="lidivicn1"><p>Priority support</p></div></li>
                                        </ul>               
                                    </div>
                                </div>
                        </div>  
                    </div>
                </div>

                <div className="col-12 mt-6 pt-6indx0">
                    <div className='container'>
                        <h2 className="text-center mb-5 hmpg-header"><b>Deep Dive Into Our Features</b></h2>                        
                        
                        <div className="row">
                            
                            {/* Finance & Payments */}
                            <div className="col-lg-4 col-12" id='payment-processing'>
                                <div className='card p-4'>
                                    <div className="feature-imgcon mb-3"><img src={pay1} /></div>
                                    <h4 className="mb-2"><b>Payments Processing</b></h4>
                                    <p className="font9">Accept secure payments, track every sale, and build capital with a dedicated savings & investment wallet.</p>
                                </div>
                            </div>

                            {/* Customer Engagement */}
                            <div className="col-lg-4 col-12" id='loyalty'>
                                <div className='card p-4'>
                                    <div className="feature-imgcon mb-3"><img src={Loyalty} /></div>
                                    <h4 className="mb-2"><b>Customer Engagement</b></h4>
                                    <p className="font9">Reward loyal customers and keep them coming back with our Loyalty Program. Engage them through SMS updates, WhatsApp integration coming soon.</p>
                                </div>
                            </div>  

                            {/* Business Management on the Go */}
                            <div className="col-12 col-lg-4" id='analytics'>                                
                                <div className='card p-4'>
                                    <div className="feature-imgcon mb-3"><img src={analytics} /></div>
                                    <h4 className="mb-2"><b>Analytics & Insights</b></h4>
                                    <p className="font9">Understand customer behavior, track performance, and make smarter business decisions with actionable insights.</p>
                                </div>
                            </div>                   
                                                        
                        </div>

                        {/* Additional Features Breakdown */}
                        <div className="row mt-2">

                            {/* BizDocs */}
                            <div className="col-12 col-lg-4" id="bizdocs">
                                <div className='card p-4'>
                                    <div className="feature-imgcon mb-3"><img src={bizdocs_img} /></div>
                                    <div className="col-12">
                                        <h4 className="mb-2"><b>BizDocs</b></h4>
                                        <p className="font9 text-fixed">Generate invoices, quotations, purchase orders, receipts, and delivery notes for free—keeping your business transactions organized and professional.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Stock & Order Management */}
                            <div className="col-lg-4 col-12" id='loyalty'>
                                <div className='card p-4'>
                                    <div className="feature-imgcon mb-3"><img src={ord} /></div>
                                    <h4 className="mb-2"><b>Stock & Order Management</b></h4>
                                    <p className="font9 text-fixed">Manage inventory and streamline order processing with full control over stock levels, order tracking, and product availability for smooth operations.</p>
                                </div>
                            </div>  

                            {/* Point of Sale App */}
                            <div className="col-12 col-lg-4" id="pos">
                                <div className='card p-4'>
                                    <div className="mrftr2"><img src={pos_gif} className='w-100 h-100' /></div>
                                    <div className="col-12 mt-3">
                                        <h4 className="mb-2"><b>Point of Sale (POS)</b></h4>
                                        <p className="font9 text-fixed">Sell seamlessly in-store or online. Track inventory, accept payments, and manage sales effortlessly in one place.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Online Store */}
                            <div className="col-lg-4 col-12" id='online-store'>
                                <div className='card p-4'>
                                    <div className="feature-imgcon mb-3"><img src={oshop} /></div>
                                    <h4 className="mb-2"><b>Online Store</b></h4>
                                    <p className="font9 text-fixed">Build a professional online store with a customizable storefront and a unique link for easy Instagram sharing and quick checkouts. Display your products beautifully and drive more sales.</p>
                                </div>
                            </div>

                            {/* Link in Bio */}
                            <div className="col-lg-4 col-12" id='link-in-bio'>
                                <div className='card p-4'>
                                    <div className="feature-imgcon mb-3"><img src={link0} /></div>
                                    <h4 className="mb-2"><b>Link in Bio</b></h4>
                                    <p className="font9 text-fixed">Boost your social media presence with a ‘Link in Bio,’ making it easy for customers to browse and buy directly from your profile. Simplify shopping and increase conversions.</p>
                                </div>
                            </div>

                            {/* Business Management on the Go */}
                            <div className="col-12 col-lg-4">                                
                                <div className='card p-4'>
                                    <div className="feature-imgcon mb-3"><img src={convv} /></div>
                                    <h4 className="mb-2 text-center"><b>Run Your Business From Anywhere</b></h4>
                                    <p className="font9 text-center">Track orders in real-time, update inventory on the go, process secure payments, and analyze sales data—all from any device.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-12 mt-6 pt-6indx0">
                    <div className='container'>
                        <h2 className="text-center mb-5 hmpg-header"><b>Got Questions? We've<br/> Got Answers</b></h2>
                        <div className="accordion" id="accordionExample5z">
  
                                {/* Who is Kadhr for? */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="true" aria-controls="faq1">
                                        Who is Kadhr for?
                                    </button>
                                    </h2>
                                    <div id="faq1" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div className="accordion-body font9">
                                        Whether you're running a business or starting a side hustle, Kadhr gives you the tools to set up and manage your online store with ease. Your business data is securely stored on encrypted servers, backed up regularly, and accessible only to you—ensuring your information stays safe while you focus on growth.
                                    </div>
                                    </div>
                                </div>

                                {/* Instant Money Withdrawals */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="false" aria-controls="faq2">
                                        How does Kadhr's Instant Money Withdrawals work?
                                    </button>
                                    </h2>
                                    <div id="faq2" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body font9">
                                        Kadhr’s Instant Money Withdrawals give merchants immediate access to their earnings after a sale. Funds can be instantly withdrawn to your Phone No. or bank accounts, ensuring fast and seamless cash flow management for their business.
                                    </div>
                                    </div>
                                </div>

                                {/* Pricing */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                                        What does it cost?
                                    </button>
                                    </h2>
                                    <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body font9">
                                        Kadhr offers flexible pricing ranging from {currency} {plan1} per month, tailored to different business needs. Each plan includes essential features to help businesses manage their online stores, with advanced options providing more tools for growth and customization.
                                    </div>
                                    </div>
                                </div>

                                {/* Transaction Charges */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">
                                        Are there any transactional charges?
                                    </button>
                                    </h2>
                                    <div id="faq4" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body font9">
                                        For automated mobile money and credit/debit card payments, we use intasend.com which charges a standard 1 - 4.8% depending on country. Manual Pyament is free.
                                    </div>
                                    </div>
                                </div>

                                {/* Why Use Kadhr */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="faq5">
                                        Why Use Kadhr for my business?
                                    </button>
                                    </h2>
                                    <div id="faq5" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body font9">
                                        Kadhr simplifies online selling for businesses by offering easy store setup, inventory management, and instant payment processing. With tools designed for local markets, affordable pricing, and seamless integration with platforms like Instagram, Kadhr helps businesses grow their online presence and streamline operations effortlessly.
                                    </div>
                                    </div>
                                </div>

                                {/* Does Kadhr support international sellers? */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq8" aria-expanded="false" aria-controls="faq8">
                                        Does Kadhr support international sellers?
                                    </button>
                                    </h2>
                                    <div id="faq8" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body font9">
                                        Yes! Kadhr supports international sellers with global payment methods and currency conversion allowing merchants to expand their business beyond local markets.
                                    </div>
                                    </div>
                                </div>

                                {/* What support does Kadhr offer? */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq9" aria-expanded="false" aria-controls="faq9">
                                        What support does Kadhr offer?
                                    </button>
                                    </h2>
                                    <div id="faq9" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body font9">
                                        Kadhr offers 24/7 customer support via live chat, email, and phone. Additionally, merchants can access tutorials, webinars, and a dedicated knowledge base for self-help solutions here. <Link to="https://www.youtube.com/@kadhrafrica" target='_blank' className="text-dark"><Youtube/> <b>Guide & Tutorials</b></Link>.
                                    </div>
                                    </div>
                                </div>

                                </div>

                    </div>
                </div>

                <div className="background0 mt-6 pt-6indx0">
                    <div className="content0">
                        <h1 className='mt-4'>Build, Sell, and Grow.</h1>
                        <p>your online success story is just minutes away</p>
                        <div  className="row">
                            <div  className="col-12 col-md-12 text-center mb-4 mt-3">                                            
                                <Link className="main-btn btn-hover text-white bg-blue" to="/seller/register">Sign up for free</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-5 mb-0 bg-light pt-6indx0">
                    <div className='container'>
                    <div className="row">
                        <div className="col-6 col-md-3 mb-3">
                            <li className="nav-item mb-2"><a className="nav-link p-0 text-body-secondary" href="#pricing">Pricing</a></li>
                            <li className="nav-item mb-2"><a className="nav-link p-0 text-body-secondary" href="#features">Features</a></li>
                            <li className="nav-item mb-2"><Link to="/help-center" className="nav-link p-0 text-body-secondary">Help Center</Link></li>
                        </div>

                        <div className="col-6 col-md-3 mb-3">
                            <ul className="nav flex-column">
                            <li className="nav-item mb-2"><Link to="/terms" className="nav-link p-0 text-body-secondary">Terms</Link></li>
                            <li className="nav-item mb-2"><Link to="/privacy" className="nav-link p-0 text-body-secondary">Privacy Policy</Link></li>
                            </ul>
                        </div>

                        <div className="col-md-5 offset-md-1 mb-3">
                            <form>
                                <h5><b>Subscribe to our newsletter</b></h5>
                                <p className='font9'>Monthly digest of what's new and exciting from us.</p>
                                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                                    <label for="newsletter1" className="visually-hidden">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <button className="btn btn-primary" type="button" onClick={handleSubscribe}>
                                        { loading === true ? <>Loading...</> : <>Subscribe</>}
                                    </button>
                                </div>
                                {message && <p className="text-primary mt-2">{message}</p>}
                            </form>
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                        <p>© {year0} Kadhr. All rights reserved.</p>
                        <ul className="list-unstyled d-flex">
                            {socialLinks.map((social) => (
                                <a
                                key={social.name}
                                href={social.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={social.name}
                                className="transition duration-300 me-4"
                                >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill={social.color} // Assign brand color
                                    viewBox="0 0 24 24"
                                    className="w35"
                                >
                                    {social.icon}
                                </svg>
                                </a>
                            ))}
                        </ul>
                    </div>

                    </div>
                </div>


            </div>
        </main>
    )
}

export default SellHome;
