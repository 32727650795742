
import React, { useEffect, useState, useContext } from 'react';
import { ExchangeRateContext } from "../ExchangeRateContext";
import { fetchExchangeRates } from "./exchangeRateService";
function Country(){  
    const [selectedCurrency, setSelectedCurrency] = useState("");
    const [loading, setLoading] = useState(true);
    const { baseCurrency, updateBaseCurrency } = useContext(ExchangeRateContext);
  
    useEffect(() => {
      // Check local storage for saved currency data
      const storedCurrency = localStorage.getItem("selectedCurrency");
      const exchangeRates = localStorage.getItem("exchangeRates");
      
      if (storedCurrency && exchangeRates) {
        const { currency } = JSON.parse(storedCurrency);
        setSelectedCurrency(currency);
        setLoading(false);
      } else {
        // Fetch user's country and set default currency
        fetch("https://ipapi.co/json/")
          .then((res) => res.json())
          .then((data) => {
            const currency = getCurrencyByCountry(data.country_code);
            const country = data.country_name;
  
            fetchExchangeRates(currency);

            const currencyData = { currency, country };
            localStorage.setItem("selectedCurrency", JSON.stringify(currencyData));
            localStorage.setItem("baseCurrency", currency);
      
            setSelectedCurrency(currency);
          })
          .catch(//(err) => console.error("Error fetching location:", err)
            )
          .finally(() => setLoading(false));
      }
    }, []);
  
    const handleCurrencyChange = (e) => {
      const selectedCurrency = e.target.value;
      const selectedCountry = e.target.options[e.target.selectedIndex].text;

      // Save to local storage
      const currencyData = { currency: selectedCurrency, country: selectedCountry };
      localStorage.setItem("selectedCurrency", JSON.stringify(currencyData));
      localStorage.setItem("baseCurrency", selectedCurrency);

      updateBaseCurrency(selectedCurrency);  
      // Update state
      setSelectedCurrency(selectedCurrency);

        // Fetch user's country and set default currency
        fetch("https://ipapi.co/json/")
          .then((res) => res.json())
          .then((data) => {
            const currency = selectedCurrency;
            const country = data.country_name;
  
            const currencyData = { currency, country };
            localStorage.setItem("selectedCurrency", JSON.stringify(currencyData));
            localStorage.setItem("baseCurrency", currency);
      
            setSelectedCurrency(currency);
          })
          .catch(
            //(err) => console.error("Error fetching location:", err)
          )
          .finally(() => setLoading(false));
      window.location.reload();
    };
  
    const getCurrencyByCountry = (countryCode) => {
      const currencyMap = {
        AF: 'AFN', // Afghanistan
        AL: 'ALL', // Albania
        DZ: 'DZD', // Algeria
        AD: 'EUR', // Andorra
        AO: 'AOA', // Angola
        AR: 'ARS', // Argentina
        AM: 'AMD', // Armenia
        AU: 'AUD', // Australia
        AT: 'EUR', // Austria
        AZ: 'AZN', // Azerbaijan
        BH: 'BHD', // Bahrain
        BD: 'BDT', // Bangladesh
        BY: 'BYN', // Belarus
        BE: 'EUR', // Belgium
        BZ: 'BZD', // Belize
        BJ: 'XOF', // Benin
        BT: 'BTN', // Bhutan
        BO: 'BOB', // Bolivia
        BA: 'BAM', // Bosnia and Herzegovina
        BW: 'BWP', // Botswana
        BR: 'BRL', // Brazil
        BN: 'BND', // Brunei
        BG: 'BGN', // Bulgaria
        BF: 'XOF', // Burkina Faso
        BI: 'BIF', // Burundi
        KH: 'KHR', // Cambodia
        CM: 'XAF', // Cameroon
        CA: 'CAD', // Canada
        CV: 'CVE', // Cape Verde
        CL: 'CLP', // Chile
        CN: 'CNY', // China
        CO: 'COP', // Colombia
        KM: 'KMF', // Comoros
        CG: 'XAF', // Congo (Republic)
        CD: 'CDF', // Congo (DR)
        CR: 'CRC', // Costa Rica
        HR: 'HRK', // Croatia
        CU: 'CUP', // Cuba
        CY: 'EUR', // Cyprus
        CZ: 'CZK', // Czech Republic
        DK: 'DKK', // Denmark
        DJ: 'DJF', // Djibouti
        DO: 'DOP', // Dominican Republic
        EG: 'EGP', // Egypt
        SV: 'SVC', // El Salvador
        EE: 'EUR', // Estonia
        ET: 'ETB', // Ethiopia
        FI: 'EUR', // Finland
        FR: 'EUR', // France
        GA: 'XAF', // Gabon
        GM: 'GMD', // Gambia
        GE: 'GEL', // Georgia
        DE: 'EUR', // Germany
        GH: 'GHS', // Ghana
        GR: 'EUR', // Greece
        GT: 'GTQ', // Guatemala
        GN: 'GNF', // Guinea
        GW: 'XOF', // Guinea-Bissau
        GY: 'GYD', // Guyana
        HT: 'HTG', // Haiti
        HN: 'HNL', // Honduras
        HK: 'HKD', // Hong Kong
        HU: 'HUF', // Hungary
        IS: 'ISK', // Iceland
        IN: 'INR', // India
        ID: 'IDR', // Indonesia
        IR: 'IRR', // Iran
        IQ: 'IQD', // Iraq
        IE: 'EUR', // Ireland
        IL: 'ILS', // Israel
        IT: 'EUR', // Italy
        JM: 'JMD', // Jamaica
        JP: 'JPY', // Japan
        JO: 'JOD', // Jordan
        KZ: 'KZT', // Kazakhstan
        KE: 'KES', // Kenya
        KW: 'KWD', // Kuwait
        KG: 'KGS', // Kyrgyzstan
        LA: 'LAK', // Laos
        LV: 'EUR', // Latvia
        LB: 'LBP', // Lebanon
        LS: 'LSL', // Lesotho
        LR: 'LRD', // Liberia
        LY: 'LYD', // Libya
        LT: 'EUR', // Lithuania
        LU: 'EUR', // Luxembourg
        MG: 'MGA', // Madagascar
        MW: 'MWK', // Malawi
        MY: 'MYR', // Malaysia
        MV: 'MVR', // Maldives
        ML: 'XOF', // Mali
        MT: 'EUR', // Malta
        MX: 'MXN', // Mexico
        MD: 'MDL', // Moldova
        MN: 'MNT', // Mongolia
        MA: 'MAD', // Morocco
        MZ: 'MZN', // Mozambique
        MM: 'MMK', // Myanmar
        NA: 'NAD', // Namibia
        NP: 'NPR', // Nepal
        NL: 'EUR', // Netherlands
        NZ: 'NZD', // New Zealand
        NI: 'NIO', // Nicaragua
        NE: 'XOF', // Niger
        NG: 'NGN', // Nigeria
        NO: 'NOK', // Norway
        OM: 'OMR', // Oman
        PK: 'PKR', // Pakistan
        PG: 'PGK', // Papua New Guinea
        PY: 'PYG', // Paraguay
        PE: 'PEN', // Peru
        PH: 'PHP', // Philippines
        PL: 'PLN', // Poland
        PT: 'EUR', // Portugal
        QA: 'QAR', // Qatar
        RO: 'RON', // Romania
        RU: 'RUB', // Russia
        RW: 'RWF', // Rwanda
        SA: 'SAR', // Saudi Arabia
        SN: 'XOF', // Senegal
        RS: 'RSD', // Serbia
        SC: 'SCR', // Seychelles
        SL: 'SLL', // Sierra Leone
        SG: 'SGD', // Singapore
        SK: 'EUR', // Slovakia
        SI: 'EUR', // Slovenia
        ZA: 'ZAR', // South Africa
        ES: 'EUR', // Spain
        LK: 'LKR', // Sri Lanka
        SD: 'SDG', // Sudan
        SR: 'SRD', // Suriname
        SZ: 'SZL', // Eswatini
        SE: 'SEK', // Sweden
        CH: 'CHF', // Switzerland
        SY: 'SYP', // Syria
        TW: 'TWD', // Taiwan
        TZ: 'TZS', // Tanzania
        TH: 'THB', // Thailand
        TG: 'XOF', // Togo
        TO: 'TOP', // Tonga
        TN: 'TND', // Tunisia
        TR: 'TRY', // Turkey
        UG: 'UGX', // Uganda
        UA: 'UAH', // Ukraine
        AE: 'AED', // United Arab Emirates
        GB: 'GBP', // United Kingdom
        US: 'USD', // United States
        UY: 'UYU', // Uruguay
        UZ: 'UZS', // Uzbekistan
        VE: 'VES', // Venezuela
        VN: 'VND', // Vietnam
        YE: 'YER', // Yemen
        ZM: 'ZMW', // Zambia
        ZW: 'ZWL'  // Zimbabwe
      };
      ;
      return currencyMap[countryCode] || 'KES'; // Default to USD if country not found
    };
  

    return (
        <>
            <select className='currency' value={selectedCurrency} onChange={handleCurrencyChange}>
                <option value={selectedCurrency.currency} defaultValue>{selectedCurrency.country}</option>
                <option value='AFN'>Afghanistan</option>
                <option value='ALL'>Albania</option>
                <option value='DZD'>Algeria</option>
                <option value='USD'>United States</option>
                <option value='EUR'>Andorra</option>
                <option value='AOA'>Angola</option>
                <option value='XCD'>Antigua and Barbuda</option>
                <option value='ARS'>Argentina</option>
                <option value='AMD'>Armenia</option>
                <option value='AWG'>Aruba</option>
                <option value='AUD'>Australia</option>
                <option value='EUR'>Austria</option>
                <option value='AZN'>Azerbaijan</option>
                <option value='BSD'>Bahamas</option>
                <option value='BHD'>Bahrain</option>
                <option value='BDT'>Bangladesh</option>
                <option value='BBD'>Barbados</option>
                <option value='BYN'>Belarus</option>
                <option value='EUR'>Belgium</option>
                <option value='BZD'>Belize</option>
                <option value='XOF'>Benin</option>
                <option value='BMD'>Bermuda</option>
                <option value='BTN'>Bhutan</option>
                <option value='BOB'>Bolivia</option>
                <option value='BAM'>Bosnia and Herzegovina</option>
                <option value='BWP'>Botswana</option>
                <option value='BRL'>Brazil</option>
                <option value='BND'>Brunei</option>
                <option value='BGN'>Bulgaria</option>
                <option value='XOF'>Burkina Faso</option>
                <option value='BIF'>Burundi</option>
                <option value='KHR'>Cambodia</option>
                <option value='XAF'>Cameroon</option>
                <option value='CAD'>Canada</option>
                <option value='CVE'>Cape Verde</option>
                <option value='KYD'>Cayman Islands</option>
                <option value='XAF'>Central African Republic</option>
                <option value='XAF'>Chad</option>
                <option value='CLP'>Chile</option>
                <option value='CNY'>China</option>
                <option value='COP'>Colombia</option>
                <option value='KMF'>Comoros</option>
                <option value='CDF'>Congo</option>
                <option value='NZD'>Cook Islands</option>
                <option value='CRC'>Costa Rica</option>
                <option value='HRK'>Croatia</option>
                <option value='CUP'>Cuba</option>
                <option value='ANG'>Curaçao</option>
                <option value='CZK'>Czech Republic</option>
                <option value='DKK'>Denmark</option>
                <option value='DJF'>Djibouti</option>
                <option value='XCD'>Dominica</option>
                <option value='DOP'>Dominican Republic</option>
                <option value='USD'>Ecuador</option>
                <option value='EGP'>Egypt</option>
                <option value='SVC'>El Salvador</option>
                <option value='XAF'>Equatorial Guinea</option>
                <option value='ERN'>Eritrea</option>
                <option value='EUR'>Estonia</option>
                <option value='SZL'>Eswatini</option>
                <option value='ETB'>Ethiopia</option>
                <option value='FKP'>Falkland Islands</option>
                <option value='DKK'>Faroe Islands</option>
                <option value='FJD'>Fiji</option>
                <option value='EUR'>Finland</option>
                <option value='EUR'>France</option>
                <option value='XAF'>Gabon</option>
                <option value='GMD'>Gambia</option>
                <option value='GEL'>Georgia</option>
                <option value='EUR'>Germany</option>
                <option value='GHS'>Ghana</option>
                <option value='GIP'>Gibraltar</option>
                <option value='EUR'>Greece</option>
                <option value='DKK'>Greenland</option>
                <option value='XCD'>Grenada</option>
                <option value='USD'>Guam</option>
                <option value='GTQ'>Guatemala</option>
                <option value='GNF'>Guinea</option>
                <option value='XOF'>Guinea-Bissau</option>
                <option value='GYD'>Guyana</option>
                <option value='HTG'>Haiti</option>
                <option value='HNL'>Honduras</option>
                <option value='HKD'>Hong Kong</option>
                <option value='HUF'>Hungary</option>
                <option value='ISK'>Iceland</option>
                <option value='INR'>India</option>
                <option value='IDR'>Indonesia</option>
                <option value='IRR'>Iran</option>
                <option value='IQD'>Iraq</option>
                <option value='EUR'>Ireland</option>
                <option value='ILS'>Israel</option>
                <option value='EUR'>Italy</option>
                <option value='JMD'>Jamaica</option>
                <option value='JPY'>Japan</option>
                <option value='JOD'>Jordan</option>
                <option value='KZT'>Kazakhstan</option>
                <option value='KES'>Kenya</option>
                <option value='AUD'>Kiribati</option>
                <option value='KPW'>North Korea</option>
                <option value='KRW'>South Korea</option>
                <option value='KWD'>Kuwait</option>
                <option value='KGS'>Kyrgyzstan</option>
                <option value='LAK'>Laos</option>
                <option value='EUR'>Latvia</option>
                <option value='LBP'>Lebanon</option>
                <option value='LSL'>Lesotho</option>
                <option value='LRD'>Liberia</option>
                <option value='LYD'>Libya</option>
                <option value='CHF'>Liechtenstein</option>
                <option value='EUR'>Lithuania</option>
                <option value='EUR'>Luxembourg</option>
                <option value='MOP'>Macau</option>
                <option value='MKD'>North Macedonia</option>
                <option value='MGA'>Madagascar</option>
                <option value='MWK'>Malawi</option>
                <option value='MYR'>Malaysia</option>
                <option value='MVR'>Maldives</option>
                <option value='XOF'>Mali</option>
                <option value='EUR'>Malta</option>
                <option value='USD'>Marshall Islands</option>
                <option value='MRU'>Mauritania</option>
                <option value='MUR'>Mauritius</option>
                <option value='MXN'>Mexico</option>
                <option value='USD'>Micronesia</option>
                <option value='MDL'>Moldova</option>
                <option value='EUR'>Monaco</option>
                <option value='MNT'>Mongolia</option>
                <option value='EUR'>Montenegro</option>
                <option value='XCD'>Montserrat</option>
                <option value='MAD'>Morocco</option>
                <option value='MZN'>Mozambique</option>
                <option value='MMK'>Myanmar</option>
                <option value='NAD'>Namibia</option>
                <option value='AUD'>Nauru</option>
                <option value='NPR'>Nepal</option>
                <option value='EUR'>Netherlands</option>
                <option value='NZD'>New Zealand</option>
                <option value='NIO'>Nicaragua</option>
                <option value='XOF'>Niger</option>
                <option value='NGN'>Nigeria</option>
                <option value='AUD'>Niue</option>
                <option value='USD'>Northern Mariana Islands</option>
                <option value='NOK'>Norway</option>
                <option value='OMR'>Oman</option>
                <option value='PKR'>Pakistan</option>
                <option value='USD'>Palau</option>
                <option value='ILS'>Palestine</option>
                <option value='PAB'>Panama</option>
                <option value='PGK'>Papua New Guinea</option>
                <option value='PYG'>Paraguay</option>
                <option value='PEN'>Peru</option>
                <option value='PHP'>Philippines</option>
                <option value='PLN'>Poland</option>
                <option value='EUR'>Portugal</option>
                <option value='USD'>Puerto Rico</option>
                <option value='QAR'>Qatar</option>
                <option value='RON'>Romania</option>
                <option value='RUB'>Russia</option>
                <option value='RWF'>Rwanda</option>
                <option value='EUR'>San Marino</option>
                <option value='XCD'>Saint Kitts and Nevis</option>
                <option value='XCD'>Saint Lucia</option>
                <option value='XCD'>Saint Vincent and the Grenadines</option>
                <option value='WST'>Samoa</option>
                <option value='STN'>São Tomé and Príncipe</option>
                <option value='SAR'>Saudi Arabia</option>
                <option value='XOF'>Senegal</option>
                <option value='SGD'>Singapore</option>
                <option value='ZAR'>South Africa</option>
                <option value='KRW'>South Korea</option>
                <option value='LKR'>Sri Lanka</option>
                <option value='SEK'>Sweden</option>
                <option value='CHF'>Switzerland</option>
                <option value='THB'>Thailand</option>
                <option value='TRY'>Turkey</option>
                <option value='UGX'>Uganda</option>
                <option value='UAH'>Ukraine</option>
                <option value='AED'>United Arab Emirates</option>
                <option value='GBP'>United Kingdom</option>
                <option value='UYU'>Uruguay</option>
                <option value='UZS'>Uzbekistan</option>
                <option value='VND'>Vietnam</option>
                <option value='YER'>Yemen</option>
                <option value='ZMW'>Zambia</option>
            </select>
        </>
    )
}

export default Country;