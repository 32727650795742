import axios from 'axios';

// Base URL for API requests
const API_BASE_URL = process.env.REACT_APP_BASE_URL || 'https://kadhr.com/api';

const api5 = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true, // Send cookies with requests (important for CSRF)
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to fetch and set CSRF token
export const fetchCsrfToken = async () => {
  try {
    const response = await api5.get('/get-csrf-token/');
    const csrfToken = response.data.csrfToken;

    // Set CSRF token for future requests
    api5.defaults.headers.common['X-CSRFToken'] = csrfToken;

    //console.log('CSRF token fetched successfully:', csrfToken);
  } catch (error) {
    //console.error('Failed to fetch CSRF token:', error);
  }
};

// Fetch CSRF token on app start
fetchCsrfToken();

export default api5;
