import {Link} from 'react-router-dom';
import logo from '../logo.svg';
function SingleSeller(props){
    if(!props.seller.profile_img){
        props.seller.profile_img=logo;
    }
    return (
        
        <div className="col-4 col-md-1 col-lg-1 mb-4 me-3 text-center">
            <div className='row'>
                <div className='col-12'><img src={props.seller.profile_img} className="seller-img bg-light" alt="..."/></div>
                <div className='col-12 mt-2 seller-name'><Link to={`/seller/${props.seller.id}/${props.seller.user.username}`}>{props.seller.user.username}</Link></div>
            </div>
        </div> 
    )
}

export default SingleSeller;