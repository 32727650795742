import orderlogo from '../../order.jpg';
import Sidebar from './sidebar';
import { Gift, MessageSquare} from 'react-feather';
import { useState,useEffect } from 'react';
import { useParams } from "react-router-dom";
import api from "../api";
import { PaystackButton } from 'react-paystack';
function SellerCustomerOrders(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrlImg = process.env.REACT_APP_BASE_URL_IMG;   
    const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY; 
    const {customer_id}=useParams();
    const [OrderItems,setOrderItems]=useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [randomCode, setRandomCode] = useState("");    
    const [OfferAmount, setOfferAmount] = useState(0);
    const [MobileNoError,setMobileNoError]=useState(false);
    const [PayMethod, SetPayMethod]=useState('');
    const [SmsAmount,setSmsAmount]=useState(false);
    const [response, setResponse] = useState(null);
    const [UsernameError,setUsernameError]=useState(false);
    const [EmailError,setEmailError]=useState(false);
    const [UsernameError0,setUsernameError0]=useState('0');
    const [EmailError0,setEmailError0]=useState('');
    const [SmsVal,setSmsVal]=useState(false);
    const [SmsVal0,setSmsVal0]=useState('0');
    const [PayError, SetPayError]=useState('');
    const [ProfileData,setProfileData]=useState({  
        "user_id":'', 
        "first_name":'',
        "last_name":'',
        "username":'',
        "customer_email":'',
        "mobile":0,
        "address":'',
        "p_image":'',
    });

    const token = localStorage.getItem("access_token");  
    
    const inputHandler =(event) => {
        setProfileData({
            ...ProfileData,
            [event.target.name]:event.target.value
        });
    };

    const fetchData = async () => {
        try {
          const response = await api.get(`${baseUrl}/vendor-customer/${customer_id}/orderitems`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setOrderItems(response.data);
        } catch (err) {
          //console.error("Error fetching products:", err);
          setError("Failed to fetch products. Please try again.");
        }finally {
            setLoading(false);
        }
    };

    useEffect(()=>{
        fetchData();
    },[]);

    const handleOfferChange = (event) => {
        setOfferAmount(event.target.value);
    };
    
    const getVendorCurrency = () => {
        return localStorage.getItem("VendorCurrency");
    };

    // Function to generate a random code
    const generateRandomCode = () => {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const numbers = "0123456789";

        // Generate two random letters
        const randomLetters = letters.charAt(Math.floor(Math.random() * 26)) + letters.charAt(Math.floor(Math.random() * 26));

        // Generate four random numbers
        let randomNumbers = "";
        for (let i = 0; i < 4; i++) {
            randomNumbers += numbers.charAt(Math.floor(Math.random() * 10));
        }

        // Combine letters and numbers
        const newCode = randomLetters + randomNumbers;
        setRandomCode(newCode);
    };
    const credits = OrderItems.sms;
    const message = `Hi ${OrderItems.customer_name}, Use code ${randomCode} to get ${getVendorCurrency()}. ${OfferAmount} off your next order as a thank you. Shop now: https://kadhr.com/${OrderItems.vendor_username}.`;
   
    function changePaymentMethod(payMethod){
        SetPayMethod(payMethod);
    }

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && !emailPattern.test(email)) {
            setEmailError(true);setEmailError0('0');
        } else {
            setEmailError(false);setEmailError0('1');
        }
    };
    const validateUsername = (username) => {
        if(username.length<3){
            setUsernameError(true);setUsernameError0('0'); return;
        }else{
            setUsernameError(false);setUsernameError0('1');
        } 
    };
    const validateSmsVal= (smsval) => {
        if(smsval < 10){
            setSmsVal(true);setSmsVal0('0'); return;
        }else{
            setSmsVal(false);setSmsVal0('1');
        } 
    };

    const handlePaymentSuccess = async (response) => {
    
        const payload = {
            email: ProfileData.email,
            total_amount:ProfileData.smsval,
            trxref: response.trxref,
            trans: response.trans,
        };
    
        try {
            const res = await api.post(baseUrl+`/sms-checkout/`, payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            }); 
            if(res.data.success){
                window.location.href = '/seller/customers';
            }
        } catch (error) {
            //console.error('Failed to update order status:', error.response?.data || error.message);
            //alert('Failed to update order status.');
        }
    };

    const handlePaymentClose = async () => {    
        SetPayError('Transaction was not completed.');
    };
    
    const onSuccess = (response) => {
        handlePaymentSuccess(response);
    };

    const onClose = () => {
        alert('Transaction was not completed, window closed.');
        //console.error('Transaction was not completed, window closed.');
        handlePaymentClose();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await api.post(baseUrl+"/customer-reward/", {
            cust_phone: customer_id,
            total_spending: OfferAmount,
            voucher_code: randomCode,
          });
            if(response.status=='201'){setResponse("SMS sent successfully!");}
        } catch (error) {
          //console.error("Error adding reward:", error);
        }
    };

    const sendSMS = async () => {
        if(credits===0){setError("Kindly recharge your SMS Credit below to proceed.");return;}
        if (!customer_id || !message) {
          setError("Kindly enter both phone number and message.");
          return;
        }
        setResponse(null);
        setLoading(true);
        setError(null);
    
        try {
          const res = await api.post(baseUrl+"/sms/", {
            number: customer_id,
            message: message,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          // Extract status from API response
          const status = res.data?.SMSMessageData?.Recipients?.[0]?.status;

          // Set response message based on status
          if (status === "UserInBlacklist") {
              setError("User has blacklisted promotional messages.");
          } else if (status === "Success") {
              handleSubmit();              
          } else {
              setError("Failed to send SMS. Kindly try again.");
          }
        } catch (err) {
          setError("Failed to send SMS. Kindly try again.");
        }
    
        setLoading(false);
    };
      
    if(loading == true){return "Loading";}

    return (
        <section className="container mtopcon dashb">     
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">
                    <div className="row mb-3 ph-header-con">
                        <div className="col-12 pt-2"><h6 className="pageheader-title mb-3">Customer Spending Overview </h6></div>
                    </div>
                    <div className="row dashhome-rw">
                        <div className="col-12 col-lg-4">
                            <div className="card mb-3">
                                <div className="row p-0 m-0">
                                    <div className="row ordbgrw pb-3">
                                        <div className="col-6 ord-sz1 pt-2">
                                            <div className="row p-0 m-0"><div className="col-12 grey-text font9">Customer Name</div><div className="col-12">{OrderItems.customer_name}</div></div>
                                        </div>
                                        <div className="col-6 ord-sz1 pt-2">
                                            <div className="row p-0 m-0"><div className="col-12 grey-text font9">Email</div><div className="col-12">{OrderItems.customer_email}</div></div>
                                        </div>
                                        <div className="col-6 ord-sz1 pt-2">
                                            <div className="row p-0 m-0"><div className="col-12 grey-text font9">Total Expenditure</div><div className="col-12">{getVendorCurrency()}. {OrderItems.total_spent}</div></div>
                                        </div>
                                        <div className="col-6 ord-sz1 pt-2">
                                            <div className="row p-0 m-0"><div className="col-12 grey-text font9">Total Orders</div><div className="col-12">{OrderItems.total_orders}</div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card shadow-sm border border-secondary border-opacity-10">
                                <div className="card-body"> 
                                        <div className="mb-3">
                                            <label htmlFor="lnm" className="form-label mb-0"><b>Reward Vouchers</b></label>
                                            <div className="grey-text font9 mb-2">Send SMS vouchers to reward loyal customers based on their spending</div>  
                                            <button type="button" className="col-12 btn btn-warning mt-2 addpymt" data-bs-toggle="modal" data-bs-target="#addaccount" onClick={generateRandomCode}><Gift /> Reward Customer</button>
                                        </div>

                                        <div className="accordion accordion-flush mt-4 bg-white br-10" id="accordionFlushExample0011">
                                            <div className="accordion-item">
                                                <h6 className="accordion-header">
                                                    <button className="accordion-button collapsed grey-text p-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1z1" aria-expanded="false" aria-controls="flush-collapseOne1z1">
                                                        Vouchers Awarded History
                                                    </button>
                                                </h6>
                                                <div id="flush-collapseOne1z1" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample0011">
                                                    <div className="accordion-body font9 p-0 mt-2 h400scroll">
                                                        {OrderItems.customer_rewards && OrderItems.customer_rewards.length > 0 ? (
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">#</th>
                                                                        <th scope="col">Voucher</th>
                                                                        <th scope="col">Value</th>
                                                                        <th scope="col">Issue Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {OrderItems.customer_rewards && 
                                                                        OrderItems.customer_rewards
                                                                            .sort((a, b) => new Date(b.voucher_date) - new Date(a.voucher_date))  // Sort by voucher_date in descending order
                                                                            .map((reward, index) => (
                                                                                <tr key={index}>
                                                                                    <th scope="row">{index + 1}</th>
                                                                                    <td>{reward.voucher_code || "N/A"}</td>
                                                                                    <td>{reward.total_spending || "N/A"}</td>
                                                                                    <td>{reward.voucher_date ? new Date(reward.voucher_date).toLocaleDateString() : "N/A"}</td>
                                                                                </tr>
                                                                            ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        ) : (
                                                            <p className="text-muted">No vouchers awarded to this customer yet.</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <hr className="hddsktp"></hr>
                        <div className="col-12 col-lg-8 orderscontainer"> 
                            <h6 className="mb-3">{OrderItems.customer_name}'s Order History </h6>                       
                            <div className="mb-4">                                
                                {/* Orders List */}
                                <div className="accordion accordion-flush mt-3" id="accordionOrders">
                                    {OrderItems.order_items && OrderItems.order_items.map((item, index) => {
                                        const { order, product, qty, size, price } = item;
                                        return (
                                            <div className="accordion-item mb-3 border-0" key={order.id}>
                                                <h2 className="accordion-header" id={`heading-${index}`}>
                                                    <div className="accordion-button collapsed p-1 bx-shadow br-10"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#collapse-${index}`}
                                                        aria-expanded="false"
                                                        aria-controls={`collapse-${index}`}>
                                                        <div className="row ord-bg bg-white mb-0 p-2 hvrw-100 w-100 mlrnew0">
                                                            <div className="col-2 col-lg-1 p-0"><div className="ord-img br-50"><img src={orderlogo} className='w-100 h-100'/></div></div>
                                                            <div className="col-9 col-lg-11 pl-0 pr-0">
                                                                <div className="row">
                                                                    <div className="col-7 col-lg-3 ord-sz">#{order.id}</div>
                                                                    <div className="col-5 col-lg-3 ord-sz text-primary">{order.payment_status}</div>
                                                                    <div className="col-7 col-lg-3 ord-sz grey-text">{getVendorCurrency()} {order.total_amount.toLocaleString()}</div>
                                                                    <div className="col-5 col-lg-3 ord-sz grey-text">{new Date(order.order_time).toLocaleDateString()}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </h2>
                                                <div id={`collapse-${index}`} className="accordion-collapse collapse"
                                                    aria-labelledby={`heading-${index}`}
                                                    data-bs-parent="#accordionOrders">
                                                    <div className="accordion-body">
                                                        <div className="row ord-bg bg-white p-2 hvr bx-shadow mlrnew">
                                                            <div className="col-3 col-lg-1 p-0">
                                                                <div className="ord-img br-10">
                                                                    <img src={baseUrlImg+product.product_imgs?.[0]?.image || orderlogo} className="w-100 h-100" alt={product.title} />
                                                                </div>
                                                            </div>
                                                            <div className="col-9 col-lg-11 pl-0 pr-0">
                                                                <div className="row">
                                                                    <div className="col-7 col-lg-3 ord-sz">{product.title}</div>
                                                                    <div className="col-5 col-lg-3 ord-sz">{getVendorCurrency()} {parseFloat(price).toLocaleString()}</div>
                                                                    <div className="col-7 col-lg-3 ord-sz">Qty: {qty}</div>
                                                                    <div className="col-5 col-lg-3 ord-sz">Size: {size || "N/A"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                    
                            </div>
                        </div>
                    </div>
            </div>
            </div>

            <div className="modal fade" id="addaccount" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mt70">
                    <div className="modal-content">
                        <div className="modal-body pb-2">                                  
                            <h6><Gift width={'18px'} className="rwdlogo" /> Reward Customer</h6>
                            <div className="mb-4">
                                <div className="grey-text font9 mb-3">{OrderItems.customer_name} has spent {getVendorCurrency()}. {OrderItems.total_spent} so far—offering at least {getVendorCurrency()}. {OrderItems.total_spent/10} as a discount can go a long way in boosting loyalty and retention.</div>
                                <hr/>                               
                                 
                                <div className="row">
                                    <div className="col-6">
                                        <div className="grey-text font9">Voucher Code</div>
                                        <div className="input-group">
                                            <input type="text" className="form-control font9 ht40" name="pay_mode_desc_paybill" value={randomCode} readOnly/>
                                        </div> 
                                    </div>
                                    <div className="col-6">
                                        <div className="grey-text font9">Voucher Amount</div>
                                        <div className="input-group">
                                            <input type="number" className="form-control font9 ht40" name="OfferAmount" value={OfferAmount} onChange={handleOfferChange}/>
                                        </div> 
                                    </div>
                                </div>                              
                                <div className="grey-text font9 mt-3">SMS</div>
                                <div className="input-group">
                                    <textarea
                                        className="form-control font9"
                                        name="text_message"
                                        readOnly
                                        value={message}                                
                                    />
                                    <p></p>
                                </div>                                
                                <button type="button" onClick={sendSMS} disabled={loading} className="col-12 btn btn-primary mt-4">{loading ? "Sending..." : "Send SMS"}</button>
                                {error && <p className="mt-4 text-danger font9 text-center">{error}</p>}
                                {response && (
                                <p className="mt-4 text-success font9 text-center">
                                    SMS Sent Successfully: {JSON.stringify(response)}
                                </p>
                                )}

                                {OrderItems.sms == 0 && <><span className="badge bg-danger mt-4 me-2" data-bs-toggle="modal" data-bs-target="#smspy">Available SMS Credits: {OrderItems.sms}</span><span className='pointer' data-bs-toggle="modal" data-bs-target="#smspy">Recharge</span></>}
                                {OrderItems.sms !== 0 && <span className="badge bg-success mt-4">Available SMS Credits: {OrderItems.sms}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="smspy" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mt70 mw400">
                    <div className="modal-content">
                        <div className="modal-body pb-2">                                  
                            <h6><MessageSquare width={'18px'} className="rwdlogo" /> Recharge SMS Credits</h6>
                            <div className="mb-4">
                                <div className="grey-text font9 mb-1">Top up your SMS balance to send vouchers and promotions to customers effortlessly.</div>
                                <div className="grey-text font9 mb-3">Current Balance: {OrderItems.sms}</div>
                                <hr/>  
                                <div className="tab-content pymt pb-2" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                        <>
                                            <div className="mb-3 mt-3">
                                                    <label htmlFor="lnm" className="grey-text font9 mb-0 pb-0">Username</label>
                                                    <input type="text" className="form-control" id="lnm" name='username' onBlur={(e) => validateUsername(e.target.value)} onChange={inputHandler} value={ProfileData.username} />                                                        
                                                    {UsernameError && <small className="text-danger font8">Confirm username</small>}
                                            </div>
                                            <div className="mb-3 mt-3">
                                                    <label htmlFor="lnm" className="grey-text font9 mb-0 pb-0">Email</label>
                                                    <input type="email" className="form-control" id="lnm" name='email' onBlur={(e) => validateEmail(e.target.value)}  onChange={inputHandler} value={ProfileData.email} />                                                        
                                                    {EmailError && <small className="text-danger font8">Invalid email format. Kindly enter a valid email.</small>}
                                            </div>
                                            <div className="mb-3 mt-3">
                                                    <label htmlFor="lnm" className="grey-text font9 mb-0 pb-0">Amount</label>
                                                    <input type="number" className="form-control" id="lnm" name='smsval' onBlur={(e) => validateSmsVal(e.target.value)}  onChange={inputHandler} value={ProfileData.smsval} />                                                        
                                                    {SmsVal && <small className="text-danger font8">Amount should be atleast 10.</small>}
                                            </div>
                                            { UsernameError0 == '1' && EmailError0 == '1' && SmsVal0 == '1' ? <>
                                                    <PaystackButton 
                                                        className='btn col-12 text-light bg-dark mt-4'
                                                        text="Complete"
                                                        currency="KES"
                                                        publicKey={publicKey}
                                                        email={ProfileData.email}
                                                        amount={ProfileData.smsval * 100}
                                                        onSuccess={onSuccess}
                                                        onClose={onClose}
                                                    />                                                    
                                            </> : <>                                                    
                                                <button className='btn col-12 text-light bg-dark mt-3' disabled>Complete</button>
                                            </>} 
                                        </> 
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SellerCustomerOrders;