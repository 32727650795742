import Sidebar from './sidebar';
import { ArrowDown, Mail, Phone } from 'react-feather';
function BizDocsPdf(){
    return (
        <section className="container mtopcon dashb">           
            <div className="row">
                
                    <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max">
                        <div className="row mb-3 ph-header-con">
                            <div className="col-8 pt-2"><h6 className="pageheader-title mb-3">Download Invoice</h6></div>
                            <div className="col-4"><button className="btn text-white bg-dark f85 float-right"><ArrowDown className="topheadersvg"/></button></div>
                        </div>
                        <div className="pdfcon">
                            <div className="a4-container">
                                <div className="row p-3">

                                    <div className="col-3">
                                        <div className="mt-2" style={{ height: 70, width: '100%', background: 'blue'}}></div>
                                    </div>
                                    <div className="col-6 text-center">
                                        <ul>
                                            <li><b>EDGE GROUP AFRICA COMPANY LIMITED</b></li>
                                            <li>John Kemei</li>
                                            <li>Nairobi</li>
                                            <li><Phone width="18px" height="18px" className="mtn4"/> 0724166678 <Mail width="18px" height="18px" className="mtn4"/> stevemuokz@gmail.com</li>
                                            <li>PVT/5043452 <b>00100</b></li>
                                        </ul>
                                    </div>
                                    <div className="col-3 text-right">
                                        <b className="font2em">
                                            TAX<br/>
                                            INVOICE
                                        </b>
                                    </div>
                                    <hr/>

                                    <div className="col-4">
                                        <ul className="pl-0">
                                            <li><b>BILL TO</b></li>
                                            <li><b>Kadhr Africa</b></li>
                                            <li>Kadhr Africa</li>
                                            <li><Phone width="15px" height="15px"/> +254724166678</li>
                                            <li><Mail width="15px" height="15px" /> stevemuokz@gmail.com</li>
                                        </ul>
                                    </div>
                                    <div className="col-4">
                                        <ul>
                                            <li><b>SHIP TO</b></li>
                                            <li>Kadhr Africa</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-right">
                                        <ul>
                                            <li><b className="me-3">Invoice#</b> INV-1</li>
                                            <li><b className="me-3">Invoice Date:</b> 17/12/2025</li>
                                        </ul>
                                    </div>

                                    <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table align-middle table-nowrap table-centered mb-0 invcpdf">
                                                        <thead>
                                                            <tr className="bg-grey">
                                                                <th><div className="thmin">#</div></th>
                                                                <th><div className="thmin">DESCRIPTION</div></th>
                                                                <th className="text-end"><div className="thmin">QTY</div></th>
                                                                <th className="text-end"><div className="thmin">PRICE</div></th>
                                                                <th className="text-end"><div className="thmax">TAXABLE<br/>AMOUNT</div></th>
                                                                <th className="text-end"><div className="thmin">TAX</div></th>
                                                                <th className="text-end" ><div className="thmin">TOTAL</div></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                                <tr>
                                                                    <th scope="row">1</th>
                                                                    <td><b>Lorry brakes fluid</b><br/>jsdhfhdsf</td>
                                                                    <td className="text-end">2<br/>kg</td>
                                                                    <td className="text-end">Ksh 2,000</td>
                                                                    <td className="text-end">Ksh 2,000</td>
                                                                    <td className="text-end">Ksh 0.00<br/>0.0%</td>
                                                                    <td className="text-end">Ksh 2,000</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="4" className="border-bottom-none"></td>
                                                                    <td className="text-left b1pxgrey" colSpan="2">TAXABLE AMOUNT<br/>TAX</td>
                                                                    <td className="text-end b1pxgrey">Ksh 200<br/>Ksh 0.0</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="4" className="border-bottom-none"></td>
                                                                    <td className="text-left b2pxgrey bg-grey" colSpan="2"><b>GRAND TOTAL</b></td>
                                                                    <td className="text-end b2pxgrey bg-grey"><b>Ksh 2000</b></td>
                                                                </tr>

                                                        </tbody>
                                                </table>
                                            </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                            <div className="table-responsive">
                                                <table className="table align-middle table-nowrap table-centered mb-0">
                                                        <tbody>
                                                                <tr>
                                                                    <td className="col-9"><b>Terms & Conditions</b><br/>
                                                                        <ul className="trmslist">
                                                                            <li>17/12/2025</li>
                                                                            <li>17/12/2025</li>
                                                                            <li>17/12/2025</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td className="col-3"><b>Payment Instructions</b><br/>
                                                                        <ul className="trmslist">
                                                                            <li> Payment should be done via MPESA; 071111111</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="col-9"></td>
                                                                    <td className="col-3 text-end">
                                                                        <b>For, EDGE GROUP AFRICA COMPANY LIMITED</b><br/>
                                                                        <div className="mt-2" style={{ height: 70, width: '100%', background: 'blue'}}></div><br/>
                                                                        AUTHORIZED SIGNATURE
                                                                    </td>
                                                                </tr>

                                                        </tbody>
                                                </table>
                                            </div>
                                    </div>
                                    

                                </div>
                            </div>
                        </div>


                </div>
            </div>
        </section>
    )
}

export default BizDocsPdf;