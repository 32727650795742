import { useState } from "react";
import {Link} from 'react-router-dom';
import { useParams, useNavigate } from "react-router-dom";
import api from "./api"; // Your Axios instance

function VendorResetPassword() {
  const { token } = useParams(); // Get the token from the URL
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }
    
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await api.post(`/vendor/reset-password/${token}/`, { password });

      if (response.data.success) {
        setMessage("Password reset successful. Redirecting...");
        setError("");
        setTimeout(() => navigate("/seller/login"), 3000); // Redirect to login
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
    }
  };

  return (
    <section className="container mtopcon normal-mt-sctn">
                <div className="row">
                    <div className="col-12 col-lg-4 reglogincon">
                        <div className="card shadow-sm border border-secondary border-opacity-10">
                            <div className="card-header">
                                <div className='row p-0 m-0'>
                                    <p className=''>Reset Password </p>
                                </div>
                            </div> 
                            <div className="card-body">                          
                                    {error && <p className="text-danger">{error}</p>}
                                    {message && <p className="text-success">{message}</p>}
                                    <div className="mb-3 font9">
                                        <p>Enter new password</p>
                                    </div>
                                    <div className="mb-3">
                                        <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Enter new password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    
                                    <div className="mb-3">
                                        <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Confirm password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </div>
                                    
                                    <button 
                                        type="button" 
                                        className="col-12 btn btn-primary" 
                                        onClick={handleSubmit}
                                    >
                                        Reset Password
                                    </button> 
                                    <div className="mb-2 mt-4 font9">
                                        <p>Dont have an account? <Link to="/seller/register">Get started</Link></p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
  );
}

export default VendorResetPassword;
