import logo from '../logo.svg';
import {Link} from 'react-router-dom';
import { useState,useEffect } from 'react';
import { ChevronUp} from 'react-feather';
function Categories(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [categories,setCategories]=useState([]);
    const [totalResult,setTotalResults]=useState(0);
    const [loading, setLoading] = useState(false);

    const imgStyle = {
        width: '100%',
        height: '10vw',
        objectFit: 'contain',
    };

    useEffect(()=>{
        fetchData(baseUrl+'/categories');
    },[]);

    function fetchData(baseurl){
        setLoading(true);
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setLoading(false);
            setCategories(data.results);
            setTotalResults(data.count);
        });
    }

    function changeUrl(baseurl){
        fetchData(baseurl);
    }

    var links=[];
    var limit=12;
    var totalLinks=totalResult/limit;
    for(let i=1; i<=totalLinks; i++){
        links.push(<li className="page-item"><Link onClick={()=>changeUrl(baseUrl+`/categories/?page=${i}`)} to={`/categories/?page=${i}`} className="page-link">{i}</Link></li>)
    }

    return (
        <section className="container mtopcon normal-mt-sctn">
                <div className="col-12 cat_div p-2"> 
                    <h3 className="mb-5 text-center">Sell Anything</h3>       
                    <div className='row'>
                        { loading === true && <>Loading...</>}
                        {
                            categories.map((category)=>{
                                return (
                                        <div className='col-6 col-lg-3'>
                                            <Link to={`/category/${category.title}/${category.id}`} className='text-dark'>
                                                <div className='cat-imgcon'><img src={category.cat_img} alt="..."/></div>
                                            </Link> 
                                        </div>
                                    
                                )
                                
                            })
                        }     
                    </div> 
                </div>

        </section>
    )
}

export default Categories;