import {Link} from 'react-router-dom';
import { Plus, Edit, Trash} from 'react-feather';
import Sidebar from './sidebar';
import { useState,useEffect,useContext } from 'react';
import api from "../api";
import { ExchangeRateContext } from '../../ExchangeRateContext';
import SingleProductPos from '../singleproductpos';
import { FaReceipt } from 'react-icons/fa';
import useStoreSetupCheck from './useStoreSetupCheck';
function SellerPos(){    
    const setupStatus = useStoreSetupCheck();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl0 = process.env.REACT_APP_BASE_URL_IMG;
    const [ProductData, setProductData] = useState([]);
    const [productImgs, setProductImgs] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOption, setSortOption] = useState("default"); 
    const [error, setError] = useState("");
    const [DelId, setDelId] = useState("");
    const [DelIdStatus, setDelIdStatus] = useState(false);
  
    const { exchangeRates, baseCurrency } = useContext(ExchangeRateContext);
    const token = localStorage.getItem("access_token");
    const fetchVendorProducts = async () => {
        try {
          const response = await api.get("/vendor-products/", {
            headers: {
              Authorization: `Bearer ${token})}`,
            },
          });
          setProductData(response.data.products);
          setProductImgs(response.data.product_imgs);
        } catch (error) {
          //console.error("Error fetching products:", err.toJSON());
          /*setError("Failed to fetch products. Please try again.");
            if (axios.isCancel(error)) {
                console.log("Request canceled:", error.message);
            } else if (error.response) {
                // Server responded with a status outside the 2xx range
                console.error("Error status:", error.response.status);
                console.error("Response data:", error.response.data);
            } else if (error.request) {
                // Request made, but no response received
                console.error("No response received:", error.request);
            } else {
                // Something else triggered the error
                console.error("Error:", error.message);
            }*/
        }
    };
    
    useEffect(() => {
        fetchVendorProducts();
    }, []);

    function deleteStepOne(product){
        setDelId(product);
        setDelIdStatus(false);
      }
      function handleDelete(){
          api.delete(baseUrl+'/product/'+DelId+'/', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function (response){
            //console.log(response.status);
              if(response.status==204){
                  fetchVendorProducts();
                  setDelIdStatus(true);
              }
          })
          .catch(function(error){
              //console.log(error);
          });
      }

    // Filter and Sort Products
    const filteredAndSortedResults = ProductData.filter((product) => {
    const search = searchTerm.toLowerCase();
    return (
        (product.title && product.title.toLowerCase().includes(search)) ||
        (product.price && product.price.toString().toLowerCase().includes(search))
    );
    }).sort((a, b) => {
        if (sortOption === "price_high_low") return b.price - a.price;
        if (sortOption === "price_low_high") return a.price - b.price;
        if (sortOption === "qty_high_low") return b.stock - a.stock;
        if (sortOption === "qty_low_high") return a.stock - b.stock;
        if (sortOption === "default") return b.id - a.id;
        return 0; // Default sorting (no change)
    });

    const getVendorCurrency = () => {
        return localStorage.getItem("VendorCurrency");
    };
    let previousCart = localStorage.getItem('cartData');
    let cartJson = JSON.parse(previousCart) || [];
    if(cartJson == null){
        var cartItems=0;
    }else{
        var cartItems=cartJson.length;
    }
    return (
        <section className="container mtopcon dashb">    
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max orderscontainer">      
                        <Link to="/checkout" className="wappcomms0 tp80 btn-hover float-left">
                            <FaReceipt className='text-white'/> {cartItems}
                        </Link>     
                        <div className="row">
                            <h6 className="pageheader-title mb-0">Point Of Sale</h6>
                            <p className="grey-text lh0 font8">Quickly take orders, process payments, and manage sales in one smooth workflow.</p>
                        </div>
                        <div className="row mb-3">
                            <div className="col-8">
                                <input
                                    className="filter2 border me-2 w-100"
                                    type="search"
                                    placeholder="Search Products"
                                    aria-label="Search"
                                    onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on keyup
                                />
                            </div>
                            <div className="col-4">
                                    <select className="filter2 text-center border w-100 h-100" name='category' onChange={(e) => setSortOption(e.target.value)}>
                                        <option value="default">Sort</option>
                                        <option value="price_high_low">Price from High to Low</option>
                                        <option value="price_low_high">Price from Low to High</option>
                                        <option value="qty_high_low">Qty from High to Low</option>
                                        <option value="qty_low_high">Qty from Low to High</option>
                                    </select>
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="row mb-4 mt-3">  
                                {filteredAndSortedResults.length === 0 ? (
                                    <p>No products found.</p>
                                ) : (
                                    <>
                                        {filteredAndSortedResults.map((product) => (
                                            <SingleProductPos product={product} key={product.id} />
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                </div>
            </div>

            <div className="modal fade" id="confirmdelModal" tabIndex="-1" aria-labelledby="confirmdelModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header p-2">
                        <h1 className="modal-title fs-5" id="confirmdelModalLabel">Confirm</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        {DelIdStatus==true && <>Deleted Successfully</>}
                        {DelIdStatus==false && <>Are you sure you want to delete this product?</>}
                        
                    </div>
                    <div className="modal-footer p-2">                      
                        {DelIdStatus==true && <><button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button></>}
                        {DelIdStatus==false && <><button type="button" className="btn btn-danger" onClick={() => handleDelete()}>Delete</button></>}
                        
                    </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default SellerPos;