import React, { createContext, useState, useEffect } from "react";

export const UserContext = createContext({ 'login': false });
export const VendorContext = createContext({ 'login': false });
export const CartContext = createContext();
export const VendorDataContext = createContext();


// Create context
export const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [exchangeRate, setExchangeRate] = useState(1); // Default exchange rate (1:1 for USD)
  const [baseCurrency, setBaseCurrency] = useState("USD"); // Original currency stored when adding products

  useEffect(() => {
    // Get currency from local storage
    const storedCurrencyData = localStorage.getItem("selectedCurrency");
    if (storedCurrencyData) {
      const { currency } = JSON.parse(storedCurrencyData);
      setSelectedCurrency(currency);
    }

    // Fetch latest exchange rate
    if (selectedCurrency && selectedCurrency !== baseCurrency) {
      fetch(`https://api.exchangerate-api.com/v4/latest/${baseCurrency}`)
        .then((res) => res.json())
        .then((data) => {
          setExchangeRate(data.rates[selectedCurrency] || 1);
        })
        .catch((err) => console.error("Error fetching exchange rate:", err));
    }
  }, [selectedCurrency, baseCurrency]);

  return (
    <CurrencyContext.Provider value={{ selectedCurrency, exchangeRate, setSelectedCurrency, setBaseCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};
