import {Link, useParams, useNavigate} from 'react-router-dom';
import sellerlogo1 from '../shoplogo2.jpg';
import { ShoppingCart, Heart, CreditCard, Share2, Facebook, Twitter,Phone, MapPin, XSquare, Star} from 'react-feather';
import SingleProduct from './singleproduct';
import { useState,useEffect, useContext } from 'react';
import { UserContext,CartContext } from '../context';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { ExchangeRateContext } from '../ExchangeRateContext';

function ProductDetail(){  
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const location = useLocation();
    const [vendorID,setvendorID]=useState([]);
    const [productData,setProductData]=useState([]);
    const [productImgs,setProductImgs]=useState([]);
    const [ReviewList,setReviewList]=useState([]);
    const [relatedProducts,setrelatedProduct]=useState([]);
    const {product_slug,product_id} = useParams();
    const [cartButtonClickStatus,setcartButtonClickStatus]=useState(false);
    const [ProductInWishlist,setProductInWishlist]=useState(false);
    const [shareContainer, setShareContainer] = useState(false);
    const [SizeError,setSizeError]=useState('');
    const [SizeItems,setSizeItems]=useState([["", ""]]);
    const navigate = useNavigate();

    const {cartData,setCartData}=useContext(CartContext);
    const userContext=useContext(UserContext);    

    const { exchangeRates, baseCurrency } = useContext(ExchangeRateContext);
    if(exchangeRates==null){window.location.reload();}
    
    const customerId = localStorage.getItem('customer_id');

    useEffect(()=>{
        fetchData(baseUrl+'/minimal-product/'+product_id);
        fetchTestimonialData(baseUrl+'/productrating/');
        fetchRelatedData(baseUrl+'/related-products/'+product_id,product_id);
        checkProductInCart(product_id);
        if(customerId!==null){checkProductInWishlist(baseUrl+'/check-in-wishlist/',product_id);}  
        fetchSizeItems(baseUrl+'/getsizesu/'+product_id);    
    },[]);

    var _stars=[];var _stars0=[];
    function fetchData(baseurl) {
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setProductData(data); // Set product data
            setProductImgs(data.product_imgs); // Set product images            
            localStorage.setItem('active_vendor_name', data.vendor.user.username);    
            // Extract vendor ID from the product data
            const vendorID = data.vendor ? data.vendor.id : null;
            if (vendorID) {
                fetchTrafficData(vendorID); // Pass vendorID to the traffic data function
            }
        })
        .catch((error) => {
            //console.error('Error fetching product data:', error.message);
        });
    }
    
    const fetchTrafficData = async (vendorID) => {
        try {
            // Fetch IP address without sending credentials
            const ipResponse = await fetch('https://api.ipify.org?format=json', { credentials: 'omit' });
            const ipData = await ipResponse.json();
            const userIP = ipData.ip;
        
            // Gather other details
            const userAgent = navigator.userAgent;
            const deviceType = /Mobi|Android/i.test(userAgent) ? 'Mobile' : 'Desktop';
            const os = navigator.platform;
            const browser = (() => {
                if (userAgent.includes('Chrome')) return 'Chrome';
                if (userAgent.includes('Firefox')) return 'Firefox';
                if (userAgent.includes('Safari')) return 'Safari';
                if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) return 'Internet Explorer';
                return 'Unknown';
            })();
        
            // Check if vendorID is missing or invalid, and provide a fallback value
            if (!vendorID) {
                //console.error('No vendor ID available, using default value.');
                vendorID = 'default_vendor_id'; // You can use a default ID or empty string, depending on your business logic.
            }
    
            // Prepare traffic data
            const trafficData = {
                ip: userIP,
                deviceType,
                os,
                browser,
                product: product_id, // Ensure product_id is defined or passed as needed
                vendor: vendorID, // Pass vendorID as part of the traffic data
            };
        
            // Post the data using fetch
            const response = await fetch(baseUrl + '/prodview/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(trafficData)
            });
        
            if (!response.ok) {
                throw new Error(`Failed to log traffic data: ${response.statusText}`);
            }
            
            //console.log('Traffic data logged successfully');
        } catch (error) {
            //console.error('Error logging traffic data:', error.message);
        }
    };
    
    

    function checkProductInCart(product_id){
        var previousCart=localStorage.getItem('cartData');
        var cartJson=JSON.parse(previousCart);
        if(cartJson!=null){
            cartJson.map((cart)=>{
                if(cart!=null && cart.product.id == product_id){
                    setcartButtonClickStatus(true);
                }
            });
        }
    }

    function fetchTestimonialData(baseurl){
       fetch(baseurl)
       .then((response) => response.json())
       .then((data) => {
           setReviewList(data.results);
       });
   }

   function fetchSizeItems(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {            
            const sizes = data.results.map(sizeItem => [sizeItem.size, sizeItem.qty]);
            setSizeItems(sizes);
        });
    }

    function fetchRelatedData(baseurl, product_id) {
        fetch(baseurl)
            .then((response) => response.json())
            .then((data) => {
                // Filter out the product with the specified product_id
                const filteredProducts = data.results.filter((product) => product.id !== product_id);
                setrelatedProduct(filteredProducts);
            });
    }
    

    const cartAddButtonHandler = () => {
        setSizeError("");  
        if (selectedOption === "") {
            // Scroll to the size pick container
            const sizePickCon = document.querySelector('.size-pick-con');
            if (sizePickCon) {
                sizePickCon.scrollIntoView({ behavior: 'smooth', block: 'center' });
                setSizeError("Kindly select preferred size.");                
            }
            return;
        }
        let previousCart = localStorage.getItem('cartData');
        let cartJson = JSON.parse(previousCart) || [];
        
        const cartData = {
            'product': {
                'id': productData.id,
                'title': productData.title,
                'image': productData.product_imgs[0].image,
                'price': productData.price,
                'currency': productData.currency,
                'size': selectedOption,
                'quantity': 1,
            },
            'user': {
                'id': 1,
            },
            'total_amount': 0,
        };
    
        // Check if the product is already in the cart
        let productExists = false;
        cartJson = cartJson.map(cartItem => {
            if (cartItem && cartItem.product.id === productData.id && cartItem.product.size === selectedOption) {
                productExists = true;
                cartItem.quantity += 1; // Increment quantity if the product exists
                cartItem.total_amount = cartItem.quantity * cartData.product.price; // Update total amount
            }
            return cartItem;
        });
    
        if (!productExists) {
            cartData.total_amount = cartData.quantity * cartData.product.price; // Set initial total amount
            cartJson.push(cartData); // Add the product as a new item if it doesn't exist
        }
    
        localStorage.setItem('cartData', JSON.stringify(cartJson));
        setCartData(cartJson);
        setcartButtonClickStatus(true);
    };
    
    const buyAddButtonHandler = () => {
        setSizeError("");  
        if (selectedOption === "") {
            // Scroll to the size pick container
            const sizePickCon = document.querySelector('.size-pick-con');
            if (sizePickCon) {
                sizePickCon.scrollIntoView({ behavior: 'smooth', block: 'center' });
                setSizeError("Kindly select preferred size.")                
            }
            return;
        }
        let previousCart = localStorage.getItem('cartData');
        let cartJson = JSON.parse(previousCart) || [];
        
        const cartData = {
            'product': {
                'id': productData.id,
                'title': productData.title,
                'image': productData.product_imgs[0].image,
                'price': productData.price,
                'currency': productData.currency,
                'size': selectedOption,
                'quantity': 1,
            },
            'user': {
                'id': 1,
            },
            'total_amount': 0  // Initialize quantity as 1
        };
    
        // Check if the product is already in the cart
        let productExists = false;
        cartJson = cartJson.map(cartItem => {
            if (cartItem && cartItem.product.id === productData.id && cartItem.product.size === selectedOption) {
                productExists = true;
                cartItem.quantity += 1; // Increment quantity if the product exists
                cartItem.total_amount = cartItem.quantity * cartData.product.price; // Update total amount
            }
            return cartItem;
        });
    
        if (!productExists) {
            cartData.total_amount = cartData.quantity * cartData.product.price; // Set initial total amount
            cartJson.push(cartData); // Add the product as a new item if it doesn't exist
        }
    
        localStorage.setItem('cartData', JSON.stringify(cartJson));
        setCartData(cartJson);
        setcartButtonClickStatus(true);
        window.location.href='/checkout';
    }
    const cartRemoveButtonHandler = () => {
        var previousCart=localStorage.getItem('cartData');
        var cartJson=JSON.parse(previousCart);
        cartJson.map((cart,index)=>{
            if(cart!=null && cart.product.id == productData.id){
                cartJson.splice(index,1);
            }
        });
        var cartString=JSON.stringify(cartJson);
        localStorage.setItem('cartData',cartString);
        setcartButtonClickStatus(false);
        setCartData(cartJson);
    }

    function saveInWishlist(){
        const formData=new FormData();
        formData.append('customer',customerId);
        formData.append('product',productData.id);
        
        
        //SUBMIT DATA
        axios.post(baseUrl+'/wishlist/',formData)
        .then(function (response){
            if(response.data.id){
                setProductInWishlist(true);
            }   
        })
        .catch(function(error){
            //console.log(error);
        });        
    }
    
    function checkProductInWishlist(baseurl,product_id){
        const customerId = localStorage.getItem('customer_id');
        const formData=new FormData();
        formData.append('customer',customerId);
        formData.append('product',product_id);        
        
        //SUBMIT DATA
        axios.post(baseurl,formData)
        .then(function (response){
            if(response.data.bool==true){
                setProductInWishlist(true);
            }else{setProductInWishlist(false);}    
        })
        .catch(function(error){
            //console.log(error);
        });
    }
    
    function open(){
        setShareContainer(!shareContainer);
    };

    const [selectedOption, setSelectedOption] = useState('');

    // Function to handle the change in selection
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    var _stars=[];var _stars0=[];
    const ratingstar = productData.vendor ? Math.floor(productData.vendor.ratings_average) : null;
    const nullratingstar = 5 - ratingstar;

    for(let i=0; i<ratingstar; i++){
        _stars.push('1');
    }

    for(let j=0; j<nullratingstar; j++){
        _stars0.push('1');
    }
           
    var price = productData.price;
    var currency = productData.currency;

    // Convert price from the product's original currency to the selected base currency
    const productExchangeRate = exchangeRates[currency] || 1;
    const baseExchangeRate = exchangeRates[baseCurrency] || 1;

    // Calculate the converted price
    const convertedPrice = ((price / productExchangeRate) * baseExchangeRate).toFixed(2);

    
    const fetchStoreStatus = async () => {
        try {
            const response = await axios.get(`${baseUrl}/${productData.vendor.user.username}/status/`);

            // Redirect to shop suspended page if package is inactive
            if (response.data.package_status === "inactive" || response.data.package_status === null) {
                navigate("/loader");
            }
        } catch (error) {
            //console.error("Error fetching store status:", error);
        }
    };

    fetchStoreStatus();
    
    return (
        <section className="container mtopcon normal-mt-sctn dtlspg">
            <div className="row">
                <div className="col-12 col-md-4 col-lg-4 pt-0 w500">                    
                    <div className="col-12 shadow-sm border border-secondary border-opacity-10 rounded-3 pro-box">
                        <div id="carouselExampleIndicators11" className="carousel carousel-light slide bg-white text-dark" data-bs-ride="true">
                         <div className="carousel-indicators">
                            {
                                productImgs.map((img,index)=>{
                                    if(index===0){
                                        return <button type="button" data-bs-target="#carouselExampleIndicators11" data-bs-slide-to={index} className="active details-indicators" aria-current="true" aria-label="Slide 1"></button>                            
                                    }else{
                                        return <button type="button" data-bs-target="#carouselExampleIndicators11" data-bs-slide-to={index} aria-current="true" className='details-indicators' aria-label="Slide 1"></button>                                              
                                    }
                                })
                            }
                        </div>
                        <div className="carousel-inner">
                            {
                                productImgs.map((img,index)=>{
                                    if(index===0){
                                        return <div className="carousel-item active">
                                                    <img src={img.image} className="card-img-top pro-img" alt={index}/>
                                                </div>                            
                                    }else{
                                        return <div className="carousel-item">
                                                    <img src={img.image} className="card-img-top pro-img" alt={index}/>
                                                </div>                                                
                                    }
                                    
                                })
                            }
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 detail-con">                    
                    <div className='row new-card'>
                        <div className='col-10'>
                            <h5 className='text-dark'><b>{productData?.title || 'Product Title'}</b></h5>                            
                            <h5 className="bluetxt"><b>{baseCurrency}. {new Intl.NumberFormat().format(convertedPrice)}</b></h5>
                        </div>
                        <div className='col-2'>
                            <div className='share-btn' onClick={open}><Share2/></div>
                            {shareContainer && (
                                <div className="row sharelinks">
                                    {/* Facebook Share */}
                                    <div className="col-4">
                                        <div 
                                            className="col-12 fb" 
                                            onClick={() => {
                                            const url = window.location.href;
                                            const text = encodeURIComponent(
                                                `Hey! I have this brand called ${productData.vendor.user.username} and we've got this cool ${productData.title} going for ${productData.price}. Check it out here: ${url}`
                                            );
                                            window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`, '_blank');
                                            }}
                                        >
                                            <Facebook />
                                        </div>
                                    </div>

                                    {/* Twitter Share */}
                                    <div className="col-4">
                                        <div 
                                            className="col-12 tw" 
                                            onClick={() => {
                                            const url = window.location.href;
                                            const text = encodeURIComponent(
                                                `Hey! I have this brand called ${productData.vendor.user.username} and we've got this cool ${productData.title} for ${productData.price}. Check it out here:`
                                            );
                                            window.open(`https://twitter.com/intent/tweet?text=${text}&url=${url}`, '_blank');
                                            }}
                                        >
                                            <Twitter />
                                        </div>
                                    </div>

                                    {/* WhatsApp Share */}
                                    <div className="col-4">
                                        <div 
                                            className="col-12 wa" 
                                            onClick={() => {
                                            const url = window.location.href;
                                            const text = encodeURIComponent(
                                                `Hey! I have this brand called ${productData.vendor.user.username} and we've got this cool ${productData.title} for ${productData.price}. Check it out here: ${url}`
                                            );
                                            window.open(`https://wa.me/?text=${text}`, '_blank');
                                            }}
                                        >
                                            <Phone />
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        <div className='col-12'>
                            <hr/>
                            <div className="row body-font"><p className='txtlight col-12'>{productData.detail}</p></div>
                            <div className='row mt-2 body-font'>
                                <div className='col-12 mb-3'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='col-12'><b>Gender</b></div>
                                            <div className='col-12 txtlight'>{productData.gender}</div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='col-12'><b>Category</b></div>
                                            <div className='col-12 txtlight'>
                                                {
                                                    productData.category && <>{productData.category.title}</>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </div>
                    
                    <div className='col-12 text-dark mb-3 mt-4'><h5 className=''><b>Discover Who We Are</b></h5></div>
                    <div className="container card d-flex justify-content-left p-3">
                        <div className="d-flex align-items-center dtlspage">
                            <div className="ml-3 w-100">   
                                    <div className='row'>
                                        <div className='col-12 p-0 strprfldtls'>
                                            <p className="mb-2 p-0 text-dark">
                                                <b className='tcone'>
                                                    {
                                                        productData.vendor && <Link to={`https://kadhr.com/${productData.vendor.user.username}`}>                                             
                                                        {productData.vendor.user.username}</Link> 
                                                    }    
                                                </b>
                                            </p>
                                                <div className="p-2 bg-shade d-flex justify-content-between rounded text-white stats">
                                                        <div className="d-flex flex-column">
                                                            <span className="articles">Products</span>
                                                            <span className="number1">
                                                                {
                                                                    productData.vendor && <>{productData.vendor.total_products}</>
                                                                }
                                                            </span>                        
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <span className="articles">Orders</span>						                        
                                                            <span className="number1">
                                                                {
                                                                    productData.vendor && <>{productData.vendor.total_orders}</>
                                                                }
                                                            </span>                      
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <span className="articles">Rating</span>
                                                            <span className="number1">
                                                                {
                                                                    _stars.map(()=> <Star className='orange-font'/>)
                                                                }
                                                                {
                                                                    _stars0.map(()=> <Star className='grey-text'/>)
                                                                }
                                                            </span>                        
                                                        </div>                      
                                                </div>
                                        </div>
                                        <div className="p-0 joineddt strloc grey-text mt-3 tcone"><MapPin className='me-1'/>
                                            {
                                                productData.vendor && <>{productData.vendor.address}</>
                                            }
                                        </div>
                                    </div>	
                            </div>                    
                        </div>            
                    </div>  
                    
                </div>
                <div className="col-12 col-md-4 col-lg-4 pt-0 detail-con">
                <div className='col-12 text-dark'><h5 className=''><b>Select Size</b></h5></div>
                    <div className="mb-3 box-shadow">
                        <div className="row mb-3">
				            <ul className='size-pick-con p-0 m-0'>
                                {
                                    SizeItems.map((item,index)=>{ 
                                        return <li key={index}>
                                                    <div className="left">{item[0]}</div>
                                                    <div className="right">
                                                        <input
                                                            type="radio"
                                                            className="itmsz"
                                                            value={item[0]}
                                                            checked={selectedOption === item[0]}
                                                            onChange={handleOptionChange}
                                                        />
                                                    </div>
                                                </li>
                                        })
                                }
                            </ul>		
                            <div className="clear"></div>
                            {SizeError && <span className='text-danger font8 mt-2'>{SizeError}</span>}
                        </div>
                        
                    </div>

                    <div className="card-footer border-0 mt-5 hdmobile">
                        {!cartButtonClickStatus && <>
                                <button type="button"  onClick={buyAddButtonHandler} className="btn btn-danger prodt orange mb-3 me-3 btnhght"><CreditCard /> Buy now</button>
                                <button type="button" onClick={cartAddButtonHandler} className="btn btn-info prodt text-white mb-3 me-3 btnhght"><ShoppingCart /> Add to cart </button> 
                            </>
                        }
                        {cartButtonClickStatus && <>
                                <button type="button" onClick={cartRemoveButtonHandler} className="btn font-bold prodt text-danger mb-3 me-3 btnhght">Remove</button> 
                                <button type="button"  onClick={buyAddButtonHandler} className="btn btn-success prodt mb-3 me-3 btnhght"><ShoppingCart /> View cart</button>
                            </>
                        }
                        
                            {
                                (userContext && !ProductInWishlist) && 
                                <button className='btn btn-outline-dark prodt w-auto grey-text mb-3 me-3 btnhght'><Heart className='m-0' onClick={saveInWishlist}/></button>
                            }
                            {
                                (userContext && ProductInWishlist) && 
                                <Link to={`/customer/wishlist`}><button className='btn btn-danger prodt w-auto mb-3 me-3 btnhght'><Heart className='m-0'/></button></Link>
                            }
                            {
                                userContext == null && <Link to={`/customer/login`}><button className='btn btn-outline-dark prodt w-auto grey-text mb-3 me-3 btnhght'><Heart onClick={saveInWishlist}/></button></Link>
                            }  
                    </div>

                </div>
                {/*Related pros start*/}
                <div className="col-12 mt-5">
                        
                    <h3>You may also like</h3><br/>
                    
                    <div className="row mb-5">
                            {
                                relatedProducts.map((product,index)=><SingleProduct key={index} product={product} />)
                            }
                    </div>
                </div>
                {/*Related pros end*/}
            </div>
            <div className='like-box hddsktp br-10'>
                {
                    (userContext && !ProductInWishlist) && 
                    <button className='btn btn-outline-dark prodt w-auto grey-text'><Heart className='m-0' onClick={saveInWishlist}/></button>
                }
                {
                    (userContext && ProductInWishlist) && 
                    <Link to={`/customer/wishlist`}><button className='btn btn-danger prodt w-auto'><Heart className='m-0'/></button></Link>
                }
                {
                    userContext == null && <Link to={`/customer/login`}><button className='btn btn-outline-dark prodt w-auto grey-text'><Heart className='m-0' onClick={saveInWishlist}/></button></Link>
                } 
            </div>
            <div className={`row btm-nav dtlsbtmnav`}>     
                    {!cartButtonClickStatus && <>
                        <div className='col-6'><button type="button"  onClick={buyAddButtonHandler} className="btn btn-danger prodt orange mb-3 me-3 w-100"><CreditCard /> Buy now</button></div> 
                        <div className='col-6'><button type="button" onClick={cartAddButtonHandler} className="btn btn-info prodt text-white mb-3 w-100"><ShoppingCart /> Add to cart </button></div>
                        </>
                    }
                    {cartButtonClickStatus && <>
                        <div className='col-6'><button type="button" onClick={cartRemoveButtonHandler} className="btn font-bold prodt text-danger mb-3 w-100">Remove </button></div>
                        <div className='col-6'><Link to="/checkout"><button type="button" className="btn btn-success prodt text-white mb-3 me-3 w-100"><ShoppingCart /> View cart</button></Link></div> 
                        </>
                    }

            </div>

        </section>
    )
}

export default ProductDetail;