import {Link} from 'react-router-dom';
import { useState,useEffect } from 'react';
import { useParams } from "react-router-dom";
import SingleProduct from './singleproduct';
import Testimonial from './testimonial';
import { Facebook, Instagram, MapPin, Star, Twitter, Youtube } from 'react-feather';
import { FaBars, FaStar, FaTiktok } from "react-icons/fa";
import useStoreStatusCheck from './useStoreSetupCheck';
function ShopPage(){    
    useStoreStatusCheck();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl0 = process.env.REACT_APP_BASE_URL_IMG;
    const [ProductList,setProductList]=useState([]);
    const { shopname } = useParams();
    const [sortOption, setSortOption] = useState("default"); 
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [VendorData,setVendorData]=useState({
        'profile_img':'',
        'user':{
            'username':'',
            'total_products':0
        }
    });
    const [totalResult,setTotalResults]=useState(0);
    const [ReviewList,setReviewList]=useState([]);

    const lastLogTime = localStorage.getItem('lastLogTime');
    const currentTime = new Date().getTime();
    const interval = 10 * 60 * 1000; // 10 minutes in milliseconds

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
        try {
            const response = await fetch(`${baseUrl}/shop/${shopname}/categories/`, {
            headers: {
                "Content-Type": "application/json",
            },
            });
            if (response.ok) {
            const data = await response.json();
            setCategories(data.categories);
            } else {
            //console.error('Failed to fetch categories');
            }
        } catch (error) {
            //console.error('Error fetching categories:', error);
        }
        };

        fetchCategories();
    }, [shopname]);

    useEffect(()=>{      
        fetchVendorData(baseUrl+'/minimal-vndr/'+shopname);
        fetchData(baseUrl+'/vndr-products/'+shopname);
        fetchTestimonialData(baseUrl+'/productrating/');          
    },[]);       

    function fetchData(baseurl){
        setLoading(true);
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setLoading(false);
            setProductList(data.results);
            setTotalResults(data.count);
        });
    }

    function fetchVendorData(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            if (data.bool === false) {
                window.location.href = "/categories"; // Redirect to categories page
            }
            setVendorData(data);   
            localStorage.setItem('active_vendor_name', data.user.username);
            fetchTrafficData(data.id);
        });
    }

    function changeUrl(baseurl){
        fetchData(baseurl);
    }

    var links=[];
    var limit=12;
    var totalLinks=totalResult/limit;
    for(let i=1; i<=totalLinks; i++){
        links.push(<li className="page-item"><Link className="page-link">{i}</Link></li>)
    }

    function fetchTestimonialData(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setReviewList(data.results);
        });
    }
    

    const fetchTrafficData = async (sellerId) => {
        try {
            // Get user IP
            const ipResponse = await fetch('https://api.ipify.org?format=json');
            const ipData = await ipResponse.json();
            const userIP = ipData.ip;
    
            // Get device, OS, and browser information
            const userAgent = navigator.userAgent;
            const deviceType = /Mobi|Android/i.test(userAgent) ? 'Mobile' : 'Desktop';
            const os = navigator.platform;
            const browser = (() => {
                if (userAgent.includes('Chrome')) return 'Chrome';
                if (userAgent.includes('Firefox')) return 'Firefox';
                if (userAgent.includes('Safari')) return 'Safari';
                if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) return 'Internet Explorer';
                return 'Unknown';
            })();
    
            // Prepare traffic data
            const trafficData = {
                ip: userIP,
                deviceType,
                os,
                browser,
                timestamp: new Date().toISOString(),
                store: sellerId,
            };
    
            // Check time interval before logging data
            const currentTime = Date.now();
            const lastLogTime = localStorage.getItem('lastLogTime');
            const interval = 60000; // 1 minute
    
            if (!lastLogTime || currentTime - lastLogTime > interval) {
                const response = await fetch(`${baseUrl}/movement/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(trafficData)
                });
    
                if (response.ok) {
                    localStorage.setItem('lastLogTime', currentTime.toString());
                } else {
                    //console.error("Error logging traffic", response.statusText);
                }
            }
        } catch (error) {
            //console.error('Error logging traffic data:', error);
        }
    };

    // Filter and Sort Products
    const filteredAndSortedResults = ProductList.sort((a, b) => {
        if (sortOption === "price_high_low") return b.price - a.price;
        if (sortOption === "price_low_high") return a.price - b.price;
        if (sortOption === "default") return b.id - a.id;
        return 0; // Default sorting (no change)
    });
    

    var _stars=[];var _stars0=[];
    const ratingstar = VendorData ? Math.floor(VendorData.ratings_average) : null;
    const nullratingstar = 5 - ratingstar;

    for(let i=0; i<ratingstar; i++){
        _stars.push('1');
    }

    for(let j=0; j<nullratingstar; j++){
        _stars0.push('1');
    }
    const getFirstLetter = (str) => str?.charAt(0) || '';
    return (
        <section className="container mtopcon normal-mt-sctn">
            <a data-bs-toggle="modal" data-bs-target="#exampleModal1" className="wappcomms0 tp80 btn-hover float-left">
                <FaBars className='text-white'/>
            </a>
            {/*Categories start*/}
                <div className="row mb-3 p-2">          
                    <div className="d-flex flex-row flex-nowrap overflow-auto p-1">
                        {categories.map((category) => (
                            <div key={category.id} className="col-6 col-md-3 col-lg-3 mb-2 mt-2">
                                    <Link to={`/${shopname}/${category.title.replace(/\s+/g, '-').toLowerCase()}/${category.id}`}><img src={baseUrl0+category.cat_img} className="card-img-top br-10" alt="..."/></Link>
                            </div>
                        ))}
                    </div>
                </div>        
                {/*Categories end*/}           
            <ul className="nav shoptab nav-pill font9" id="pills-tab" role="tablist">
                <li className="col-12 nav-item ms-auto mb-4 mb-lg-0" role="presentation">
                    <select className="filter2 text-center border w-100" name='category' onChange={(e) => setSortOption(e.target.value)}>
                        <option value="default">Sort Products</option>
                        <option value="price_high_low">Price High to Low</option>
                        <option value="price_low_high">Price Low to High</option>
                    </select>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                    <div className="row mb-4 mt-3">        
                        {filteredAndSortedResults.length === 0 ? (                            
                            loading === true ? <><p>Loading</p></> : <><p>This Shop hasn't uploaded products yet.</p></>
                        ) : (
                            <>
                                {filteredAndSortedResults.map((product) => (
                                    <SingleProduct product={product} key={product.id} />
                                ))}
                            </>
                        )}

                    </div>  
                    
                </div>
            </div>
                  

                <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog mt70">
                        <div className="modal-content">
                        <div className="container shopdtlcontmax d-flex justify-content-left p-3">
                                    <div className="col-12">
                                        <div className="ml-3 w-100">   
                                            <div className='row'>
                                                <div className='col-3'><p  className="prfl-img tcone">{getFirstLetter(VendorData.user.username)}</p></div>
                                                <div className='col-9 strprfldtls'>
                                                    <h4 className="mb-1 mt-0"><b>{VendorData.user.username}</b></h4>
                                                    <span className="joineddt strloc grey-text"><MapPin/> {VendorData.address}</span>
                                                </div>
                                            </div>
                                            <div className="p-4 mt-3 bg-shade d-flex justify-content-between rounded text-white stats">
                                                    <div className="d-flex flex-column">
                                                        <span className="articles">Products</span>
                                                        <span className="number1">
                                                            {
                                                                VendorData && <>{VendorData.total_products}</>
                                                            }
                                                        </span>                        
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <span className="articles">Orders</span>						                        
                                                        <span className="number1">
                                                            {
                                                                VendorData && <>{VendorData.total_orders}</>
                                                            }
                                                        </span>                      
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <span className="articles">Rating</span>
                                                        <span className="number1">
                                                            {
                                                                _stars.map(()=> <FaStar className='orange-font'/>)
                                                            }
                                                            {
                                                                _stars0.map(()=> <Star className='grey-text'/>)
                                                            }
                                                        </span>                        
                                                    </div>                      
                                            </div>
                                            <div className='row mt-4 m-0 p-0'>
                                                <h6 className='font-bold mb-3 text-left'>Let's connect</h6>
                                                {VendorData.fb && (
                                                    <div className="col-auto">
                                                        <a href={`https://facebook.com/${VendorData.fb}`} target="_blank">
                                                            <button className="btn btn-sm w-100 prflbtn" style={{ backgroundColor: '#1877F2', color: '#fff' }}>
                                                                <Facebook />
                                                            </button>
                                                        </a>
                                                    </div>
                                                )}
                                                {VendorData.tw && (
                                                    <div className="col-auto mb-3">
                                                        <a href={`https://twitter.com/${VendorData.tw}`} target="_blank">
                                                            <button className="btn btn-sm w-100 prflbtn" style={{ backgroundColor: '#1DA1F2', color: '#fff' }}>
                                                                <Twitter />
                                                            </button>
                                                        </a>
                                                    </div>
                                                )}
                                                {VendorData.ig && (
                                                    <div className="col-auto mb-3">
                                                        <a href={`https://instagram.com/${VendorData.ig}`} target="_blank">
                                                            <button className="btn btn-sm w-100 prflbtn" style={{ backgroundColor: '#E4405F', color: '#fff' }}>
                                                                <Instagram />
                                                            </button>
                                                        </a>
                                                    </div>
                                                )}
                                                {VendorData.tk && (
                                                    <div className="col-auto mb-3">
                                                        <a href={`https://tiktok.com/${VendorData.tk}`} target="_blank">
                                                            <button className="btn btn-sm w-100 prflbtn" style={{ backgroundColor: '#000', color: '#fff' }}>
                                                                <FaTiktok />
                                                            </button>
                                                        </a>
                                                    </div>
                                                )}
                                                {VendorData.yt && (
                                                    <div className="col-auto mb-3">
                                                        <a href={`https://youtube.com/${VendorData.yt}`} target="_blank">
                                                            <button className="btn btn-sm w-100 prflbtn" style={{ backgroundColor: '#FF0000', color: '#fff' }}>
                                                                <Youtube />
                                                            </button>
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='row mt-4 m-0 p-0'>
                                                <div className="col-12 mb-4">
                                                    <a href={`tel:${VendorData.mobile}`}><button className="btn btn-sm btn-primary w-100 prflbtn">Call Us</button></a> 
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className='row mt-4 m-0 p-0'>
                                                <div className="col-12 mb-4">
                                                    <h5 className='mb-2'><b>Reviews</b></h5> 
                                                    {
                                                        ReviewList.map((item,index)=>{ 
                                                            return <Testimonial item={item} key={index} index={index} />
                                                        })
                                                    }
                                                    {ReviewList.length == 0 && <p>No reviews yet</p>}
                                                </div>
                                            </div>

                                            	
                                        </div>                    
						            </div>            
						        </div>             
						    </div>
                        </div>
                    </div>

        </section>
    )
}

export default ShopPage;