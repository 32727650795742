import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { ArrowLeft, Plus, X} from 'react-feather';
import { Link } from "react-router-dom";
import Sidebar from './sidebar';
import { XCircle} from 'react-feather';
import { useParams } from "react-router-dom";
import api from "../api";
import useStoreSetupCheck from './useStoreSetupCheck';
function UpdateProduct(){
    const setupStatus = useStoreSetupCheck();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const VendorId = localStorage.getItem('vendor_id');
    const {product_id} = useParams();
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [CategoryData, setCategoryData] = useState([]);
    const [ProductData, setProductData] = useState({
        "vendor": '',
        "category": '',
        "title": '',
        "slug": '',
        "detail": '',
        "offer_price": 0,
        "price": '',
        "usd_price": '',
        "keywords": '',
        "gender": '',
        "image": '',
        "visits": '',
        "status": '',
        "currency":'',
    });
    const [ProductImgs, setProductImgs] = useState([]);
    const [ProductImgs0, setProductImgs0] = useState([]);
    const [SizeItems,setSizeItems]=useState([["", ""]]);
    const [inputFields, setInputFields] = useState([["", ""]]);
    const token = localStorage.getItem("access_token");
    // Reference to the div with id="image_preview1"
    const imagePreviewRef = useRef(null);

    useEffect(() => {
        setProductData({
            ...ProductData,
        });
        fetchProductData();
    }, []);

    const fetchProductData = async () => {
        try {
            const response = await api.get(baseUrl+'/product/'+product_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data;     
            fetchData(baseUrl + '/categories/');       
            fetchSizeItems();
            setProductData({
                "vendor":VendorId,
                "category":data.category,
                "title":data.title,
                "slug":data.slug,
                "detail":data.detail,
                "price":data.price,
                "offer_price":data.offer_price,
                "usd_price":data.usd_price,
                "keywords":data.keywords,
                "gender":data.gender,
                "image":data.image,
                "visits":data.visits,
                "status":data.status,
                "product_imgs":data.product_imgs,
                "currency":data.currency,
            });
            setProductImgs(data.product_imgs);
        } catch (error) {
            //setError('Failed to fetch customer data. Please try again later.');
        } finally {
            //setLoading(false);
        }
    };

    const fetchSizeItems = async () => {
        try {
            const response = await api.get(baseUrl+'/getsizes/'+product_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data.results;
            const sizes = data.map(sizeItem => [sizeItem.size, sizeItem.qty]);
            setSizeItems(sizes);
        } catch (error) {
            //setError('Failed to fetch customer data. Please try again later.');
        } finally {
            //setLoading(false);
        }
    };

    function fetchData(baseurl) {
        fetch(baseurl)
            .then((response) => response.json())
            .then((data) => {
                setCategoryData(data.results);
            });
    }

    const inputHandler = (event) => {
        setProductData({
            ...ProductData,
            [event.target.name]: event.target.value
        });
    };

    const multipleFilesHandler = (event) => {
        const files = Array.from(event.target.files); // Convert FileList to array
        if (files.length > 0) {
            setProductImgs(files);
            setProductImgs0(files);
        }
    };

    const addInputFields = () => {
        setInputFields([...inputFields, ["", ""]]);
    };

    const handleInputChange = (index, subIndex, event) => {
        const values = [...inputFields];
        values[index][subIndex] = event.target.value;
        setInputFields(values);
    };
    const handleInputChange0 = (index, subIndex, event) => {
        const values = [...SizeItems]; // Create a shallow copy of SizeItems
        values[index][subIndex] = event.target.value; // Update the specific index and sub-index with the new value
        setSizeItems(values); // Set the new state
    };

    const removeInputFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    };

    const removeInputFields0 = (index) => {
        const values = [...SizeItems];
        values.splice(index, 1);
        setSizeItems(values);
    };

    const deleteAllSizes = async (product_id) => {
        try {
          const response = await api.delete(`${baseUrl}/sizeout/${product_id}/`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 204) {
            //console.log("All sizes deleted successfully.");
            // Optionally, refresh the data or handle UI changes
          } else {
            //console.log("Failed to delete sizes; server response:", response);
          }
        } catch (error) {
          console.error("Error deleting sizes:", error.response || error.message);
        }
      };

    const submitHandler = async (event) => {
        event.preventDefault();
        const allFieldsFilled = inputFields.every((pair) => pair[0] && pair[1]);
        
        if (!allFieldsFilled || !ProductImgs.length) {
            setErrorMsg('Kindly fill out all added size fields or remove the un-filled.');
            setSuccessMsg('');
            return;
        }
    
        const formData = new FormData();
        formData.append('category', ProductData.category);
        formData.append('title', ProductData.title);
        formData.append('slug', ProductData.title);
        formData.append('detail', ProductData.detail);
        formData.append('offer_price', ProductData.price);
        formData.append('price', ProductData.price);
        formData.append('usd_price', ProductData.usd_price);
        formData.append('keywords', ProductData.keywords);
        formData.append('gender', ProductData.gender);
        formData.append('visits', 0);
        formData.append('status', 1);
    
        try {
            const response = await api.patch(`${baseUrl}/product/${product_id}/`, formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: `Bearer ${token}`, }
            });
    
            if (response.status === 200) {       
                await deleteAllSizes(product_id);  // Ensure deletion before uploading new sizes
                
                const productId = response.data.id;
                
                const newSizes = inputFields.map(([size, qty]) => ({
                    product: productId,
                    size: size,  // Accessing the first element for size
                    qty: qty     // Accessing the second element for quantity
                }));
                
                // Prepare existing sizes data for upload
                const existingSizes = SizeItems.map((item) => ({
                    product: productId,
                    size: item[0],  // Accessing the first element for size
                    qty: item[1]    // Accessing the second element for quantity
                }));
                
                // Upload all sizes: new and existing ones
                try {
                    await Promise.all([...newSizes, ...existingSizes].map(row => axios.post(baseUrl + "/size/", row)));
                } catch (error) {
                    setErrorMsg('Failed to upload sizes. Please try again.');
                }

                
    
                if (ProductImgs0.length > 0) {
                    await Promise.all(ProductImgs.map((img) => {
                        const ImgFormData = new FormData();
                        ImgFormData.append('product', productId);
                        ImgFormData.append('image', img);
                        return axios.post(baseUrl + '/product-imgs/', ImgFormData);
                    }));
                }
    
                setSuccessMsg('Product Updated Successfully');
                setErrorMsg('');
    
            } else {
                setErrorMsg('There was a problem adding the product. Please try again.');
                setSuccessMsg('');
            }
        } catch (error) {
            setErrorMsg('An error occurred. Please try again.');
        }
    };
    
    // Helper to reset form data after successful upload
    function resetForm() {
        setProductImgs([]);
        setProductData({
            vendor: '',
            category: '',
            title: '',
            slug: '',
            detail: '',
            price: '',
            offer_price: '',
            usd_price: '',
            keywords: '',
            gender: '',
            image: '',
            visits: '',
            status: '',
        });
        setInputFields([["", ""]]);
        if (imagePreviewRef.current) {
            imagePreviewRef.current.innerHTML = '';
        }
    }
    

    function deleteImage(image_id){
        //SUBMIT DATA
       axios.delete(baseUrl+'/product-img/'+image_id+'/')
       .then(function (response){
           if(response.status==204){
               window.location.reload();
           }
       })
       .catch(function(error){
       });
   }
   
   
   const buttonEnable = ProductImgs.length > 0 && ProductData.category && ProductData.title && ProductData.price && ProductData.detail;
   
    return (
        <section className="container mtopcon dashb">
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    
                    <div className="col-12 col-lg-10 dash-max">                  
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Update Product </h6></div>
                            <div className="col-5"><Link to="/seller/products"><button className="btn btn-primary f85 float-right"><ArrowLeft/></button></Link></div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <div className="card shadow-sm border border-secondary border-opacity-10">                               
                                
                                    <div className="card-body">  

                                            <div className="row">
                                                <label htmlFor="formFile" className="form-label font9 mb-0">Select atleast 2 Product Images</label>
                                                <div className="upimgcon">
                                                    <input type="file" onChange={multipleFilesHandler} name="images[]" id="images" multiple className="form-control opzero" required/> <button className="btn addimg"><Plus/> </button>
                                                </div>
                                                <div className="col-8 p-0">
                                                    <div id="image_preview">
                                                        <div className="">
                                                            {
                                                                ProductData.product_imgs && ProductData.product_imgs.map((img,index)=>     
                                                                    <div className="img-div" id="img-div0" onClick={()=>deleteImage(img.id)}>                                                              
                                                                        <i className="middle orange br-10 text-white" role='button'><XCircle/></i>
                                                                        <img src={img.image} className="img rounded border mt-2 me-2 w-100 h-100" />
                                                                    </div>
                                                                
                                                                )
                                                            }                                    
                                                        </div>                                                            
                                                    </div>
                                                </div>
                                            </div>   

                                            <div className="mb-3">
                                                <label htmlFor="InputEmail" className="form-label font9 mb-0">Gender</label>
                                                <select className="form-control addprobginpt" name='gender' onChange={inputHandler}>
                                                    <option value={ProductData.gender}>{ProductData.gender}</option>
                                                    <option value='Unisex'>Unisex</option>
                                                    <option value='Female'>Female</option>
                                                    <option value='Male'>Male</option>
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="InputEmail" className="form-label font9 mb-0">Category</label>
                                                <select className="form-control addprobginpt" name='category' onChange={inputHandler}>
                                                    {
                                                        CategoryData.map((item,index)=><option defaultValue={item.id==ProductData.category} value={item.id}>{item.title}</option>)
                                                    }
                                                </select>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-8">
                                <div className="card shadow-sm border border-secondary border-opacity-10">
                                
                                    <div className="card-body">                        
                                        <form>
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="fnm" className="form-label font9 mb-0">Product Name</label>
                                                    <input type="text" onChange={inputHandler} value={ProductData.title} name="title"  className="form-control addprobginpt" id="fnm"/>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="lnm" className="form-label font9 mb-0">Price</label>
                                                    <input type="number" onChange={inputHandler} value={ProductData.price} name="price" className="form-control addprobginpt" id="lnm"/>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="lnm" className="form-label font9 mb-0">Description</label>
                                                <textarea type="text" onChange={inputHandler} value={ProductData.detail} name="detail" className="form-control addprobginpt" id="lnm"/>
                                            </div>
                                            <div className="mb-3">                                           
                                                    <label htmlFor="formFile" className="form-label font9 mb-0">Stock & Size</label>
                                                    <div className="row mb-0">
                                                        <div className="col-5">
                                                            <label htmlFor="formFile" className="form-label font9 mb-0 grey-text">Size Name</label>
                                                        </div>
                                                        <div className="col-5">
                                                            <label htmlFor="formFile" className="form-label font9 mb-0 grey-text">Qty</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        SizeItems.map((item,index)=>{ 
                                                            return <div key={index} className="row mb-3">
                                                                    <div className="col-5">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control addprobginpt w-100 size_mn"
                                                                            value={item[0]}
                                                                            onChange={(event) => handleInputChange0(index, 0, event)}
                                                                            placeholder="XL, ml ,kg, Inches, Generic"
                                                                        />
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control addprobginpt w-100 qty_mn"
                                                                            value={item[1]}
                                                                            onChange={(event) => handleInputChange0(index, 1, event)}
                                                                            placeholder="1"
                                                                        />
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <span onClick={() => removeInputFields0(index)} style={{ marginLeft: "5px" }}>
                                                                            <X/>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            
                                                        })
                                                    }
                                                    {inputFields.map((pair, index) => (
                                                        <div key={index} className="row mb-3">
                                                            <div className="col-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control addprobginpt w-100 size_mn"
                                                                    value={pair[0]}
                                                                    onChange={(event) => handleInputChange(index, 0, event)}
                                                                    placeholder="XL, ml ,kg, Inches, Generic"
                                                                />
                                                            </div>
                                                            <div className="col-5">
                                                                <input
                                                                    type="number"
                                                                    className="form-control addprobginpt w-100 qty_mn"
                                                                    value={pair[1]}
                                                                    onChange={(event) => handleInputChange(index, 1, event)}
                                                                    placeholder="1"
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <span onClick={() => removeInputFields(index)} style={{ marginLeft: "5px" }}>
                                                                    <X/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <div onClick={addInputFields} className="font9 pointer">
                                                        + Add Size Variant
                                                    </div>
                                            </div>

                                            <button type="button" disabled={!buttonEnable} onClick={submitHandler} className="col-12 btn btn-primary mt-3">Update</button>
                                            
                                            
                                            {successMsg && <p className="text-success text-center mt-4"><b>{successMsg}</b></p> } 
                                            {errorMsg && <p className="text-danger text-center mt-4"><b>{errorMsg}</b></p> } 
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



            </div>
        </section>
    )
}

export default UpdateProduct;