import { Link } from "react-router-dom";
import { useState} from 'react';
import { Copy } from 'react-feather';

function ShopSuspended() {
    const [copiedIndex, setCopiedIndex] = useState(null);
        const [selectedCategory, setSelectedCategory] = useState('All');
    
        const handleCopy = (value, index) => {
            const tempInput = document.createElement('input');
            tempInput.value = value;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
            setCopiedIndex(index);
            setTimeout(() => setCopiedIndex(null), 2000);
        };
    
        const items = [
            {
              "value": "I have Ksh. [amount] and I want to start a profitable business in Kenya. What are some realistic ideas with high potential?",
              "title": "Business Ideas for Kenya",
              "category": "Entrepreneurship"
            },
            {
              "value": "What are the top habits of successful entrepreneurs, and how can I apply them to my daily routine?",
              "title": "Success Habits of Entrepreneurs",
              "category": "Self-Improvement"
            },
            {
              "value": "I want to improve my financial discipline. Can you give me a step-by-step guide to budgeting and saving money effectively?",
              "title": "Personal Finance 101",
              "category": "Finance"
            },
            {
              "value": "How do I use AI tools to improve my business operations and marketing strategies?",
              "title": "AI for Business Growth",
              "category": "Technology"
            },
            {
              "value": "Can you break down the concept of passive income and give me practical ways to start earning it?",
              "title": "Building Passive Income",
              "category": "Wealth Creation"
            },
            {
              "value": "What are the top digital marketing strategies for small businesses looking to increase their online visibility?",
              "title": "Digital Marketing Guide",
              "category": "Marketing"
            },
            {
              "value": "How can I use social media to build a strong personal brand and attract business opportunities?",
              "title": "Personal Branding on Social Media",
              "category": "Marketing"
            },
            {
              "value": "What are some proven negotiation tactics that can help me close better deals in business?",
              "title": "Mastering Negotiation Skills",
              "category": "Business Development"
            },
            {
              "value": "How can I develop a powerful morning routine that boosts productivity and focus?",
              "title": "Optimizing Your Morning Routine",
              "category": "Self-Improvement"
            },
            {
              "value": "What are the most common psychological tricks used in sales and marketing, and how can I use them ethically?",
              "title": "Psychology of Sales & Marketing",
              "category": "Marketing"
            },
            {
              "value": "I want to learn how to invest my money wisely. Can you explain different investment options and their risks?",
              "title": "Investment Strategies",
              "category": "Finance"
            },
            {
              "value": "How can I become more productive without feeling overwhelmed or burned out?",
              "title": "Productivity Hacks",
              "category": "Self-Improvement"
            },
            {
              "value": "What are the best side hustles I can start while working a full-time job?",
              "title": "Side Hustle Ideas",
              "category": "Entrepreneurship"
            },
            {
              "value": "How can I improve my public speaking skills and become a more confident communicator?",
              "title": "Mastering Public Speaking",
              "category": "Personal Development"
            },
            {
              "value": "Can you give me a simple breakdown of cryptocurrency, blockchain, and how I can safely invest?",
              "title": "Crypto & Blockchain Explained",
              "category": "Finance & Technology"
            },
            {
              "value": "What are the top mistakes new entrepreneurs make, and how can I avoid them?",
              "title": "Entrepreneurship Mistakes to Avoid",
              "category": "Business Development"
            },
            {
              "value": "How do I create an online store and start selling products with little to no capital?",
              "title": "Starting an E-commerce Business",
              "category": "E-commerce"
            },
            {
              "value": "What are some powerful networking strategies that can help me connect with influential people in my industry?",
              "title": "Networking Like a Pro",
              "category": "Business & Career"
            },
            {
              "value": "How can I develop a millionaire mindset and start thinking like a successful entrepreneur?",
              "title": "Developing a Wealth Mindset",
              "category": "Mindset & Success"
            },
            {
              "value": "What are some practical ways to turn my passion into a profitable business?",
              "title": "Monetizing Your Passion",
              "category": "Entrepreneurship"
            }
        ];
        
        const filteredItems = selectedCategory === 'All' ? items : items.filter(item => item.category === selectedCategory);
        const [copySuccess, setCopySuccess] = useState(false);
    return (
        <section className="container mtopcon normal-mt-sctn p-4">
            <h2 className="fbold">We'll be back</h2>
            <p className="mb-3">Hang Tight! Our Shop is Getting a Makeover ✨, in the meantime have fun with these cool AI prompts.</p>
            
            <div className='row'>
                <div>
                    <div className="mb-3">
                        <select className="form-select" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                        <option value="All">All</option>
                        {[...new Set(items.map(item => item.category))].map(category => (
                            <option key={category} value={category}>{category}</option>
                        ))}
                        </select>
                    </div>
                    <div className="row">
                        {filteredItems.map((item, index) => (
                        <div className='col-12 col-lg-3' key={index}>
                            <div className='card'>
                                <p className='p-3 pb-0 font9 mh100 text-grey'>{item.value}</p>
                                <div className='card-footer pt-0 pb-0 pr-0'>
                                    <div className='row'>
                                    <div className='col-8 font9'><p className='mt-2'>{item.title}</p></div>
                                    <div className='col-4 text-right'>
                                        {copiedIndex === index ? (
                                        <button className='btn text-green'><b>Copied</b></button>
                                        ) : (
                                        <button className='btn btn-default' onClick={() => handleCopy(item.value, index)}><Copy /></button>
                                        )}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>                            
            </div>
        </section>
    );
}

export default ShopSuspended;
