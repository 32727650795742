import {Link} from 'react-router-dom';
import Sidebar from './sidebar';
import LineChart from './LineChart';
import { Filter } from 'react-feather';
import { useState,useContext,useEffect } from 'react';
import api from "../api";
import useStoreSetupCheck from './useStoreSetupCheck';

function SellerReports(){
    const setupStatus = useStoreSetupCheck();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [OrderItems,setOrderItems]=useState([]);
    const [statsLoading, setstatsLoading] = useState(false);
    const [stats, setStats] = useState({
        units_sold: 0,
        total_sales: 0,
        lifetime_revenue: 0,
    });
    const [filter, setFilter] = useState("week");
    
    useEffect(() => {
        fetchStats(filter);
    }, [filter]);

    const fetchStats = async (filterOption) => {
        setstatsLoading(true);
        try {
            const response = await api.get(`/vendor-sales-stats-reports/?filter=${filterOption}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
            });
            setStats(response.data);
            setstatsLoading(false);
        } catch (error) {
            //console.error("Error fetching stats:", error);
        }
    };
    return (
        <section className="container mtopcon dashb">
            <div className="row">
                    <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max">
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Analytics</h6></div>
                        </div>
                        <div className="row">                                                
                            <div className="mb-3">
                                <div className="col-12">
                                    <select className="filter2 text-center border w-100" value={filter} onChange={(e) => setFilter(e.target.value)}>
                                        <option value="today">{ statsLoading === true ? <p>Loading...</p> : <p>Today</p>}</option>
                                        <option value="week">{ statsLoading === true ? <p>Loading...</p> : <p>This Week</p>}</option>
                                        <option value="month">{ statsLoading === true ? <p>Loading...</p> : <p>This Month</p>}</option>
                                        <option value="year">{ statsLoading === true ? <p>Loading...</p> : <p>This Year</p>}</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="row">
                                    <div className="col-7 stat-con mb-1 pr-0">
                                        <Link to="/seller/orders" className=''><div className="card mb-2">
                                            <div className="card-body">
                                                <h5 className="text font8 grey-text">Total Sales</h5>
                                                <div className="metric-value d-inline-block">
                                                    <h1 className="mb-1">{stats.total_sales.toFixed(2)}</h1>
                                                </div>
                                                <div className="metric-label d-inline-block float-right text-success">
                                                    {stats.total_sales_deviation >= '0%' && <><span className='text-success font8'>+{stats.total_sales_deviation}</span></>}
                                                    {stats.total_sales_deviation < '0%' && <><span className='text-danger font8'>{stats.total_sales_deviation}</span></>} 
                                                </div>
                                            </div>
                                        </div></Link>
                                    </div>
                                    <div className="col-5 stat-con mb-1">
                                        <Link to="/seller/products" className=''><div className="card mb-2">
                                            <div className="card-body">
                                                <h5 className="text font8 grey-text">Units Sold</h5>
                                                <div className="metric-value d-inline-block">
                                                    <h1 className="mb-1">{stats.units_sold}</h1>
                                                </div>
                                                <div className="metric-label d-inline-block float-right text-primary">
                                                    {stats.units_sold_deviation >= '0%' && <><span className='text-success font8'>+{stats.units_sold_deviation}</span></>}
                                                    {stats.units_sold_deviation < '0%' && <><span className='text-danger font8'>{stats.units_sold_deviation}</span></>} 
                                                </div>
                                            </div>
                                        </div></Link>
                                    </div>
                                    <div className="col-5 stat-con pr-0 mb-1">
                                        <Link to="/seller/reports" className=''><div className="card mb-2">
                                            <div className="card-body">
                                                <h5 className="text font8 grey-text">Customers</h5>
                                                <div className="metric-value d-inline-block">
                                                    <h1 className="mb-1">{stats.unique_customers}</h1>
                                                </div>
                                                <div className="metric-label d-inline-block float-right text-success">
                                                    {stats.unique_customers_deviation >= '0%' && <><span className='text-success font8'>+{stats.unique_customers_deviation}</span></>}
                                                    {stats.unique_customers_deviation < '0%' && <><span className='text-danger font8'>{stats.unique_customers_deviation}</span></>} 
                                                </div>
                                            </div>
                                        </div></Link>
                                    </div>
                                    <div className="col-7 stat-con mb-1">
                                        <Link to="/seller/finance" className=''><div className="card mb-2">
                                            <div className="card-body">
                                                <h5 className="text font8 grey-text">Repeat Customers</h5>
                                                <div className="metric-value d-inline-block">
                                                    <h1 className="mb-1">{stats.repeat_customers}</h1>
                                                </div>
                                                <div className="metric-label d-inline-block float-right text-secondary">
                                                    {stats.repeat_customers_deviation >= '0%' && <><span className='text-success font8'>+{stats.repeat_customers_deviation}</span></>}
                                                    {stats.repeat_customers_deviation < '0%' && <><span className='text-danger font8'>{stats.repeat_customers_deviation}</span></>} 
                                                </div>
                                            </div>
                                        </div></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-3">
                                <div className="card f8 pt-3">
                                    <LineChart chart_title={'Shop Visits'} chart_categories={stats.shop_visits && stats.shop_visits.categories} chart_data={stats.shop_visits && stats.shop_visits.data}/>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-3">
                                <div className="card f8 pt-3">
                                    <LineChart chart_title={'Products Visits'}  chart_categories={stats.product_visits && stats.product_visits.categories} chart_data={stats.product_visits && stats.product_visits.data}/>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-3">
                                <div className="card f8 pt-3">
                                    <LineChart chart_title={'Revenue Overview'}  chart_categories={stats.revenue_overview && stats.revenue_overview.categories} chart_data={stats.revenue_overview && stats.revenue_overview.data}/>
                                </div>
                            </div>  
                            <div className="col-12 col-lg-4">
                                <div className="card f8">                                
                                    <div className='row p-2 lh35'>
                                        <p className='m-0 col-8'><b>Customers by Order Value</b></p>
                                        <div className='col-4 text-right'><Link to="/seller/customers" className='fgreen'><button className='btn btn-dark p-1 w-auto font9'>View more</button></Link></div>
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        {stats?.top_customers?.map((customer, index) => (
                                            <li key={index} className="list-group-item border-bottom-0 border-top">
                                                {customer.name} ({customer.phone}) 
                                                <span className="badge rounded-pill bg-primary float-right">
                                                    Ksh. {customer.amount_spent.toLocaleString()}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>  
                            <div className="col-12 col-lg-4">
                                <div className="card f8">                                
                                    <div className='row p-2 lh35'>
                                        <p className='m-0 col-8'><b>Revenue by Source</b></p>
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        {stats?.revenue_by_location?.map((location, index) => (
                                            <li key={index} className="list-group-item border-bottom-0 border-top">
                                                {location.location}
                                                <span className="badge rounded-pill bg-primary float-right">
                                                    Ksh. {location.revenue.toLocaleString()}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>                     
                        </div>
                    </div>
            </div>
        </section>
    )
}

export default SellerReports;