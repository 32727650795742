import {Link} from 'react-router-dom';
import axios from "axios";
import {useState} from "react";
import { Calendar, Plus } from 'react-feather';
import VendorMoneyMapUpload from './upload';
import VendorMoneyMapDashboard from './moneymapdash';

function VendorMoneyMap(props){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    
    const [analytics, setAnalytics] = useState(null);

    const formatDate = (date) => {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        const dayName = days[date.getDay()];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
    
        const getOrdinalSuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };
    
        return `${dayName}, ${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
    };    
    const currentDate = new Date();
    return (
        <section className="container mtopcon normal-mt-sctn">
            <div className="row">
                <div className="row dash-top-row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12"><p className="pageheader-title">Welcome back</p></div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 hdmobile">
                        <div className="row dash-top-row mb-4">
                            <ul className="h-100 p-0">
                                <Link to="/seller/addproduct"><li className='float-right h-100'><button className='btn btn-primary h-100'><Plus className='me-2'/>New Product</button></li></Link>
                                <li className='float-right h-100 me-3 topdate'><Calendar/><p className='float-right'> {formatDate(currentDate)}</p></li>
                            </ul>                                
                            
                        </div>
                    </div>
                </div>

                <div>
                    <h1>Bank Statement Analyzer</h1>
                    <VendorMoneyMapUpload />
                    {analytics && <VendorMoneyMapDashboard data={analytics} />}
                </div>
            </div>
        </section>
    )
}

export default VendorMoneyMap;