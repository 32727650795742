import {Link} from 'react-router-dom';
import tshirts from '../tshirts.jpg';
import fshoes from '../fshoes.jpg';
import mshoes from '../mshoes.jpg';
import hoodies from '../hoodies.jpg';
import SingleProduct from './singleproduct';
import { X, ChevronUp} from 'react-feather';
import { useState,useEffect } from 'react';
function Home(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [products,setProducts]=useState([]);
    const [ReviewList,setReviewList]=useState([]);
    const [VendorList,setVendorList]=useState([]);
    const [PopularProduct,setPopularProduct]=useState([]);
    const [PopularCategoryList,setPopularCategoryList]=useState([]);
    const [categories,setCategories]=useState([]);
    const [showSideCategories, setShowSideCategories] = useState(false);
    const [showSideCategories_dark, setShowSideCategories_dark] = useState(false);


    useEffect(()=>{
        fetchData(baseUrl+'/products/?fetch_limit=10');
        fetchTestimonialData(baseUrl+'/productrating/');
        fetchPopularVendors(baseUrl+'/vendors/?fetch_limit=1');
        fetchPopularProducts(baseUrl+'/products/?fetch_limit=40');
        fetchPopularCategoryList(baseUrl+'/categories/?fetch_limit=40');
    },[]);

    function fetchCatData(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setCategories(data.results);
        });
    }

    function fetchData(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setProducts(data.results);
        });
    }
     function fetchTestimonialData(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setReviewList(data.results);
        });
    }
    function fetchPopularVendors(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setVendorList(data.results);
        });
    }
    function fetchPopularProducts(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setPopularProduct(data.results);
        });
    }
    function fetchPopularCategoryList(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setPopularCategoryList(data.results);
        });
    }
    function close(){
        setShowSideCategories(!showSideCategories);
        setShowSideCategories_dark(!showSideCategories_dark);
        document.body.style.overflow = "scroll";
    };
    function open(){
        setShowSideCategories(!showSideCategories);
        setShowSideCategories_dark(!showSideCategories_dark);
        document.body.style.overflow = "hidden";
    };
    return (
        <main className="mtopcon normal-mt-sctn">
            <div className="container">
                {/*Categories start*/}
                <div className="mb-4 section-title-con"><span className='section-ttl'>What would you like to find?</span> <button className="btn btn-sm vwallbtn btn-light float-end" onClick={open}>explore</button></div>
                <div className="row mb-4 p-0">
                    <div className="col-6 col-md-3 col-lg-3 mb-4">
                         <img src={tshirts} className="card-img-top br-10" alt="..."/>
                    </div>
                    <div className="col-6 col-md-3 col-lg-3 mb-4">
                         <img src={fshoes} className="card-img-top br-10" alt="..."/>
                    </div>
                    <div className="col-6 col-md-3 col-lg-3 mb-4">
                         <img src={mshoes} className="card-img-top br-10" alt="..."/>
                    </div>
                    <div className="col-6 col-md-3 col-lg-3 mb-4">
                         <img src={hoodies} className="card-img-top br-10" alt="..."/>
                    </div>                    

                </div>        
                {/*Categories end*/}   


                {/*latest products start*/}
                <div className="mb-4 section-title-con"><span className='section-ttl'>Latest Products</span> <Link className="btn btn-sm vwallbtn btn-light float-end" aria-current="page" to="/products">explore</Link></div>
                <div className="row mb-4">
                    {
                        products.map((product)=><SingleProduct product={product} />)
                    }                   
                </div>        
                {/*latest products end*/}

                {/*Products start*/}
                <div className="mb-4 section-title-con"><span className='section-ttl'>Recommended For you</span> <Link className="btn btn-sm vwallbtn btn-light float-end" aria-current="page" to="/products">explore</Link></div>
                <div className="row mb-4">

                    {
                        PopularProduct.map((product)=><SingleProduct product={product} />)
                    } 
                    
                </div>        
                {/*Popular Products end*/}
                

                
            </div>
            {showSideCategories_dark && <div className='cover' onClick={close}></div>}
            {showSideCategories && <div className='sidebar-cat'>
                <div className="col-12 cat_div"> 
                    <h3 className="mb-0">
                    <div className="row p-3 sidebar-header"><div className="col-10">All Categories</div><div className="col-2"  onClick={close}><X/></div></div> 
                    </h3>       
                    <div className="con-responsive sidebarcon">                        
                        <input className="form-control m-3 cat-input" type="search" placeholder="Search categories" aria-label="Search"/>
                        {
                            categories.map((category)=>{
                                return (
                                        <Link to={`/category/${category.title}/${category.id}`}><div className="row"> 
                                            <div className="col-10"><img src={category.cat_img} alt="..."/><span className="p-4">{category.title}</span></div> 
                                            <div className="col-2"><ChevronUp/></div> 
                                        </div></Link>                                     
                                )
                                
                            })
                        }      
                    </div>
                </div>
            </div>}
            </main>
    )
}

export default Home;
