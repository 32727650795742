import {Link} from 'react-router-dom';
import { Plus, Edit, Trash} from 'react-feather';
import Sidebar from './sidebar';
import { useState,useEffect,useContext } from 'react';
import api from "../api";
import { ExchangeRateContext } from '../../ExchangeRateContext';
function SellerProducts(){    
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl0 = process.env.REACT_APP_BASE_URL_IMG;
    const [ProductData, setProductData] = useState([]);
    const [productImgs, setProductImgs] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOption, setSortOption] = useState("default"); 
    const [error, setError] = useState("");
    const [DelId, setDelId] = useState("");
    const [DelIdStatus, setDelIdStatus] = useState(false);
  
    const { exchangeRates, baseCurrency } = useContext(ExchangeRateContext);
    const token = localStorage.getItem("access_token");
    const fetchVendorProducts = async () => {
        try {
          const response = await api.get("/vendor-products/", {
            headers: {
              Authorization: `Bearer ${token})}`,
            },
          });
          setProductData(response.data.products);
          setProductImgs(response.data.product_imgs);
        } catch (error) {
          //console.error("Error fetching products:", err.toJSON());
          /*setError("Failed to fetch products. Please try again.");
            if (axios.isCancel(error)) {
                console.log("Request canceled:", error.message);
            } else if (error.response) {
                // Server responded with a status outside the 2xx range
                console.error("Error status:", error.response.status);
                console.error("Response data:", error.response.data);
            } else if (error.request) {
                // Request made, but no response received
                console.error("No response received:", error.request);
            } else {
                // Something else triggered the error
                console.error("Error:", error.message);
            }*/
        }
    };
    
    useEffect(() => {
        fetchVendorProducts();
    }, []);

    function deleteStepOne(product){
        setDelId(product);
        setDelIdStatus(false);
      }
      function handleDelete(){
          api.delete(baseUrl+'/product/'+DelId+'/', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function (response){
            //console.log(response.status);
              if(response.status==204){
                  fetchVendorProducts();
                  setDelIdStatus(true);
              }
          })
          .catch(function(error){
              //console.log(error);
          });
      }

    // Filter and Sort Products
    const filteredAndSortedResults = ProductData.filter((product) => {
    const search = searchTerm.toLowerCase();
    return (
        (product.title && product.title.toLowerCase().includes(search)) ||
        (product.price && product.price.toString().toLowerCase().includes(search))
    );
    }).sort((a, b) => {
        if (sortOption === "price_high_low") return b.price - a.price;
        if (sortOption === "price_low_high") return a.price - b.price;
        if (sortOption === "qty_high_low") return b.stock - a.stock;
        if (sortOption === "qty_low_high") return a.stock - b.stock;
        if (sortOption === "default") return b.id - a.id;
        return 0; // Default sorting (no change)
    });

    const getVendorCurrency = () => {
        return localStorage.getItem("VendorCurrency");
    };
    return (
        <section className="container mtopcon dashb">
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max orderscontainer">                        
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Products</h6></div>
                            <div className="col-5"><Link to="/seller/addproduct"><button className="btn btn-primary f85 float-right"><Plus/> Product</button></Link></div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-8">
                                <input
                                    className="filter2 border me-2 w-100"
                                    type="search"
                                    placeholder="Search Products"
                                    aria-label="Search"
                                    onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on keyup
                                />
                            </div>
                            <div className="col-4">
                                    <select className="filter2 text-center border w-100 h-100" name='category' onChange={(e) => setSortOption(e.target.value)}>
                                        <option value="default">Sort</option>
                                        <option value="price_high_low">Price from High to Low</option>
                                        <option value="price_low_high">Price from Low to High</option>
                                        <option value="qty_high_low">Qty from High to Low</option>
                                        <option value="qty_low_high">Qty from Low to High</option>
                                    </select>
                            </div>
                        </div>

                        <div className="mb-4">
                                {filteredAndSortedResults.length === 0 ? (
                                    <p>No products found.</p>
                                ) : (
                                    <>
                                        {filteredAndSortedResults.map((product,index) => (
                                                    <>
                                                        <div className="row ord-bg bg-white p-2 hvr bx-shadow" key={product.id}>
                                                                <div className="col-3 col-lg-1 p-0"><span className="float-left"></span>
                                                                    <div className="ord-img float-left">
                                                                    <img src={baseUrl0+product.product_imgs[0].image} className='w-100 h-100'/>       
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 col-lg-6 pl-0 pr-0">
                                                                    <div className="row">
                                                                        <div className="col-11 col-lg-5 ord-sz"><Link to={`/product/${product.title}/${product.id}`}>{product.title}</Link></div>
                                                                        <div className="col-8 col-lg-5 ord-sz">
                                                                            {
                                                                                <span>{getVendorCurrency()} {product.price}</span>
                                                                            } 
                                                                        </div>
                                                                        <div className="col-4 col-lg-2 ord-sz grey-text">Qty: {product.stock}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4 col-lg-4 f8 text-black prodedtbtn text-right p-0 mt-1 lh34">
                                                                        <Trash onClick={()=>deleteStepOne(product.id)} className="text-danger border-0 me-2" data-bs-toggle="modal" data-bs-target="#confirmdelModal"/>
                                                                        <Link to={`/seller/update-product/${product.id}`}><Edit className="text-success border-0  ms-2"/></Link>
                                                                </div>
                                                        </div>
                                                    </>
                                            ))}
                                    </>
                                )}
                            

                        </div>
                </div>
            </div>

            <div className="modal fade" id="confirmdelModal" tabIndex="-1" aria-labelledby="confirmdelModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header p-2">
                        <h1 className="modal-title fs-5" id="confirmdelModalLabel">Confirm</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        {DelIdStatus==true && <>Deleted Successfully</>}
                        {DelIdStatus==false && <>Are you sure you want to delete this product?</>}
                        
                    </div>
                    <div className="modal-footer p-2">                      
                        {DelIdStatus==true && <><button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button></>}
                        {DelIdStatus==false && <><button type="button" className="btn btn-danger" onClick={() => handleDelete()}>Delete</button></>}
                        
                    </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default SellerProducts;