import {Link} from 'react-router-dom';
import {useState} from "react";
import api from "../api";
function SellerRegister(props){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [errorMsg,seterrorMsg]=useState('');
    const [successMsg,setsuccessMsg]=useState('');
    const [loading, setLoading] = useState(false);
    const [ShowEmail, setShowEmail] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emailError, setEmailError] = useState('');
    const [registerFormData, setRegisterFormData]=useState({
        "first_name":'',
        "username":'',
        "email":'',
        "code":'254',
        "address":'',
        "password":'',
    });

    const inputHandler =(event) => {
        setRegisterFormData({
            ...registerFormData,
            [event.target.name]:event.target.value
        })
    };

    const submitHandler =(event) => {
        setLoading(true);
        setShowEmail(true);
        const formData=new FormData();
        formData.append('first_name',registerFormData.first_name);
        formData.append('username',registerFormData.username);
        formData.append('email',registerFormData.email);
        //formData.append('mobile',`${registerFormData.code}${registerFormData.mobile}`);
        formData.append('address',registerFormData.address);
        formData.append('password',registerFormData.password);

        //SUBMIT DATA
        api.post(baseUrl+'/vendor/register/',formData)
        .then(function (response){
            if(response.data.bool==false){                    
                seterrorMsg(response.data.msg)
                setsuccessMsg('');
                return;
            }else{
                setRegisterFormData({
                    "first_name":'',
                    "username":'',
                    "email":'',
                    "code":'',
                    "address":'',
                    "password":'',
                    "confirmPassword":'',
                });
                seterrorMsg('');
                setLoading(false);
                setShowEmail(false);
                setsuccessMsg(response.data.msg);

                setTimeout(() => {
                    window.location.href = '/seller/login';
                }, 5000);
            }
        })
        .catch(function(error){
            seterrorMsg(error.response?.data?.msg || "An error occurred. Kindly try again.");
            setLoading(false);  
            setShowEmail(false);          
        });
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && !emailPattern.test(email)) {
            setEmailError('Invalid email format. Kindly enter a valid email.');
        } else {
            setEmailError('');
        }
    };

    const buttonEnable=(registerFormData.first_name!='') && (registerFormData.username!='') && (registerFormData.email!='') && (emailError=='') && (registerFormData.address!='') && (registerFormData.password!='') && (registerFormData.password==confirmPassword)
    const buttonEnable0=(registerFormData.email_code!='')

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const checkPasswordsMatch = () => {
        if (registerFormData.password !== confirmPassword) {
          setErrorMessage('*Passwords do not match');
        } else {
          setErrorMessage('');
        }
    };

    const sendVerificationCode = () => {
        setLoading(true);
        api.post(baseUrl + "/send-verification-email/", { email: registerFormData.email,username: registerFormData.username })
            .then((response) => {
                if (response.data.success === true) {
                    setLoading(false);
                    setErrorMessage('');
                    seterrorMsg('');
                    setsuccessMsg('');
                    setShowEmail(true);
                } else {
                    seterrorMsg(response.data.message);                    
                    setShowEmail(false);
                    setLoading(false);
                }
            })
            .catch(setShowEmail(false));
    };

    const verifyCode = () => {
        api.post(baseUrl + "/verify-email-code/", { email: registerFormData.email, code: registerFormData.email_code })
            .then((response) => {
                if (response.data.success === true) {
                    setShowEmail(true);
                    submitHandler(); // Call the registration function
                } else {
                    seterrorMsg(response.data.message);
                }
            })
            .catch((error) => seterrorMsg(error.response?.data?.message || "An error occurred."));
    };
    const retry = () => {
        window.location.href = '/seller/register';
    };
    

    return (
        <section className="container mtopcon">
            <div className="row">
                <div className="col-12 col-lg-4 reglogincon">
                    <div className="card shadow-none">
                        <div className="card-body"> 
                            <h2 className='mb-3 text-center'><b>Create an account</b></h2>                     
                            <form>
                                    
                                {ShowEmail == false && <>
                                    <div className="row">
                                        <div className="col-6 mt-2">
                                            <label htmlFor="fnm" className="form-label font9 grey-text mb-0">Owner Name</label>
                                            <input type="text" name="first_name" onChange={inputHandler} value={registerFormData.first_name} className="form-control font9" id="fnm"/>
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label htmlFor="lnm" className="form-label font9 grey-text mb-0">Business Name</label>
                                            <input type="text" name="username" onChange={inputHandler} value={registerFormData.username} className="form-control font9" id="lnm"/>
                                        </div>
                                    </div>
                                    <div className="row display-none">
                                        <div className="col-4 mt-2">
                                            <label htmlFor="InputEmail" className="form-label font9 grey-text mb-0">Code</label>
                                            <select className="form-select" aria-label="Default select example" onChange={inputHandler} value={registerFormData.code}>
                                                <option value="254" defaultValue>+254</option>
                                                <option value="93">+93</option>
                                                <option value="355">+355</option>
                                                <option value="213">+213</option>
                                                <option value="1684">+1684</option>
                                                <option value="376">+376</option>
                                                <option value="244">+244</option>
                                                <option value="1264">+1264</option>
                                                <option value="672">+672</option>
                                                <option value="1268">+1268</option>
                                                <option value="54">+54</option>
                                                <option value="374">+374</option>
                                                <option value="297">+297</option>
                                                <option value="61">+61</option>
                                                <option value="43">+43</option>
                                                <option value="994">+994</option>
                                                <option value="1242">+1242</option>
                                                <option value="973">+973</option>
                                                <option value="880">+880</option>
                                                <option value="1246">+1246</option>
                                                <option value="375">+375</option>
                                                <option value="32">+32</option>
                                                <option value="501">+501</option>
                                                <option value="229">+229</option>
                                                <option value="1441">+1441</option>
                                                <option value="975">+975</option>
                                                <option value="591">+591</option>
                                                <option value="387">+387</option>
                                                <option value="267">+267</option>
                                                <option value="55">+55</option>
                                                <option value="673">+673</option>
                                                <option value="359">+359</option>
                                                <option value="226">+226</option>
                                                <option value="257">+257</option>
                                                <option value="855">+855</option>
                                                <option value="237">+237</option>
                                                <option value="1">+1</option>
                                                <option value="238">+238</option>
                                                <option value="1345">+1345</option>
                                                <option value="236">+236</option>
                                                <option value="235">+235</option>
                                                <option value="56">+56</option>
                                                <option value="86">+86</option>
                                                <option value="57">+57</option>
                                                <option value="269">+269</option>
                                                <option value="242">+242</option>
                                                <option value="243">+243</option>
                                                <option value="682">+682</option>
                                                <option value="506">+506</option>
                                                <option value="225">+225</option>
                                                <option value="385">+385</option>
                                                <option value="53">+53</option>
                                                <option value="357">+357</option>
                                                <option value="420">+420</option>
                                                <option value="45">+45</option>
                                                <option value="253">+253</option>
                                                <option value="1767">+1767</option>
                                                <option value="1809">+1809</option>
                                                <option value="593">+593</option>
                                                <option value="20">+20</option>
                                                <option value="503">+503</option>
                                                <option value="240">+240</option>
                                                <option value="291">+291</option>
                                                <option value="372">+372</option>
                                                <option value="251">+251</option>
                                                <option value="679">+679</option>
                                                <option value="358">+358</option>
                                                <option value="33">+33</option>
                                                <option value="241">+241</option>
                                                <option value="220">+220</option>
                                                <option value="995">+995</option>
                                                <option value="49">+49</option>
                                                <option value="233">+233</option>
                                                <option value="350">+350</option>
                                                <option value="30">+30</option>
                                                <option value="1473">+1473</option>
                                                <option value="502">+502</option>
                                                <option value="224">+224</option>
                                                <option value="245">+245</option>
                                                <option value="592">+592</option>
                                                <option value="509">+509</option>
                                                <option value="504">+504</option>
                                                <option value="852">+852</option>
                                                <option value="36">+36</option>
                                                <option value="354">+354</option>
                                                <option value="91">+91</option>
                                                <option value="62">+62</option>
                                                <option value="98">+98</option>
                                                <option value="964">+964</option>
                                                <option value="353">+353</option>
                                                <option value="972">+972</option>
                                                <option value="39">+39</option>
                                                <option value="1876">+1876</option>
                                                <option value="81">+81</option>
                                                <option value="962">+962</option>
                                                <option value="7">+7</option>
                                                <option value="254">+254</option>
                                                <option value="82">+82</option>
                                                <option value="965">+965</option>
                                                <option value="996">+996</option>
                                                <option value="856">+856</option>
                                                <option value="371">+371</option>
                                                <option value="961">+961</option>
                                                <option value="266">+266</option>
                                                <option value="231">+231</option>
                                                <option value="218">+218</option>
                                                <option value="423">+423</option>
                                                <option value="370">+370</option>
                                                <option value="352">+352</option>
                                                <option value="853">+853</option>
                                                <option value="389">+389</option>
                                                <option value="261">+261</option>
                                                <option value="265">+265</option>
                                                <option value="60">+60</option>
                                                <option value="960">+960</option>
                                                <option value="223">+223</option>
                                                <option value="356">+356</option>
                                                <option value="222">+222</option>
                                                <option value="230">+230</option>
                                                <option value="52">+52</option>
                                                <option value="373">+373</option>
                                                <option value="377">+377</option>
                                                <option value="976">+976</option>
                                                <option value="382">+382</option>
                                                <option value="212">+212</option>
                                                <option value="258">+258</option>
                                                <option value="95">+95</option>
                                                <option value="264">+264</option>
                                                <option value="977">+977</option>
                                                <option value="31">+31</option>
                                                <option value="64">+64</option>
                                                <option value="234">+234</option>
                                                <option value="47">+47</option>
                                                <option value="968">+968</option>
                                                <option value="92">+92</option>
                                                <option value="507">+507</option>
                                                <option value="595">+595</option>
                                                <option value="51">+51</option>
                                                <option value="63">+63</option>
                                                <option value="48">+48</option>
                                                <option value="351">+351</option>
                                                <option value="974">+974</option>
                                                <option value="40">+40</option>
                                                <option value="7">+7</option>
                                                <option value="250">+250</option>
                                                <option value="966">+966</option>
                                                <option value="221">+221</option>
                                                <option value="381">+381</option>
                                                <option value="65">+65</option>
                                                <option value="27">+27</option>
                                                <option value="34">+34</option>
                                                <option value="94">+94</option>
                                                <option value="249">+249</option>
                                                <option value="46">+46</option>
                                                <option value="41">+41</option>
                                                <option value="963">+963</option>
                                                <option value="886">+886</option>
                                                <option value="255">+255</option>
                                                <option value="66">+66</option>
                                                <option value="228">+228</option>
                                                <option value="216">+216</option>
                                                <option value="90">+90</option>
                                                <option value="256">+256</option>
                                                <option value="380">+380</option>
                                                <option value="971">+971</option>
                                                <option value="44">+44</option>
                                                <option value="1">+1</option>
                                                <option value="598">+598</option>
                                                <option value="998">+998</option>
                                                <option value="58">+58</option>
                                                <option value="84">+84</option>
                                                <option value="967">+967</option>
                                                <option value="260">+260</option>
                                                <option value="263">+263</option>


                                            </select>
                                        </div>
                                        <div className="col-8  mt-2">
                                            <label htmlFor="InputEmail" className="form-label font9 grey-text mb-0">Phone No.</label>
                                            <input type="number" name="mobile" placeholder="724 000 000" onChange={inputHandler} value={registerFormData.mobile} className="form-control font9"/>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="InputEmail" className="form-label font9 grey-text mb-0">Email</label>
                                        <input 
                                            type="email" 
                                            name="email" 
                                            onChange={inputHandler} 
                                            onBlur={(e) => validateEmail(e.target.value)} 
                                            value={registerFormData.email} 
                                            className="form-control font9" 
                                            id="InputEmail" 
                                            aria-describedby="emailHelp" 
                                        />
                                        {emailError && <small className="text-danger font8">{emailError}</small>}
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="InputEmail" className="form-label font9 grey-text mb-0">Business Location</label>
                                        <textarea type="text" name="address" onChange={inputHandler} value={registerFormData.address} className="form-control font9"></textarea>
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="InputPassword1" className="form-label font9 grey-text mb-0">Password</label>
                                        <input type="password" name="password" onChange={inputHandler} value={registerFormData.password} className="form-control font9" id="InputPassword1"/>
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="InputPassword1" className="form-label font9 grey-text mb-0">Confirm Password</label>
                                        <input type="password" name="password" onChange={handleConfirmPasswordChange} value={confirmPassword} onBlur={checkPasswordsMatch} className="form-control font9" id="InputPassword1"/>
                                        <span className="text-danger font9">{errorMessage}</span>
                                    </div>          
                                    {successMsg && <p className="text-success text-center mt-4 mb-0"><b>{successMsg}</b></p> } 
                                    {errorMsg && <p className="text-danger text-center mt-4 mb-0"><b>{errorMsg}</b></p> } 
                                
                                                 
                                    <button type="button" disabled={!buttonEnable} onClick={sendVerificationCode} className="col-12 btn btn-primary mt-4">{ loading === true ? <>Loading...</> : <>Continue</>}</button>   
                                                    
                                    <div className="mb-3 mt-2">
                                        <p className='f85'>We value your privacy, by Proceeding you agree to our <Link to="/terms">Terms & Conditions</Link> and <Link to="/privacy">Privacy Policy</Link></p>
                                    </div>
                                    <div className="mb-3 font9">
                                        <p>Already have an account? <Link to="/seller/login">Sign in</Link></p>
                                    </div> 
                                </>}
                                {ShowEmail == true && <>
                                    <div className="mt-4 mb-4 border p-4 br-10">
                                        {successMsg && <p className="text-success text-center mb-3 font9 mb-0"><b>{successMsg}</b></p> } 
                                        {errorMsg && <p className="text-danger text-center mb-3 font9 mb-0"><b>{errorMsg}</b></p> } 
                                        <label htmlFor="InputPassword1" className="form-label font9 dark-text mb-0"><b>Email Code</b></label>
                                        <label htmlFor="InputPassword1" className="form-label font8 grey-text mb-3">Kindly enter code sent to <u>{registerFormData.email}</u> to complete.</label>
                                        <input type="number" name="email_code" onChange={inputHandler} value={registerFormData.email_code} placeholder='- - - - - -' className="form-control font9" id="InputPassword1"/>
                                        <div onClick={sendVerificationCode} className='font9 mt-3 text-blue pointer'>Resend</div>
                                        <button type="button" disabled={!buttonEnable0} onClick={verifyCode} className="col-12 btn btn-primary mt-3">{ loading === true ? <>Loading...</> : <>Complete</>}</button>  
                                    </div>
                                </>}
  
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SellerRegister;