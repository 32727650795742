import axios from "axios";

// Initialize Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true, // Allow cookies to be sent
  headers: {
    "Content-Type": "application/json",
  },
});


// Add response interceptor for token refresh
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Avoid infinite loop and token refresh on login page
    if (
      error.response?.status === 401 &&
      error.response?.data?.code === "token_not_valid" &&
      !originalRequest._retry &&
      window.location.pathname !== "/seller/login" // Prevent retry on login page
    ) {
      originalRequest._retry = true; // Prevent infinite loops
      const refreshToken = localStorage.getItem("refresh_token");

      if (refreshToken) {
        try {
          // Attempt to refresh the token
          const { data } = await api.post("/token/refresh/", {
            refresh: refreshToken,
          });

          // Save new access token
          localStorage.setItem("access_token", data.access);
          localStorage.setItem("refresh_token", data.refresh); // Save the new refresh token


          // Update the failed request with the new token
          originalRequest.headers["Authorization"] = `Bearer ${data.access}`;

          return api.request(originalRequest); // Retry the request
        } catch (refreshError) {
          //console.error("Refresh token failed:", refreshError);
          localStorage.clear();
          if (window.location.pathname !== "/seller/login") {
            window.location.href = "/seller/login"; // Redirect to login
          }
        }
      } else {
        if (window.location.pathname !== "/seller/login") {
          window.location.href = "/seller/login"; // No refresh token, redirect to login
        }
      }
    }

    return Promise.reject(error); // Reject other errors
  }
);

export default api;
