import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';

const VendorMoneyMapDashboard = ({ data }) => {
    const chartData = {
        labels: data.categories,
        datasets: [
            {
                label: 'Spending by Category',
                data: data.amounts,
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    };

    return (
        <div>
            <h2>Spending Analytics</h2>
            <Bar data={chartData} />
            <Pie data={chartData} />
        </div>
    );
};

export default VendorMoneyMapDashboard;