import axios from "axios";
import {Link} from 'react-router-dom';
import {useState} from "react";
import api from "../api";
function CustomerRegister(props){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [formError,setFormError]=useState(false);
    const [errorMsg,seterrorMsg]=useState('');
    const [successMsg,setsuccessMsg]=useState('');
    const [loading, setLoading] = useState(false);
    const [ShowEmail, setShowEmail] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [registerFormData, setRegisterFormData]=useState({
        "username":'',
        "email":'',
        "mobile":'',
        "password":'',
    });
    const [emailError, setEmailError] = useState('');

    const inputHandler =(event) => {
        setRegisterFormData({
            ...registerFormData,
            [event.target.name]:event.target.value
        })
    };

    const submitHandler =(event) => {
        setLoading(true);
        setShowEmail(true);
        const formData=new FormData();
            formData.append('username',registerFormData.username);
            formData.append('email',registerFormData.email);
            //formData.append('mobile',registerFormData.mobile);
            formData.append('password',registerFormData.password);

            //SUBMIT DATA
            axios.post(baseUrl+'/customer/register/',formData)
            .then(function (response){
                if(response.data.bool==false){                    
                    seterrorMsg(response.data.msg)
                    setsuccessMsg('');
                }else{
                    setRegisterFormData({
                        "username":'',
                        "email":'',
                        "mobile":'',
                        "password":'',
                    });
                    seterrorMsg('');setConfirmPassword('');setLoading(false);
                    setShowEmail(false);
                    setsuccessMsg(response.data.msg);
    
                    setTimeout(() => {
                        window.location.href = '/customer/login';
                    }, 5000);
                }
            })
            .catch(function(error){
                seterrorMsg(error.response?.data?.msg || "An error occurred. Please try again.");
                setLoading(false);setShowEmail(false);
            });
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && !emailPattern.test(email)) {
            setEmailError('Invalid email format. Kindly enter a valid email.');
        } else {
            setEmailError('');
        }
    };

    const buttonEnable = (registerFormData.username!='') && (emailError=='') && (registerFormData.password!='')  && (registerFormData.password==confirmPassword)
    const buttonEnable0=(registerFormData.email_code!='')

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const checkPasswordsMatch = () => {
        if (registerFormData.password !== confirmPassword) {
          setErrorMessage('*Passwords do not match');
        } else {
          setErrorMessage('');
        }
    };
    
    const sendVerificationCode = () => {
        setLoading(true);
        api.post(baseUrl + "/send-verification-email/", { email: registerFormData.email,username: registerFormData.username })
            .then((response) => {
                if (response.data.success === true) {
                    setLoading(false);
                    setErrorMessage('');
                    seterrorMsg('');
                    setsuccessMsg('');
                    setShowEmail(true);
                } else {
                    seterrorMsg(response.data.message);                    
                    setShowEmail(false);
                    setLoading(false);
                }
            })
            .catch(setShowEmail(false));
    };

    const verifyCode = () => {
        api.post(baseUrl + "/verify-email-code/", { email: registerFormData.email, code: registerFormData.email_code })
            .then((response) => {
                if (response.data.success === true) {
                    setShowEmail(true);
                    submitHandler(); // Call the registration function
                } else {
                    seterrorMsg(response.data.message);
                }
            })
            .catch((error) => seterrorMsg(error.response?.data?.message || "An error occurred."));
    };
    const retry = () => {
        window.location.href = '/customer/register';
    };
    return (
        <section className="container mtopcon normal-mt-sctn">
            <div className="row">
                <div className="col-12 col-lg-4 reglogincon">
                    <div className="card shadow-none">
                        <div className="card-body"> 
                            <h2 className='mb-3 text-center'><b>Create an account</b></h2>                   
                            <form>
                                
                                {ShowEmail == false && <>
                                    <div className="mb-3">
                                        <label htmlFor="lnm" className="form-label font9 grey-text mb-0">Name*</label>
                                        <input type="text" name="username" onChange={inputHandler} value={registerFormData.username} className="form-control font9" id="lnm"/>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label htmlFor="InputMobile" className="form-label font9 grey-text mb-0">Email*</label>
                                            <input 
                                                type="email" 
                                                name="email" 
                                                placeholder="abc@gmail.com" 
                                                onChange={inputHandler} 
                                                onBlur={(e) => validateEmail(e.target.value)} 
                                                value={registerFormData.email} 
                                                className="form-control font9" 
                                                id="InputEmail" 
                                                aria-describedby="emailHelp" 
                                            />
                                            {emailError && <small className="text-danger font8">{emailError}</small>}
                                        </div>
                                    </div>
                                    <div className="row mb-3 display-none">
                                        <div className="col-12">
                                            <label htmlFor="InputMobile" className="form-label font9 grey-text mb-0">Phone No.</label>
                                            <input type="number" name="mobile" placeholder="254724000000" onChange={inputHandler} value={registerFormData.mobile} className="form-control font9" id="InputMobile" aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="InputPassword1" className="form-label font9 grey-text mb-0">Password*</label>
                                        <input type="password" name="password" onChange={inputHandler} value={registerFormData.password} className="form-control font9" id="InputPassword1"/>
                                    </div>  
                                    <div className="mb-3">
                                        <label htmlFor="InputPassword1" className="form-label font9 grey-text mb-0">Confirm Password*</label>
                                        <input type="password" name="password" onChange={handleConfirmPasswordChange} value={confirmPassword} onBlur={checkPasswordsMatch} className="form-control font9" id="InputPassword1"/>
                                        <span className="text-danger font9">{errorMessage}</span>
                                    </div>        
                                    {successMsg && <p className="text-success text-center mt-3 mb-3"><b>{successMsg}</b></p> } 
                                    {errorMsg && <p className="text-danger text-center mt-3 mb-3"><b>{errorMsg}</b></p> }                       
                                    <button type="button" disabled={!buttonEnable} onClick={sendVerificationCode} className="col-12 btn btn-primary">{ loading === true ? <>Loading...</> : <>Continue</>}</button>                           
                                    <div className="mb-3 mt-3">
                                        <p className='f85'>We value your privacy, by Proceeding you agree to our <Link to="/terms">Terms & Conditions</Link> and <Link to="/privacy">Privacy Policy</Link></p>
                                    </div>
                                    <div className="mb-3 font9">
                                        <p>Already have an account? <Link to="/customer/login">Log in</Link></p>
                                    </div>
                                </>}
                                {ShowEmail == true && <>
                                    <div className="mt-4 mb-4 border p-4 br-10">
                                        {successMsg && <p className="text-success text-center mb-3 font9 mb-0"><b>{successMsg}</b></p> } 
                                        {errorMsg && <p className="text-danger text-center mb-3 font9 mb-0"><b>{errorMsg}</b></p> } 
                                        <label htmlFor="InputPassword1" className="form-label font9 dark-text mb-0"><b>Email Code</b></label>
                                        <label htmlFor="InputPassword1" className="form-label font8 grey-text mb-3">Kindly enter code sent to <u>{registerFormData.email}</u> to complete.</label>
                                        <input type="number" name="email_code" onChange={inputHandler} value={registerFormData.email_code} placeholder='- - - - - -' className="form-control font9" id="InputPassword1"/>
                                        <div onClick={sendVerificationCode} className='font9 mt-3 text-blue'>Resend</div>
                                        <button type="button" disabled={!buttonEnable0} onClick={verifyCode} className="col-12 btn btn-primary mt-3">{ loading === true ? <>Loading...</> : <>Complete</>}</button>  
                                    </div>
                                </>}

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CustomerRegister;