import { useState, useEffect } from "react";
import { X } from 'react-feather';
import Sidebar from './sidebar';
import api from "../api";

function VendorDelivery() {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [inputFields, setInputFields] = useState([["", ""]]);
    const [existingDeliveries, setExistingDeliveries] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("access_token");

    useEffect(() => {
        fetchExistingDeliveries();
    }, []);

    const fetchExistingDeliveries = async () => {
        try {
            const response = await api.get(baseUrl + '/deliveries/', { headers: { Authorization: `Bearer ${token}` } });
            setExistingDeliveries(response.data);
        } catch (error) {
            setErrorMsg('Failed to fetch deliveries');
        } finally {
            setLoading(false);
        }
    };

    const addInputFields = () => setInputFields([...inputFields, ["", ""]]);
    const handleInputChange = (index, subIndex, event) => {
        const values = [...inputFields];
        values[index][subIndex] = event.target.value;
        setInputFields(values);
    };
    const removeInputFields = (index) => setInputFields(inputFields.filter((_, i) => i !== index));

    const deleteDelivery = async (id) => {
        try {
            await api.delete(baseUrl + `/deliveries/${id}/`, { headers: { Authorization: `Bearer ${token}` } });
            fetchExistingDeliveries();
        } catch (error) {
            setErrorMsg('Failed to delete delivery');
        }
    };

    const submitHandler = async () => {
        try {
            const locations = inputFields.map(field => ({ location: field[0], fee: field[1] }));
            await api.post(baseUrl + '/add-delivery/', { locations }, { headers: { Authorization: `Bearer ${token}` } });
            setInputFields([["", ""]]);
            setSuccessMsg('Delivery locations updated successfully!');
            fetchExistingDeliveries();
        } catch (error) {
            setErrorMsg(error.response?.data?.error || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const isButtonDisabled = inputFields.length === 0 || inputFields.some(pair => pair[0].trim() === '' || pair[1].trim() === '');


    return (
        <section className="container mtopcon dashb">
            <div className="row">
            <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">
                    
                        <div className="row mb-4 ph-header-con">
                            <h6 className="pageheader-title mb-0">Setup Delivery</h6>
                            <p className="grey-text lh0 font8">Set up delivery options for smooth order fulfillment.</p>
                        </div>
                        
                        <div className="row d-flex justify-content-center mt-4">
                            <div className="col-12 col-lg-4">
                                <div className="card shadow-sm border border-secondary border-opacity-10">
                                    <div className="card-body">
               
                                    <div className="mb-3">
                                            <label className="form-label font9 mb-0">Delivery Locations & Fees</label><br/>
                                            <label className="form-label font8 grey-text mb-2">Below is an overview of your delivery locations</label>
                                            <div className="row mb-0">
                                                <div className="col-7">
                                                    <label className="form-label font9 mb-0 grey-text">Location</label>
                                                </div>
                                                <div className="col-4 pl-0">
                                                    <label className="form-label font9 mb-0 grey-text">Fee</label>
                                                </div>
                                            </div>
                                            {loading === true && <p>Loading...</p>}
                                            {existingDeliveries.map((delivery) => (
                                                <div key={delivery.id} className="row mb-3">
                                                    <div className="col-7">
                                                        <input
                                                            type="text"
                                                            className="form-control addprobginpt w-100 size_mn"
                                                            value={delivery.location}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-4 pl-0">
                                                        <input
                                                            type="number"
                                                            className="form-control addprobginpt w-100 qty_mn"
                                                            value={delivery.fee}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-1 p-0 text-center">
                                                        <span onClick={() => deleteDelivery(delivery.id)}>
                                                            <X/>
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}

                                            {inputFields.map((pair, index) => (
                                                <div key={index} className="row mb-3">
                                                    <div className="col-7">
                                                        <input
                                                            type="text"
                                                            className="form-control bg-white border w-100 size_mn"
                                                            value={pair[0]}
                                                            onChange={(event) => handleInputChange(index, 0, event)}
                                                            placeholder="Onsite, Within Nairobi CBD, Outside Nairobi"
                                                        />
                                                    </div>
                                                    <div className="col-4 pl-0">
                                                        <input
                                                            type="number"
                                                            className="form-control bg-white border w-100 qty_mn"
                                                            value={pair[1]}
                                                            onChange={(event) => handleInputChange(index, 1, event)}
                                                            placeholder="200"
                                                        />
                                                    </div>
                                                    <div className="col-1 p-0 text-center">
                                                        <span onClick={() => removeInputFields(index)}>
                                                            <X/>
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div onClick={addInputFields} className="font9 pointer">
                                            + Add Delivery Location
                                        </div>
                                        
                                        <button type="button" onClick={submitHandler} disabled={isButtonDisabled} className="col-12 btn btn-primary mt-3">Update</button>
                                            
                                        {successMsg && <p className="text-success text-center mt-3"><b>{successMsg}</b></p> } 
                                        {errorMsg && <p className="text-danger text-center mt-3"><b>{errorMsg}</b></p> } 

                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </section>
    )
}

export default VendorDelivery;