import orderlogo from '../../order.jpg';
import {Link} from 'react-router-dom';
import Sidebar from './sidebar';
import { MoreVertical} from 'react-feather';
import { useState,useContext,useEffect } from 'react';
import api from "../api";
import useStoreSetupCheck from './useStoreSetupCheck';
function SellerOrders(){
    const setupStatus = useStoreSetupCheck();
    const [OrderItems,setOrderItems]=useState([]);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [statsLoading, setstatsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [VendorCurrency,setVendorCurrency]=useState([]); 

    const token = localStorage.getItem("access_token");

    const [stats, setStats] = useState({
        units_sold: 0,
        total_sales: 0,
        lifetime_revenue: 0,
    });
    const [filter, setFilter] = useState("today");

    useEffect(() => {
        fetchStats(filter);
    }, [filter]);

    const fetchStats = async (filterOption) => {
        setstatsLoading(true);
        try {
            const response = await api.get(`/vendor-sales-stats/?filter=${filterOption}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
            });
            setStats(response.data);
            setstatsLoading(false);
        } catch (error) {
            //console.error("Error fetching stats:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(()=>{
        fetchData();
    },[]);

    const fetchData = async () => {
        try {
          const response = await api.get(baseUrl+"/vendor-orderitems/", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          if(response.data){
            setOrderItems(response.data.order_items);
          }
        } catch (err) {
          //console.error("Error fetching products:", err);
          setError("Failed to fetch products. Please try again.");
        }finally {
            setLoading(false);
        }
    };
    
    function formatDate(dateString) {
        const date = new Date(dateString);
        const month = date.toLocaleDateString('en-US', { month: 'short' }); // "Nov"
        const day = date.getDate(); // 1
        const time = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        }).toLowerCase(); // "11:01 pm"
    
        return `${month} ${day}, ${time.replace(':', '.')}`; // "Nov 01 11.01pm"
    }
    // Filter quotations based on the search term
    const filteredResults = OrderItems.filter((product) => {
        const search = searchTerm.toLowerCase();
        return (
            (product.order.cust_name && product.order.cust_name.toLowerCase().includes(search)) ||
            (product.order.total_amount && product.order.total_amount.toLowerCase().includes(search))
        );
    });
    return (
        <section className="container mtopcon dashb">         
            <div className="row">
                
            <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max orderscontainer">
                        <div className="row mb-3 ph-header-con">
                            <div className="col-8 pt-2"><h6 className="pageheader-title mb-3">Orders Overview </h6></div>
                            <div className="col-4"><button className="btn text-white bg-dark f85 float-right hide">Export</button></div>
                        </div>                        
                        <div className="row mb-3">
                            <div className="col-12">
                                <select className="filter2 text-center border w-100" value={filter} onChange={(e) => setFilter(e.target.value)}>
                                        <option value="today">{ statsLoading === true ? <p>Loading...</p> : <p>Today</p>}</option>
                                        <option value="week">{ statsLoading === true ? <p>Loading...</p> : <p>This Week</p>}</option>
                                        <option value="month">{ statsLoading === true ? <p>Loading...</p> : <p>This Month</p>}</option>
                                        <option value="year">{ statsLoading === true ? <p>Loading...</p> : <p>This Year</p>}</option>
                                </select>
                            </div>
                        </div>
                        
                        <div className="row mb-3 ordststsdiv">
                            <div className="col-lg-3 col-md-7 col-sm-7 col-7 stat-con">
                                <div className="card mb-2">
                                    <div className="card-body p-2">
                                        <h5 className="text font8 grey-text">Total Sales</h5>
                                        <div className="metric-value d-inline-block">                                        
                                            <h1 className="mb-1"><span className='hdmobile'>{VendorCurrency}</span> {parseFloat(stats.total_sales.toFixed(2)).toLocaleString()}</h1>
                                        </div>
                                        <div className="metric-label d-inline-block float-right text-success">
                                            {stats.total_sales_deviation >= '0%' && <><span className='text-success font8'>+{stats.total_sales_deviation}</span></>}
                                            {stats.total_sales_deviation < '0%' && <><span className='text-danger font8'>{stats.total_sales_deviation}</span></>} 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-5 col-sm-5 col-5 stat-con pl-0">
                                <div className="card mb-2">
                                    <div className="card-body p-2">
                                        <h5 className="text font8 grey-text">Units Sold</h5>
                                        <div className="metric-value d-inline-block">
                                            <h1 className="mb-1">{stats.units_sold}</h1>
                                        </div>
                                        <div className="metric-label d-inline-block float-right text-primary">
                                            {stats.units_sold_deviation >= '0%' && <><span className='text-success font8'>+{stats.units_sold_deviation}</span></>}
                                            {stats.units_sold_deviation < '0%' && <><span className='text-danger font8'>{stats.units_sold_deviation}</span></>}                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-5 col-sm-5 col-5 stat-con pr-0">
                                <div className="card mb-2">
                                    <div className="card-body p-2">
                                        <h5 className="text font8 grey-text">New Customers</h5>
                                        <div className="metric-value d-inline-block">
                                            <h1 className="mb-1">{stats.unique_customers}</h1>
                                        </div>
                                        <div className="metric-label d-inline-block float-right text-primary">
                                            {stats.unique_customers_deviation >= '0%' && <><span className='text-success font8'>+{stats.unique_customers_deviation}</span></>}
                                            {stats.unique_customers_deviation < '0%' && <><span className='text-danger font8'>{stats.unique_customers_deviation}</span></>}                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-7 col-sm-7 col-7 stat-con">
                                <div className="card mb-2">
                                    <div className="card-body p-2">
                                        <h5 className="text font8 grey-text">Lifetime Revenue</h5>
                                        <div className="metric-value d-inline-block">
                                            <h1 className="mb-1"><span className='hdmobile'>{VendorCurrency}</span> {parseFloat(stats.lifetime_revenue.toFixed(2)).toLocaleString()}</h1>
                                        </div>
                                        <div className="metric-label d-inline-block float-right text-success">
                                            {stats.lifetime_revenue_deviation >= '0%' && <><span className='text-success font8'>+{stats.lifetime_revenue_deviation}</span></>}
                                            {stats.lifetime_revenue_deviation < '0%' && <><span className='text-danger font8'>{stats.lifetime_revenue_deviation}</span></>} 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <input
                                    className="filter2 border me-2 w-100"
                                    type="search"
                                    placeholder="Search Orders"
                                    aria-label="Search"
                                    onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on keyup
                                />
                            </div>
                        </div>
                        
                        <div className="row mb-2 ph-header-con">
                            <div className="col-8 pt-2"><h6 className="pageheader-title mb-3">Recent Orders </h6></div>
                        </div>

                        <div className="mb-4">
                            {filteredResults.length === 0 ? (
                                loading === true ? <p>Loading...</p> : <p>No orders found.</p>
                            ) : (
                                <>
                                    {// Sort OrderItems by order_time in descending order (newest first)
                                    filteredResults.sort((a, b) => new Date(b.order.order_time) - new Date(a.order.order_time))
                                    .map((item, index) => { 
                                        return (
                                            <Link to={`/seller/order/${item.order.order_id}`} className='text-dark' key={index}>
                                                <div className="row ord-bg bg-white p-2 pointer hvr bx-shadow">
                                                    <div className="col-2 col-lg-1 p-0">
                                                        <div className="ord-img br-50">
                                                            <img src={orderlogo} className='w-100 h-100' alt="Order logo" />
                                                        </div>
                                                    </div>
                                                    <div className="col-9 col-lg-9 pl-0 p-0">
                                                        <div className="row">
                                                            <div className="col-7 col-lg-3 ord-sz">{item.order.cust_name}</div>
                                                            <div className="col-5 col-lg-3 ord-sz text-primary">
                                                                {item.order.order_status === false && (
                                                                    <span className="badge rounded-pill text-bg-warning">{item.order.payment_status}</span>
                                                                )}
                                                                {item.order.order_status === true && (
                                                                    <span className="badge rounded-pill text-bg-success">Complete</span>
                                                                )}
                                                            </div>
                                                            <div className="col-7 col-lg-3 ord-sz grey-text">{formatDate(item.order.order_time)}</div>
                                                            <div className="col-5 col-lg-3 ord-sz grey-text">
                                                                    <span className='cart-pro-ttl'>
                                                                        {item.product.currency} {parseFloat(item.order.total_amount).toLocaleString()}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-1 col-lg-2 text-right f8 text-black p-0 mt-1 lh34"><MoreVertical/></div>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </>
                            )}  

                        </div>
                </div>
            </div>
        </section>
    )
}

export default SellerOrders;