import { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import api from "./components/api";

const PackageContext = createContext();

export const PackageProvider = ({ children }) => {
    const [packageDetails, setPackageDetails] = useState(null);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const location = useLocation();

    const authPaths = [
        '/customer/login',
        '/customer/register',
        '/customer/reset-link',
        '/customer/reset-password',
        '/seller/login',
        '/seller/register',
        '/seller/reset-link',
        '/seller/reset-password',
        '/vendor/reset-password',
        '/customer/logout',
        '/customer/dashboard',
        '/customer/orders',
        '/customer/wishlist',
        '/customer/change-password',
        '/customer/addresses',
        '/customer/addaddress',
        '/customer/update-address',
        '/customer/add-review',
        '/category',
        '/categories',
        '/products',
        '/product',
        '/checkout',
        '/confirm-order',
        '/order/success',
        '/order/failed',
        '/sellers',
        '/terms',
        '/privacy',
        '/help-center',
        '/categories',
        '/loader',
        '/error404',
        '/',
    ];

    const isAuthPage = authPaths.some(
        path =>
            path === location.pathname ||
            /^\/[^/]+$/.test(location.pathname) || // Match dynamic shop names (e.g., /shopname)
            /^\/category\/[^/]+\/\d+$/.test(location.pathname) || // Match dynamic category paths (e.g., /category/Jersey/1)
            /^\/product\/[^/]+\/\d+$/.test(location.pathname) || // Match dynamic product paths (e.g., /product/Jersey/1)
            /^\/[^/]+\/[^/]+\/\d+$/.test(location.pathname) // ✅ Match /shopname/categoryname/categoryid
    );

    const fetchPackageDetails = async () => {
        try {
            const token = localStorage.getItem("access_token");
            if (!isAuthPage) {
                const response = await api.get(baseUrl + "/vendor-package/", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setPackageDetails(response.data);
            } else {
                setPackageDetails(null); // Clear package details for auth pages
            }
        } catch (error) {
            setPackageDetails(null);
            // console.error("Error fetching package details:", error);
        }
    };

    useEffect(() => {
        fetchPackageDetails();
    }, [location.pathname]); // Refetch when pathname changes

    return (
        <PackageContext.Provider value={{ packageDetails, fetchPackageDetails }}>
            {children}
        </PackageContext.Provider>
    );
};

// Custom Hook for using PackageContext
export const usePackage = () => useContext(PackageContext);
