import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from './sidebar';
import api from "../api";

function StoreSetupGuide() {
    const navigate = useNavigate();
    const [setupStatus, setSetupStatus] = useState({
        subscription: false,
        payment: false,
        delivery: false,
        product: false,
    });

    useEffect(() => {
        const fetchSetupStatus = async () => {
            try {
                const token = localStorage.getItem("access_token");
                const response = await api.get("/vendor/store-setup-status/", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setSetupStatus(response.data);
            } catch (error) {
                //console.error("Error fetching setup status:", error);
            }
        };

        fetchSetupStatus();
    }, []);

    const handleNavigation = (step) => {
        switch (step) {
            case "payment":
                navigate("/seller/finance");
                break;
            case "delivery":
                navigate("/seller/delivery");
                break;
            case "product":
                navigate("/seller/addproduct");
                break;
            case "subscription":
                navigate("/seller/subscription");
                break;
            default:
                break;
        }
    };

    return (
        <section className="container mtopcon dashb">
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="menu-list">
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar />
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">
                    <div className="row mb-4 ph-header-con">
                        <h6 className="pageheader-title mb-0">Setup Guide</h6>
                        <p className="grey-text lh0 font9">Proceed to complete setting up your business.</p>
                    </div>
                    
                    <div className="row d-flex justify-content-center mt-4">
                        <div className="col-12 col-lg-4 font9">
                            <div className="card p-2 shadow-sm bg-white">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h5 className="mb-0"></h5>
                                    <a href="https://www.youtube.com/@kadhrafrica" target="_blank" className="text-primary pr-3"><b>Tutorials</b></a>
                                </div>

                                {/* Step 1: Payment Methods */}
                                <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
                                    <div className="d-flex align-items-center">
                                        <span className="step-number me-3">1</span>
                                        <span>Set Up Payments</span>
                                    </div>
                                    <button 
                                        className={`font8 p-1 btn ${setupStatus.payment ? "btn-success disabled" : "btn-primary"}`}
                                        onClick={() => handleNavigation("payment")}
                                    >
                                        {setupStatus.payment ? "Completed" : "Add Payment"}
                                    </button>
                                </div>

                                {/* Step 2: Delivery Options */}
                                <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
                                    <div className="d-flex align-items-center">
                                        <span className="step-number me-3">2</span>
                                        <span>Set Up Delivery</span>
                                    </div>
                                    <button 
                                        className={`font8 p-1 btn ${setupStatus.delivery ? "btn-success disabled" : "btn-primary"}`}
                                        onClick={() => handleNavigation("delivery")}
                                        disabled={!setupStatus.payment}
                                    >
                                        {setupStatus.delivery ? "Completed" : "Add Delivery"}
                                    </button>
                                </div>

                                {/* Step 3: Add First Product */}
                                <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
                                    <div className="d-flex align-items-center">
                                        <span className="step-number me-3">3</span>
                                        <span>Add Your First Product</span>
                                    </div>
                                    <button 
                                        className={`font8 p-1 btn ${setupStatus.product ? "btn-success disabled" : "btn-primary"}`}
                                        onClick={() => handleNavigation("product")}
                                        disabled={!setupStatus.delivery}
                                    >
                                        {setupStatus.product ? "Completed" : "Add Product"}
                                    </button>
                                </div>

                                {/* Step 4: Subscription Plan */}
                                <div className="d-flex align-items-center justify-content-between p-3">
                                    <div className="d-flex align-items-center">
                                        <span className="step-number me-3">4</span>
                                        <span>Select Subscription</span>
                                    </div>
                                    <button 
                                        className={`font8 p-1 btn ${setupStatus.subscription ? "btn-success disabled" : "btn-primary"}`}
                                        onClick={() => handleNavigation("subscription")}
                                        disabled={!setupStatus.product}
                                    >
                                        {setupStatus.subscription ? "Completed" : "Select Plan"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StoreSetupGuide;
