import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { ArrowLeft, Plus, X } from 'react-feather';
import Sidebar from './sidebar';
import { Link } from "react-router-dom";
import api from "../api";

function AddProduct() {    
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const VendorId = localStorage.getItem('vendor_id');
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [CategoryData, setCategoryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState("");
    const [ProductData, setProductData] = useState({
        "vendor": '',
        "category": '',
        "title": '',
        "slug": '',
        "detail": '',
        "offer_price": 0,
        "price": '',
        "usd_price": '',
        "keywords": '',
        "gender": '',
        "image": '',
        "visits": '',
        "status": '',
        "currency":'',
    });
    const [ProductImgs, setProductImgs] = useState([]);
    const [inputFields, setInputFields] = useState([["", ""]]);
    const token = localStorage.getItem("access_token");

    // Reference to the div with id="image_preview1"
    const imagePreviewRef = useRef(null);

    useEffect(() => {
        setProductData({
            ...ProductData,
            'vendor': VendorId
        });
        fetchData(baseUrl + '/categories/');
         // Retrieve customer data from localStorage when the component mounts
        const storedCurrency = localStorage.getItem('baseCurrency');
        if (storedCurrency) {
            setSelectedCurrency(storedCurrency);
        }
    }, []);

    function fetchData(baseurl) {
        fetch(baseurl)
            .then((response) => response.json())
            .then((data) => {
                setCategoryData(data.results);
            });
    }

    const inputHandler = (event) => {
        setProductData({
            ...ProductData,
            [event.target.name]: event.target.value
        });
    };

    const multipleFilesHandler = (event) => {
        const files = Array.from(event.target.files); // Convert FileList to array
        if (files.length > 0) {
            setProductImgs(files);
        }
    };

    const addInputFields = () => {
        setInputFields([...inputFields, ["", ""]]);
    };

    const handleInputChange = (index, subIndex, event) => {
        const values = [...inputFields];
        values[index][subIndex] = event.target.value;
        setInputFields(values);
    };

    const removeInputFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    };
    const submitHandler = async (event) => {
        setLoading(true);
        event.preventDefault();
        const allFieldsFilled = inputFields.every((pair) => pair[0] && pair[1]);
        
        if (!allFieldsFilled || !ProductImgs.length) {
            alert("Kindly fill out all fields and upload at least one image.");
            return;
        }

        const formData = new FormData();
        formData.append('vendor', VendorId);
        formData.append('category', ProductData.category);
        formData.append('title', ProductData.title);
        formData.append('slug', ProductData.title);
        formData.append('detail', ProductData.detail);
        formData.append('offer_price', ProductData.price);
        formData.append('price', ProductData.price);
        formData.append('usd_price', ProductData.usd_price);
        formData.append('keywords', ProductData.keywords);
        formData.append('gender', ProductData.gender);
        formData.append('visits', 0);
        formData.append('status', 1);
        formData.append('currency', selectedCurrency.currency);

        try {
            const response = await api.post(baseUrl + '/products/', formData, {
                headers: { 'content-type': 'multipart/form-data',Authorization: `Bearer ${token}` }
            });

            if (response.status == 201) {
                const productId = response.data.id;
                
                const postData = inputFields.map(([size, qty]) => ({
                    product: productId,
                    size: size,
                    qty: qty
                }));

                await Promise.all(postData.map(row => axios.post(baseUrl + "/size/", row)));

                if (Array.isArray(ProductImgs)) {
                    await Promise.all(ProductImgs.map((img) => {
                        const ImgFormData = new FormData();
                        ImgFormData.append('product', productId);
                        ImgFormData.append('image', img);
                        return axios.post(baseUrl + '/product-imgs/', ImgFormData);
                    }));
                }
                setLoading(false);

                setSuccessMsg('Product Added Successfully');
                setErrorMsg('');
                setProductImgs([]);
                setProductData({
                    "vendor": '',
                    "category": '',
                    "title": '',
                    "slug": '',
                    "detail": '',
                    "price": '',
                    "offer_price": '',
                    "usd_price": '',
                    "keywords": '',
                    "gender": '',
                    "image": '',
                    "visits": '',
                    "status": '',
                    "currency": '',
                });
                setInputFields([["", ""]]);

                // Clear the contents of the image preview div
                if (imagePreviewRef.current) {
                    imagePreviewRef.current.innerHTML = ''; // Clear contents of image_preview1
                }
            } else {
                setLoading(false);
                setErrorMsg('There was a problem adding the product. Kindly try again.');
                setSuccessMsg('');
            }
        } catch (error) {
            setLoading(false);
            //console.error("Error during product submission:", error);
            setErrorMsg('An error occurred. Kindly try again.');
        }
    };

    const buttonEnable = ProductImgs.length > 0 && ProductData.category && ProductData.title && ProductData.price && ProductData.detail;


    return (
        <section className="container mtopcon dashb">
            <div className="row">
            <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">
                    
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Add New Product</h6></div>
                            <div className="col-5"><Link to="/seller/products"><button className="btn btn-primary f85 float-right"><ArrowLeft/></button></Link></div>
                        </div>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="card shadow-sm border border-secondary border-opacity-10">                               
                               
                                <div className="card-body">   
                                        <div className="row">
                                            <label className="form-label font9 mb-0">Select atleast 2 Product Images</label>
                                            <div className="upimgcon">
                                                <input type="file" onChange={multipleFilesHandler} value={ProductData.image} name="images[]" id="images1" multiple className="form-control opzero" required/> <button className="btn addimg"><Plus/> </button>
                                            </div>
                                            <div className="col-7 p-0">
                                                <div id="image_preview1" ref={imagePreviewRef}>
                                                   
                                                </div>
                                            </div>
                                        </div>   
                                        
                                        <div className="mb-3">
                                            <label className="form-label font9 mb-0">Gender</label>
                                            <select className="form-control addprobginpt" name='gender' onChange={inputHandler} value={ProductData.gender}>
                                                    <option value="">Select</option>
                                                    <option value='Unisex'>Unisex</option>
                                                    <option value='Female'>Female</option>
                                                    <option value='Male'>Male</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label font9 mb-0">Category</label>
                                            <select className="form-control addprobginpt" name='category' onChange={inputHandler} value={ProductData.category}>
                                            <option value="">Select</option>
                                                {
                                                    CategoryData.map((item,index)=>{ 
                                                        return <option value={item.id} key={index}>{item.title}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="card shadow-sm border border-secondary border-opacity-10">
                            
                                <div className="card-body">                        
                                    <form>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label className="form-label font9 mb-0">Product Name</label>
                                                <input type="text" onChange={inputHandler} value={ProductData.title} name="title"  className="form-control addprobginpt" id="fnm"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label className="form-label font9 mb-0">Price</label>
                                                <input type="number" onChange={inputHandler} value={ProductData.price} name="price" className="form-control addprobginpt" id="lnm"/>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label font9 mb-0">Description</label>
                                            <textarea type="text" onChange={inputHandler} value={ProductData.detail} name="detail" className="form-control addprobginpt" id="lnm"/>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label font9 mb-0">Stock & Size</label>
                                            <div className="row mb-0">
                                                <div className="col-5">
                                                    <label className="form-label font9 mb-0 grey-text">Size Name</label>
                                                </div>
                                                <div className="col-5">
                                                    <label className="form-label font9 mb-0 grey-text">Qty</label>
                                                </div>
                                            </div>

                                            {inputFields.map((pair, index) => (
                                                <div key={index} className="row mb-3">
                                                    <div className="col-5">
                                                        <input
                                                            type="text"
                                                            className="form-control addprobginpt w-100 size_mn"
                                                            value={pair[0]}
                                                            onChange={(event) => handleInputChange(index, 0, event)}
                                                            placeholder="XL, ml ,kg, Inches, Generic"
                                                        />
                                                    </div>
                                                    <div className="col-5">
                                                        <input
                                                            type="number"
                                                            className="form-control addprobginpt w-100 qty_mn"
                                                            value={pair[1]}
                                                            onChange={(event) => handleInputChange(index, 1, event)}
                                                            placeholder="1"
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <span onClick={() => removeInputFields(index)} style={{ marginLeft: "5px" }}>
                                                            <X/>
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div onClick={addInputFields} className="font9 pointer">
                                            + Add Size Variant
                                        </div>
                                        { loading === true ? <><button type="button" className="col-12 btn btn-primary mt-3">Loading...</button></> : <><button type="button" disabled={!buttonEnable} onClick={submitHandler} className="col-12 btn btn-primary mt-3">Add Product</button></>}
                                        
                                            
                                        {successMsg && <p className="text-success text-center mt-3"><b>{successMsg}</b></p> } 
                                        {errorMsg && <p className="text-danger text-center mt-3"><b>{errorMsg}</b></p> } 
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddProduct;