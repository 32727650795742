import logo from '../logo.svg';
import {Link} from 'react-router-dom';
import { Heart} from 'react-feather';
import { useState,useEffect, useContext } from 'react';
import { UserContext,CartContext } from '../context';
import { FaHeart } from "react-icons/fa";
import axios from 'axios';
import { ExchangeRateContext } from '../ExchangeRateContext';
function SingleProduct(props){    
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [productData,setProductData]=useState([]);
    const [cartButtonClickStatus,setcartButtonClickStatus]=useState(false);
    const {cartData,setCartData}=useContext(CartContext);
    const userContext=useContext(UserContext);
    const [ProductInWishlist,setProductInWishlist]=useState(false);
    const product_id=props.product.id;
    const [productImgs,setProductImgs]=useState([]);
    
    const customerId = localStorage.getItem('customer_id');

    const { exchangeRates, baseCurrency } = useContext(ExchangeRateContext);

    if(exchangeRates==null){window.location.reload();}
    
    var price = props.product.price;
    var currency = props.product.currency;

    // Convert price from the product's original currency to the selected base currency
    const productExchangeRate = exchangeRates[currency] || 1;
    const baseExchangeRate = exchangeRates[baseCurrency] || 1;

    // Calculate the converted price
    const convertedPrice = ((price / productExchangeRate) * baseExchangeRate).toFixed(2);

    if(!props.product.image){
        props.product.image=logo;
    }

    useEffect(()=>{
        checkProductInCart(product_id);
        if(customerId!=null){checkProductInWishlist(baseUrl+'/check-in-wishlist/',product_id);}         
        fetchData(baseUrl+'/minimal-product/'+product_id);
    },[]);

    function fetchData(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setProductData(data);
            setProductImgs(data.product_imgs);
        });
    }

    function checkProductInCart(product_id){
        var previousCart=localStorage.getItem('cartData');
        var cartJson=JSON.parse(previousCart);
        if(cartJson!=null){
            cartJson.map((cart)=>{
                if(cart!=null && cart.product.id === product_id){
                    setcartButtonClickStatus(true);
                }
            });
        }
    }

    function saveInWishlist(){
        const formData=new FormData();
        formData.append('customer',customerId);
        formData.append('product',product_id);
        
        
        //SUBMIT DATA
        axios.post(baseUrl+'/wishlist/',formData)
        .then(function (response){
            if(response.data.id){
                setProductInWishlist(true);
            }   
        })
        .catch(function(error){
            //console.log(error);
        });        
    }
    
    function checkProductInWishlist(baseurl,product_id){
        const customerId = localStorage.getItem('customer_id');
        const formData=new FormData();
        formData.append('customer',customerId);
        formData.append('product',product_id);        
        
        //SUBMIT DATA
        axios.post(baseurl,formData)
        .then(function (response){
            if(response.data.bool===true){
                setProductInWishlist(true);
            }else{setProductInWishlist(false);}    
        })
        .catch(function(error){
            //console.log(error);
        });
        
    }

    return (
        <div className="col-6 col-md-4 col-lg-3 prod-col">
            <div className="card prodcon">
                <Link to={`/product/${props.product.title}/${props.product.id}`}>
                    {
                        productImgs.map((img,index)=>{
                            if(index===0){
                                return <img src={img.image} className="card-img-top pro-img" alt={index} key={index}/>                           
                            }
                            
                        })
                    }
                </Link>
                <div className="card-footer border-0 likebtn0">     
                    <p className="card-title pro-title fsz-95 mb-0"><Link to={`/product/${props.product.title}/${props.product.id}`}>{props.product.title}</Link></p>
                    <p className="card-title text-price fsz-1 mb-1"><b>{baseCurrency} {new Intl.NumberFormat().format(convertedPrice)}</b></p>   
                </div>
                <div className="card-footer border-0 likebtn">
                        {
                            (userContext && !ProductInWishlist) && 
                            <Heart onClick={saveInWishlist}/>
                        }
                        {
                            (userContext && ProductInWishlist) && 
                            <Link to={`customer/wishlist`}><FaHeart className='inwish'/></Link>
                        }
                        {
                            userContext === null && <Link to={`/customer/login`}><Heart /></Link>
                        }  
                </div>
            </div>
        </div>
         
    )
}

export default SingleProduct;