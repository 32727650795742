import Sidebar from './sidebar';
import {Link} from 'react-router-dom';
import { useState,useEffect } from 'react';
import OrderRow from "./orderrows";
import api from "../api0";
import { ArrowLeft } from 'react-feather';
function CustomerOrders(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [OrderItems,setOrderItems]=useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchData();
    },[]);

    const fetchData = async () => {
        try {
          const response = await api.get(`${baseUrl}/customer-orderitems/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          setOrderItems(response.data.results);
        } catch (error) {
          //console.error("Error fetching products:", err.toJSON());
        }
    };
    // Filter quotations based on the search term
    const filteredResults = OrderItems.filter((product) => {
        const search = searchTerm.toLowerCase();
        return (
            (product.product.title && product.product.title.toLowerCase().includes(search)) ||
            (product.order.total_amount && product.order.total_amount.toLowerCase().includes(search))
        );
    });

    return (
        <section className="container mtopcon dashb">
            <div className="row">             
                <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div> 
                <div className="col-12 col-lg-10 dash-max">                        
                        <div className="row mb-3 ph-header-con">
                            <div className="col-8 pt-2"><h6 className="pageheader-title mb-3">Orders Overview</h6></div>
                            <div className="col-4"><Link to="/customer/dashboard"><button className="btn btn-primary f85 float-right h40 lh40"><ArrowLeft width='20px'/></button></Link></div>
                        </div>
                        
                        <div className="mb-4 ordststsdiv">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <input
                                        className="filter2 border me-2 w-100"
                                        type="search"
                                        placeholder="Search Orders"
                                        aria-label="Search"
                                        onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on keyup
                                    />
                                </div>
                            </div>     
                            {filteredResults.count === 0 ? (
                                loading === true ? <p>Loading...</p> : <p>No orders found.</p>
                            ) : (
                                <>
                                    {// Sort OrderItems by order_time in descending order (newest first)
                                filteredResults.sort((a, b) => new Date(b.order.order_time) - new Date(a.order.order_time))
                                .map((item, index) => { 
                                    return (
                                        <OrderRow item={item} key={index} index={index} />
                                    );
                                })}
                                </>
                            )}                          
                        </div>
                </div>
            </div>
        </section>
    )
}

export default CustomerOrders;