import axios from "axios";
import { useState, useEffect } from "react";
import { ArrowLeft} from 'react-feather';
import Sidebar from './sidebar';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import api from "../api";

function BizdocsEditProduct() {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const VendorId = localStorage.getItem('vendor_id');
    const {uniqid} = useParams();
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [ProductData, setProductData] = useState({
        "vendor": '',
        "title": '',
        "detail": '',
        "unit": '',
        "price": '',
        "tax": '',
        "hsn": '',
    });
    const token = localStorage.getItem("access_token");
    
    useEffect(() => {
        setProductData({
            ...ProductData,
            'vendor': VendorId
        });
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await api.get(baseUrl+'/bizproproduct/'+uniqid, {
                headers: {
                Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data;
            setProductData({
                "title": data.title,
                "detail": data.detail,
                "unit": data.unit,
                "price": data.price,
                "tax": data.tax,
                "hsn": data.hsn,
            });
        } catch (error) {
            //setError('Failed to fetch customer data. Please try again later.');
        } finally {
            //setLoading(false);
        }
    };

    const inputHandler = (event) => {
        setProductData({
            ...ProductData,
            [event.target.name]: event.target.value
        });
    };

    const submitHandler = async (event) => {
        event.preventDefault(); // Prevent form from reloading page
        const formData = new FormData();
        formData.append('title', ProductData.title);
        formData.append('detail', ProductData.detail);
        formData.append('unit', ProductData.unit);
        formData.append('price', ProductData.price);
        formData.append('tax', ProductData.tax);
        formData.append('hsn', ProductData.hsn);
    
        try {
            const response = await api.patch(`${baseUrl}/bizproproduct/${uniqid}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Correct content type for FormData
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.status === 200) { // PATCH successful status is 200
                setSuccessMsg('Product Updated Successfully');
                setErrorMsg('');
            } else {
                setErrorMsg('There was a problem updating the product. Kindly try again.');
                setSuccessMsg('');
            }
        } catch (error) {
            console.error(error);
            setErrorMsg('An error occurred while updating the product. Please try again.');
            setSuccessMsg('');
        }
    };
    
    

    const buttonEnable = ProductData.title && ProductData.detail && ProductData.unit && ProductData.price && ProductData.tax


    return (
        <section className="container mtopcon dashb">
            <div className="row">
            <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">
                    
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Edit Product</h6></div>
                            <div className="col-5"><Link to="/bizdocs/products"><button className="btn btn-primary f85 float-right"><ArrowLeft/></button></Link></div>
                        </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-4">
                            <div className="card shadow-sm border border-secondary border-opacity-10">                               
                               
                            <div className="card-body">  
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Product Name</label>
                                                <input type="text" onChange={inputHandler} value={ProductData.title} name="title"  className="form-control addprobginpt" id="fnm"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="lnm" className="form-label font9 mb-0">Price</label>
                                                <input type="number" onChange={inputHandler} value={ProductData.price} name="price" className="form-control addprobginpt" id="lnm"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Unit Of Measure (Kg/g, CM, ml/l etc)</label>
                                                <input type="email" onChange={inputHandler} value={ProductData.unit} name="unit"  className="form-control addprobginpt" id="fnm"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Tax (%)</label>
                                                <input type="text" onChange={inputHandler} value={ProductData.tax} name="tax"  className="form-control addprobginpt" id="fnm"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Description</label>
                                                <textarea type="text" onChange={inputHandler} value={ProductData.detail} name="detail" className="form-control addprobginpt" id="lnm"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">HSN</label>
                                                <input type="text" onChange={inputHandler} value={ProductData.hsn} name="hsn"  className="form-control addprobginpt" id="fnm"/>
                                            </div>
                                        </div>

                                        <button type="button" disabled={!buttonEnable} onClick={submitHandler} className="col-12 btn btn-primary mt-3">Add Product</button>

                                        {successMsg && <p className="text-success mt-4 text-center"><b>{successMsg}</b></p> } 
                                        {errorMsg && <p className="text-danger mt-4 text-center"><b>{errorMsg}</b></p> }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BizdocsEditProduct;