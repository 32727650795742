import React, { useState } from 'react';
import api from '../api';

const VendorMoneyMapUpload = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("access_token");
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = api.post(baseUrl+'/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token})`,
                },
            });
            alert(response.data.message);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="file" onChange={handleFileChange} accept=".pdf,.xlsx,.xls" />
            <button type="submit">Upload</button>
        </form>
    );
};

export default VendorMoneyMapUpload;