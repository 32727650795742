import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "./components/api";

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = useState(true);
    const [packageDetails, setPackageDetails] = useState(null);

    useEffect(() => {
        const fetchPackageDetails = async () => {
            try {
                const token = localStorage.getItem("access_token"); 
                const response = await api.get(baseUrl + "/vendor-package/", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setPackageDetails(response.data);
            } catch (error) {
                //console.error("Error fetching package details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPackageDetails();
    }, []);

    if (loading) return <p>Loading...</p>;

    var status_pay = 1;
    if(packageDetails == null || packageDetails.status === "0"){
        status_pay = 0;
    }else if(packageDetails.package_name === "Personal"){
        status_pay = 0;
    }
    // If package status is "0", redirect to subscription page
    if (status_pay == 0) {
       // navigate("/seller/subscription");
        return null;
    }

    return children; // Render the requested page if status is not "0"
};

export default ProtectedRoute;
