import { useState,useEffect,useContext } from 'react';
import Sidebar from './sidebar';
import { Plus } from 'react-feather';
import api from '../api';
function SellerProfile(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [ProfileData,setProfileData]=useState({  
        "user_id":'', 
        "first_name":'',
        "last_name":'',
        "username":'',
        "email":'',
        "mobile":'',
        "address":'',
        "p_image":'',               
        "ig":'',
        "tk":'',
        "tw":'',
        "yt":'',
        "fb":'',
    });
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    
    const token = localStorage.getItem("access_token");

    useEffect(()=>{
        fetchData();
    },[]);

    const fetchData = async () => {
        try {
            const response = await api.get(baseUrl+'/vendor/', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data;
            setProfileData({
                "user_id":data.user.id,
                "first_name":data.user.first_name,
                "last_name":data.user.last_name,
                "username":data.user.username,
                "email":data.user.email,
                "mobile":data.mobile,
                "address":data.address,
                "p_image":data.profile_img,                     
                "ig":data.ig,
                "tk":data.tk,
                "tw":data.tw,
                "yt":data.yt,
                "fb":data.fb,
            });
        } catch (error) {
            //setError('Failed to fetch customer data. Please try again later.');
        } finally {
            //setLoading(false);
        }
    };

    const inputHandler =(event) => {
        setProfileData({
            ...ProfileData,
            [event.target.name]:event.target.value
        });
    };

    const handleFileChange =(event) => {
        setProfileData({
            ...ProfileData,
            [event.target.name]:event.target.files[0]
        })
    };

    const submitHandler =(event) => {
        setLoading(true);
        const formData=new FormData();
            formData.append('user',ProfileData.user_id);
            formData.append('mobile',ProfileData.mobile);
            formData.append('address',ProfileData.address);       
            formData.append('ig',ProfileData.ig);
            formData.append('tk',ProfileData.tk);
            formData.append('tw',ProfileData.tw);
            formData.append('yt',ProfileData.yt);
            formData.append('fb',ProfileData.fb);

            // Add profile image only if a new file is selected
            if (ProfileData.length > 0) {
                formData.append("profile_img", ProfileData.p_image);
            }

            //SUBMIT DATA
            api.put(baseUrl+'/vendor/',formData,{
                headers: {
                    'content-type' : 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            })
            .then(function (response){
                //window.location.reload();
                setLoading(false);     
                setSuccessMsg('Profile updated successfully');
                setErrorMsg('');
            })
            .catch(function(error){
                setLoading(false);     
                //console.log(error);
                setSuccessMsg('');
            });

            const formUserData=new FormData();
            formUserData.append('first_name',ProfileData.first_name);
            formUserData.append('last_name',ProfileData.last_name);
            formUserData.append('username',ProfileData.username);
            formUserData.append('email',ProfileData.email);     
            //SUBMIT DATA
            api.put(baseUrl+'/user/',formUserData, {
                headers: {
                    "content-type": "multipart/form-data",
                    Authorization: `Bearer ${token}`
                },
            })
            .then(function (response){
                setLoading(false);     
                //console.log(response);
            })
            .catch(function(error){               
                setErrorMsg('There was a problem. Kindly try again.');
                setSuccessMsg('');
            });
    };
    
    const socialLinks = [        
        {
            name: "Instagram",
            color: "#E4405F", // Instagram Pinkish Red
            icon: (
              <path d="M7 2c-2.8 0-5 2.2-5 5v10c0 2.8 2.2 5 5 5h10c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5H7zm8 2a1 1 0 011 1v1a1 1 0 01-2 0V5a1 1 0 011-1zm-5 3a5 5 0 015 5 5 5 0 01-5 5 5 5 0 01-5-5 5 5 0 015-5zm0 2a3 3 0 100 6 3 3 0 000-6z" />
            ),
        },
        {
          name: "TikTok",
          color: "#000000", // TikTok Black
          icon: (
            <path d="M12 2c-5.5 0-10 4.5-10 10s4.5 10 10 10c5.3 0 9.7-4.1 10-9.4h-3.2c-.3 3.3-3.1 5.9-6.8 5.9-3.8 0-6.8-3.1-6.8-6.8S8.2 4.2 12 4.2c1.3 0 2.5.4 3.5 1l1.5-2.4C15.6 1.4 13.8 1 12 1zM18.6 3.4l-1.6 2.4c.6.3 1.1.7 1.6 1.1V3.4z" />
          ),
        },
        {
          name: "Twitter",
          color: "#1DA1F2", // Twitter Blue
          icon: (
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53A4.48 4.48 0 007.86 7.53v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
          ),
        },
        {
            name: "YouTube",
            color: "#FF0000", // YouTube Red
            icon: <path d="M21.8 8s-.2-1.4-.8-2a3.7 3.7 0 00-2-1.4C16.7 4 12 4 12 4s-4.7 0-7 .6a3.7 3.7 0 00-2 1.4c-.6.6-.8 2-.8 2S2 9.7 2 11.5v1c0 1.8.2 3.5.2 3.5s.2 1.4.8 2a3.7 3.7 0 002 1.4c2.3.6 7 .6 7 .6s4.7 0 7-.6a3.7 3.7 0 002-1.4c.6-.6.8-2 .8-2s.2-1.7.2-3.5v-1c0-1.8-.2-3.5-.2-3.5zm-12 6.7V9.3l5.3 2.7-5.3 2.7z" />,
        },
        {
          name: "Facebook",
          color: "#1877F2", // Facebook Blue
          icon: (
            <path d="M22 12.1C22 6.5 17.5 2 12 2S2 6.5 2 12.1c0 4.7 3.4 8.6 7.8 9.5v-6.7H7.1V12h2.7V9.7c0-2.6 1.6-4 3.9-4 1.1 0 2.1.1 2.4.1v2.8h-1.6c-1.3 0-1.5.6-1.5 1.4V12h2.7l-.4 2.9h-2.3V22c4.4-.9 7.8-4.8 7.8-9.5z" />
          ),
        },
      ];
      

    return (
        <section className="container mtopcon dashb">
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max">
                        
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Business Profile </h6></div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-lg-4">
                                <div className="card shadow-sm border border-secondary border-opacity-10 ">
                                    <div className="card-body">                        
                                    <form>
                                            <div className="row mb-3">
                                                <div className="col-6">
                                                    <label htmlFor="fnm" className="form-label font9">CEO Name*</label>
                                                    <input type="text" className="form-control font9" id="lnm" name='first_name' onChange={inputHandler} value={ProfileData.first_name} />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="lnm" className="form-label font9">Business Name*</label>
                                                    <input type="text" className="form-control font9" id="lnm" name='username' onChange={inputHandler} value={ProfileData.username} />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="InputEmail" className="form-label font9">Email address*</label>
                                                    <input type="email" className="form-control font9" id="InputEmail" aria-describedby="emailHelp"  name='email' onChange={inputHandler} value={ProfileData.email} />
                                                </div>
                                            </div>                                
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="InputEmail" className="form-label font9">Phone No*.</label>
                                                    <input type="number" className="form-control font9" id="InputEmail" aria-describedby="emailHelp"  name='mobile' onChange={inputHandler} value={ProfileData.mobile} />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="InputEmail" className="form-label font9">Location</label>
                                                <textarea type="text" className="form-control font9"  name='address' onChange={inputHandler} value={ProfileData.address}></textarea>
                                            </div>
                                            <label htmlFor="InputEmail" className="form-label font9">
                                            Social media links
                                            </label>
                                            <div className="accordion" id="accordionExamplelnks">  
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            
                                                        <ul className="list-unstyled d-flex p-0 m-0">
                                                            {socialLinks.map((social) => (
                                                                <>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill={social.color} // Assign brand color
                                                                    viewBox="0 0 24 24"
                                                                    className="w18 me-2"
                                                                >
                                                                    {social.icon}
                                                                </svg>
                                                                </>
                                                            ))}
                                                        </ul>

                                                    </button>
                                                    </h2>
                                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExamplelnks">
                                                    <div className="accordion-body">                                                            
                                                            <div className="mb-3">
                                                                <label for="basic-url" className="form-label font9">Instagram</label>
                                                                <div className="input-group">
                                                                    <span className="font8 input-group-text" id="basic-addon3">instagram.com/</span>
                                                                    <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4" placeholder='username' name='ig'  onChange={inputHandler} value={ProfileData.ig}/>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label for="basic-url" className="form-label font9">Tiktok</label>
                                                                <div className="input-group">
                                                                    <span className="font8 input-group-text" id="basic-addon3">tiktok.com/</span>
                                                                    <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4" placeholder='username' name='tk'  onChange={inputHandler} value={ProfileData.tk}/>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label for="basic-url" className="form-label font9">Twitter</label>
                                                                <div className="input-group">
                                                                    <span className="font8 input-group-text" id="basic-addon3">twitter.com/</span>
                                                                    <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4" placeholder='username' name='tw'  onChange={inputHandler} value={ProfileData.tw}/>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label for="basic-url" className="form-label font9">Youtube</label>
                                                                <div className="input-group">
                                                                    <span className="font8 input-group-text" id="basic-addon3">youtube.com/</span>
                                                                    <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4" placeholder='username' name='yt'  onChange={inputHandler} value={ProfileData.yt}/>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label for="basic-url" className="form-label font9">Facebook</label>
                                                                <div className="input-group">
                                                                    <span className="font8 input-group-text" id="basic-addon3">facebook.com/</span>
                                                                    <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4" placeholder='username' name='fb'  onChange={inputHandler} value={ProfileData.fb}/>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <label htmlFor="formFile" className="form-label font9">Profile Image</label>
                                                <div className="upimgcon">
                                                    <input type="file" onChange={handleFileChange} name="images[]" id="images2" className="form-control opzero" required/> <button className="btn addimg"><Plus/> </button>
                                                </div>
                                                <div className="col-8 p-0">
                                                    <div id="image_preview2">
                                                        <img src={ProfileData.p_image} width='100' className="mb-1"></img>
                                                    </div>
                                                </div>
                                            </div> 
                                            { loading === true ? <>
                                                <button type="button" disabled className="col-12 btn btn-primary">Loading...</button>
                                            </> : <>
                                                <button type="button" onClick={submitHandler} className="col-12 btn btn-primary">Update</button>
                                            </>}
                                            
                                            {successMsg && <p className="text-success mt-4 text-center"><b>{successMsg}</b></p> } 
                                            {errorMsg && <p className="text-danger mt-4 text-center"><b>{errorMsg}</b></p> }   
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </section>
    )
}

export default SellerProfile;