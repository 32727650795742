import { useState, useEffect } from "react";
import { ArrowLeft, Edit, Plus, ArrowDown, Trash } from 'react-feather';
import userlogo from '../../user.jpg';
import Sidebar from './sidebar';
import { Link } from "react-router-dom";
import api from "../api";

function BizdocsPurchaseOrder() {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const VendorId = localStorage.getItem('vendor_id');
    const [customers, setCustomers] = useState([]);
    const [products, setproducts] = useState([]);
    const [terms, setterms] = useState([]);
    const [selectedTerms, setSelectedTerms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [customer, setCustomer] = useState(null);
    const [chargeLabel, setChargeLabel] = useState('');
    const [amount, setAmount] = useState('');
    const [tax, setTax] = useState(0);
    const [charges, setCharges] = useState([]);
    const [quotationProducts, setQuotationProducts] = useState([]);
    const [totalTax, setTotalTax] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTermB, setSearchTermB] = useState("");

    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const token = localStorage.getItem("access_token");

    useEffect(() => {
        const fetchCustomers = async () => {            
            try {
                const response = await api.get(`${baseUrl}/bizprocustomer/list/`, {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    },
                });
                setCustomers(response.data.results);
            } catch (error) {
                setError('Failed to fetch customer data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
        fetchCustomers();

        const fetchproducts = async () => {            
            try {
                const response = await api.get(`${baseUrl}/bizproproduct/list/`, {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    },
                });
                setproducts(response.data.products);
            } catch (error) {
                setError('Failed to fetch product data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        const fetchterms = async () => {            
            try {
                const response = await api.get(`${baseUrl}/bizproterms/list/`, {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    },
                });
                setterms(response.data.results);
            } catch (error) {
                setError('Failed to fetch term data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchterms();

        fetchproducts();

        const fetchSelectedTerms = () => {
            const terms = JSON.parse(localStorage.getItem('purchaseordersselectedTerms')) || [];
            setSelectedTerms(terms);
        };

        fetchSelectedTerms();

        // Retrieve customer data from localStorage when the component mounts
        const storedCustomer = JSON.parse(localStorage.getItem('purchaseorderselectedCustomer'));
        if (storedCustomer) {
            setCustomer(storedCustomer);
        }

        fetchQuotationProducts();

        fetchCharges();

        // Listen to changes in localStorage
        const handleStorageChange = () => {
            fetchDataFromLocalStorage();
            calculateTotals();
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };

    }, []);

    useEffect(() => {
        fetchDataFromLocalStorage();
        calculateTotals();
    }, [charges, quotationProducts]);

    const handleRemoveFromLocalStorage = () => {
        // Remove the customer data from localStorage
        localStorage.removeItem('purchaseorderselectedCustomer');
        setCustomer(null); // Optionally clear the state
    };

    const [productDetails, setProductDetails] = useState({
        price: '',
        qty: '',
        tax: ''
    });

    // Function to handle the "Add to Quotation" button click
    const handleAddToQuotation = (productId) => {
        // Get the current product data
        const product = products.find(p => p.id === productId);

        // Create a new product object with the input data
        const newProduct = {
            productId: product.id,
            title: product.title,
            price: productDetails.price,
            qty: productDetails.qty,
            tax: productDetails.tax,
            unit: product.unit,
            desc: product.detail
        };

        // Get existing products from localStorage or initialize an empty array
        const existingProducts = JSON.parse(localStorage.getItem('purchaseorderProducts')) || [];

        // Add the new product to the array
        existingProducts.push(newProduct);

        // Save the updated list back to localStorage
        localStorage.setItem('purchaseorderProducts', JSON.stringify(existingProducts));

        // Optionally clear the form inputs after adding
        setProductDetails({
            price: '1',
            qty: '',
            tax: ''
        });

        window.location.reload();
    };

    // Update product details state based on user input
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
    };


    // Function to fetch products from localStorage
    const fetchQuotationProducts = () => {
        const storedProducts = JSON.parse(localStorage.getItem('purchaseorderProducts')) || [];
        setQuotationProducts(storedProducts);
    };

    // Function to handle removal of product from localStorage
    const removeProductFromLocalStorage = (productId) => {
        // Get current products from localStorage
        const storedProducts = JSON.parse(localStorage.getItem('purchaseorderProducts')) || [];

        // Filter out the product with the given ID
        const updatedProducts = storedProducts.filter(product => product.productId !== productId);

        // Update localStorage with the filtered products
        localStorage.setItem('purchaseorderProducts', JSON.stringify(updatedProducts));

        // Update state to reflect the changes
        setQuotationProducts(updatedProducts);
    };

    const handleRowClick = (term) => {
        // Get the existing terms from localStorage
        const existingTerms = JSON.parse(localStorage.getItem('purchaseordersselectedTerms')) || [];
        
        // Check if the term is already in the array
        const isTermAlreadyAdded = existingTerms.some((existingTerm) => existingTerm.id === term.id);
        
        if (!isTermAlreadyAdded) {
            // Add the new term to the array
            const updatedTerms = [...existingTerms, term];
            
            // Store the updated array in localStorage
            localStorage.setItem('purchaseordersselectedTerms', JSON.stringify(updatedTerms));

            window.location.reload();
        }
    };

    // Remove a term from localStorage
    const handleRemoveTerm = (termId) => {
        const updatedTerms = selectedTerms.filter((term) => term.id !== termId);
        setSelectedTerms(updatedTerms); // Update state
        localStorage.setItem('purchaseordersselectedTerms', JSON.stringify(updatedTerms)); // Update localStorage
    };

     // Function to handle adding charge to localStorage
     const handleAddCharge = () => {
        // Create a charge object with the input data
        const chargeData = {
            charge_label: chargeLabel,
            amount: parseFloat(amount),
            tax: parseFloat(tax),
        };
    
        // Save the new charge to localStorage, replacing any existing charges
        localStorage.setItem('purchaseordersCharges', JSON.stringify([chargeData]));
    
        // Clear the inputs
        setChargeLabel('');
        setAmount('');
        setTax(0);
    
        // Trigger UI update (optional: remove reload if using state for dynamic updates)
        window.location.reload();
    };
    

    // Check if the button should be active
    const isButtonDisabled = !chargeLabel || !amount;

    // Fetch charges from localStorage
    const fetchCharges = () => {
        const storedCharges = JSON.parse(localStorage.getItem('purchaseordersCharges')) || [];
        setCharges(storedCharges);
    };

    // Remove charge from localStorage
    const handleRemoveCharge = (index) => {
        const updatedCharges = charges.filter((_, i) => i !== index);
        localStorage.setItem('purchaseordersCharges', JSON.stringify(updatedCharges));
        setCharges(updatedCharges);
    };

     // Calculate total tax and amount
     const calculateTotals = () => {
        let tax = 0;
        let amount = 0;

        // Calculate totals for charges
        charges.forEach((charge) => {
            const chargeAmount = parseFloat(charge.amount) || 0;
            const chargeTax = (chargeAmount * (parseFloat(charge.tax) || 0)) / 100;

            tax += chargeTax;
            amount += chargeAmount;
        });

        // Calculate totals for products
        quotationProducts.forEach((product) => {
            const productPrice = parseFloat(product.price) || 0;
            const productQty = parseInt(product.qty, 10) || 0;
            const productTax = (productPrice * (parseFloat(product.tax) || 0)) / 100;

            const productTotal = productPrice * productQty;

            tax += productTax * productQty;
            amount += (productTotal);
        });
        
        amount+=tax;

        setTotalTax(tax.toFixed(2));
        setTotalAmount(amount.toFixed(2));
    };

    // Fetch charges and products from localStorage
    const fetchDataFromLocalStorage = () => {
        const storedCharges = JSON.parse(localStorage.getItem('purchaseordersCharges')) || [];
        const storedProducts = JSON.parse(localStorage.getItem('purchaseorderProducts')) || [];

        setCharges(storedCharges);
        setQuotationProducts(storedProducts);
    };

    const handleSubmit = async () => {
        try {
            const charges = JSON.parse(localStorage.getItem('purchaseordersCharges')) || [];
            const quotationProducts = JSON.parse(localStorage.getItem('purchaseorderProducts')) || [];
            const selectedCustomer = JSON.parse(localStorage.getItem('purchaseorderselectedCustomer')) || {};
            const selectedTerms = JSON.parse(localStorage.getItem('purchaseordersselectedTerms')) || [];
    
            const data = {
                charges,
                quotationProducts,
                selectedCustomer,
                selectedTerms,
            };
    
            const response = await api.post(baseUrl+'/create-purchaseorder/', data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 201) {
                localStorage.removeItem('purchaseordersCharges');
                localStorage.removeItem('purchaseorderProducts');
                localStorage.removeItem('purchaseorderselectedCustomer');
                localStorage.removeItem('purchaseordersselectedTerms');

                window.location.href = '/bizdocs/purchaseorders';
            }
        } catch (error) {
            console.error('Error creating purchase order:', error);
            alert('Failed to create the purchase order. Please try again.');
        }
    };

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0'); // Get day and pad with 0 if necessary
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = today.getFullYear();
        return `${day}/${month}/${year}`;
    };

    // Filter based on the search term
    const filteredResults = products.filter((product) => {
        const search = searchTerm.toLowerCase();
        return (
            (product.title && product.title.toLowerCase().includes(search)) ||
            (product.price && product.price.toLowerCase().includes(search))
        );
    });

    // Filter based on the search term
    const filteredCustomerResults = customers.filter((customer) => {
        const search = searchTermB.toLowerCase();
        return (
            (customer.name && customer.name.toLowerCase().includes(search)) ||
            (customer.mobile && customer.mobile.toString().toLowerCase().includes(search)) ||
            (customer.email && customer.email.toLowerCase().includes(search))
        );
    });
    
    const getVendorCurrency = () => {
        return localStorage.getItem("VendorCurrency");
    };


    return (
        <section className="container mtopcon dashb">
            <div className="row">
            <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">
                    
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Make Purchase Order</h6></div>
                            <div className="col-5"><Link to="/bizdocs/purchaseorders"><button className="btn btn-primary f85 float-right"><ArrowLeft/></button></Link></div>
                        </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-4">
                            <div className="card shadow-sm border border-secondary border-opacity-10">                               
                               
                            <div className="card-body">  
                                        {successMsg && <p className="text-success"><b>{successMsg}</b></p> } 
                                        {errorMsg && <p className="text-danger"><b>{errorMsg}</b></p> }                                            

                                        <div className="row mb-3">
                                            <div className="col-6">                                                
                                                <div htmlFor="fnm" className="form-label font9 mb-0 col-12 grey-text font9">Purchase Order Date</div>
                                                <div htmlFor="fnm" className="form-label font9 mb-0 col-12">{getCurrentDate()}</div>
                                            </div>
                                            <div className="col-6 text-right">                             
                                                <div htmlFor="fnm" className="form-label font9 mb-0 col-12 grey-text font9">Purchase Order No.</div>
                                                <div htmlFor="fnm" className="form-label font9 mb-0 col-12">-</div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <div className="input-group pointer" data-bs-toggle="modal" data-bs-target="#addcustomer">
                                                    <input type="text" value="To (Customer)" name="title"  className="form-control addprobginpt" readOnly id="fnm"/>
                                                    <button className="btn btn-dark" type="button"><Plus/></button>
                                                </div>
                                                {customer !== null &&
                                                    <div className="row invoice-ord-bg ord-bg bg-white p-2 hvr bx-shadow w-100 mt-3 mlrnew0">
                                                            <div className="col-11 col-lg-11 pl-2 pr-0">
                                                                <div className="row">
                                                                    <div className="col-11 col-lg-11 text-dark ord-sz">{customer.name}</div>
                                                                    <div className="col-6 col-lg-6 ord-sz grey-text">{customer.mobile}</div>
                                                                    <div className="col-5 col-lg-5 ord-sz text-right grey-text">{customer.email}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-1 col-lg-1 f8 text-black prodedtbtn text-right p-0 mt-1 lh34">
                                                                    <Trash onClick={handleRemoveFromLocalStorage} />
                                                            </div>
                                                    </div>
                                                }
                                                
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <div className="input-group" data-bs-toggle="modal" data-bs-target="#addaccount">
                                                    <input type="text" value="Products" name="title"  className="form-control addprobginpt" readOnly id="fnm"/>
                                                    <button className="btn btn-dark" type="button"><Plus/></button>
                                                </div>
                                                {quotationProducts.map((product) => (
                                                    <div className="row invoice-ord-bg ord-bg bg-white p-2 hvr bx-shadow w-100 mt-3 mlrnew0" key={product.productId}>
                                                        <div className="col-11 col-lg-11 pl-2 pr-0">
                                                            <div className="row">
                                                                <div className="col-12 col-lg-12 ord-sz text-dark">{product.title}</div>
                                                                <div className="col-6 col-lg-6 ord-sz grey-text">Amount:</div>
                                                                <div className="col-5 col-lg-5 ord-sz grey-text text-right">
                                                                    {product.qty} * {getVendorCurrency()} {product.price}
                                                                </div>
                                                                <div className="col-6 col-lg-6 ord-sz grey-text">Tax ({product.tax}%):</div>
                                                                <div className="col-5 col-lg-5 ord-sz grey-text text-right">
                                                                    {getVendorCurrency()} {(product.price * product.qty * product.tax) / 100}
                                                                </div>
                                                                <div className="col-6 col-lg-6 ord-sz grey-text">Total amount:</div>
                                                                <div className="col-5 col-lg-5 ord-sz text-right">
                                                                    <b>
                                                                        {getVendorCurrency()} {(product.price * product.qty) + ((product.price * product.qty * product.tax) / 100)}
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-1 col-lg-1 f8 text-black prodedtbtn text-right p-0 mt-1 lh34">
                                                            <Trash onClick={() => removeProductFromLocalStorage(product.productId)} />
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <div className="input-group" data-bs-toggle="modal" data-bs-target="#othercharge">
                                                    <input type="text" value="Other Charge" name="title"  className="form-control addprobginpt" readOnly id="fnm"/>
                                                    <button className="btn btn-dark" type="button"><Plus/></button>
                                                </div>
                                                {charges.map((charge, index) => (
                                                    <div
                                                        key={index}
                                                        className="row invoice-ord-bg ord-bg bg-white p-2 hvr bx-shadow w-100 mt-3 mlrnew0"
                                                    >
                                                        <div className="col-11 col-lg-11 pl-2 pr-0">
                                                            <div className="row">
                                                                <div className="col-9 col-lg-9 ord-sz text-dark">{charge.charge_label}</div>
                                                                <div className="col-6 col-lg-6 ord-sz grey-text">Amount:</div>
                                                                <div className="col-5 col-lg-5 ord-sz grey-text text-right">
                                                                    {getVendorCurrency()} {charge.amount.toFixed(2)}
                                                                </div>
                                                                <div className="col-6 col-lg-6 ord-sz grey-text">Tax ({charge.tax}%):</div>
                                                                <div className="col-5 col-lg-5 ord-sz grey-text text-right">
                                                                    {getVendorCurrency()} {((charge.amount * charge.tax) / 100).toFixed(2)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-1 col-lg-1 f8 text-black prodedtbtn text-right p-0 mt-1 lh34">
                                                            <Trash onClick={() => handleRemoveCharge(index)} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-12">
                                                <div className="input-group" data-bs-toggle="modal" data-bs-target="#addterm">
                                                    <input type="text" value="Terms & Conditions" name="title"  className="form-control addprobginpt" readOnly id="fnm"/>
                                                    <button className="btn btn-dark" type="button"><Plus/></button>
                                                </div>
                                                {selectedTerms.map((term) => (
                                                    <div
                                                        className="row invoice-ord-bg ord-bg bg-white p-2 hvr bx-shadow w-100 mt-3 mlrnew0"
                                                        key={term.id} // Add a unique key for each term
                                                    >
                                                        <div className="col-11 col-lg-11 pl-2 pr-0">
                                                            <div className="row">
                                                                <div className="col-10 col-lg-10 ord-sz text-dark">{term.title}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-1 col-lg-1 f8 text-black prodedtbtn text-right p-0 mt-1 lh34">
                                                            <Trash onClick={() => handleRemoveTerm(term.id)} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            <div className="col-6">                                                
                                                <div htmlFor="fnm" className="form-label font9 mb-0 col-12 grey-text">Total Tax</div>
                                                <div htmlFor="fnm" className="form-label font9 mb-0 col-12"><b>{getVendorCurrency()} {totalTax}</b></div>
                                            </div>
                                            <div className="col-6">                             
                                                <div htmlFor="fnm" className="form-label font9 mb-0 col-12 grey-text">Amount Due</div>
                                                <div htmlFor="fnm" className="form-label font9 mb-0 col-12"><b>{getVendorCurrency()} {totalAmount}</b></div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12"><button type="button" className="col-12 btn btn-dark mt-3" onClick={handleSubmit}>Generate</button></div>
                                        </div>

                                        

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="addcustomer" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mt70">
                    <div className="modal-content">
                        <div className="modal-body pb-4">                             
                            <div className="row mb-3">
                                <div className="col-8"><input
                                    className="filter2 border me-2 w-100"
                                    type="search"
                                    placeholder="Search Customer"
                                    aria-label="Search"
                                    onChange={(e) => setSearchTermB(e.target.value)} // Update the search term on keyup
                                /></div>
                                <div className="col-4"><Link to="/bizdocs/addcustomer"><button className="btn btn-primary f85 float-right h100 w100"><Plus/> <span>Add</span></button></Link></div>
                            </div>
                                {filteredCustomerResults.length === 0 ? (
                                    <p>No customers registered yet.</p>
                                ) : (
                                    <>
                                        {filteredCustomerResults.map((customer) => (                                                
                                            <div className="row invoice-ord-bg ord-bg bg-white p-2 hvr bx-shadow mlrnew" onClick={() => {localStorage.setItem('purchaseorderselectedCustomer', JSON.stringify(customer));window.location.reload();}}>
                                                <div className="col-2 col-lg-2 p-0"><span className="float-left"></span><div className="ord-img w30 float-left"><img src={userlogo} className='w-100 h-100'/></div></div>
                                                <div className="col-8 pl-0 pr-0">
                                                    <div className="row">
                                                        <div className="col-11 col-lg-11 text-dark ord-sz">{customer.name}</div>
                                                        <div className="col-6 col-lg-6 ord-sz grey-text">{customer.mobile}</div>
                                                        <div className="col-5 col-lg-5 ord-sz text-right grey-text">{customer.email}</div>
                                                    </div>
                                                </div>
                                                <div className="col-2 col-lg-2 f8 text-black prodedtbtn text-right p-0 mt-1 lh34">
                                                    <Link to={`/bizdocs/editcustomer/${customer.id}`}><button className="btn btn-default border-0"><Edit className="text-success" /></button></Link>  
                                                </div>
                                            </div>                                                                                              
                                        ))}
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="addaccount" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mt70">
                    <div className="modal-content">
                        <div className="modal-body pb-4">                             
                            <div className="row mb-3">
                                <div className="col-8"><input
                                    className="filter2 border me-2 w-100"
                                    type="search"
                                    placeholder="Search Products"
                                    aria-label="Search"
                                    onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on keyup
                                /></div>
                                <div className="col-4"><Link to="/bizdocs/addproduct"><button className="btn btn-primary f85 float-right h100 w100"><Plus/> <span>Add</span></button></Link></div>
                            </div>
                            <div className="accordion accordion-flush" id="invncprod">
                            {filteredResults.length === 0 ? (
                                <p>No products registered yet.</p>
                            ) : (
                                <>
                                        {filteredResults.map((product) => (
                                            <div className="accordion-item" key={product.id}>
                                                <h2 className="accordion-header" id={`flush-headingOne${product.id}`}>
                                                    <div className="accordion-button invcaccordion pl-0 pr-0 pb-0 pt-1 collapsed bg-white" 
                                                        type="button" data-bs-toggle="collapse" 
                                                        data-bs-target={`#flush-collapseOne${product.id}`} 
                                                        aria-expanded="false" 
                                                        aria-controls={`flush-collapseOne${product.id}`}>
                                                        <div className="row invoice-ord-bg ord-bg bg-white p-2 hvr bx-shadow w-100 mlrnew0">
                                                            <div className="col-10 pl-3 pr-0">
                                                                <div className="row">
                                                                    <div className="col-11 col-lg-11 ord-sz text-dark">{product.title}</div>
                                                                    <div className="col-5 col-lg-5 ord-sz">{getVendorCurrency()} {product.price}</div>
                                                                    <div className="col-6 col-lg-6 ord-sz grey-text"><span className="hddsktp">TAX: </span> {product.tax}%</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 col-lg-2 f8 text-black prodedtbtn text-right p-0 mt-1 lh34">
                                                                <ArrowDown/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </h2>
                                                <div id={`flush-collapseOne${product.id}`} className="accordion-collapse collapse" 
                                                    aria-labelledby={`flush-headingOne${product.id}`} data-bs-parent="#invncprod">
                                                    <div className="accordion-body bgaccr">
                                                        <div className="row mb-3">
                                                            <div className="col-6">
                                                                <label htmlFor="lnm" className="form-label font9 mb-0">Price</label>
                                                                <input 
                                                                    type="number" 
                                                                    name="price" 
                                                                    value={productDetails.price} 
                                                                    onChange={handleInputChange} 
                                                                    className="form-control addprobginpt" 
                                                                    id="lnm" />
                                                            </div>
                                                            <div className="col-6">
                                                                <label htmlFor="lnm" className="form-label font9 mb-0">Qty</label>
                                                                <input 
                                                                    type="number" 
                                                                    name="qty" 
                                                                    value={productDetails.qty} 
                                                                    onChange={handleInputChange} 
                                                                    className="form-control addprobginpt" 
                                                                    id="lnm" />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-12">
                                                                <label htmlFor="lnm" className="form-label font9 mb-0">Tax(%)</label>
                                                                <input 
                                                                    type="number" 
                                                                    name="tax" 
                                                                    value={productDetails.tax} 
                                                                    onChange={handleInputChange} 
                                                                    className="form-control addprobginpt" 
                                                                    id="lnm" />
                                                            </div>
                                                        </div>
                                                        <button 
                                                            type="button" 
                                                            className="col-12 btn btn-primary mt-3"
                                                            onClick={() => handleAddToQuotation(product.id)}> 
                                                            Add To Purchase Order
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </>
                            )}
                                
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="othercharge" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mt70">
                    <div className="modal-content">
                        <div className="modal-body pb-4">                            
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label htmlhtmlFor="charge_label" className="form-label font9 mb-0">
                                        Charge Label
                                    </label>
                                    <input
                                        type="text"
                                        name="charge_label"
                                        className="form-control addprobginpt"
                                        id="charge_label"
                                        value={chargeLabel}
                                        onChange={(e) => setChargeLabel(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label htmlhtmlFor="amount" className="form-label font9 mb-0">
                                        Amount
                                    </label>
                                    <input
                                        type="number"
                                        name="amount"
                                        className="form-control addprobginpt"
                                        id="amount"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label htmlhtmlFor="tax" className="form-label font9 mb-0">
                                        Tax(%)
                                    </label>
                                    <input
                                        type="number"
                                        name="tax"
                                        className="form-control addprobginpt"
                                        id="tax"
                                        value={tax}
                                        onChange={(e) => setTax(e.target.value)}
                                    />
                                </div>
                            </div>

                            <button
                                type="button"
                                className="col-12 btn btn-primary mt-3"
                                onClick={handleAddCharge}
                                disabled={isButtonDisabled} // Disable button if fields are empty
                            >
                                Add Charge
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="addterm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mt70">
                    <div className="modal-content">
                        <div className="modal-body pb-4">                             
                            <div className="row mb-3">
                                <h5>Terms & Conditions</h5>
                            </div>
                                    {terms.length === 0 ? (
                                        <p>No terms added yet.</p>
                                    ) : (
                                        <>
                                            {terms
                                                .filter((term) => term.type === 'Purchase Order')
                                                .map((term) => (
                                                    
                                                        <div key={term.id} className="row ord-bg bg-white p-2 hvr bx-shadow font9 mlrnew0"  onClick={() => handleRowClick(term)}>
                                                            <div className="col-10">
                                                                <p className="mb-0">
                                                                    {term.title}                                                                     
                                                                </p>
                                                            </div>
                                                            <div className="col-2"><Link
                                                                        to={`/bizdocs/editterms/${term.id}`}
                                                                        className="text-dark"
                                                                        key={term.id} // Add a unique key for each item
                                                                    ><Edit className="ms-2" width="18px" height="18px" /></Link></div>
                                                        </div>                                                    
                                                ))
                                            }
                                            {terms.every((term) => term.type !== 'Purchase Order') && (
                                                <p>No terms added yet.</p>
                                            )}
                                        </>
                                    )}
                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default BizdocsPurchaseOrder;