import axios from "axios";
import {Link} from 'react-router-dom';
import { Home,Package,CreditCard,Tag,ToggleRight,Activity,BarChart2,Settings,Power, Users, Lock, User, Share2, MessageCircle, Briefcase, Gift, ExternalLink, Truck, Plus, Tablet} from 'react-feather';
import React, { useEffect, useState } from 'react';
import api from "../api";
import { usePackage } from "../../PackageContext";
import { useLocation } from 'react-router-dom';
import { FaCashRegister, FaPrint } from "react-icons/fa";
function Sidebar(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { packageDetails } = usePackage();
    const location = useLocation();
    const [VendorData, setVendorData] = useState({
        'totalProducts': 0,
        'totalOrders': 0,
        'totalCustomers': 0,
        'name': '',
    });
    const isSellerPage = location.pathname.includes('/seller/' && !['/seller/login', '/seller/register']); 
    const fetchData = async () => {
        try {
          const response = await api.get(`${baseUrl}/vendor-dashboard/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          setVendorData(response.data);
          localStorage.setItem('VendorCurrency', response.data.currency);
        } catch (err) {
          //console.error("Error fetching products:", err.toJSON());
        }
    };
    const vendor_username = localStorage.getItem('vendor_username');
  

    // Update localStorage whenever VendorData changes
    useEffect(() => {
        localStorage.setItem('VendorData', JSON.stringify(VendorData));
        
    }, [VendorData]);
    useEffect(() => {  
        if(isSellerPage && localStorage.getItem("access_token") != null){
            fetchData();
        }     
    }, []);

    var status_pay = 1;
    if(packageDetails == null || packageDetails.status === "0"){
        status_pay = 0;
    }else if(packageDetails.package_name === "Personal"){
        status_pay = 0;
    }else{status_pay = 1;}

    return ( 
        <ul className="navbar-nav flex-column w-100">                                       
            <div className='mbladdbtn hddsktp'>
                <Link to="/seller/addproduct"><button className='btn btn-primary text-center p-0 m-0'><Plus /></button></Link>
            </div> 
            <li className="nav-divider sideproficon">                     
                <Link to={'https://kadhr.com/'+vendor_username} target="_blank" className="nav-link text-grey pb-0 pt-0" aria-current="true">{vendor_username} <span className="text-right ms-1"><ExternalLink/></span></Link>             
            </li>
            <li className="nav-item">
                <Link to="/seller/dashboard" className="nav-link" aria-current="true"><Home/> Dashboard</Link>
            </li>
            <li className="nav-item">
                <Link to="/seller/orders" className="nav-link" aria-current="true"><Gift/> Orders <span className="nav-ord-span">{VendorData.totalOrders}</span></Link>
            </li>            
            {status_pay === 0 ? (
                <>
                    <li className="nav-item">
                        <Link to="/seller/subscription" className="nav-link" aria-current="true"><FaCashRegister/> POS <span className="upgrade">upgrade</span></Link>
                    </li>
                </>
            ) : (
                <>
                    <li className="nav-item">
                        <Link to="/seller/pos" className="nav-link" aria-current="true"><FaCashRegister/> POS</Link>
                    </li>
                </>
            )}
            <li className="nav-item">
                <Link to="/bizdocs/dashboard" className="nav-link" aria-current="true"><FaPrint/> Bizdocs</Link>
            </li>            
            <li className="nav-item">
                <Link to="/seller/products" className="nav-link" aria-current="true"><Package/> Products</Link>
            </li> 
            {status_pay === 0 ? (
                <>
                    <li className="nav-item">
                        <Link to="/seller/subscription" className="nav-link" aria-current="true"><Tag/> Customers <span className="upgrade">upgrade</span></Link>
                    </li>
                </>
            ) : (
                <>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <li className="nav-item accordion-button collapsed text-blue" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne112" aria-expanded="false" aria-controls="flush-collapseOne112">
                                <Link to="#" className="nav-link" aria-current="true"><Tag/> Customers</Link>
                            </li>
                            <div id="flush-collapseOne112" className="accordion-collapse collapse pb-2 pt-2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <li className="nav-item">
                                        <Link to="/seller/customers" className="nav-link" aria-current="true"><Users/> Customer Loyalty</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/seller/reviews" className="nav-link" aria-current="true"><MessageCircle/> Reviews</Link>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div> 
                </>
            )}  
            {status_pay === 0 ? (
                <>
                    <li className="nav-item">
                        <Link to="/seller/subscription" className="nav-link" aria-current="true"><Activity/> Analytics <span className="upgrade">upgrade</span></Link>
                    </li>
                </>
            ) : (
                <>
                    <li className="nav-item">
                        <Link to="/seller/reports" className="nav-link" aria-current="true"><Activity/> Analytics</Link>
                    </li> 
                </>
            )}
            <li className="nav-item">
                <Link to="/seller/finance" className="nav-link" aria-current="true"><CreditCard/> Finance</Link>
            </li>
            <li className="nav-item">
                <Link to="/seller/subscription" className="nav-link" aria-current="true"><ToggleRight/> Subscription</Link>
            </li>
            <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                    <li className="nav-item accordion-button collapsed text-blue" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        <Link to="/seller/reports" className="nav-link" aria-current="true"><Settings/> Settings</Link>
                    </li>
                    <div id="flush-collapseOne" className="accordion-collapse collapse pb-2 pt-2" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <li className="nav-item">
                                <Link to="/seller/profile" className="nav-link" aria-current="true"><User/> Profile</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/seller/delivery" className="nav-link" aria-current="true"><Truck/> Setup Delivery</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/seller/reset-link" className="nav-link" aria-current="true"><Lock/> Change Password</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/seller/share" className="nav-link" aria-current="true"><Share2/> Share Shop</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/seller/logout" className="nav-link" aria-current="true"><Power/> Logout</Link>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
            <li className="nav-item">
                <Link to="/seller/aiprompts" className="nav-link" aria-current="true"><BarChart2/> AI for Business</Link>
            </li>
                        
        </ul>
    )
}

export default Sidebar;