import axios from "axios";
import { useState, useEffect } from "react";
import { Plus, Edit } from 'react-feather';
import Sidebar from './sidebar';
import { Link } from "react-router-dom";
import api from "../api";

function BizdocsTerms() {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [terms, setterms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [ProductData, setProductData] = useState({
        "vendor": '',
        "title": '',
        "type": '',
    });
    const token = localStorage.getItem("access_token");

    useEffect(() => {
        const fetchterms = async () => {
            try {
                const response = await api.get(`${baseUrl}/bizproterms/list/`, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                setterms(response.data.results);
            } catch (error) {
                //setError('Failed to fetch term data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchterms();
    }, []);

    const inputHandler = (event) => {
        setProductData({
            ...ProductData,
            [event.target.name]: event.target.value
        });
    };

    const submitHandler = async (event) => {
        event.preventDefault(); // Prevent form from reloading page
        const formData = new FormData();
        formData.append('title', ProductData.title);
        formData.append('type', ProductData.type);
    
        try {
            const response = await api.post(`${baseUrl}/bizproterms/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Correct content type for FormData
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.status === 201) {
                setErrorMsg('');
                setProductData({
                    vendor: '',
                    title: '',
                    type: ''
                });
                window.location.reload();
            } else {
                setErrorMsg('There was a problem adding terms & conditions. Kindly try again.');
                setSuccessMsg('');
            }
        } catch (error) {
            console.error(error);
            setErrorMsg('An error occurred while adding terms & conditions. Please try again.');
            setSuccessMsg('');
        }
    };
    
    if (loading) return <p>Loading terms...</p>;
    if (error) return <p>{error}</p>;

    const buttonEnable = ProductData.title && ProductData.type

    return (
        <section className="container mtopcon dashb">
            <div className="row">
            <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">
                    
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Terms and Conditions</h6></div>
                            <div className="col-5"><button className="btn btn-primary f85 float-right" data-bs-toggle="modal" data-bs-target="#addterm"><Plus/> Terms</button></div>
                        </div>

                        <ul className="nav shoptab nav-pills font9" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link text-dark active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#quotation" type="button" role="tab" aria-controls="quotation" aria-selected="true">Quotation</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link text-dark" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#invoice" type="button" role="tab" aria-controls="invoice" aria-selected="false">Invoice</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link text-dark" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#po" type="button" role="tab" aria-controls="po" aria-selected="false">PO</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active pt-3" id="quotation" role="tabpanel" aria-labelledby="quotation" tabIndex="0">
                                
                                <div className="mb-4">

                                            

                                    {terms.length === 0 ? (
                                        <p>No terms added yet.</p>
                                    ) : (
                                        <>
                                            {terms
                                                .filter((term) => term.type === 'Quotation')
                                                .map((term) => (
                                                    <Link
                                                        to={`/bizdocs/editterms/${term.id}`}
                                                        className="text-dark"
                                                        key={term.id} // Add a unique key for each item
                                                    >
                                                        <div className="row ord-bg bg-white p-2 hvr bx-shadow font9">
                                                            <div className="col-12">
                                                                <p className="mb-0">
                                                                    {term.title} 
                                                                    <Edit className="ms-2" width="18px" height="18px" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))
                                            }
                                            {terms.every((term) => term.type !== 'Quotation') && (
                                                <p>No terms added yet.</p>
                                            )}
                                        </>
                                    )}
                                                                                 

                                </div>

                            </div>
                            <div className="tab-pane fade pt-3" id="invoice" role="tabpanel" aria-labelledby="invoice" tabIndex="0">
                                    {terms.length === 0 ? (
                                        <p>No terms added yet.</p>
                                    ) : (
                                        <>
                                            {terms
                                                .filter((term) => term.type === 'Invoice')
                                                .map((term) => (
                                                    <Link
                                                        to={`/bizdocs/editterms/${term.id}`}
                                                        className="text-dark"
                                                        key={term.id} // Add a unique key for each item
                                                    >
                                                        <div className="row ord-bg bg-white p-2 hvr bx-shadow font9">
                                                            <div className="col-12">
                                                                <p className="mb-0">
                                                                    {term.title} 
                                                                    <Edit className="ms-2" width="18px" height="18px" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))
                                            }
                                            {terms.every((term) => term.type !== 'Invoice') && (
                                                <p>No terms added yet.</p>
                                            )}
                                        </>
                                    )}
                            </div>
                            <div className="tab-pane fade pt-3" id="po" role="tabpanel" aria-labelledby="po" tabIndex="0">
                                    {terms.length === 0 ? (
                                        <p>No terms added yet.</p>
                                    ) : (
                                        <>
                                            {terms
                                                .filter((term) => term.type === 'Purchase Order')
                                                .map((term) => (
                                                    <Link
                                                        to={`/bizdocs/editterms/${term.id}`}
                                                        className="text-dark"
                                                        key={term.id} // Add a unique key for each item
                                                    >
                                                        <div className="row ord-bg bg-white p-2 hvr bx-shadow font9">
                                                            <div className="col-12">
                                                                <p className="mb-0">
                                                                    {term.title} 
                                                                    <Edit className="ms-2" width="18px" height="18px" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))
                                            }
                                            {terms.every((term) => term.type !== 'Purchase Order') && (
                                                <p>No terms added yet.</p>
                                            )}
                                        </>
                                    )}
                            </div>
                        </div>
                </div>
            </div>

            <div className="modal fade" id="addterm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mt70">
                    <div className="modal-content">
                        <div className="modal-body pb-4"> 
                            <h6>Add Terms & Conditions</h6>    
                            {successMsg && <p className="text-success"><b>{successMsg}</b></p> } 
                            {errorMsg && <p className="text-danger"><b>{errorMsg}</b></p> }                        

                            <div className="row mb-3">
                                <div className="col-12">
                                    <label htmlFor="lnm" className="form-label font9 mb-0">Type</label>
                                    <select className="filter2 border w-100 addprobginpt" name='type' onChange={inputHandler} value={ProductData.type}>
                                        <option>Select</option>
                                        <option value="Quotation">Quotation</option>
                                        <option value="Invoice">Invoice</option>
                                        <option value="Purchase Order">Purchase Order</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label htmlFor="lnm" className="form-label font9 mb-0">Description</label>
                                    <textarea type="text" onChange={inputHandler} value={ProductData.title} name="title" className="form-control addprobginpt" id="lnm"/>
                                </div>
                            </div>

                            <button type="button" disabled={!buttonEnable} onClick={submitHandler} className="col-12 btn btn-primary mt-3">Add</button> 
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default BizdocsTerms;