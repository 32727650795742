import { useState, useEffect } from "react";
import { ArrowLeft} from 'react-feather';
import Sidebar from './sidebar';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import api from "../api";

function BizdocsEditTerms() {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const {uniqid} = useParams();
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [ProductData, setProductData] = useState({
        "vendor": '',
        "name": '',
        "company": '',
        "email": '',
        "mobile": '',
        "addressone": '',
        "addresstwo": '',
        "other_info": '',
        "shipping_address": '',
        "register_date": ''
    });
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("access_token");
    
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {            
        try {
            const response = await api.get(`${baseUrl}/bizproterms/`+uniqid, {
                headers: {
                Authorization: `Bearer ${token}`,
                },
            });
            setProductData({
                "title": response.data.title,
                "type": response.data.type
            });
        } catch (error) {
            //setError('Failed to fetch term data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const inputHandler = (event) => {
        setProductData({
            ...ProductData,
            [event.target.name]: event.target.value
        });
    };

    const submitHandler = async (event) => {
        event.preventDefault(); // Prevent form from reloading page
        const formData = new FormData();
        formData.append('title', ProductData.title);
        formData.append('type', ProductData.type);
    
        try {
            const response = await api.patch(`${baseUrl}/bizproterms/${uniqid}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Correct content type for FormData
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.status === 200) { // PATCH successful status is usually 200 OK
                setSuccessMsg('Updated Successfully');
                setErrorMsg('');
            } else {
                setErrorMsg('There was a problem updating terms & conditions. Kindly try again.');
                setSuccessMsg('');
            }
        } catch (error) {
            console.error(error);
            setErrorMsg('An error occurred while updating terms & conditions. Please try again.');
            setSuccessMsg('');
        }
    };
    
    
    const buttonEnable = ProductData.title && ProductData.type

    return (
        <section className="container mtopcon dashb">
            <div className="row">
            <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">
                    
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Edit Terms</h6></div>
                            <div className="col-5"><Link to="/bizdocs/terms"><button className="btn btn-primary f85 float-right"><ArrowLeft/></button></Link></div>
                        </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-4">
                            <div className="card shadow-sm border border-secondary border-opacity-10"> 
                                <div className="card-body">                                           

                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="lnm" className="form-label font9 mb-0">Type</label>
                                                <select className="filter2 border w-100 addprobginpt" name='type' onChange={inputHandler} value={ProductData.type}>
                                                    <option value={ProductData.type}>{ProductData.type}</option>
                                                    <option value="Quotation">Quotation</option>
                                                    <option value="Invoice">Invoice</option>
                                                    <option value="Purchase Order">Purchase Order</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="lnm" className="form-label font9 mb-0">Description</label>
                                                <textarea type="text" onChange={inputHandler} value={ProductData.title} name="title" className="form-control addprobginpt" id="lnm"/>
                                            </div>
                                        </div>

                                        <button type="button" disabled={!buttonEnable} onClick={submitHandler} className="col-12 btn btn-primary mt-3">Update</button>

                                        {successMsg && <p className="text-success mt-4 text-center"><b>{successMsg}</b></p> } 
                                        {errorMsg && <p className="text-danger mt-4 text-center"><b>{errorMsg}</b></p> }  

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BizdocsEditTerms;