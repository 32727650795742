import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChart = ({ chart_title, chart_data,chart_categories  }) => {
  const series = [
    {
      name: '',
      data: chart_data,
    },
  ];

  const chartOptions = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    title: {
      text: [chart_title],
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // alternating row colors
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: chart_categories,
    },
  };

  return (
    <div id="chart">
      <ReactApexChart options={chartOptions} series={series} type="line" height={350} />
    </div>
  );
};

export default LineChart;
