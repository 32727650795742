import {Routes,Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js'; 

import ProtectedRoute from './ProtectedRoute';
import SellHome from './components/sell';
import E404 from './components/error404';
import Home from './components/home';
import Header from './components/header';
import Footer from './components/footer';
import Categories from './components/categories';
import ProductDetail from './components/productdetail';
import CategoryProducts from './components/CategoryProducts';
import TagProducts from './components/tagproducts';
import AllProducts from './components/allproducts';
import Checkout from './components/checkout';
import OrderSuccess from './components/ordersuccess';
import OrderFailed from './components/orderfailed';
import AllSellers from './components/allsellers';
import Terms from './components/terms';
import Privacy from './components/privacy';
import Help from './components/help';

import CustomerRegister from './components/customer/register';
import CustomerLogin from './components/customer/login';
import CustomerLogout from './components/customer/logout';
import CustomerDashboard from './components/customer/dashboard';
import CustomerOrders from './components/customer/orders';
import CustomerWishlist from './components/customer/wishlist';
import CustomerProfile from './components/customer/profile';
import CustomerAddressList from './components/customer/addresslist';
import CustomerAddAddress from './components/customer/addaddress';
import CustomerUpdateAddress from './components/customer/updateaddress';
import AddReview from './components/customer/add-review';
import CustomerResetLink from './components/customer/reset-link';

import SellerRegister from './components/seller/register';
import SellerLogin from './components/seller/login';
import SellerLogout from './components/seller/logout';
import SellerDashboard from './components/seller/dashboard';
import SellerProducts from './components/seller/products';
import AddProduct from './components/seller/addproduct';
import UpdateProduct from './components/seller/update-product';
import SellerOrders from './components/seller/orders';
import SellerCustomers from './components/seller/customers';
import SellerReports from './components/seller/reports';
import SellerDailyReports from './components/seller/daily-reports';
import SellerMonthlyReports from './components/seller/monthly-reports';
import SellerYearlyReports from './components/seller/yearly-reports';
import SellerProfile from './components/seller/profile';
import SellerCustomerOrders from './components/seller/customerorders';
import SellerWithdraw from './components/seller/finance';
import SellerSubscription from './components/seller/subscription';
import SellerOrderDetails from './components/seller/order';
import SellerReviews from './components/seller/reviews';
import Aiprompts from './components/seller/aiprompts';
import VendorResetLink from './components/seller/reset-link';
import SellerDashboardBizdocs from './components/bizdocs/dashboard';

import BizProfile from './components/bizdocs/profile';
import BizdocsAddProduct from './components/bizdocs/addproduct';
import BizdocsSellerCustomers from './components/bizdocs/customers';
import BizdocsSellerProducts from './components/bizdocs/products';
import BizDocsPdf from './components/bizdocs/pdf';
import BizdocsAddCustomer from './components/bizdocs/addcustomer';
import BizdocsEditCustomer from './components/bizdocs/editcustomer';
import BizdocsEditProduct from './components/bizdocs/editproduct';
import BizdocsTerms from './components/bizdocs/terms';
import BizdocsEditTerms from './components/bizdocs/editterms';

import BizdocsQuotation from './components/bizdocs/quotation';
import BizdocsQuotationList from './components/bizdocs/quotations';
import QuotationBizDocsPdf from './components/bizdocs/getquotation';
import BizdocsEditQuotation from './components/bizdocs/editquotation';

import BizdocsInvoice from './components/bizdocs/invoice';
import BizdocsInvoiceList from './components/bizdocs/invoices';
import InvoiceBizDocsPdf from './components/bizdocs/getinvoice';
import BizdocsEditInvoice from './components/bizdocs/editinvoice';

import BizdocsPurchaseOrder from './components/bizdocs/purchaseorder';
import BizdocsPurchaseOrderList from './components/bizdocs/purchaseorders';
import BizdocsEditPurchaseOrder from './components/bizdocs/editpurchaseorder';
import PurchaseOrderBizDocsPdf from './components/bizdocs/getpurchaseorder';

import BizdocsProformaInvoice from './components/bizdocs/proforma';
import BizdocsProformaInvoiceList from './components/bizdocs/proformas';
import ProformaInvoiceBizDocsPdf from './components/bizdocs/getproforma';
import BizdocsEditProformaInvoice from './components/bizdocs/editproforma';

import BizdocsDeliveryNote from './components/bizdocs/deliverynote';
import BizdocsDeliveryNotesList from './components/bizdocs/deliverynotes';

import { CartContext, VendorDataContext} from './context';
import { PackageProvider } from "./PackageContext";
import { ExchangeRateProvider } from './ExchangeRateContext';
import {useState} from 'react';
import DeliveryNoteBizDocsPdf from './components/bizdocs/getdeliverynote';
import BizdocsEditDeliveryNote from './components/bizdocs/editdeliverynote';
import VendorMoneyMap from './components/seller/money-map';
import VendorDelivery from './components/seller/delivery';
import ShopPage from './components/shoppage';
import SellerShare from './components/seller/share';
import VendorResetPassword from './components/reset-password';
import CustomerResetPassword from './components/reset-password_c';
import StoreSetupGuide from './components/seller/StoreSetupGuide';
import ShopSuspended from './components/ShopSuspended';
import CategoryProductsShop from './components/CategoryProducts0';
import SellerPos from './components/seller/pos';
const checkCart=localStorage.getItem('cartData');
const checkVendorData=localStorage.getItem('VendorData');

function App() {
  const [cartData,setCartData]=useState(JSON.parse(checkCart));
  const [vendorData,setVendorData]=useState(JSON.parse(checkVendorData));
  
  
  return (
    <ExchangeRateProvider>    
    <CartContext.Provider value={{cartData,setCartData}}>
      <VendorDataContext.Provider value={{vendorData,setVendorData}}>

      <PackageProvider>
          <Header />
          <Routes>
            {/* Seller Routes */}
                  <Route
                    path="/admin"
                    element={() => {
                      window.location.href = "/admin/";
                      return null;
                    }}
                  />
                  <Route path='/' element={<SellHome/>} />
                  <Route path="/:shopname" element={<ShopPage />} />
                  <Route path="/:shopname/:categoryname/:categoryid" element={<CategoryProductsShop />} />
                  <Route path="/loader" element={<ShopSuspended />} />

                  <Route path='/error404' element={<E404/>} />
                  <Route path='/terms' element={<Terms/>} />
                  <Route path='/privacy' element={<Privacy/>} />
                  <Route path='/help-center' element={<Help/>} />
                  <Route path='/categories' element={<Categories/>} />
                  <Route path='/products' element={<AllProducts/>} />
                  <Route path='/category/:category_slug/:category_id' element={<CategoryProducts/>} />
                  <Route path='/products/:tag' element={<TagProducts/>} />
                  <Route path='/product/:product_slug/:product_id' element={<ProductDetail/>} />
                  <Route path='/checkout' element={<Checkout/>} />
                  <Route path='/order/success/:order_id' element={<OrderSuccess/>} />
                  <Route path='/order/failed' element={<OrderFailed/>} />
                  <Route path='/sellers' element={<AllSellers/>} />

                  <Route path='/customer/register' element={<CustomerRegister/>} />
                  <Route path='/customer/reset-link' element={<CustomerResetLink/>} />
                  <Route path='/customer/logout' element={<CustomerLogout/>} />
                  <Route path='/customer/dashboard' element={<CustomerDashboard/>} />
                  <Route path='/customer/orders' element={<CustomerOrders/>} />
                  <Route path='/customer/wishlist' element={<CustomerWishlist/>} />
                  <Route path='/customer/addresses' element={<CustomerAddressList/>} />
                  <Route path='/customer/addaddress' element={<CustomerAddAddress/>} />
                  <Route path='/customer/update-address/:address_id' element={<CustomerUpdateAddress/>} />
                  <Route path='/customer/add-review/:product_id' element={<AddReview/>} />
                  <Route path="/seller/reset-password/:token" element={<VendorResetPassword />} />
                  <Route path="/customer/reset-password/:token" element={<CustomerResetPassword />} />
                  <Route path='/customer/login' element={<CustomerLogin/>} />

                  <Route path='/seller/login' element={<SellerLogin/>} />
                  <Route path='/seller/register' element={<SellerRegister/>} />
                  <Route path='/seller/reset-link' element={<VendorResetLink/>} />

                  <Route path='/seller/help-center' element={<Help/>} />
                  <Route path='/seller/logout' element={<SellerLogout/>} />
                  <Route path='/seller/dashboard' element={<SellerDashboard/>} />
                  <Route path='/seller/products' element={<SellerProducts/>} />
                  <Route path='/seller/addproduct' element={<AddProduct/>} />
                  <Route path='/seller/update-product/:product_id' element={<UpdateProduct/>} /> 
                  <Route path='/seller/orders' element={<SellerOrders/>} />
                  
                  <Route path='/seller/pos' element={<ProtectedRoute><SellerPos/></ProtectedRoute>} />
                  <Route path='/seller/customers' element={<ProtectedRoute><SellerCustomers/></ProtectedRoute>} />
                  <Route path='/seller/customer/:customer_id/orderitems' element={<ProtectedRoute><SellerCustomerOrders/></ProtectedRoute>} />
                  <Route path='/seller/reports' element={<ProtectedRoute><SellerReports/></ProtectedRoute>} />
                  <Route path='/seller/daily-report' element={<SellerDailyReports/>} />
                  <Route path='/seller/monthly-report' element={<SellerMonthlyReports/>} />
                  <Route path='/seller/yearly-report' element={<SellerYearlyReports/>} />
                  <Route path='/seller/finance' element={<SellerWithdraw/>} />
                  <Route path='/seller/subscription' element={<SellerSubscription/>} />
                  <Route path='/seller/order/:order_id' element={<SellerOrderDetails/>} />
                  <Route path='/seller/reviews' element={<ProtectedRoute><SellerReviews/></ProtectedRoute>} />
                  <Route path='/seller/aiprompts' element={<Aiprompts/>} />
                  <Route path='/seller/money-map' element={<VendorMoneyMap/>} />
                  <Route path='/seller/delivery' element={<VendorDelivery/>} />
                  <Route path='/seller/setup' element={<StoreSetupGuide/>} />
                                  
                  <Route path='/seller/share' element={<SellerShare/>} />
                  <Route path='/seller/profile' element={<SellerProfile/>} />
                  <Route path='/customer/profile' element={<CustomerProfile/>} />

                  <Route path='/bizdocs/dashboard' element={<SellerDashboardBizdocs/>} />
                  <Route path='/bizdocs/profile' element={<BizProfile/>} />
                  <Route path='/bizdocs/addproduct' element={<BizdocsAddProduct/>} />
                  <Route path='/bizdocs/customers' element={<BizdocsSellerCustomers/>} />
                  <Route path='/bizdocs/products' element={<BizdocsSellerProducts/>} />
                  <Route path='/bizdocs/pdf' element={<BizDocsPdf/>} />
                  <Route path='/bizdocs/addcustomer' element={<BizdocsAddCustomer/>} />
                  <Route path='/bizdocs/editcustomer/:uniqid' element={<BizdocsEditCustomer/>} />
                  <Route path='/bizdocs/editproduct/:uniqid' element={<BizdocsEditProduct/>} />

                  <Route path='/bizdocs/terms' element={<BizdocsTerms/>} />
                  <Route path='/bizdocs/editterms/:uniqid' element={<BizdocsEditTerms/>} />

                  <Route path='/bizdocs/quotation' element={<BizdocsQuotation/>} />
                  <Route path='/bizdocs/quotations' element={<BizdocsQuotationList/>} />
                  <Route path='/bizdocs/getquotation/:uniqid' element={<QuotationBizDocsPdf/>} />
                  <Route path='/bizdocs/editquotation/:uniqid' element={<BizdocsEditQuotation/>} />

                  <Route path='/bizdocs/invoice' element={<BizdocsInvoice/>} />
                  <Route path='/bizdocs/invoices' element={<BizdocsInvoiceList/>} />
                  <Route path='/bizdocs/getinvoice/:uniqid' element={<InvoiceBizDocsPdf/>} />
                  <Route path='/bizdocs/editinvoice/:uniqid' element={<BizdocsEditInvoice/>} />
              
                  <Route path='/bizdocs/purchaseorder' element={<BizdocsPurchaseOrder/>} />
                  <Route path='/bizdocs/purchaseorders' element={<BizdocsPurchaseOrderList/>} />
                  <Route path='/bizdocs/getpurchaseorder/:uniqid' element={<PurchaseOrderBizDocsPdf/>} />
                  <Route path='/bizdocs/editpurchaseorder/:uniqid' element={<BizdocsEditPurchaseOrder/>} />

                  <Route path='/bizdocs/proforma' element={<BizdocsProformaInvoice/>} />
                  <Route path='/bizdocs/proformas' element={<BizdocsProformaInvoiceList/>} />
                  <Route path='/bizdocs/getproforma/:uniqid' element={<ProformaInvoiceBizDocsPdf/>} />
                  <Route path='/bizdocs/editproforma/:uniqid' element={<BizdocsEditProformaInvoice/>} />

                  <Route path='/bizdocs/delivery-note' element={<BizdocsDeliveryNote/>} />
                  <Route path='/bizdocs/delivery-notes' element={<BizdocsDeliveryNotesList/>} />
                  <Route path='/bizdocs/getdeliverynote/:uniqid' element={<DeliveryNoteBizDocsPdf/>} />
                  <Route path='/bizdocs/editdeliverynote/:uniqid' element={<BizdocsEditDeliveryNote/>} />
          </Routes>
          <Footer />
        </PackageProvider>

          
      </VendorDataContext.Provider>
    </CartContext.Provider>
    </ExchangeRateProvider>
  );
}

export default App;
