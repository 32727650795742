import Sidebar from './sidebar';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect} from 'react';
import { ArrowLeft, Star } from 'react-feather';
import { useParams } from "react-router-dom";
function AddReview(){    
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const {product_id} = useParams();
    var customer_id=localStorage.getItem('customer_id');
    const [ErrorMsg,setErrorMsg]=useState('');
    const [SuccessMsg,setSuccessMsg]=useState('');
    const [ReviewFormData,setReviewFormData]=useState({  
        "reviews":'', 
        "rating":''
    });
    const [rating, setRating] = useState(0);

    const inputHandler =(event) => {
        setReviewFormData({
            ...ReviewFormData,
            [event.target.name]:event.target.value
        });
    }; 

    const submitHandler =() => {
        if(rating==0){
            setErrorMsg('Kindly select rating');
            return false;
        }
        const formData=new FormData();
        formData.append('reviews',ReviewFormData.reviews);
        formData.append('rating',rating);
        formData.append('customer',customer_id);
        formData.append('product',product_id);

        //SUBMIT DATA
        axios.post(baseUrl+'/productrating/',formData)
        .then(function (response){
            if(response.status != 201){
                setSuccessMsg('');
                setErrorMsg('Data not saved');
            }else{
                setErrorMsg('');
                setSuccessMsg('Review & Rating saved. Thank you.');
                setReviewFormData({
                    'reviews':'',
                    'rating':''
                })
            }
            //console.log(response);
        })
        .catch(function(error){
            //console.log(error);
        });
    };

    const buttonEnable=(ReviewFormData.reviews!='');

    return (
        <section className="container mtopcon dashb">
            <div className="row">            
                <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div> 
                <div className="col-12 col-lg-10 dash-max">
                    <div className="row mb-3 ph-header-con">
                        <div className="col-8 pt-2"><h6 className="pageheader-title mb-3">Add Reveiw</h6></div>
                        <div className="col-4"><Link to="/customer/dashboard"><button className="btn btn-primary f85 float-right h40 lh40"><ArrowLeft width='20px'/></button></Link></div>
                    </div>
                    
                    <div className="card shadow-sm border border-secondary border-opacity-10">
                        <div className="card-body">      
                            <div className="mb-3">
                                <label htmlFor="InputEmail" className="form-label">Review</label>
                                <textarea className="form-control" name='reviews' onChange={inputHandler} value={ReviewFormData.reviews}></textarea>
                            </div>
                            
                            
                            <div className="form-group mb-3" id="rating-ability-wrapper">
                                <div className="mb-3">
                                    <label htmlFor="InputEmail" className="form-label">Rating</label>
                                    <select
                                        className="form-control p-2 border rounded col-12"
                                        name="rating"
                                        value={rating}
                                        onChange={(e) => setRating(Number(e.target.value))}
                                    >
                                        <option value="0">Select Rating</option>
                                        {[1, 2, 3, 4, 5].map((num) => (
                                        <option key={num} value={num}>{num}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='row'>
                                    <div className='col-auto'>
                                        {rating > 0 && (
                                            <div className="flex space-x-1">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                                <button
                                                key={star}
                                                type="button"
                                                className="p-1 btnrating btn btn-lg"
                                                data-attr={star}
                                                id={`rating-star-${star}`}
                                                >
                                                <Star
                                                    className={`w-6 h-6 transition-colors ${
                                                    star <= rating ? "orange-font" : "text-gray-400 fill-gray-400"
                                                    }`}
                                                />
                                                </button>
                                            ))}
                                            </div>
                                        )}
                                    </div>
                                    {rating > 0 && <>
                                        <div className='col-auto'>
                                            <h5 className="bold rating-header pt-2">
                                                <span className="selected-rating">{rating}</span><small> / 5</small>
                                            </h5>
                                        </div>
                                    </>}
                                </div>
                            </div>

                            <button disabled={!buttonEnable} type="button" onClick={submitHandler} className="col-12 btn btn-primary">Submit</button>

                            {ErrorMsg && <p className='text-danger mt-4 text-center'>{ErrorMsg}</p>}
                            {SuccessMsg && <p className='text-success mt-4 text-center'>{SuccessMsg}</p>}  
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddReview;