import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "./api";

const useStoreStatusCheck = () => {
    const { shopname } = useParams(); // Get store name from URL
    const navigate = useNavigate();
    const [storeStatus, setStoreStatus] = useState({ package_status: "inactive" });

    useEffect(() => {
        const fetchStoreStatus = async () => {
            try {
                const response = await api.get(`/${shopname}/status/`);
                setStoreStatus(response.data);

                // Redirect to shop suspended page if package is inactive
                if (response.data.package_status === "inactive" || response.data.package_status === null) {
                    navigate("/loader");
                }
            } catch (error) {
                //console.error("Error fetching store status:", error);
            }
        };

        fetchStoreStatus();
    }, [shopname, navigate]);

    return storeStatus;
};

export default useStoreStatusCheck;
