import { Star } from 'react-feather';
import userlogo from '../user.jpg';

function Testimonial(props) {
    const index = props.index;
    const item = props.item;

    // Create an array for filled and unfilled stars
    const filledStars = Array(item.rating).fill(1);
    const emptyStars = Array(5 - item.rating).fill(0);

    return (
        <div className='row'>
            <div className='col-12'>
                <div className="card mb-3 shadow-sm p-2 border" style={{ borderRadius: '10px' }}>
                    <div className="card-body p-0">
                        <div className="d-flex align-items-center mb-2">
                            <div className="me-2">
                                <img 
                                    src={userlogo} 
                                    alt="User" 
                                    className="rounded-circle" 
                                    style={{ width: '40px', height: '40px' }} 
                                />
                            </div>
                            <div>
                                <h6 className="mb-2">{item.customer.user.username}</h6>
                                <div className="d-flex">
                                    {/* Render filled stars */}
                                    {filledStars.map((_, index) => (
                                        <Star key={index} size={16} color="#ffc107" fill="#ffc107" />
                                    ))}
                                    {/* Render unfilled stars */}
                                    {emptyStars.map((_, index) => (
                                        <Star key={`empty-${index}`} size={16} color="#ccc" />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <p className="mb-0 text-muted">
                            "{item.reviews}"
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;
