import { useState, useEffect } from "react";
import { ArrowLeft} from 'react-feather';
import Sidebar from './sidebar';
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import api from "../api";

function BizdocsAddCustomer() {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [ProductData, setProductData] = useState({
        "uniqid": '',
        "name": '',
        "company": '',
        "email": '',
        "mobile": '',
        "addressone": '',
        "addresstwo": '',
        "other_info": '',
        "shipping_address": '',
        "register_date": ''
    });
    const token = localStorage.getItem("access_token");
    const getVendorCurrency = () => {
        return localStorage.getItem("VendorCurrency");
    };

    const inputHandler = (event) => {
        setProductData({
            ...ProductData,
            [event.target.name]: event.target.value
        });
    };

    const submitHandler = async (event) => {
        event.preventDefault(); // Prevent form from reloading page
        const uniqueRandId = uuidv4();
        const formData = new FormData();
        formData.append('uniqid', uniqueRandId);
        formData.append('name', ProductData.name);
        formData.append('company', ProductData.company);
        formData.append('email', ProductData.email);
        formData.append('mobile', ProductData.mobile);
        formData.append('addressone', ProductData.addressone);
        formData.append('addresstwo', ProductData.addresstwo);
        formData.append('other_info', ProductData.other_info);
        formData.append('shipping_address', ProductData.shipping_address);
    
        try {
            const response = await api.post(`${baseUrl}/bizprocustomer/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Correct content type for FormData
                    Authorization: `Bearer ${token}`
                },
            });
    
            if (response.status == 201) {
                setSuccessMsg('Customer Added Successfully');
                setErrorMsg('');
                setProductData({
                    uniqid: '',
                    name: '',
                    company: '',
                    email: '',
                    mobile: '',
                    addressone: '',
                    addresstwo: '',
                    other_info: '',
                    shipping_address: '',
                    register_date: ''
                });
            } else {
                setErrorMsg('There was a problem adding the customer. Kindly try again.');
                setSuccessMsg('');
            }
        } catch (error) {
            //console.error(error);
            setErrorMsg('An error occurred while adding the customer. Please try again.');
            setSuccessMsg('');
        }
    };
    

    const buttonEnable = ProductData.name && ProductData.company && ProductData.email && ProductData.addressone && ProductData.addresstwo 

    return (
        <section className="container mtopcon dashb">
            <div className="row">
            <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">
                    
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Add Customer</h6></div>
                            <div className="col-5"><Link to="/bizdocs/customers"><button className="btn btn-primary f85 float-right"><ArrowLeft/></button></Link></div>
                        </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-4">
                            <div className="card shadow-sm border border-secondary border-opacity-10"> 
                                <div className="card-body">                                           

                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Name*</label>
                                                <input type="text" onChange={inputHandler} value={ProductData.name} name="name"  className="form-control addprobginpt"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="lnm" className="form-label font9 mb-0">Company Name*</label>
                                                <input type="text" onChange={inputHandler} value={ProductData.company} name="company" className="form-control addprobginpt" id="lnm"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Email*</label>
                                                <input type="email" onChange={inputHandler} value={ProductData.email} name="email"  className="form-control addprobginpt"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Mobile*</label>
                                                <input type="number" onChange={inputHandler} value={ProductData.mobile} name="mobile"  className="form-control addprobginpt"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Address 1*</label>
                                                <input type="text" onChange={inputHandler} value={ProductData.addressone} name="addressone"  className="form-control addprobginpt"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Address 2*</label>
                                                <input type="text" onChange={inputHandler} value={ProductData.addresstwo} name="addresstwo"  className="form-control addprobginpt"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Other Info</label>
                                                <input type="text" onChange={inputHandler} value={ProductData.other_info} name="other_info"  className="form-control addprobginpt"/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="fnm" className="form-label font9 mb-0">Shipping Address</label>
                                                <textarea type="text" onChange={inputHandler} value={ProductData.shipping_address} name="shipping_address" className="form-control addprobginpt" id="lnm"/>
                                            </div>
                                        </div>

                                        <button type="button" disabled={!buttonEnable} onClick={submitHandler} className="col-12 btn btn-primary mt-3">Add Customer</button>

                                        {successMsg && <p className="text-success text-center mt-4"><b>{successMsg}</b></p> } 
                                        {errorMsg && <p className="text-danger text-center mt-4"><b>{errorMsg}</b></p> }   

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BizdocsAddCustomer;