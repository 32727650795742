import orderlogo from '../../order.jpg';
import {Link} from 'react-router-dom';
import { Plus, Edit, Trash} from 'react-feather';
import Sidebar from './sidebar';
import { useState,useEffect,useContext } from 'react';
import api from "../api";
function BizdocsSellerProducts(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [products, setproducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [DelId, setDelId] = useState("");
    const [DelIdStatus, setDelIdStatus] = useState(false);
    
    const token = localStorage.getItem("access_token");
    // Fetch vendor products
    const fetchVendorProducts = async () => {
      try {
        const response = await api.get(baseUrl+"/bizproproduct/list/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setproducts(response.data.products);
      } catch (err) {
        //console.error("Error fetching products:", err);
        setError("Failed to fetch products. Please try again.");
      }finally {
          setLoading(false);
      }
    };
    function deleteStepOne(product){
      setDelId(product);
      setDelIdStatus(false);
    }
    function handleDelete(){
        api.delete(baseUrl+'/bizproproduct/'+DelId+'/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response){
          //console.log(response.status);
            if(response.status==204){
                fetchVendorProducts();
                setDelIdStatus(true);
            }
        })
        .catch(function(error){
            //console.log(error);
        });
    }
    
    // Run fetchVendorProducts on component mount
    useEffect(() => {
      fetchVendorProducts();
    }, []);

    if (loading) return <p>Loading products...</p>;
    if (error) return <p>{error}</p>;

    // Filter quotations based on the search term
    const filteredResults = products.filter((product) => {
        const search = searchTerm.toLowerCase();
        return (
            (product.title && product.title.toLowerCase().includes(search)) ||
            (product.price && product.price.toLowerCase().includes(search))
        );
    });
    return (
        <section className="container mtopcon dashb">
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max orderscontainer">                        
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Products Overview </h6></div>
                            <div className="col-5"><Link to="/bizdocs/addproduct"><button className="btn btn-primary f85 float-right"><Plus/> Product</button></Link></div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <input
                                    className="filter2 border me-2 w-100"
                                    type="search"
                                    placeholder="Search Products"
                                    aria-label="Search"
                                    onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on keyup
                                />
                            </div>
                        </div>

                        <div className="mb-4">

                            {filteredResults.length === 0 ? (
                                <p>No products found.</p>
                            ) : (
                                <>
                                    {filteredResults
                                    .sort((a, b) => b.id - a.id) // Sort by id in descending order
                                    .map((product,index) => (
                                            <div className="row ord-bg bg-white p-2 hvr bx-shadow" key={index}>
                                                <div className="col-3 col-lg-1 p-0"><span className="float-left"></span>
                                                    <div className="ord-img float-left">
                                                        <img src={orderlogo} className='w-100 h-100'/>       
                                                    </div>
                                                </div>
                                                <div className="col-5 col-lg-9 pl-0 pr-0">
                                                    <div className="row">
                                                        <div className="col-11 col-lg-5 ord-sz text-dark">{product.title}</div>
                                                        <div className="col-5 col-lg-3 ord-sz">Ksh {product.price}</div>
                                                        <div className="col-6 col-lg-2 ord-sz grey-text">TAX: {product.tax}%</div>
                                                    </div>
                                                </div>
                                                <div className="col-4 col-lg-2 f8 text-black prodedtbtn text-right p-0 mt-1 lh34">
                                                        <Trash onClick={()=>deleteStepOne(product.id)} className="text-danger border-0 me-2" data-bs-toggle="modal" data-bs-target="#confirmdelModal"/>
                                                        <Link to={`/bizdocs/editproduct/${product.id}`}><Edit className="text-success border-0  ms-2"/></Link>
                                                </div>
                                            </div>   
                                    ))}
                                </>
                            )}                            

                        </div>
                </div>
            </div>

            <div className="modal fade" id="confirmdelModal" tabIndex="-1" aria-labelledby="confirmdelModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header p-2">
                          <h1 className="modal-title fs-5" id="confirmdelModalLabel">Confirm</h1>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body text-center">
                          {DelIdStatus==true && <>Deleted Successfully</>}
                          {DelIdStatus==false && <>Are you sure you want to delete this product?</>}
                          
                      </div>
                      <div className="modal-footer p-2">                      
                          {DelIdStatus==true && <><button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button></>}
                          {DelIdStatus==false && <><button type="button" className="btn btn-danger" onClick={() => handleDelete()}>Delete</button></>}
                          
                      </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BizdocsSellerProducts;