import axios from "axios";
import userlogo from '../../user.jpg';
import {Link} from 'react-router-dom';
import { ArrowRight} from 'react-feather';
import Sidebar from './sidebar';
import { useState,useEffect } from 'react';
import api from "../api";
import useStoreSetupCheck from './useStoreSetupCheck';
function SellerCustomers(){
    const setupStatus = useStoreSetupCheck();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [CustomerList,setCustomerList]=useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchData();
    },[]);
    const token = localStorage.getItem("access_token");
    const fetchData = async () => {
        try {
          const response = await api.get(baseUrl+"/vendor-customers/", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setCustomerList(response.data.results);
        } catch (err) {
          //console.error("Error fetching products:", err);
          setError("Failed to fetch products. Please try again.");
        }finally {
            setLoading(false);
        }
    };
    function showConfirm(customer_id){
        var _confirm=window.confirm('Are your sure you want to delete?');
        if(_confirm){
            axios.delete(baseUrl+'delete-customer-orders/'+customer_id)
            .then(function (response){
                if(response.bool==true){
                    fetchData(baseUrl+'seller/customer/'+customer_id+'/orderitems');
                 }
            })
            .catch(function(error){
                //console.log(error);
            });
        }
    }
    // Filter quotations based on the search term
    const filteredResults = CustomerList.filter((product) => {
        const search = searchTerm.toLowerCase();
        return (
            (product.cust_name && product.cust_name.toLowerCase().includes(search)) ||
            (product.cust_email && product.cust_email.toString().toLowerCase().includes(search))
        );
    });
    return (
        <section className="container mtopcon dashb">
            <div className="row">
            <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max orderscontainer">
                        <div className="row">
                            <div className="col-12 pt-2"><h6 className="pageheader-title mb-1">Customers & Rewards</h6><p className="grey-text font9">Track and engage with customers, and offer exclusive rewards to boost loyalty and sales.</p></div>
                        </div>
                        <div className="row dashhome-rw">
                            <div className="col-12">       
                                <div className="col-12 mb-3">
                                    <input
                                        className="filter2 border me-2 w-100"
                                        type="search"
                                        placeholder="Search Customers"
                                        aria-label="Search"
                                        onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on keyup
                                    />
                                </div>                 
                                <div className="mb-4">{loading}
                                        {filteredResults.length === 0 ? (                                            
                                            loading === true ? <p>Loading...</p> : <p>No customers found.</p>
                                        ) : (
                                            <>
                                                {filteredResults
                                                    .sort((a, b) => b.id - a.id) // Sort by id in descending order
                                                    .map((item,index) => (
                                                            <>
                                                                <Link to={`/seller/customer/${item.cust_email}/orderitems/`}>
                                                                        <div className="row ord-bg bg-white p-2 hvr bx-shadow mlrnew" key={index}>
                                                                            <div className="col-2 col-lg-1 p-0"><span className="float-left"></span><div className="ord-img w30 float-left"><img src={userlogo} className='w-100 h-100'/></div></div>
                                                                            <div className="col-8 pl-0 pr-0">
                                                                                <div className="row">
                                                                                    <div className="col-11 col-lg-5 text-dark ord-sz">{item.cust_name}</div>
                                                                                    <div className="col-6 col-lg-3 ord-sz grey-text">{item.cust_email}</div>
                                                                                    <div className="col-5 col-lg-2 ord-sz text-right grey-text">Orders: {item.total_orders}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2 col-lg-2 f8 text-black prodedtbtn text-right p-0 mt-1 lh34">
                                                                                <button className="btn btn-default border-0"><ArrowRight className="text-success" /></button>
                                                                            </div>
                                                                        </div>
                                                                </Link>
                                                            </>
                                                    ))}
                                            </>
                                        )}
                                    
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </section>
    )
}

export default SellerCustomers;