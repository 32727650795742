import { useState,useEffect,useContext } from 'react';
import axios from 'axios';
import Sidebar from './sidebar';
import api from "../api0";
function CustomerProfile(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [ProfileData,setProfileData]=useState({  
        "user_id":'', 
        "first_name":'',
        "last_name":'',
        "username":'',
        "email":'',
        "mobile":'',
        "p_image":'',
    });
    

    useEffect(()=>{
        fetchData();
    },[]);
    const fetchData = async () => {
        try {
          const response = await api.get(`${baseUrl}/customer/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          setProfileData({
            "user_id":response.data.user.id,
            "first_name":response.data.user.first_name,
            "last_name":response.data.user.last_name,
            "username":response.data.user.username,
            "email":response.data.user.email,
            "mobile":response.data.mobile,
            "p_image":response.data.profile_img,
        });
        
        } catch (error) {
          //console.error("Error fetching products:", err.toJSON());
        }
    };

    const inputHandler =(event) => {
        setProfileData({
            ...ProfileData,
            [event.target.name]:event.target.value
        });
    };

    const submitHandler =(event) => {
        setLoading(true);
        const formData=new FormData();
            formData.append('user',ProfileData.user_id);
            formData.append('mobile',ProfileData.mobile);

            //SUBMIT DATA
            api.put(baseUrl+'/customer/',formData,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(function (response){
                //console.log(response);
                const formUserData=new FormData();
                formUserData.append('username',ProfileData.username);

                //SUBMIT DATA
                api.put(baseUrl+'/user/',formUserData,{
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                .then(function (response){      
                    setLoading(false);                   
                    setSuccessMsg('Updated Successfully');
                    setErrorMsg('');
                })
                .catch(function(error){
                    setLoading(false);     
                    setErrorMsg('There was a problem adding the product. Please try again.');
                    setSuccessMsg('');
                });
            })
            .catch(function(error){
                setLoading(false);     
                setErrorMsg('There was a problem adding the product. Please try again.');
                setSuccessMsg('');
            });
    };

    return (
        <section className="container mtopcon dashb">
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div> 
                <div className="col-12 col-lg-10 dash-max">
                    
                    <div className="card shadow-sm border border-secondary border-opacity-10">
                        <div className="card-header">
                            <div className='row p-0 m-0'>
                                <p className=''>Profile</p>
                            </div>
                        </div> 
                        <div className="card-body">                        
                        <form>
                                <div className="mb-3">
                                    <label htmlFor="lnm" className="form-label">User Name</label>
                                    <input type="text" className="form-control" id="lnm" name='username' onChange={inputHandler} value={ProfileData.username} />
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12">
                                        <label htmlFor="InputEmail" className="form-label">Phone No.</label>
                                        <input type="number" className="form-control" id="InputEmail" aria-describedby="emailHelp"  name='mobile' onChange={inputHandler} value={ProfileData.mobile} />
                                    </div>
                                </div>
                                
                                <button type="button" onClick={submitHandler} className="col-12 btn btn-primary">{ loading === true ? <>Loading...</> : <>Update</>}</button>
                                
                                {successMsg && <p className="text-success text-center mt-3"><b>{successMsg}</b></p> } 
                                {errorMsg && <p className="text-danger text-center mt-3"><b>{errorMsg}</b></p> }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CustomerProfile;