import React from 'react';
import ecartimg from '../ecartimg.jpg';
import { Link } from 'react-router-dom';
import { ShoppingBag, CreditCard, Trash2, ArrowLeft, Copy, CheckCircle } from 'react-feather';
import { useContext, useState, useEffect } from 'react';
import { CartContext } from '../context';
import api5 from './api5';
import { ExchangeRateContext } from '../ExchangeRateContext';
import { PaystackButton } from 'react-paystack';
import { v4 as uuidv4 } from 'uuid';
import { FaCashRegister } from 'react-icons/fa';
function Checkout() {
    const orderId = uuidv4();
    const { cartData, setCartData } = useContext(CartContext);
    const [cartButtonClickStatus, setCartButtonClickStatus] = useState(true);
    const [sizeQtyData, setSizeQtyData] = useState({});
    const [quantities, setQuantities] = useState({}); 
    const [SetConfirmOrder]=useState(false);
    const [PayMethod, SetPayMethod]=useState('AUTO');
    const [PayError, SetPayError]=useState('');
    const [deliveryFee, SetdeliveryFee]=useState(0);
    const [MobileNoError,setMobileNoError]=useState(false);
    const [status] = useState('Pending');
    const [loadingPay,setloadingPay]=useState(false);
    const [UsernameError,setUsernameError]=useState(false);
    const [EmailError,setEmailError]=useState(false);
    const [UsernameError0,setUsernameError0]=useState('0');
    const [EmailError0,setEmailError0]=useState('');
    const [ProfileData,setProfileData]=useState({  
        "user_id":'', 
        "first_name":'',
        "last_name":'',
        "username":'',
        "customer_email":'',
        "mobile":'',
        "address":'',
        "p_image":'',
    });
      
    const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;

    const { exchangeRates, baseCurrency } = useContext(ExchangeRateContext);
    if(exchangeRates==null){window.location.reload();}

    const [location, setLocation] = useState('');
    const [delivery, setDelivery] = useState('');
    const [error, setError] = useState('');

    const [pymtFormData0, setPymtFormData0]=useState();  
    const [DeliveryInfo, setDeliveryInfo]=useState();
    
    const cartItems = cartData?.length || 0;
    let sum = 0;

    
  
    const inputHandler =(event) => {
        setProfileData({
            ...ProfileData,
            [event.target.name]:event.target.value
        });
    };

    function currencyChange(price,currency){
        // Convert price from the product's original currency to the selected base currency
        const productExchangeRate = exchangeRates[currency] || 1;
        const baseExchangeRate = exchangeRates[baseCurrency] || 1;

        // Calculate the converted price
        const convertedPrice = ((price / productExchangeRate) * baseExchangeRate).toFixed(2);
        
        return convertedPrice;
    }

    if (cartData) {
        cartData.forEach(item => {
            var itemQty = quantities[item.product.id] || 1; // Default to 1 if not set
            sum += (parseFloat(currencyChange(item.product.price,item.product.currency))) * itemQty + deliveryFee;
        });
    }
    const baseUrl = process.env.REACT_APP_BASE_URL;
    
    const vendor_login= localStorage.getItem("vendor_login");
    const vendor_currency = localStorage.getItem("VendorCurrency");
    const previousCart0 = localStorage.getItem('cartData');
    const cartJson = JSON.parse(previousCart0);
    var firstProductId="";
    if (cartJson && cartJson.length > 0) {
        firstProductId = cartJson[0].product.id;    
    }else{firstProductId = "null";}

    // Fetch size quantities for each product in the cart
    useEffect(()=>{
        if(firstProductId !== "null"){
            fetchData(baseUrl+'/pymt/'+firstProductId+'/');
        }        
    },[]);

    useEffect(() => {
        const storedLocation = localStorage.getItem('location');
    
        if (storedLocation) setLocation(storedLocation);
    
        if (cartItems > 0) {
            const initialQuantities = {};
            cartData.forEach(item => {
                const prd = item.product.id;
                const sz = item.product.size;
                fetchSizeQty(prd, sz);
                initialQuantities[prd] = 1; // Set default quantity
            });
            setQuantities(initialQuantities); // Set all quantities in one go
        }
    }, [cartData, cartItems]);


    function fetchSizeQty(prd, sz) {
        fetch(`${baseUrl}/sizeqty/${sz}/${prd}/`)
            .then(response => response.json())
            .then(data => {
                // Update size quantities for the specific product
                setSizeQtyData(prevData => ({
                    ...prevData,
                    [prd]: data.results // Store the results under the product ID
                }));
            });
    }

    const handleQtyChange = (productId, newQty) => {
        setQuantities(prev => ({ ...prev, [productId]: newQty }));
    };

    const cartRemoveButtonHandler = (product_id) => {
        const previousCart = JSON.parse(localStorage.getItem('cartData')) || [];
        const updatedCart = previousCart.filter(cart => cart.product.id !== product_id);
        localStorage.setItem('cartData', JSON.stringify(updatedCart));
        setCartData(updatedCart);
    };

    const getQty = (productId) => {
        return quantities[productId] || 1; // Default to 1 if quantity is not set
    };
    
    async function handleSubmit(){
        SetConfirmOrder(true);
    
        const storedcartData = localStorage.getItem('cartData');
        if (storedcartData == "") {return;}  
        const parsedcartData = JSON.parse(storedcartData);
        const orderItems = parsedcartData.map((item) => ({
            product_id: item.product.id,
            size: item.product.size,
            qty: quantities[item.product.id] || 1,
            price: parseFloat(item.product.price),
            usd_price: parseFloat(item.product.price) / 133.33, // Example conversion rate
        })); 

        const formData0 = {
            order_id: orderId,
            order_items: orderItems,
            customer_name: ProfileData.username,
            customer_phone: ProfileData.mobile || 0,
            customer_email: ProfileData.email || 0,
            delivery_loc: location,
            delivery_fee: deliveryFee,
            payment_method: PayMethod,
            total_amount: sum,
            customer_email: ProfileData.email, // Ensure email is populated
        }; 

        try {
            const response = await api5.post(baseUrl+'/checkout/', formData0);
            if (response.data.success) {                
                if(PayMethod!=='AUTO'){
                    localStorage.removeItem('cartData');
                    window.location.href='/order/success/'+response.data.redirect_url;
                }                
            } else {                
                setloadingPay(false);  
                SetPayError('');
                setMobileNoError('');
                setUsernameError(false);
                setEmailError(false);
                setCartButtonClickStatus(true);  
                SetPayError(`Incomplete Payment, Kindly try again`);
            }            
        } catch (error) {
            //console.error('Error processing payment:', error);
            setloadingPay(false);
            SetPayError('Incomplete Payment, Kindly try again');
        }
    };

    const handlePaymentSuccess = async (response) => {
        localStorage.removeItem('cartData');    
    
        const payload = {
          trxref: response.trxref,
          trans: response.trans,
          order_id:orderId,
        };
    
        try {
          const res = await api5.post(baseUrl+`/payment/success/`, payload);      
          window.location.href = res.data.redirect_url;
        } catch (error) {
          //console.error('Failed to update order status:', error.response?.data || error.message);
          //alert('Failed to update order status.');
        }
    };

    const handlePaymentClose = async () => {        
        try {
            SetPayError('Transaction was not completed.');
        } catch (error) {
          //console.error('Failed to restore stock:', error.response?.data || error.message);
        }
    };
    
    const onSuccess = (response) => {   
        startUpload("AUTO");        
        setTimeout(() => {
            handlePaymentSuccess(response);
        }, 1000);
    };

    const onClose = () => {
        alert('Transaction was not completed, window closed.');
        //console.error('Transaction was not completed, window closed.');
        handlePaymentClose();
    };

    const confirmOrderHandler = () => {
        if (!location.trim() && delivery != "Instore Pickup") {
            setError('*Delivery Address cannot be empty');
        }else if (delivery === "") {
            setError('*Delivery Category cannot be empty');
        }else {
            if (delivery === "Instore Pickup") {
                setLocation(delivery);
            }
            setError('');
            localStorage.setItem('location', location);
            setCartButtonClickStatus(false);
        }
        
    }

    function fetchData(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setProfileData({
                "user_id":data.user.id,
                "first_name":data.user.first_name,
                "last_name":data.user.last_name,
                "username":data.user.username,
                "email":data.user.email,
                "mobile":data.mobile,
                "address":data.address,
                "p_image":data.profile_img,
            });
        });
    }  
    
    const backHandler = () => {   
        setloadingPay(false);  
        SetPayError('');
        setMobileNoError('');
        setUsernameError(false);
        setEmailError(false);
        setCartButtonClickStatus(true);        
    }

    const [input1, setInput1] = useState();
    const [input2, setInput2] = useState();
    const [copySuccess, setCopySuccess] = useState('');

    const copyToClipboard = async (text, inputNumber) => {
        try {
        await navigator.clipboard.writeText(text);
        setCopySuccess(`Copied input ${inputNumber} successfully!`);
        setTimeout(() => setCopySuccess(''), 2000); // Clear message after 2 seconds
        } catch (err) {
        setCopySuccess('Failed to copy!');
        }
    };
    
    function fetchData(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
           setPymtFormData0(data);
           setDeliveryInfo(data.delivery_locations);
           setInput1(data.pay_mode_desc_paybill)
           setInput2(data.acc_no)
        });
    }
    
    const handleDeliveryChange = (e) => {
        const value = e.target.value;
        setDelivery(value);
    
        // Extract fee from the selected value
        const parts = value.split('-');
        if (parts.length === 2) {                        
            SetdeliveryFee(parseInt(currencyChange(parts[1],vendor_currency), 10));
        } else {
            SetdeliveryFee(0); // Reset if the format is incorrect
        }
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && !emailPattern.test(email)) {
            setEmailError(true);setEmailError0('0');
        } else {
            setEmailError(false);setEmailError0('1');
        }
    };
    const validateUsername = (username) => {
        if(username.length<3){
            setUsernameError(true);setUsernameError0('0'); return;
        }else{
            setUsernameError(false);setUsernameError0('1');
        } 
    };

    var strnm = localStorage.getItem('active_vendor_name');
    var homeroute = `categories`;
    if(strnm !== ''){homeroute = `/${strnm}`;}

    
    async function startUpload(section) {
        SetPayMethod(section);
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart);
        let total_amount = 0;

        if(ProfileData.username.length<3){
            setUsernameError(true); return;
        }else{
            setUsernameError(false);
        } 

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (ProfileData.email != '' && !emailPattern.test(ProfileData.email)) {
            setEmailError(true); return;
        } else {
            setEmailError(false);
        }

        if(section != "AUTO"){
            if(ProfileData.mobile.length<12){
                setMobileNoError(true); return;
            }else{
                setMobileNoError(false);
            }
        }
        setloadingPay(true);
        SetPayError('');

        try {
            // Fetch the latest product prices from the backend
            const response = await api5.post(baseUrl + '/get-latest-prices/', {
                products: cartJson.map(cart => cart.product.id),
            });
            const latestPrices = response.data; // Assuming the response returns { id: price, ... }

            // Verify each product price in the cart against the latest price
            for (let cart of cartJson) {
                const latestPrice = latestPrices[cart.product.id];
                if (!latestPrice || parseFloat(cart.product.price) !== parseFloat(latestPrice)) {                    
                    // Alert user and then clear the cart data before redirecting to the cart page
                    alert("Network Error, Kindly try again.");
                    localStorage.removeItem('cartData');
                    window.location.href = '/checkout';
                    return; // Exit the function if there's a price mismatch
                }
                total_amount += parseFloat(cart.product.price);
            }
            
            handleSubmit();

        } catch (error) {
            window.location.href='/order/failed'; 
        }
    }

    return (        
        <section className="container mtopcon cartmaincon normal-mt-sctn">   
            {cartButtonClickStatus == true && <>
                {cartItems > 0 && (
                    <div className="row">  
                        <h3 className="mb-2">Cart ({cartItems})</h3>
                        <div className="col-12 col-md-6 col-lg-6 mb-3 p-3">
                            {cartData.map((item, index) => (
                                <div className='cartordcon' key={item.product.id}>
                                    <div className="row ord-bg bg-white p-2 pointer hvr bx-shadow">
                                        <div className="col-auto me-2 p-0">
                                            <div className="ord-img br-10">
                                                <Link>
                                                    <img src={item.product.image} alt={item.product.title}/>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-8 pl-0 pr-0">
                                            <div className="row">
                                                <div className="col-8 ord-sz">
                                                    <Link className='text-black'>
                                                        {item.product.title}
                                                    </Link>
                                                </div>
                                                <div className="col-4 ord-sz text-primary text-center">
                                                    <span className="badge rounded-pill text-bg-warning">Size: {item.product.size}</span>
                                                </div>
                                                <div className="col-7 ord-sz grey-text">
                                                    {baseCurrency} {Intl.NumberFormat().format(currencyChange(item.product.price,item.product.currency))}
                                                </div>
                                                <div className="col-5 ord-sz grey-text">
                                                    <div className="row"> 
                                                        <div className='col-5 p-0'>Qty</div>
                                                        <div className='col-5 p-0 text-left'>
                                                            <select 
                                                                className="w-100 h-80" 
                                                                name='quantity'
                                                                value={quantities[item.product.id] || 1}
                                                                onChange={(e) => handleQtyChange(item.product.id, parseInt(e.target.value))}
                                                            >
                                                                {sizeQtyData[item.product.id]?.map((sizeItem, sizeIndex) => {
                                                                    const options = [];
                                                                    for (let i = 1; i <= sizeItem.qty; i++) {
                                                                        options.push(
                                                                            <option key={`${sizeIndex}-${i}`} value={i}>{i}</option>
                                                                        );
                                                                    }
                                                                    return options;
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1 col-lg-2 ms-3 p-0 f8 text-right mt-1 lh34">
                                            <Trash2 onClick={() => cartRemoveButtonHandler(item.product.id)} />
                                        </div>
                                    </div>
                                </div> 
                            ))}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 pymtcon">
                            <div className='row pay-cart'>
                                <div className='col-6 p-0 grey-text'>Delivery Category</div>
                                <div className='col-6 p-0'>
                                    <select className='' value={delivery} onChange={handleDeliveryChange}>
                                        <option value="">Select</option>
                                        <option value="Instore Pickup">Instore Pickup</option>
                                        {DeliveryInfo && DeliveryInfo.map((item, index) => (
                                            <><option value={item.location + " - " + item.fee}>{item.location + " - " + item.fee}</option></>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='row pay-cart'>
                                <div className='col-6 p-0 grey-text'>Delivery Address</div>
                                <div className='col-6 p-0'>
                                    {delivery === "Instore Pickup" && <><input className='delloc' readOnly value={delivery} onChange={(e) => setLocation(e.target.value)}/></>}
                                    {delivery != "Instore Pickup" && <><input className='u' placeholder='Westlands, Luther King Road, Appartment 4A' value={location} onChange={(e) => setLocation(e.target.value)}/></>}                                    
                                </div>
                            </div>
                            {deliveryFee > 0 && <>
                                <div className='row pay-cart'>
                                    <div className='col-6 p-0 grey-text'>Delivery Fee</div>
                                    <div className='col-6 p-0'>
                                        <input className='delloc' readOnly value={deliveryFee}/>                                        
                                    </div>
                                </div>
                            </> }
                            <div className='row pay-cart'>
                                <div className='col-6 p-0 grey-text'>Total</div>
                                <div className='col-6 p-0'>
                                    <span>{baseCurrency}  {new Intl.NumberFormat().format(sum)}</span>
                                </div>
                            </div>
                            {error && <p style={{ color: 'red' }} className='mt-3'>{error}</p>}
                            <div className='row mt-3'>
                                <div className='col-12'>
                                    <button className="col-12 btn orange text-white" onClick={confirmOrderHandler}  height="50px">Proceed to Checkout <CreditCard /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}  
                {cartItems === 0 && (

                    vendor_login === "true" ? <>
                            <div className="row text-center"> 
                                <h3 className="text-dark mt-3">Order is empty</h3> 
                                <div className='row'><div className='col-12'><div className='ecartimg mt-2 mb-2'><img src={ecartimg}/></div> </div></div>                                       
                                <Link to="/seller/pos">
                                    <button className="btn btn-info text-white">Back to POS <FaCashRegister /></button>
                                </Link>
                            </div>
                    </> : <>
                            <div className="row text-center"> 
                                <h3 className="text-dark mt-3">Your Shopping Cart is empty</h3> 
                                <div className='row'><div className='col-12'><div className='ecartimg mt-2 mb-2'><img src={ecartimg}/></div> </div></div>                                       
                                <Link to={homeroute}>
                                    <button className="btn btn-info text-white">Continue Shopping <ShoppingBag /></button>
                                </Link>
                            </div>
                    </>
                    
                )} 
            </>}
            {cartButtonClickStatus == false && <>
                <div className="row">           
                    <div className="col-12 col-lg-4 reglogincon">
                        <button className='btn btn-dark-outline mb-2' onClick={backHandler}><ArrowLeft/> Back</button>
                        <div className="card shadow-sm border border-secondary border-opacity-10">
                            <div className="card-body">
                                <div className="row ordbgrw pb-3">
                                    <div className="col-5 ord-sz1 pt-2"><font className="grey-text">Total</font><br/> 
                                        <span><b>{baseCurrency} {new Intl.NumberFormat().format(sum)}</b></span>
                                    </div>
                                    <div className="col-7 ord-sz1 pt-2"><font className="grey-text">Delivery Location:</font><br/> <b>{location}</b></div>
                                </div> 
                                {pymtFormData0.pay_mode == 0 &&
                                    <div>                                
                                        <p className='mb-2'>Kindly send payment to:</p>
                                        <div className='row'>
                                            {pymtFormData0.pay_mode_desc == "Bank" && <>
                                                <div className='col-5 font9'>
                                                    <span className='grey-text'>Paybill</span>
                                                    <div className="input-group mb-1">
                                                        <input type="text" className="form-control border-0 p-0 h25" aria-describedby="button-addon2" value={input1} readOnly/>
                                                    </div>
                                                </div>
                                                <div className='col-7 font9'>
                                                    <span className='grey-text'>Account Number</span>
                                                    <div className="input-group mb-1">
                                                        <input type="text" className="form-control border-0 p-0 h25" aria-describedby="button-addon2" value={input2} readOnly/>
                                                        <button className="btn btn-outline-secondary border-0 copybtn p-0 m-0" type="button" id="button-addon2" onClick={() => copyToClipboard(input2, 1)}>
                                                            
                                                            {!copySuccess && <p className='col-12 font9 m-0 p-0'><Copy width='20px'/></p>}
                                                            {copySuccess && <p className='col-12 text-success font9 m-0 p-0'><CheckCircle width='20px'/></p>}
                                                        </button>
                                                        <p className='col-12 text-success font9 m-0 p-0'></p>
                                                    </div>
                                                </div>                                          
                                            </>}{pymtFormData0.pay_mode_desc == "Mpesa" && <>
                                                <div className='col-12 font9'>
                                                    <span className='grey-text'>Mpesa Number</span>
                                                    <div className="input-group mb-1">
                                                        <input type="text" className="form-control border-0 p-0 h25" aria-describedby="button-addon2" value={input2} readOnly/>
                                                        <button className="btn btn-outline-secondary border-0 copybtn p-0 m-0" type="button" id="button-addon2" onClick={() => copyToClipboard(input2, 1)}>
                                                            
                                                            {!copySuccess && <p className='col-12 font9 m-0 p-0'><Copy width='20px'/></p>}
                                                            {copySuccess && <p className='col-12 text-success font9 m-0 p-0'><CheckCircle width='20px'/></p>}
                                                        </button>
                                                        <p className='col-12 text-success font9 m-0 p-0'></p>
                                                    </div>
                                                </div>                                          
                                            </>}                                                                              
                                        </div>                                       
                                        
                                        <div className="mb-3 mt-3">
                                                <label htmlFor="lnm" className="grey-text font9 mb-0 pb-0">Username</label>
                                                <input type="text" className="form-control" id="lnm" name='username' onChange={inputHandler} value={ProfileData.username} />
                                                {UsernameError && <p className='text-danger font8 p-0 m-0'>*Confirm Username</p>}
                                        </div>
                                        <div className="mb-3">
                                                <label htmlFor="InputEmail" className="grey-text font9 mb-0 pb-0">Phone No.</label>
                                                <input type="number" className="form-control" id="InputEmail" aria-describedby="emailHelp"  name='mobile' onChange={inputHandler} value={ProfileData.mobile} />
                                                {MobileNoError && <p className='text-danger font8 p-0 m-0'>*Confirm Phone No Format 254 724 000 000</p>}
                                        </div>
                                        <div className="mb-3 mt-3">
                                                <label htmlFor="lnm" className="grey-text font9 mb-0 pb-0">Email</label>
                                                <input type="email" className="form-control" id="lnm" name='email' onChange={inputHandler} value={ProfileData.email} />                                                        
                                                {EmailError && <p className="text-danger font8 p-0 m-0">Invalid email format. Kindly enter a valid email.</p>}
                                        </div>

                                        <div className='ord_outrbx_div mt-4'>                                            
                                            { loadingPay === true ? <><button className='ordprss11' disabled>Loading....</button></> : <><button className='ordprss11' onClick={()=>startUpload("PAV")}>I have Paid</button></>}
                                        </div>
                                        <div className='ord_outrbx_div mt-3 mb-2'>                                            
                                            { loadingPay === true ? <><button className='ordprss111' style={{ background: '#fff', color: '#333'}}><u>Loading....</u></button></> : <><button className='ordprss111' style={{ background: '#fff', color: '#333'}} onClick={()=>startUpload("POD")}><u>I will Pay on Delivery</u></button></>}
                                        </div>
                                    </div>
                                }
                                {pymtFormData0.pay_mode == 1 && <>
                                    <div className="tab-content pt-2 pymt pb-4" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                            <>
                                                <div className="mb-3 mt-3">
                                                        <label htmlFor="lnm" className="grey-text font9 mb-0 pb-0">Username</label>
                                                        <input type="text" className="form-control" id="lnm" name='username' onBlur={(e) => validateUsername(e.target.value)} onChange={inputHandler} value={ProfileData.username} />                                                        
                                                        {UsernameError && <small className="text-danger font8">Confirm username</small>}
                                                </div>
                                                <div className="mb-3 mt-3">
                                                        <label htmlFor="lnm" className="grey-text font9 mb-0 pb-0">Email</label>
                                                        <input type="email" className="form-control" id="lnm" name='email' onBlur={(e) => validateEmail(e.target.value)}  onChange={inputHandler} value={ProfileData.email} />                                                        
                                                        {EmailError && <small className="text-danger font8">Invalid email format. Kindly enter a valid email.</small>}
                                                </div>
                                                {PayError && <p className='text-danger font9 text-center p-0 m-0'>{PayError}</p>}
                                                {status == "Complete" && <p className='text-success font9 text-center p-0 m-0'>{status}, rerouting...</p>}      
                                                { UsernameError0 == '1' && EmailError0 == '1' ? <>
                                                    <PaystackButton 
                                                        className='btn col-12 text-light bg-dark mt-4'
                                                        text="Complete"
                                                        currency="KES"
                                                        publicKey={publicKey}
                                                        email={ProfileData.email}
                                                        amount={sum * 100}
                                                        reference={orderId}
                                                        onSuccess={onSuccess}
                                                        onClose={onClose}
                                                    />
                                                    
                                                </> : <>                                                        
                                                    <button className='btn col-12 text-light bg-dark mt-3' disabled>Complete</button>
                                                </>} 
                                                
                                            </> 
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>  
                        </div>
                    </div>
                </div>
            </>}
        </section>        
    );
}

export default Checkout;