import userlogo from '../../user.jpg';
import {Star} from 'react-feather';
import Sidebar from './sidebar';
import { useState, useEffect } from 'react';
import api from "../api";
import useStoreSetupCheck from './useStoreSetupCheck';
function SellerReviews(){
    const setupStatus = useStoreSetupCheck();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [RatingsData, setRatingsData] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch Ratings from API
    const fetchRatings = async () => {
        try {
            const response = await api.get(baseUrl + "/vendor/ratings/", {
                headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
            });
            setRatingsData(response.data);
        } catch (error) {
            setRatingsData(null); // Set to null in case of an error
        }finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRatings();
    }, []);

    // Default values if RatingsData is empty or null
    const total_reviews = RatingsData?.total_reviews ?? 0;
    const avg_rating = RatingsData?.average_rating ?? 0;
    const breakdown = RatingsData?.rating_breakdown ?? { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
    const reviews = RatingsData?.individual_reviews ?? [];

    // Calculate total number of ratings
    const totalRatings = Object.values(breakdown).reduce((sum, count) => sum + count, 0);
    const getPercentage = (count) => (totalRatings > 0 ? (count / totalRatings) * 100 : 0);
    // Function to generate stars based on the average rating
    const renderStars = (averageRating) => {
        let filledStars = Math.round(averageRating); // Round to nearest whole number
        let stars = [];

        for (let i = 0; i < 5; i++) {
            if (i < filledStars) {
                stars.push(<Star key={i} className="text-blue" />); // Filled star
            } else {
                stars.push(<Star key={i} className="grey-textd5" />); // Grey star
            }
        }

        return stars;
    };
    return (
        <section className="container mtopcon dashb">
            <div className="row">
            <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">
                        <div className="row mb-3 ph-header-con">
                            <div className="col-12 pt-2"><h6 className="pageheader-title mb-3">Customer Reviews </h6></div>
                        </div>
                        <div className="row dashhome-rw">
                            <div className="col-12 col-lg-3 mb-4">
                                {/* Total Reviews */}
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <h5 className="text font9 grey-text">Total Reviews</h5>
                                        <div className="row">
                                            <div className="col-auto">
                                                <h1 className="mb-1">{total_reviews}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-2">
                                    <div className="card-body">
                                        <h5 className="text font9 grey-text">Average Rating</h5>
                                        <div className="row mb-3">
                                            <div className="col-auto"><h1 className="mb-1">{avg_rating.toFixed(1)}</h1></div>
                                            <div className="col-8 lhrvwrating">{renderStars(avg_rating)}</div>
                                        </div>

                                        
                                        {/* Rating Breakdown */}
                                        {[5, 4, 3, 2, 1].map((rating) => (
                                            <div className="row" key={rating}>
                                                <div className="col-1">{rating}</div>
                                                <div className="col-10 pt-1">
                                                    <div className="progress bg-white mb-3">
                                                        <div
                                                            className={`progress-bar ${
                                                                rating === 5
                                                                    ? "bg-primary"
                                                                    : rating === 4
                                                                    ? "bg-success"
                                                                    : rating === 3
                                                                    ? "bg-info"
                                                                    : rating === 2
                                                                    ? "bg-warning"
                                                                    : "bg-danger"
                                                            }`}
                                                            role="progressbar"
                                                            style={{ width: `${getPercentage(breakdown[rating] || 0)}%` }}
                                                            aria-valuenow={getPercentage(breakdown[rating] || 0)}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                        >
                                                            {Math.round(getPercentage(breakdown[rating] || 0))}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9 orderscontainer">  
                                <hr className="hddsktp"></hr>
                                <div className="row mb-2">
                                    <div className="col-8"><h6 className="pageheader-title mb-2">Reviews</h6></div>
                                </div>                      
                                <div className="mb-4 f9">
                                    {/* Individual Reviews */}
                                    {reviews.length > 0 ? (
                                        reviews.map((review, index) => (
                                            <div className="row bg-white bx-shadow br-10 pt-3 mlrnew mb-3" key={index}>
                                                <div className="col-12 col-lg-4">
                                                    <div className="row mb-3">
                                                        <div className="col-3">
                                                            <img src={userlogo} className="w-100 h-100" alt="User" />
                                                        </div>
                                                        <div className="col-8">
                                                            <p className="mb-0">{review.customer_name}</p>
                                                            <p className="mb-0">Total Spent: Ksh. {review.total_spent}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-8">
                                                    <p>
                                                        <span className="me-3">
                                                            {renderStars(review.rating)}
                                                        </span>
                                                        <span className="grey-text">
                                                            {new Date(review.date).toLocaleDateString()}
                                                        </span>
                                                    </p>
                                                    <p>{review.review}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        loading === true ? <p>Loading...</p> : <p>No reviews found.</p>
                                    )}
                                        
                                    
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </section>
    )
}

export default SellerReviews;