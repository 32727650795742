import userlogo from '../../user.jpg';
import {Link} from 'react-router-dom';
import { Edit, Plus, Trash} from 'react-feather';
import Sidebar from './sidebar';
import { useState,useEffect } from 'react';
import api from "../api";
function BizdocsSellerCustomers(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [DelId, setDelId] = useState("");
    const [DelIdStatus, setDelIdStatus] = useState(false);

    const token = localStorage.getItem("access_token");
    
    const fetchCustomers = async () => {
        try {
            const response = await api.get(`${baseUrl}/bizprocustomer/list/`, {
                headers: {
                Authorization: `Bearer ${token}`,
                },
            });
            setCustomers(response.data.results);
        } catch (error) {
            setError('Failed to fetch customer data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchCustomers();
    }, []);

    function deleteStepOne(customer){
        setDelId(customer);
        setDelIdStatus(false);
      }
      function handleDelete(){
          api.delete(baseUrl+'/bizprocustomer/'+DelId+'/', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function (response){
            //console.log(response.status);
              if(response.status==204){
                  fetchCustomers();
                  setDelIdStatus(true);
              }
          })
          .catch(function(error){
              //console.log(error);
          });
      }


    // Filter quotations based on the search term
    const filteredCustomerResults = customers.filter((customer) => {
        const search = searchTerm.toLowerCase();
        return (
            (customer.name && customer.name.toLowerCase().includes(search)) ||
            (customer.mobile && customer.mobile.toString().toLowerCase().includes(search)) ||
            (customer.email && customer.email.toLowerCase().includes(search))
        );
    }).sort((a, b) => {
        return b.id - a.id;
    });

    if (loading) return <p>Loading customers...</p>;
    if (error) return <p>{error}</p>;

    return (
        <section className="container mtopcon dashb">
            <div className="row">
            <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max orderscontainer">                   
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Customers Overview </h6></div>
                            <div className="col-5"><Link to="/bizdocs/addcustomer"><button className="btn btn-primary f85 float-right"><Plus/> Customer</button></Link></div>
                        </div>
                        <div className="row dashhome-rw d-flex justify-content-center">
                            <div className="col-12 col-lg-9">    
                                <input
                                    className="filter2 border me-2 w-100 mb-3"
                                    type="search"
                                    placeholder="Search Customer"
                                    aria-label="Search"
                                    onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on keyup
                                />                    
                                <div className="mb-4">

                                    {filteredCustomerResults.length === 0 ? (
                                        <p>No customers registered yet.</p>
                                    ) : (
                                        <>
                                                {filteredCustomerResults.map((customer) => (                                                
                                                    <div className="row ord-bg bg-white p-2 hvr bx-shadow mlrnew">
                                                        <div className="col-2 col-lg-1 p-0"><span className="float-left"></span><div className="ord-img w30 float-left"><img src={userlogo} className='w-100 h-100'/></div></div>
                                                        <div className="col-8 col-lg-9 pl-0 pr-0">
                                                            <div className="row">
                                                                <div className="col-11 col-lg-5 text-dark ord-sz">{customer.name}</div>
                                                                <div className="col-6 col-lg-3 ord-sz grey-text">{customer.mobile}</div>
                                                                <div className="col-5 col-lg-2 ord-sz text-right grey-text">{customer.email}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 col-lg-2 f8 text-black prodedtbtn text-right p-0 mt-1 lh34">
                                                            <Trash onClick={()=>deleteStepOne(customer.id)} className="text-danger border-0 me-2" data-bs-toggle="modal" data-bs-target="#confirmdelModal"/>
                                                            <Link to={`/bizdocs/editcustomer/${customer.id}`}><button className="btn btn-default border-0"><Edit className="text-success" /></button></Link>
                                                        </div>
                                                    </div>                                              
                                                ))}
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                </div>
            </div>

            <div className="modal fade" id="confirmdelModal" tabIndex="-1" aria-labelledby="confirmdelModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header p-2">
                        <h1 className="modal-title fs-5" id="confirmdelModalLabel">Confirm</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        {DelIdStatus==true && <>Deleted Successfully</>}
                        {DelIdStatus==false && <>Are you sure you want to delete this customer?</>}
                        
                    </div>
                    <div className="modal-footer p-2">                      
                        {DelIdStatus==true && <><button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button></>}
                        {DelIdStatus==false && <><button type="button" className="btn btn-danger" onClick={() => handleDelete()}>Delete</button></>}
                        
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BizdocsSellerCustomers;