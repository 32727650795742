import axios from "axios";
import {Link} from 'react-router-dom';
import { Download,CheckCircle, Loader, Star, MessageSquare} from 'react-feather';
import { useState,useContext } from 'react';
import { ExchangeRateContext } from '../../ExchangeRateContext';
function OrderRow(props){
    const baseUrl0 = process.env.REACT_APP_BASE_URL_IMG;
    const index = props.index;
    const item = props.item; 
    const { exchangeRates, baseCurrency } = useContext(ExchangeRateContext);
    if(exchangeRates==null){window.location.reload();}

    var price = item.product.price;
    var currency = item.product.currency;

    // Convert price from the product's original currency to the selected base currency
    const productExchangeRate = exchangeRates[currency] || 1;
    const baseExchangeRate = exchangeRates[baseCurrency] || 1;

    // Calculate the converted price
    const convertedPrice = ((price / productExchangeRate) * baseExchangeRate).toFixed(2);
    return (        
        <div className="row ord-bg bg-white p-2 hvr bx-shadow">
            <div className="col-3 col-lg-1 p-0"><div className="ord-img br-10"><img src={`${baseUrl0}${item.product.product_imgs[0].image}`} className='w-100 h-100'/></div></div>
            <div className="col-7 col-lg-9 pl-0 pr-0">
                <div className="row">
                    <div className="col-12 col-lg-4 ord-sz">{item.product.title}</div>
                    <div className="col-5 col-lg-4 ord-sz text-primary">
                        {
                            item.order.order_status==true && <span className="badge rounded-pill text-bg-success">Processing {item.order.order_status}</span>
                        }
                        {
                            item.order.order_status==false && <span className="badge rounded-pill text-bg-warning"> Completed</span>
                        }

                    </div>
                    <div className="col-7 col-lg-4 ord-sz grey-text">
                        <span className="p-0">{baseCurrency} {new Intl.NumberFormat().format(convertedPrice)}</span>
                    </div>
                </div>
            </div>
            <div className="col-2 col-lg-2 f8 text-black text-right p-0 mt-1 lh34">
                {
                    item.order.order_status==true && <Link to={`/customer/add-review/${item.product.id}`} className='btn btn-warning btn-sm border-0 ms-2'><MessageSquare /> </Link>
                }
            </div>
        </div>
    );
}

export default OrderRow;