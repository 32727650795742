import {Link} from 'react-router-dom';
import { useState,useEffect } from 'react';
import { Briefcase, FilePlus, Package, Settings, Shield, User} from 'react-feather';
import Sidebar from './sidebar';
import { FaClipboardList, FaPiggyBank } from 'react-icons/fa';
function SellerDashboardBizdocs(){   
    const [VendorData] = useState(() => {
        const savedData = localStorage.getItem('VendorData');
        return savedData ? JSON.parse(savedData) : {
            'totalProducts': 0,
            'totalOrders': 0,
            'totalCustomers': 0,
            'name': '',
            'loc': '',
            'bal': '',
            'totalTraffic': '',
            'payMode': '',
            'payMode1': '',
            'payMode2': '',
        };
    });

    // Update localStorage whenever VendorData changes
    useEffect(() => {
        localStorage.setItem('VendorData', JSON.stringify(VendorData));
    }, [VendorData]);
    
    return (
        <section className="container mtopcon dashb">
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div>
                <div className="col-12 col-lg-10 dash-max">  
                    <div className="row dashhome-rw">
                        <div className='col-12 containerzz p-4'>
                            <p className='font9'>Generate Business Documents on the go.</p>
                            <div className="row">
                                <div className="col-3 stat-con mb-1">
                                    <Link to="/bizdocs/profile" className='text-center'>
                                        <div className="card p-2 text-center mb-2">                                            
                                            <h1 className="text-center"><Briefcase style={{ color: '#fff' }}/></h1>
                                        </div>
                                        <h5 className="text f7 text-white">Business</h5>
                                    </Link>
                                </div>
                                <div className="col-3 stat-con mb-1">
                                    <Link to="/bizdocs/customers" className='text-center'>
                                        <div className="card p-2 text-center mb-2">                                            
                                            <h1 className="text-center"><User style={{ color: '#fff' }}/></h1>
                                        </div>
                                        <h5 className="text f7 text-white">Customers</h5>
                                    </Link>
                                </div>
                                <div className="col-3 stat-con mb-1">
                                    <Link to="/bizdocs/products" className='text-center'>
                                        <div className="card p-2 text-center mb-2">                                            
                                            <h1 className="text-center"><Package style={{ color: '#fff' }}/></h1>
                                        </div>
                                        <h5 className="text f7 text-white">Products</h5>
                                    </Link>
                                </div>
                                <div className="col-3 stat-con mb-1">
                                    <Link to="/bizdocs/terms" className='text-center'>
                                        <div className="card p-2 text-center mb-2">                                            
                                            <h1 className="text-center"><Shield style={{ color: '#fff' }}/></h1>
                                        </div>
                                        <h5 className="text f7 text-white">Terms</h5>
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <hr className='mt-3'/>
                        <div className='col-12'>                            
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12"><p className="pageheader-title">Discover</p></div>
                            <div className="row">
                                <div className="col-lg-4 col-6 stat-con mb-1">
                                    <Link to="/bizdocs/quotation" className=''><div className="card mb-3">
                                        <div className="card-body">
                                            <h1 className="text-left"><FilePlus className='invoiceicons'/></h1>
                                            <label htmlFor="lnm" className="form-label mb-0"><b>Make Quotation</b></label>
                                            <div className="grey-text font8 mb-2">create new quotation</div>
                                        </div>
                                    </div></Link>
                                </div>
                                <div className="col-lg-4 col-6 stat-con mb-1">
                                    <Link to="/bizdocs/quotations" className=''><div className="card mb-3">
                                        <div className="card-body">
                                            <h1 className="text-left"><FaClipboardList className='invoiceicons'/></h1>
                                            <label htmlFor="lnm" className="form-label mb-0"><b>Quotation List</b></label>
                                            <div className="grey-text font8 mb-2">Manage all quotations</div>
                                        </div>
                                    </div></Link>
                                </div>
                                <div className="col-lg-4 col-6 stat-con mb-1">
                                    <Link to="/bizdocs/invoice" className=''><div className="card mb-3">
                                        <div className="card-body">
                                            <h1 className="text-left"><FilePlus className='invoiceicons'/></h1>
                                            <label htmlFor="lnm" className="form-label mb-0"><b>Make Invoice</b></label>
                                            <div className="grey-text font8 mb-2">Create new invoice</div>
                                        </div>
                                    </div></Link>
                                </div>
                                <div className="col-lg-4 col-6 stat-con mb-1">
                                    <Link to="/bizdocs/invoices" className=''><div className="card mb-3">
                                        <div className="card-body">
                                            <h1 className="text-left"><FaClipboardList className='invoiceicons'/></h1>
                                            <label htmlFor="lnm" className="form-label mb-0"><b>Invoice List</b></label>
                                            <div className="grey-text font8 mb-2">Manage all invoices</div>
                                        </div>
                                    </div></Link>
                                </div>
                                <div className="col-lg-4 col-6 stat-con mb-1">
                                    <Link to="/bizdocs/purchaseorder" className=''><div className="card mb-3">
                                        <div className="card-body">
                                            <h1 className="text-left"><FilePlus className='invoiceicons'/></h1>
                                            <label htmlFor="lnm" className="form-label mb-0"><b>Make Purchase Order</b></label>
                                            <div className="grey-text font8 mb-2">Create Purchase Order</div>
                                        </div>
                                    </div></Link>
                                </div>
                                <div className="col-lg-4 col-6 stat-con mb-1">
                                    <Link to="/bizdocs/purchaseorders" className=''><div className="card mb-3">
                                        <div className="card-body">
                                            <h1 className="text-left"><FaClipboardList className='invoiceicons'/></h1>
                                            <label htmlFor="lnm" className="form-label mb-0"><b>Purchase Order List</b></label>
                                            <div className="grey-text font8 mb-2">View & Manage all Generated Purchase Orders</div>
                                        </div>
                                    </div></Link>
                                </div>
                                <div className="col-lg-4 col-6 stat-con mb-1">
                                    <Link to="/bizdocs/proforma" className=''><div className="card mb-3">
                                        <div className="card-body">
                                            <h1 className="text-left"><FilePlus className='invoiceicons'/></h1>
                                            <label htmlFor="lnm" className="form-label mb-0"><b>Make Proforma Invoice</b></label>
                                            <div className="grey-text font8 mb-2">Create new Proforma Invoice</div>
                                        </div>
                                    </div></Link>
                                </div>
                                <div className="col-lg-4 col-6 stat-con mb-1">
                                    <Link to="/bizdocs/proformas" className=''><div className="card mb-3">
                                        <div className="card-body">
                                            <h1 className="text-left"><FaClipboardList className='invoiceicons'/></h1>
                                            <label htmlFor="lnm" className="form-label mb-0"><b>Proforma Invoice List</b></label>
                                            <div className="grey-text font8 mb-2">View & Manage all Generated Proforma Invoices</div>
                                        </div>
                                    </div></Link>
                                </div>
                                <div className="col-lg-4 col-6 stat-con mb-1">
                                    <Link to="/bizdocs/delivery-note" className=''><div className="card mb-3">
                                        <div className="card-body">
                                            <h1 className="text-left"><FilePlus className='invoiceicons'/></h1>
                                            <label htmlFor="lnm" className="form-label mb-0"><b>Make Delivery Note</b></label>
                                            <div className="grey-text font8 mb-2">Create Note</div>
                                        </div>
                                    </div></Link>
                                </div>
                                <div className="col-lg-4 col-6 stat-con mb-1">
                                    <Link to="/bizdocs/delivery-notes" className=''><div className="card mb-3">
                                        <div className="card-body">
                                            <h1 className="text-left"><FaClipboardList className='invoiceicons'/></h1>
                                            <label htmlFor="lnm" className="form-label mb-0"><b>Delivery Note List</b></label>
                                            <div className="grey-text font8 mb-2">Manage all Delivery Notes</div>
                                        </div>
                                    </div></Link>
                                </div>
                                <div className="col-lg-4 col-6 stat-con mb-1">
                                    <div className="card mb-3"  data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        <div className="card-body">
                                            <h1 className="text-left"><Settings className='invoiceicons'/></h1>
                                            <label htmlFor="lnm" className="form-label mb-0"><b>Settings</b></label>
                                            <div className="grey-text font8 mb-2">Manage all Settings</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-6 stat-con mb-1">
                                    <Link to="https://paystack.com/pay/kadhr" target='_blank' className=''><div className="card mb-3">
                                        <div className="card-body">
                                            <h1 className="text-left"><FaPiggyBank className='invoiceicons orange'/></h1>
                                            <label htmlFor="lnm" className="form-label mb-0 orange-font"><b>Support Us</b></label>
                                            <div className="text-dark font-bold font8 mb-2">Fuel our growth.</div>
                                        </div>
                                    </div></Link>
                                </div>

                            </div>
                        </div>

                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default SellerDashboardBizdocs;