import axios from "axios";

function SellerLogout() {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem('vendor_token');

    // Notify backend to invalidate the token (optional, if supported by your backend)
    if (token) {
        axios.post(baseUrl + '/vendor/logout/', {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).catch(error => {
            console.error("Error during logout:", error);
        });
    }

    // Clear all localStorage items related to the vendor session
    localStorage.clear();

    // Redirect to the login page
    window.location.href = '/seller/login';
}

export default SellerLogout;
