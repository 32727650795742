import logo from '../logo.svg';
import {Link} from 'react-router-dom';
import { AlertTriangle} from 'react-feather';
function E404(){
    return (
        <section className="container mtopcon normal-mt-sctn">
            <div className="row">
                <div className="col-12 col-lg-6 reglogincon" max-width='100px'>
                    <div className="border-0">
                        <div className="card-body mb-0">                        
                                <div className="row text-center">
                                    <div className="col-md-12 mb-4">
                                        <button className="btn btn-outline-danger border-0"><AlertTriangle width='100px' height='100px' /></button>
                                    </div>
                                    <div className="col-12">
                                        <h2>Page Not Found</h2>
                                        <p className='grey-text'>There was a problem processing your request.</p>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <hr/>
                    <div className="row  mt-4 w-90">      
                        <div className='col-12'><Link to="/"><button className="btn btn-info text-white h40 w-100">Home</button></Link></div>      
                    </div>

                </div>
            </div>
        </section>
    )
}

export default E404;