import { useState,useEffect,useContext } from 'react';
import axios from 'axios';
import Sidebar from './sidebar';
import { ArrowLeft, Plus } from 'react-feather';
import { Link } from "react-router-dom";
import api from '../api';
function BizProfile(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = useState(false);
    const [ProfileData, setProfileData] = useState({
        user_id: "",
        first_name:"",
        last_name:"",
        username:"",
        email:"",
        mobile: "",
        profile_img: null,
        profile_img_preview: "",
        brand_desc: "",
        address: "",
        pay_mode: "",
        pay_mode_desc: "",
        pay_mode_desc_paybill: "",
        acc_no: "",
        bal: "",
        signature: null,
        signature_preview: "",
        bpin: "",
        bnum: "",
        bcat: "",
        bpymt: "",
    });
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const token = localStorage.getItem("access_token");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await api.get(baseUrl+'/vendor/', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data;
            setProfileData({
                user_id: data.user.id,
                mobile: data.mobile,
                first_name:data.user.first_name,
                last_name:data.user.last_name,
                username:data.user.username,
                email:data.user.email,
                profile_img_preview: data.profile_img,
                brand_desc: data.brand_desc,
                address: data.address,
                pay_mode: data.pay_mode,
                pay_mode_desc: data.pay_mode_desc,
                pay_mode_desc_paybill: data.pay_mode_desc_paybill,
                acc_no: data.acc_no,
                bal: data.bal,
                signature_preview: data.signature,
                bpin: data.bpin,
                bnum: data.bnum,
                bcat: data.bcat,
                bpymt: data.bpymt,
            });
        } catch (error) {
            //setError('Failed to fetch customer data. Please try again later.');
        } finally {
            //setLoading(false);
        }
    };

    const inputHandler = (event) => {
        const { name, value } = event.target;
        setProfileData((prevState) => ({
        ...prevState,
        [name]: value,
        }));
    };

    const handleFileChange = (event) => {
        const { name, files } = event.target;
        const file = files[0];
        if (file) {
        const previewUrl = URL.createObjectURL(file);
        setProfileData((prevState) => ({
            ...prevState,
            [name]: file,
            [`${name}_preview`]: previewUrl,
        }));
        }
    };

    const submitHandler = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("user", ProfileData.user_id);
        formData.append("mobile", ProfileData.mobile);
        // Add profile image only if a new file is selected
        if (ProfileData.profile_img) {
            formData.append("profile_img", ProfileData.profile_img);
        }
    
        // Add signature only if a new file is selected
        if (ProfileData.signature) {
        formData.append("signature", ProfileData.signature);
        }
        formData.append("brand_desc", ProfileData.brand_desc);
        formData.append("address", ProfileData.address);
        formData.append("pay_mode", ProfileData.pay_mode);
        formData.append("pay_mode_desc", ProfileData.pay_mode_desc);
        formData.append("pay_mode_desc_paybill", ProfileData.pay_mode_desc_paybill);
        formData.append("acc_no", ProfileData.acc_no);
        formData.append("bal", ProfileData.bal);
        formData.append("bpin", ProfileData.bpin);
        formData.append("bnum", ProfileData.bnum);
        formData.append("bcat", ProfileData.bcat);
        formData.append("bpymt", ProfileData.bpymt);

        api
        .put(baseUrl + "/vendor/", formData, {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            setLoading(false);
            setSuccessMsg('Profile updated successfully');
            setErrorMsg('');
            fetchData(baseUrl + "/vendor/");
        })
        .catch(function(error){ 
            setLoading(false);               
            setErrorMsg(error);
            setSuccessMsg('');
        });

            const formUserData=new FormData();
            formUserData.append('first_name',ProfileData.first_name);
            formUserData.append('last_name',ProfileData.last_name);
            formUserData.append('username',ProfileData.username);
            formUserData.append('email',ProfileData.email);

            //SUBMIT DATA
            axios.put(baseUrl+'/user/'+ProfileData.user_id+'/',formUserData, {
                headers: {
                    "content-type": "multipart/form-data",
                    Authorization: `Bearer ${token}`
                },
            })
            .then(function (response){
                setLoading(false);
                
            })
            .catch(function(error){                
                setErrorMsg('There was a problem. Kindly try again.');
                setSuccessMsg('');
            });
    };

    return (
        <section className="container mtopcon dashb">
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="COL-12 col-lg-10 dash-max">
                        
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Business Profile </h6></div>
                            <div className="col-5"><Link to="/bizdocs/dashboard"><button className="btn btn-primary f85 float-right"><ArrowLeft/></button></Link></div>
                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-lg-4">
                            <div className="card shadow-sm border border-secondary border-opacity-10">
                                    <div className="card-body">                         
                                    <form>
                                            <div className="row mb-3 d-flex">
                                                <div className="col-6">
                                                    <label htmlFor="fnm" className="form-label font9 grey-text mb-0">Owner Name*</label>
                                                    <input type="text" className="form-control font9" id="lnm1" name='first_name' onChange={inputHandler} value={ProfileData.first_name} />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="lnm" className="form-label font9 grey-text mb-0">Business Name*</label>
                                                    <input type="text" className="form-control font9" id="lnm0" name='username' onChange={inputHandler} value={ProfileData.username} />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="InputEmail" className="form-label font9 grey-text mb-0">Email address*</label>
                                                    <input type="email" className="form-control font9" id="InputEmail" aria-describedby="emailHelp"  name='email' onChange={inputHandler} value={ProfileData.email} />
                                                </div>
                                            </div>                                
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="InputEmail" className="form-label font9 grey-text mb-0">Phone No*.</label>
                                                    <input type="number" className="form-control font9" id="InputEmail" aria-describedby="emailHelp"  name='mobile' onChange={inputHandler} value={ProfileData.mobile} />
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="InputEmail" className="form-label font9 grey-text mb-0">Address</label>
                                                <textarea type="text" className="form-control font9"  name='address' onChange={inputHandler} value={ProfileData.address}></textarea>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="InputEmail" className="form-label font9 grey-text mb-0">Business PIN/GSTIN/VAT Number</label>
                                                    <input type="text" className="form-control font9" id="InputEmail" aria-describedby="emailHelp" name='bpin' onChange={inputHandler} value={ProfileData.bpin} />
                                                </div>
                                            </div> 
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="InputEmail" className="form-label font9 grey-text mb-0">Business Number*</label>
                                                    <input type="text" className="form-control font9" id="InputEmail" aria-describedby="emailHelp" name='bnum' onChange={inputHandler} value={ProfileData.bnum} />
                                                </div>
                                            </div>  
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="InputEmail" className="form-label font9 grey-text mb-0">Business Category*</label>
                                                    <input type="text" className="form-control font9" aria-describedby="emailHelp" name='bcat' onChange={inputHandler} value={ProfileData.bcat} />
                                                </div>
                                            </div>  
                                            <div className="mb-2">
                                                <label htmlFor="InputEmail" className="form-label font9 grey-text mb-0">Payment Instructions - Bank Details</label>
                                                <textarea type="text" className="form-control font9"  name='bpymt' onChange={inputHandler} value={ProfileData.bpymt}></textarea>
                                            </div>

                                            <div className="row mt-3">
                                                    <div className='col-6'>
                                                        <label htmlFor="formFile" className="form-label font9 grey-text mb-0">Company Logo</label>
                                                        <div className="upimgcon">
                                                            <input type="file" accept=".jpg, .jpeg, .png" onChange={handleFileChange} name="profile_img" id="images2" className="form-control opzero" required/> <button className="btn addimg"><Plus/> </button>
                                                        </div>
                                                        <div className="col-10 p-0">
                                                                {ProfileData.profile_img_preview && (
                                                                    <img
                                                                    src={ProfileData.profile_img_preview}
                                                                    alt="Profile Preview"
                                                                    className="img-thumbnail mt-2"
                                                                    style={{ width: "100px", height: "100px" }}
                                                                    />
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <label htmlFor="formFile" className="form-label font9 grey-text mb-0">Signature</label>
                                                        <div className="upimgcon">
                                                            <input type="file" accept=".jpg, .jpeg, .png" onChange={handleFileChange} name="signature" className="form-control opzero" required/> <button className="btn addimg"><Plus/> </button>
                                                        </div>
                                                        <div className="col-10 p-0">
                                                                {ProfileData.signature_preview && (
                                                                    <img
                                                                    src={ProfileData.signature_preview}
                                                                    alt="Signature Preview"
                                                                    className="img-thumbnail mt-2"
                                                                    style={{ width: "100px", height: "100px" }}
                                                                    />
                                                                )}
                                                        </div>
                                                    </div>                                                
                                            </div>

                                            <button type="button" onClick={submitHandler} className="col-12 btn btn-primary mt-3">{ loading === true ? <>Loading...</> : <>Update</>}</button>

                                                {successMsg && <p className="text-success mt-4 text-center"><b>{successMsg}</b></p> } 
                                                {errorMsg && <p className="text-danger mt-4 text-center"><b>{errorMsg}</b></p> }   
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        
                </div>
            </div>
        </section>
    )
}

export default BizProfile;


