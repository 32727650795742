import logo from '../logo.svg';
import {Link} from 'react-router-dom';
import { useState,useEffect, useContext } from 'react';
import { CartContext } from '../context';
import { FaCartPlus,FaReceipt } from "react-icons/fa";
import { ExchangeRateContext } from '../ExchangeRateContext';
function SingleProductPos(props){    
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [productData,setProductData]=useState([]);
    const [cartButtonClickStatus,setcartButtonClickStatus]=useState(false);
    const {cartData,setCartData}=useContext(CartContext);
    const product_id=props.product.id;
    const [productImgs,setProductImgs]=useState([]);
    
    const [selectedOption, setSelectedOption] = useState('');
    const [SizeItems,setSizeItems]=useState([["", ""]]);
    const [SizeError,setSizeError]=useState(false);

    const { exchangeRates, baseCurrency } = useContext(ExchangeRateContext);

    if(exchangeRates==null){window.location.reload();}
    
    var price = props.product.price;
    var currency = props.product.currency;

    // Convert price from the product's original currency to the selected base currency
    const productExchangeRate = exchangeRates[currency] || 1;
    const baseExchangeRate = exchangeRates[baseCurrency] || 1;

    // Calculate the converted price
    const convertedPrice = ((price / productExchangeRate) * baseExchangeRate).toFixed(2);

    if(!props.product.image){
        props.product.image=logo;
    }

    useEffect(()=>{
        checkProductInCart(product_id);       
        fetchData(baseUrl+'/minimal-product/'+product_id);
        fetchSizeItems(baseUrl+'/getsizesu/'+product_id); 
    },[]);

    function fetchData(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setProductData(data);
            setProductImgs(data.product_imgs);
        });
    }

    function fetchSizeItems(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {            
            const sizes = data.results.map(sizeItem => [sizeItem.size, sizeItem.qty]);
            setSizeItems(sizes);
        });
    }

    function checkProductInCart(product_id){
        var previousCart=localStorage.getItem('cartData');
        var cartJson=JSON.parse(previousCart);
        if(cartJson!=null){
            cartJson.map((cart)=>{
                if(cart!=null && cart.product.id === product_id){
                    setcartButtonClickStatus(true);
                }
            });
        }
    }

    // Function to handle the change in selection
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const cartAddButtonHandler = () => {
        setSizeError(false);  
        if (selectedOption === "") {
            setSizeError(true); 
            return;
        }
        let previousCart = localStorage.getItem('cartData');
        let cartJson = JSON.parse(previousCart) || [];
        
        const cartData = {
            'product': {
                'id': productData.id,
                'title': productData.title,
                'image': productData.product_imgs[0].image,
                'price': productData.price,
                'currency': productData.currency,
                'size': selectedOption,
                'quantity': 1,
            },
            'user': {
                'id': 1,
            },
            'total_amount': 0,
        };
    
        // Check if the product is already in the cart
        let productExists = false;
        cartJson = cartJson.map(cartItem => {
            if (cartItem && cartItem.product.id === productData.id && cartItem.product.size === selectedOption) {
                productExists = true;
                cartItem.quantity += 1; // Increment quantity if the product exists
                cartItem.total_amount = cartItem.quantity * cartData.product.price; // Update total amount
            }
            return cartItem;
        });
    
        if (!productExists) {
            cartData.total_amount = cartData.quantity * cartData.product.price; // Set initial total amount
            cartJson.push(cartData); // Add the product as a new item if it doesn't exist
        }
    
        localStorage.setItem('cartData', JSON.stringify(cartJson));
        setCartData(cartJson);
        setcartButtonClickStatus(true);
    };
    const cartRemoveButtonHandler = () => {
        var previousCart=localStorage.getItem('cartData');
        var cartJson=JSON.parse(previousCart);
        cartJson.map((cart,index)=>{
            if(cart!=null && cart.product.id == productData.id){
                cartJson.splice(index,1);
            }
        });
        var cartString=JSON.stringify(cartJson);
        localStorage.setItem('cartData',cartString);
        setcartButtonClickStatus(false);
        setCartData(cartJson);
    }

    return (
        <div className="col-6 col-md-4 col-lg-3 prod-col">
            <div className="card prodcon">
                {
                    productImgs.map((img,index)=>{
                        if(index===0){
                            return <img src={img.image} className="card-img-top pro-img" alt={index} key={index}/>                           
                        }
                        
                    })
                }
                <div className="card-footer border-0 likebtn0">     
                    <p className="card-title pro-title fsz-95 mb-0">{props.product.title}</p>
                    <p className="card-title text-price fsz-1 mb-1"><b>{baseCurrency} {new Intl.NumberFormat().format(convertedPrice)}</b></p>  
                    <select className="form-label col-12 h40 mb-0 font9 grey-text" onChange={handleOptionChange}>
                        <option value="">Select</option>
                        {
                            SizeItems.map((item,index)=>{ 
                                return <option value={item[0]} key={index}>{item[0]}</option>
                            })
                        }
                    </select> 
                    {SizeError}
                    {!cartButtonClickStatus && <>
                            <button type="button" onClick={cartAddButtonHandler} className="btn btn-info prodt text-white mb-3 mt-3 me-3 btnhght col-12 pos"><FaCartPlus /> </button> 
                        </>
                    }
                    {cartButtonClickStatus && <>
                            <button type="button" onClick={cartRemoveButtonHandler} className="btn font-bold prodt text-danger mb-3 mt-3 p-0 btnhght">Remove</button> 
                        </>
                    }
                </div>
            </div>
        </div>
         
    )
}

export default SingleProductPos;