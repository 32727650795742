import {Link} from 'react-router-dom';
import orderlogo from '../../order.jpg';
import { Trash2,MoreVertical} from 'react-feather';
import Sidebar from './sidebar';
import { useState,useEffect,useContext } from 'react';
import { ExchangeRateContext } from '../../ExchangeRateContext';
import api from "../api0";
function CustomerWishlist(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl0 = process.env.REACT_APP_BASE_URL_IMG;
    const [WishItems,setWishItems]=useState([]);
    const { exchangeRates, baseCurrency } = useContext(ExchangeRateContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchData();
    },[]);

    const fetchData = async () => {
        try {
            const response = await api.get(baseUrl+'/customer-wishitems', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
            const data = response.data;     
            setWishItems(data.results);
        } catch (error) {
            //setError('Failed to fetch customer data. Please try again later.');
        } finally {
            //setLoading(false);
        }
    };

    function removeFromWishlist(wishlist_id){
        const formData=new FormData();
        formData.append('wishlist_id',wishlist_id);
        
        
        //SUBMIT DATA
        api.post(baseUrl+'/remove-from-wishlist/',formData,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            }
        })
        .then(function (response){
            if(response.data.bool==true){
                document.getElementById('row'+wishlist_id).remove();
            }   
        })
        .catch(function(error){
            //console.log(error);
        });
        
    }
    function changeCurrency(price,currency){
        var productExchangeRate = exchangeRates[currency] || 1;
        var baseExchangeRate = exchangeRates[baseCurrency] || 1;
        var convertedPrice = ((price / productExchangeRate) * baseExchangeRate).toFixed(2);
        return convertedPrice
    }
    // Filter quotations based on the search term
    const filteredResults = WishItems.filter((product) => {
        const search = searchTerm.toLowerCase();
        return (
            (product.product.title && product.product.title.toLowerCase().includes(search)) 
        );
    });
    return (
        <section className="container mtopcon dashb">
            <div className="row">                
                <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div> 

                <div className="col-12 col-lg-10 dash-max">
                        <div className="row ph-header-con">
                            <div className="col-8 pt-2"><h6 className="pageheader-title">Wishlist Overview </h6></div>
                        </div>
                        
                        <div className="row mb-3">
                            <div className="col-12"><input
                                className="filter2 border me-2 w-100"
                                type="search"
                                placeholder="Search Wishlist"
                                aria-label="Search"
                                onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on keyup
                            /></div>
                        </div>

                        <div className="mb-4 ordststsdiv">   

                            {filteredResults.count === 0 ? (
                                loading === true ? <p>Loading...</p> : <p>No products found.</p>
                            ) : (
                                <>
                                    {// Sort OrderItems by order_time in descending order (newest first)
                                filteredResults.sort((a, b) => new Date(b.id) - new Date(a.id))
                                .map((item, index) => { 
                                    return (
                                        <div className="row ord-bg bg-white p-2 pointer hvr bx-shadow" id={`row${item.id}`}>
                                        <div className="col-3 col-lg-1 p-0"><div className="ord-img br-10"><img src={`${baseUrl0}/${item.product.product_imgs[0].image}`} className="w-100 h-100"/></div></div>
                                        <div className="col-7 col-lg-9 pl-0 pr-0">
                                            <div className="row">
                                                <div className="col-11 col-lg-3 ord-sz"><Link to={`/product/${item.product.title}/${item.product.id}`}> {item.product.title}</Link></div>
                                                <div className="col-6 col-lg-3 ord-sz grey-text">
                                                    {baseCurrency} {changeCurrency(item.product.price,item.product.currency)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 col-lg-2 f8 text-danger p-0 mt-1 lh34"><Trash2 onClick={()=>removeFromWishlist(item.id)} /></div>
                                    </div>
                                    );
                                })}
                                </>
                            )} 


                        </div>

                </div>
            </div>
        </section>
    )
}

export default CustomerWishlist;