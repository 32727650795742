function Terms(){
   
    return (
        <main className="mtopcon normal-mt-sctn">
            <div className="container">
                <h2 className="text-center"><b>Terms & Conditions</b></h2>
                <p className="text-center">Updated December 18, 2024</p>                

                <h3>1. Introduction</h3>
                <p>Welcome to Kadhr Africa. These terms and conditions outline the rules and regulations for the use of Kadhr Africa's Website.</p>

                <h3>2. Acceptance of Terms</h3>
                <p>By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use Kadhr Africa's website if you do not accept all of the terms and conditions stated on this page.</p>

                <h3>3. Intellectual Property Rights</h3>
                <p>Unless otherwise stated, Kadhr Africa and/or its licensors own the intellectual property rights for all material on the website. All intellectual property rights are reserved. You may view and/or print pages from https://kadhr.com for your own personal use subject to restrictions set in these terms and conditions.</p>

                <h3>4. User Accounts</h3>
                <p>When creating an account, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

                <h3>5. Prohibited Uses</h3>
                <p>You are prohibited from using the site or its content:</p>
                <ul>
                <li>For any unlawful purpose.</li>
                <li>To solicit others to perform or participate in any unlawful acts.</li>
                <li>To violate any international, federal, provincial, or state regulations, rules, laws, or local ordinances.</li>
                <li>To infringe upon or violate our intellectual property rights or the intellectual property rights of others.</li>
                <li>To harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability.</li>
                <li>To submit false or misleading information.</li>
                <li>To upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet.</li>
                <li>To collect or track the personal information of others.</li>
                <li>To spam, phish, pharm, pretext, spider, crawl, or scrape.</li>
                <li>For any obscene or immoral purpose.</li>
                <li>To interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet.</li>
                </ul>

                <h3>6. Limitation of Liability</h3>
                <p>In no event shall Kadhr Africa, nor any of its officers, directors, and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and Kadhr Africa, including its officers, directors, and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.</p>

                <h3>7. Indemnification</h3>
                <p>You hereby indemnify to the fullest extent Kadhr Africa from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney's fees) arising out of or in any way related to your breach of any of the provisions of these Terms.</p>

                <h3>8. Severability</h3>
                <p>If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.</p>

                <h3>9. Variation of Terms</h3>
                <p>Kadhr Africa is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website.</p>

                <h3>10. Governing Law & Jurisdiction</h3>
                <p>These Terms will be governed by and construed in accordance with the laws of the country in which Kadhr Africa operates, and you submit to the non-exclusive jurisdiction of the state and federal courts located in that country for the resolution of any disputes.</p>

            </div>
        </main>
    )
}

export default Terms;
