import Sidebar from './sidebar';
import axios from 'axios';
import { useState,useEffect,useContext } from 'react';
function CustomerAddAddress(){    
    /*const baseUrl = process.env.REACT_APP_BASE_URL;
    var customer_id=localStorage.getItem('customer_id');
    const [ErrorMsg,setErrorMsg]=useState('');
    const [SuccessMsg,setSuccessMsg]=useState('');
    const [AddressFormData,setAddressFormData]=useState({  
        "address":'', 
        "customer":customer_id
    });

    const inputHandler =(event) => {
        setAddressFormData({
            ...AddressFormData,
            [event.target.name]:event.target.value
        });
    };

    const submitHandler =() => {
        const formData=new FormData();
            formData.append('address',AddressFormData.address);
            formData.append('customer',AddressFormData.customer);

            //SUBMIT DATA
            axios.post(baseUrl+'/address/',formData)
            .then(function (response){
                if(response.status != 201){
                    setSuccessMsg('');
                    setErrorMsg('Data not saved');
                }else{
                    setErrorMsg('');
                    setSuccessMsg('Data saved');
                    setAddressFormData({
                        'address':''
                    });
                }
                console.log(response);
            })
            .catch(function(error){
                console.log(error);
            });
    };

    const buttonEnable=(AddressFormData.address!='')


    return (
        <section className="container mtopcon dashb">
            <div className="row">              
                <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div> 
                <div className="col-12 col-lg-10 dash-max">
                    
                    <div className="card shadow-sm border border-secondary border-opacity-10">
                        <div className="card-header p-2">                            
                            <div className='row p-0 m-0 border-bottom'>
                                <p className='pl-3 m-0 col-7'><b>Add Address</b></p>
                            </div>
                        </div>
                        <div className="card-body"> 
                            {ErrorMsg && <p className='alert alert-danger'>{ErrorMsg}</p>}
                            {SuccessMsg && <p className='alert alert-success'>{SuccessMsg}</p>}       
                            <div className="mb-3">
                                <label htmlFor="InputEmail" className="form-label">Address</label>
                                <textarea className="form-control" name='address' onChange={inputHandler} value={AddressFormData.address}></textarea>
                            </div>
                            <button disabled={!buttonEnable} type="button" onClick={submitHandler} className="col-12 btn btn-primary">Proceed</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )*/
}

export default CustomerAddAddress;