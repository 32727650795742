import {Link} from 'react-router-dom';
import googleLogo from '../../google.png';
import fbLogo from '../../fb.png';
import axios from "axios";
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'; // For getting the current route
function CustomerLogin(props){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [formError,setFormError]=useState(false);
    const [errorMsg,seterrorMsg]=useState('');    
    const [loading, setLoading] = useState(false);
    const [loginFormData, setLoginFormData]=useState({
        "username":'',
        "password":''
    });

    const inputHandler =(event) => {
        setLoginFormData({
            ...loginFormData,
            [event.target.name]:event.target.value
        })
    };
    
    const submitHandler = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = {
            email: loginFormData.username,
            password: loginFormData.password,
        };
    
        try {
            const response = await axios.post(baseUrl + '/customer/login/', formData);
    
            if (response.data.bool) {
                const { access_token, refresh_token, user, id } = response.data;
    
                // Store secure details in local storage
                localStorage.setItem('access_token', access_token);
                localStorage.setItem('refresh_token', refresh_token);
                localStorage.setItem('customer_username', user);
                localStorage.setItem('customer_id', id);
                localStorage.setItem('customer_login', true);
                setFormError(false);
                setLoading(false);
                seterrorMsg('');
    
                // Redirect to the dashboard
                window.location.href = '/customer/dashboard';
            } else {
                setFormError(true);
                setLoading(false);
                seterrorMsg(response.data.message || 'Login failed');
            }
        } catch (error) {
            console.error('Login error:', error);
            setLoading(false);
            setFormError(true);
            seterrorMsg('Invalid login credentials. Kindly try again.');
        }
    };

    const location = useLocation(); // Get the current route
    
    useEffect(() => {
        const token = localStorage.getItem('vendor_token');
    
        if (!token && location.pathname !== '/customer/login') {
            // Redirect to login page if no token and not already on the login page
            window.location.href = '/customer/login';
        }
    
        if (token) {
            axios
                .get(baseUrl + '/vendor/validate-token/', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    if (!response.data.valid) {
                        // Invalid token
                        localStorage.clear();
                        window.location.href = '/customer/login';
                    }
                })
                .catch(() => {
                    // Clear storage on error
                    localStorage.clear();
                    window.location.href = '/customer/login';
                });
        }
    }, [location.pathname]); // Only re-run if the route changes

    const buttonEnable = loginFormData.username !== '' && loginFormData.password !== '';

    return (
        <section className="container mtopcon normal-mt-sctn">
            <div className="row">
                <div className="col-12 col-lg-4 reglogincon">
                    <div className="card shadow-none">
                        <div className="card-body">       
                            <h2 className='mb-3 text-center'><b>Welcome back</b></h2>                   
                                {formError &&
                                    <p className='text-danger text-center'>{errorMsg}</p>
                                }
                                <div className="mb-3">
                                    <label htmlFor="lnm" className="form-label mb-0 font9">Email</label>
                                    <input type="email" name='username' value={loginFormData.username} onChange={inputHandler} className="form-control" id="lnm"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="InputPassword1" className="form-label mb-0 font9">Password</label>
                                    <input type="password" name='password' value={loginFormData.password} onChange={inputHandler} className="form-control" id="InputPassword1"/>
                                </div>
                                <div className="mb-3 font9">
                                    <p><Link to="/customer/reset-link">Forgot Password? </Link></p>
                                </div>
                                <button type="button" disabled={!buttonEnable} onClick={submitHandler} className="col-12 btn btn-primary">{ loading === true ? <>Loading...</> : <>Continue</>}</button>     
                                <div className="mb-3 mt-3 font9">
                                    <p>Dont have an account? <Link to="/customer/register">Sign Up</Link></p>
                                    <p>Vendor Login? <Link to="/seller/login">Proceed</Link></p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CustomerLogin;