import {Link} from 'react-router-dom';
import axios from "axios";
import {useState, useEffect} from "react";
function VendorResetLink(props){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [csrfToken, setCsrfToken] = useState("");
    const [loading, setLoading] = useState(false);

    // Fetch CSRF token when component loads
    useEffect(() => {
        axios.get(`${baseUrl}/get-csrf-token/`, { withCredentials: true })
            .then(response => {
                setCsrfToken(response.data.csrfToken);
            })
            .catch();
    }, []);

    const submitHandler = () => {
        setLoading(true);
        axios.post(
            `${baseUrl}/vendor/reset-password/`, 
            { email }, 
            {
                withCredentials: true, // Include cookies
                headers: { "X-CSRFToken": csrfToken } // Attach CSRF token
            }
        )
        .then(response => {
            if (response.data.success) {
                setMessage(response.data.message);
                setError("");
                setLoading(false);
            } else {
                setError(response.data.message);
                setMessage("");
                setLoading(false);
            }
        })
        .catch(() => setError("Something went wrong"));
    };

    const buttonEnable=(email!='');

    return (
        <section className="container mtopcon normal-mt-sctn">
            <div className="row">
                <div className="col-12 col-lg-4 reglogincon">
                    <div className="card shadow-none">
                        <div className="card-body">     
                            <h3 className='mb-1 text-center'><b>Forgot Password?</b></h3>      
                            <div className="mb-3 font9 text-center"><p>Enter registered email to proceed</p></div>                  
                                {error && <p className="text-danger text-center">{error}</p>}
                                {message && <p className="text-success text-center">{message}</p>}
                                <div className="mb-3">
                                    <input
                                        type="email"
                                        className="form-control mb-2"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <button type="button" disabled={!buttonEnable} onClick={submitHandler} className="col-12 btn btn-primary">{ loading === true ? <>Loading...</> : <>Send Reset Link</>}</button>  
                                <div className="mb-2 mt-4 font9">
                                    <p>Dont have an account? <Link to="/seller/register">Get started</Link></p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VendorResetLink;