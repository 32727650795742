import Sidebar from './sidebar';
import axios from 'axios';
import { useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
function CustomerUpdateAddress(){    
    /*const baseUrl = process.env.REACT_APP_BASE_URL;
    const {address_id} = useParams();
    var customer_id=localStorage.getItem('customer_id');
    const [ErrorMsg,setErrorMsg]=useState('');
    const [SuccessMsg,setSuccessMsg]=useState('');
    const [AddressFormData,setAddressFormData]=useState({  
        "address":'', 
        "customer":customer_id
    });

    useEffect(()=>{
        fetchData(baseUrl+'/address/'+address_id);
    },[]);

    function fetchData(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
            setAddressFormData({
                'address':data.address,
                'customer':customer_id
            });
        });
    }

    const inputHandler =(event) => {
        setAddressFormData({
            ...AddressFormData,
            [event.target.name]:event.target.value
        });
    };

    const submitHandler =() => {
        const formData=new FormData();
        formData.append('address',AddressFormData.address);
        formData.append('customer',AddressFormData.customer);

        //SUBMIT DATA
        axios.put(baseUrl+'/address/'+address_id+'/',formData)
        .then(function (response){
            if(response.status != 200){
                setSuccessMsg('');
                setErrorMsg('Data not saved');
            }else{
                setErrorMsg('');
                setSuccessMsg('Data saved');
            }
            console.log(response);
        })
        .catch(function(error){
            console.log(error);
        });
    };

    const buttonEnable=(AddressFormData.address!='')


    return (
        <section className="container mtopcon dashb">
            <div className="row">            
                <div className="col-12 col-lg-2 dash-min mb-3">
                    <div className="nav-left-sidebar sidebar-dark pc">
                        <div className="slimScrollDiv" ><div className="menu-list" >
                            <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                <a className="d-xl-none d-lg-none" href="#"></a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <Sidebar/>
                                </div>
                            </nav>
                        </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                    </div>
                </div> 
                <div className="col-12 col-lg-10 dash-max">
                        <div className="row mb-3 ph-header-con">
                            <div className="col-8 pt-2"><h6 className="pageheader-title mb-3">Update Address</h6></div>
                        </div>
                    
                    <div className="card shadow-sm border border-secondary border-opacity-10">
                        <div className="card-body"> 
                            {ErrorMsg && <p className='alert alert-danger'>{ErrorMsg}</p>}
                            {SuccessMsg && <p className='alert alert-success'>{SuccessMsg}</p>}       
                            <div className="mb-3">
                                <label htmlFor="InputEmail" className="form-label">Update Address</label>
                                <textarea className="form-control" name='address' onChange={inputHandler} value={AddressFormData.address}></textarea>
                            </div>
                            <button disabled={!buttonEnable} type="button" onClick={submitHandler} className="col-12 btn btn-primary">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )*/
}

export default CustomerUpdateAddress;