import axios from "axios";
import orderlogo from '../../order.jpg';
import userlogo from '../../user.jpg';
import {Link} from 'react-router-dom';
import Sidebar from './sidebar';
import { ArrowLeft, CheckCircle, Plus, Trash } from 'react-feather';
import { useState,useContext,useEffect } from 'react';
import { FaFacebook, FaTwitter, FaWhatsapp, FaInstagram, FaCopy } from "react-icons/fa";
import { QRCodeCanvas } from "qrcode.react";
import useStoreSetupCheck from './useStoreSetupCheck';

function SellerShare(){
    const setupStatus = useStoreSetupCheck();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("access_token");
    const [copied, setCopied] = useState(false);
    const vendor_username = localStorage.getItem('vendor_username');
    
    var shopName  = vendor_username;
    var shopLink  = 'https://kadhr.com/'+vendor_username;

    const shareText = `Wassgud, ${shopName} here, we got some cool merchandise in our store, Check us out on ${shopLink}`;
    const socialLinks = {
        whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText)}`,
        twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`,
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shopLink)}`,
        instagram: "https://www.instagram.com/", // Instagram doesn't allow direct URL sharing
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shopLink);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset message after 2 seconds
    };
    return (
        <section className="container mtopcon dashb">         
            <div className="row">
                
                    <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max">
                        <div className="row ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title">Share Shop</h6></div>
                            <div className="col-5"><Link to="/seller/dashboard"><button className="btn btn-primary f85 float-right"><ArrowLeft/></button></Link></div>
                        </div>
                        
                        <div className="row mb-3">
                            <div className="container d-flex justify-content-center mt-5">
                                <div className="card text-center shadow-xs p-4 rounded" style={{ maxWidth: "400px", width: "100%" }}>
                                    {/* Shop Name */}
                                    <h3 className="fw-bold">{shopName}</h3>

                                    {/* QR Code */}
                                    <div className="my-3">
                                    <QRCodeCanvas value={shopLink} size={200} />
                                    </div>

                                    {/* Copy Link */}
                                    <div className="mt-4 mb-3">
                                        <button className="col-12 btn btn-outline-dark d-flex align-items-center gap-2" style={{ borderWidth: "4px" }} onClick={copyToClipboard}>
                                            <FaCopy /> {copied ? "Copied!" : "Copy Link"}
                                        </button>
                                    </div>
                                    <hr/>
                                    <span className="text-center font9">Share On</span>
                                    {/* Share Buttons */}
                                    <div className="d-flex justify-content-around mt-3 font9 pb-2">
                                        <a href={socialLinks.whatsapp} target="_blank" rel="noopener noreferrer" className="btn btn-success ms-2">
                                            <FaWhatsapp size={24} />
                                        </a>
                                        <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer" className="btn btn-info text-white ms-2">
                                            <FaTwitter size={24} />
                                        </a>
                                        <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer" className="btn btn-primary ms-2">
                                            <FaFacebook size={24} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>                       


                </div>
            </div>


        </section>
    )
}

export default SellerShare;