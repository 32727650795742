import React, { createContext, useState, useEffect } from "react";
import { fetchExchangeRates, getStoredExchangeRates, getStoredBaseCurrency } from "./components/exchangeRateService";

export const ExchangeRateContext = createContext();

export const ExchangeRateProvider = ({ children }) => {
    const [exchangeRates, setExchangeRates] = useState(getStoredExchangeRates());
    const [baseCurrency, setBaseCurrency] = useState(getStoredBaseCurrency());

    useEffect(() => {
        if (!exchangeRates) {
            fetchExchangeRates(baseCurrency).then((rates) => {
                if (rates) setExchangeRates(rates);
            });
        }
    }, [baseCurrency]);

    const updateBaseCurrency = (newCurrency) => {
        setBaseCurrency(newCurrency);
        fetchExchangeRates(newCurrency).then((rates) => {
            if (rates) setExchangeRates(rates);
        });
    };

    return (
        <ExchangeRateContext.Provider value={{ exchangeRates, baseCurrency, updateBaseCurrency }}>
            {children}
        </ExchangeRateContext.Provider>
    );
};
