import Sidebar from './sidebar';
import { ArrowDown, Mail, Map, MapPin, Phone } from 'react-feather';
import { useState,useContext,useEffect,useRef } from 'react';
import { useParams } from "react-router-dom";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import api from "../api";
import { ExchangeRateContext } from '../../ExchangeRateContext';
import useStoreSetupCheck from './useStoreSetupCheck';
function SellerOrderDetails(){
    const setupStatus = useStoreSetupCheck();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl0 = process.env.REACT_APP_BASE_URL_IMG;
    const {order_id} = useParams();
    const vendor_username = localStorage.getItem('vendor_username');
    const [products, setProducts] = useState();
    const [timestamp, setTimestamp] = useState('');
    const [UpdateStatus, setUpdateStatus] = useState(false);
    const [UpdateStatus0, setUpdateStatus0] = useState(false);
    const [UpdateStatus01, setUpdateStatus01] = useState(false);
    const [OrderItems,setOrderItems] = useState({
        "orderid":"",
        "date":"",
        "cust_name":"",
        "cust_phone":"",
        "del_loc":"",
        "del_fee":"",
        "pay_sts":"",
        "pay_mode":"",
        "ttl":"",
    });    
    const { exchangeRates, baseCurrency } = useContext(ExchangeRateContext);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("access_token");
    const [VendorData, setVendorData] = useState(() => {
        const savedData = localStorage.getItem('VendorData');
        return savedData ? JSON.parse(savedData) : {
            'totalProducts': 0,
            'totalOrders': 0,
            'totalCustomers': 0,
            'name': '',
            'loc': '',
        };
    });
    const fetchData0 = async () => {
        try {
          const response = await api.get(`${baseUrl}/vendor-dashboard/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
          setVendorData(response.data);
        } catch (err) {
          //console.error("Error fetching products:", err.toJSON());
        }
    };

     // Update localStorage whenever VendorData changes
     useEffect(() => {
        localStorage.setItem('VendorData', JSON.stringify(VendorData));
    }, [VendorData]);

    function currencyChange(price,currency){
        // Convert price from the product's original currency to the selected base currency
        const productExchangeRate = exchangeRates[currency] || 1;
        const baseExchangeRate = exchangeRates[baseCurrency] || 1;

        // Calculate the converted price
        const convertedPrice = ((price / productExchangeRate) * baseExchangeRate).toFixed(2);
        
        return convertedPrice;
    }

    useEffect(() => {
        const now = new Date();
        const dateStr = now.toLocaleDateString();
        const timeStr = now.toLocaleTimeString();
        const fullTimestamp = `${dateStr} ${timeStr}`;
        fetchData();
        fetchData0();
        setTimestamp(fullTimestamp);
    }, []); 

    const fetchData = async () => {
        try {
            const response = await api.get(baseUrl+'/itemsorder/'+order_id+'/', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data;
            if(data.count>0){
                setOrderItems({
                    "orderid":data.results[0].order.id,
                    "date":data.results[0].order.order_time.split('T')[0],
                    "cust_name":data.results[0].order.cust_name,
                    "cust_phone":data.results[0].order.cust_phone,
                    "del_loc":data.results[0].order.delivery_loc,
                    "del_fee":data.results[0].order.delivery_fee,
                    "pay_sts":data.results[0].order.payment_status,
                    "pay_mode":data.results[0].order.payment_mode,
                    "ttl":data.results[0].order.total_amount,
                    "ttl_usd":data.results[0].order.total_usd_amount,
                    "order_status":data.results[0].order.order_status,
                 });
                 setProducts(data.results);
            }else{window.location.href='/error404'; }
        } catch (error) {
            //setError('Failed to fetch customer data. Please try again later.');
        } finally {
            //setLoading(false);
        }
    };
    
    function changeOrderStatus(order_id, ord_status) {
        const timestamp = new Date().toISOString();  // Current timestamp
        if(OrderItems.pay_mode=="Gateway"){
            const formData=new FormData();
            formData.append('order_status',ord_status);
            formData.append('delivery_date',timestamp);
            api.patch(baseUrl + '/order-modify/' + order_id,formData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
            .then(response => {
                fetchData();  // Refresh orders
                setUpdateStatus(true);
            })
            .catch(error => {
                console.error("Error updating order status:", error);
            });
        }else{
            const formData=new FormData();
            formData.append('order_status',ord_status);
            formData.append('delivery_date',timestamp);
            formData.append('trackingId',null);
            api.patch(baseUrl + '/order-modify/' + order_id,formData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
            .then(response => {
                fetchData();  // Refresh orders
                setUpdateStatus(true);
            })
            .catch(error => {
                console.error("Error updating order status:", error);
            });         
        }        
    }

    function changePayStatus(order_id, ord_status) {
        const timestamp = new Date().toISOString();  // Current timestamp
        const formData0=new FormData();
        formData0.append('payment_status',ord_status);
        formData0.append('trans_ref',null);
        formData0.append('paymentMethod',OrderItems.pay_mode);
        formData0.append('code',null);
        formData0.append('updated_at',timestamp);   
        api.patch(baseUrl + '/order-modify/' + order_id,formData0,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
        .then(response => {
            fetchData();  // Refresh orders
            setUpdateStatus0(true);
        })
        .catch(error => {
            console.error("Error updating order status:", error);
        });
    }

    function deleteOrder(order_id) {
        api.delete(baseUrl + '/order-delete/' + order_id, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
            setUpdateStatus01(true);
            setTimeout(() => {
                window.location.href = "/seller/orders";
            }, 3000);
        })
        .catch(error => {
            //console.error("Error deleting order:", error);
        });
    }
    
    const printRef = useRef();

    const handleDownloadPdf = async () => {
        const element = printRef.current;

        printRef.current.style.display = 'block';
        
        // Capture the div content as canvas
        const canvas = await html2canvas(element, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        
        // Define A4 size in pixels (595 x 842 for portrait)
        const pdf = new jsPDF('p', 'px', [595, 842]);
        
        // Scale the image to fit within A4 dimensions
        const pdfWidth = 595;
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        
        // Download the PDF
        pdf.save('receipt.pdf');

        
        printRef.current.style.display = 'none';
        setLoading(false);
    };
    return (
        <section className="container mtopcon dashb">           
            <div className="row">
                
                    <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max">
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Order Details </h6></div>
                            <div className="col-5"><button className="btn text-white bg-dark f85 float-right"  onClick={handleDownloadPdf}><ArrowDown className="topheadersvg"/> <span>{loading === true ? <>Loading...</> : <>Receipt</>}</span></button></div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6 orderdtls-info">
                                <div className="row bg-white br-10 ord-div bx-shadow orddetailspopcon zindx">  
                                    <div className="row p-0 m-0">
                                        <div className="row ordbgrw pb-3">
                                            <div className="col-6 ord-sz1 pt-2">
                                                <div className="row p-0 m-0"><div className="col-12 grey-text font9">Order Id</div><div className="col-12">#{OrderItems.orderid}</div></div>
                                            </div>
                                            <div className="col-6 ord-sz1 pt-2">
                                                <div className="row p-0 m-0"><div className="col-12 grey-text font9">Date</div><div className="col-12">{OrderItems.date}</div></div>
                                            </div>
                                            <div className="col-6 ord-sz1 pt-2">
                                                <div className="row p-0 m-0"><div className="col-12 grey-text font9">Customer Name</div><div className="col-12">{OrderItems.cust_name}</div></div>
                                            </div>
                                            <div className="col-6 ord-sz1 pt-2">
                                                <div className="row p-0 m-0"><div className="col-12 grey-text font9">Contact</div><div className="col-12">{OrderItems.cust_phone}</div></div>
                                            </div>
                                            <div className="col-6 ord-sz1 pt-2">
                                                <div className="row p-0 m-0"><div className="col-12 grey-text font9">Delivery Location</div><div className="col-12">{OrderItems.del_loc}</div></div>
                                            </div>
                                            <div className="col-6 ord-sz1 pt-2">
                                                <div className="row p-0 m-0"><div className="col-12 grey-text font9">Delivery Fee:</div><div className="col-12">{OrderItems.del_fee}</div></div>
                                            </div>
                                            <div className="col-6 ord-sz1 pt-2">
                                                <div className="row p-0 m-0"><div className="col-12 grey-text font9">Delivery Status:</div>
                                                    <div className="col-12">
                                                            {OrderItems.order_status == false && <><span className="badge rounded-pill text-bg-warning">Processing</span></>}
                                                            {OrderItems.order_status == true && <><span className="badge rounded-pill text-bg-success">Complete</span></>} 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 ord-sz1 pt-2">
                                                <div className="row p-0 m-0"><div className="col-12 grey-text font9">Payment Mode:</div><div className="col-12">{OrderItems.pay_mode}</div></div>
                                            </div>
                                            <div className="col-6 ord-sz1 pt-2">
                                                <div className="row p-0 m-0"><div className="col-12 grey-text font9">Payment Status:</div><div className="col-12">
                                                        {OrderItems.pay_sts !== "Complete" && <><span className="badge rounded-pill text-bg-warning">{OrderItems.pay_sts}</span></>}
                                                        {OrderItems.pay_sts == "Complete" && <><span className="badge rounded-pill text-bg-success">Complete</span></>} 
                                                </div></div>
                                            </div>
                                            <div className="col-6 ord-sz1 pt-2">
                                                <div className="row p-0 m-0"><div className="col-12 grey-text font9">Total:</div>
                                                    <div className="col-12">
                                                        <span className='cart-pro-ttl'>{baseCurrency} {Intl.NumberFormat().format(currencyChange(OrderItems.ttl))} </span>                                                  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="row bg-white br-10 ord-div bx-shadow orddetailspopcon zindx">  
                                {loading === true ? <>Loading...</> : <>
                                            
                                    {products &&
                                        products.map((item,index)=>{ 
                                            return <>
                                            <div className="row ord-bg pt-2 pb-2">
                                                <div className="col-3 p-0"><div className="ord-img"><img src={`${baseUrl0}/${item.product.product_imgs[0].image}`} className='w-100 h-100'/></div></div>
                                                <div className="col-9 pl-0 pr-0">
                                                    <div className="row">
                                                        <div className="col-8 ord-sz mb-1">{item.product.title}</div>
                                                        <div className="col-4 ord-sz mb-1 grey-text">Qty: {item.qty}</div>
                                                        <div className="col-6 ord-sz mb-1 grey-text">Size: {item.size}</div>
                                                        <div className="col-6 ord-sz mb-1 grey-text">
                                                            <span className='cart-pro-ttl'>{baseCurrency} {Intl.NumberFormat().format(currencyChange(item.price,item.currency))} </span>                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                        })
                                    }

                                    <div className="row m-0 p-0 ord-btns mt-3">                      
                                        {OrderItems.order_status == false && <><div className="col-12 mb-3"><button className="btn orange f8 text-white br-18 w-100"  data-bs-toggle="modal" data-bs-target="#confirmdelModal11">Mark as Delivered</button></div></>}                                         
                                        {OrderItems.pay_sts !== "Complete" && <><div className="col-12 mb-3"><button className="btn btn-dark f8 text-white br-18 w-100" data-bs-toggle="modal" data-bs-target="#confirmdelModal110">Mark as Paid</button></div></>}                                         
                                        
                                        <div className="col-12"><button className="btn btn-white f8 text-dark br-18 w-100" data-bs-toggle="modal" data-bs-target="#confirmdelOrd"><u>Remove Order</u></button></div>
                                    </div>
                                
                                </>}

                                </div>
                                
                            </div>

                            
                            <div className="col-12 orderdtls-info">
                                <div ref={printRef} style={{ padding: 20, backgroundColor: 'white', display: 'none'}}>
                                    <div className="row">
                                            <div className="col-3">
                                                <div className="mt-2 p-0" style={{ height: 80, width: '100%'}}>
                                                    <button className="btn btn-dark p-3 tc" style={{ width: "100%", height: "100%" }}>{vendor_username}</button>
                                                </div>
                                            </div>
                                            <div className="col-6 text-center">
                                                <ul>
                                                    <li className="tc"><b>{vendor_username}</b></li>
                                                    <li className="tcone pt-1"><MapPin width="18px" height="18px"/> {VendorData.loc}</li>
                                                    <li className='pt-1'><Phone width="18px" height="18px"/> {VendorData.mobile}</li>
                                                    <li className='pt-1'> <Mail width="18px" height="18px"/> {VendorData.email}</li>
                                                </ul>
                                            </div>
                                            <div className="col-3 text-right">
                                                <b className="font2em">
                                                    RECEIPT<br/>
                                                    #{OrderItems.orderid}
                                                </b>
                                            </div>
                                        <div className="mt-4"></div>
                                        <hr className='mb-4'/>
                                        <div className="col-6">
                                            <div className="row ordbgrw pb-3">
                                                <h6 className="col-12 pl-nw0"><b>BILLED TO:</b></h6>
                                                <div className="col-6 ord-sz1 pt-2">
                                                    <div className="row p-0 m-0"><div className="col-12 grey-text">Customer Name</div><div className="col-12">{OrderItems.cust_name}</div></div>
                                                </div>
                                                <div className="col-6 ord-sz1 pt-2">
                                                    <div className="row p-0 m-0"><div className="col-12 grey-text">Contact</div><div className="col-12">{OrderItems.cust_phone}</div></div>
                                                </div>
                                                <div className="col-6 ord-sz1 pt-2">
                                                    <div className="row p-0 m-0"><div className="col-12 grey-text">Delivery Location</div><div className="col-12">{OrderItems.del_loc}</div></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                                <h6 className="col-12 pl-nw"><b>ORDER SUMMARY:</b></h6>
                                            <div className="row ordbgrw pb-3">
                                                <div className="col-6 ord-sz1 pt-2">
                                                    <div className="row p-0 m-0"><div className="col-12 grey-text">Order ID</div><div className="col-12">#{OrderItems.orderid}</div></div>
                                                </div>
                                                <div className="col-6 ord-sz1 pt-2">
                                                    <div className="row p-0 m-0"><div className="col-12 grey-text">Date</div><div className="col-12">{OrderItems.date}</div></div>
                                                </div>
                                                <div className="col-6 ord-sz1 pt-2">
                                                    <div className="row p-0 m-0"><div className="col-12 grey-text">Delivery Status:</div>
                                                    <div className="col-12">
                                                            {OrderItems.order_status == false && <><span className="badge rounded-pill text-bg-warning">Processing</span></>}
                                                            {OrderItems.order_status == true && <><span className="badge rounded-pill text-bg-success">Complete</span></>} 
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="col-6 ord-sz1 pt-2">
                                                    <div className="row p-0 m-0"><div className="col-12 grey-text">Payment Status:</div><div className="col-12">
                                                            {OrderItems.pay_sts !== "Complete" && <><span className="badge rounded-pill text-bg-warning">{OrderItems.pay_sts}</span></>}
                                                            {OrderItems.pay_sts == "Complete" && <><span className="badge rounded-pill text-bg-success">Complete</span></>} 
                                                    </div></div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 mt-4">
                                                <div className="table-responsive">
                                                    <table className="table align-middle table-nowrap table-centered mb-0 invcpdf">
                                                            <thead>
                                                                <tr className="bg-grey">
                                                                    <th><div className="thmin">#</div></th>
                                                                    <th><div className="thmin">DESCRIPTION</div></th>
                                                                    <th className="text-end"><div className="thmin">SIZE</div></th>
                                                                    <th className="text-end"><div className="thmin">QTY</div></th>
                                                                    <th className="text-end" ><div className="thmin">PRICE</div></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            
                                                            {products &&
                                                                products.map((item,index)=>{ 
                                                                    return <>                                                            
                                                                            <tr key={index}>
                                                                                <th scope="row">{index+1}</th>
                                                                                <td>
                                                                                    <b>{item.product.title}</b>
                                                                                </td>
                                                                                <td className="text-end">{item.size}</td>
                                                                                <td className="text-end">{item.qty}</td>
                                                                                <td className="text-end">{baseCurrency} {Intl.NumberFormat().format(currencyChange(item.price,item.currency))}</td>
                                                                            </tr>
                                                                        </>
                                                                })
                                                            }
                                                                    <tr>
                                                                        <td colSpan="3" className="border-bottom-none"></td>
                                                                        <td className="text-right b1pxgrey" colSpan="1"><b>DELIVERY FEE<br/>TOTAL</b></td>
                                                                        <td className="text-end b1pxgrey">{baseCurrency} {OrderItems.del_fee}<br/>{baseCurrency} {Intl.NumberFormat().format(currencyChange(OrderItems.ttl))}</td>
                                                                    </tr>
                                                            </tbody>
                                                    </table>
                                                </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>


                </div>
            </div>

            <div className="modal fade" id="confirmdelModal11" tabIndex="-1" aria-labelledby="confirmdelModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header p-2">
                        <h1 className="modal-title fs-5" id="confirmdelModalLabel">Confirm</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        {UpdateStatus==true && <>Updated Successfully</>}
                        {UpdateStatus==false && <>Mark Order as Delivered?</>}
                    </div>
                    <div className="modal-footer p-2">
                        {UpdateStatus==true && <><button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button></>}
                        {UpdateStatus==false && <><button type="button" className="btn btn-danger" onClick={() => changeOrderStatus(OrderItems.orderid,"True")}>Proceed</button></>}
                    </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="confirmdelModal110" tabIndex="-1" aria-labelledby="confirmdelModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header p-2">
                        <h1 className="modal-title fs-5" id="confirmdelModalLabel">Confirm</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        {UpdateStatus0==true && <>Updated Successfully</>}
                        {UpdateStatus0==false && <>Mark Order as Paid?</>}
                    </div>
                    <div className="modal-footer p-2">
                        {UpdateStatus0==true && <><button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button></>}
                        {UpdateStatus0==false && <><button type="button" className="btn btn-danger" onClick={() => changePayStatus(OrderItems.orderid,"Complete")}>Proceed</button></>}
                    </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="confirmdelOrd" tabIndex="-1" aria-labelledby="confirmdelModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header p-2">
                        <h1 className="modal-title fs-5" id="confirmdelModalLabel">Confirm</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        {UpdateStatus01==true && <>Deleted Successfully, </>}
                        {UpdateStatus01==false && <>Delete this order?</>}
                    </div>
                    <div className="modal-footer p-2">
                        {UpdateStatus01==true && <><button type="button" className="btn btn-primary">Redirecting....</button></>}
                        {UpdateStatus01==false && <><button type="button" className="btn btn-danger" onClick={() => deleteOrder(OrderItems.orderid)}>Proceed</button></>}
                    </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default SellerOrderDetails;