import Sidebar from './sidebar';
import { useState,useEffect } from 'react';
import Chart from "react-apexcharts";
function SellerMonthlyReports(){
    const [OrderItems,setOrderItems]=useState([]);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const vendorId = localStorage.getItem('vendor_id');
    const [Dates,setDates]=useState([]);
    const [Data,setData]=useState([]);

    useEffect(()=>{
        fetch_report(`${baseUrl}/vendor/${vendorId}/`);
    },[]);
    
    function fetch_report(baseurl){
        fetch(baseurl)
        .then((response) => response.json())
        .then((data) => {
           setDates(data.show_chart_monthly_orders.dates);
           setData(data.show_chart_monthly_orders.data);
        });
    }
    const chartOptions = {
        options: {
            chart: {
              id: "basic-bar"
            },
            xaxis: {
              categories: Dates
            }
          },
          series: [
            {
              name: "Orders",
              data: Data
            }
          ]
    };
    const chartElement=<Chart options={chartOptions.options} series={chartOptions.series} type="bar" width="500"
  />
    return (
        <section className="container mtopcon dashb">
            <div className="row">
                <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max">
                    <h4>Daily Reports</h4>
                    <div className="row mt-2">
                        {chartElement}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SellerMonthlyReports;