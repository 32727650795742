import axios from "axios";
import orderlogo from '../../order.jpg';
import userlogo from '../../user.jpg';
import {Link} from 'react-router-dom';
import Sidebar from './sidebar';
import { CheckCircle, Plus } from 'react-feather';
import { useState,useEffect } from 'react';
import api from "../api";
function SellerWithdraw(){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("access_token");
    const [OrderItems,setOrderItems]=useState([]);
    const [selectedPaymentMode, setSelectedPaymentMode] = useState('');
    const [PayDiv1,setPayDiv1]=useState(false);
    const [PayDiv2,setPayDiv2]=useState(false);
    const [PayDiv3,setPayDiv3]=useState(false);
    const [PayDiv4,setPayDiv4]=useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [pymtFormData, setPymtFormData]=useState({
        "pay_mode_desc":'',
        "pay_mode_desc_paybill":'',
        "acc_no":'',
    });
    const [pymtFormData0, setPymtFormData0]=useState({
        "pay_mode_desc":'',
        "pay_mode_desc_paybill":'',
        "acc_no":'',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [VendorCurrency,setVendorCurrency]=useState([]);     
    const [strbal, setBalance] = useState('');
    const [strdev, setDeviation] = useState('');
    const [amount, setAmount] = useState('');
    const [pass, setPass] = useState('');
    const [loadingWithdraw, setLoadingWithdraw] = useState(false);
    const [message, setMessage] = useState(null);
    const [message0, setMessage0] = useState(null);

    useEffect(()=>{
        fetchData();
        fetchData0();
    },[]);
    const fetchData = async () => {
        try {
            const response = await api.get("/vendor/", {
            headers: {
                Authorization: `Bearer ${token})}`,
            },
            });
            setSelectedPaymentMode(response.data.pay_mode);
            setPymtFormData0({
                    "pay_mode_desc":response.data.pay_mode_desc,
                    "pay_mode_desc_paybill":response.data.pay_mode_desc_paybill,
                    "acc_no":response.data.acc_no,
            });

        } catch (error) {
            //console.error("Error fetching products:", err.toJSON());
        }
    }
    const fetchData0 = async () => {
        try {
          const response = await api.get(baseUrl+"/vendor-orderitems/", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setOrderItems(response.data.order_items);
          setBalance(response.data.vendor_balance);
          setDeviation(response.data.weekly_deviation);
          setVendorCurrency(response.data.currency);
        } catch (err) {
          //console.error("Error fetching products:", err);
          setError("Failed to fetch products. Please try again.");
        }finally {
            setLoading(false);
        }
    };

    const handleRadioChange = async (event) => {
        const newPaymentMode = event.target.value;
        setSelectedPaymentMode(newPaymentMode);

        try {
            const response = await api.patch("/vendor/", 
                { pay_mode: newPaymentMode }, // Data payload
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setErrorMsg('');
            setSuccessMsg('Payment mode updated successfully!');
        } catch (error) {
            setErrorMsg('Failed to update payment mode. Please try again.');
            setSuccessMsg('');
        }

    };
    const inputHandler =(event) => {
        setPymtFormData({
            ...pymtFormData,
            [event.target.name]:event.target.value
        })
    };
    const submitHandler = async (event) => {
        var paybill=0;
        if(pymtFormData.pay_mode_desc == ""){
            setPayDiv1(true);setPayDiv2(false);setPayDiv3(false);setPayDiv4(false);
        }else if(pymtFormData.pay_mode_desc_paybill.length < 6 && pymtFormData.pay_mode_desc == "Bank"){
            setPayDiv1(false);setPayDiv2(true);setPayDiv3(false);setPayDiv4(false);
        }else if(pymtFormData.acc_no.length < 12){
            setPayDiv1(false);setPayDiv2(false);setPayDiv3(true);setPayDiv4(false);
        }else{
            if(pymtFormData.pay_mode_desc=="Bank"){paybill=pymtFormData.pay_mode_desc_paybill;}
            const formData=new FormData();
            formData.append('pay_mode_desc',pymtFormData.pay_mode_desc);
            formData.append('pay_mode_desc_paybill',paybill);
            formData.append('acc_no',pymtFormData.acc_no);

            try {
                const response = await api.patch("/vendor/", 
                    formData, // Data payload
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                    setPayDiv1(false);setPayDiv2(false);setPayDiv3(false);setPayDiv4(true);
                    setPymtFormData0({
                        "pay_mode_desc":response.data.pay_mode_desc,
                        "pay_mode_desc_paybill":response.data.pay_mode_desc_paybill,
                        "acc_no":response.data.acc_no,
                   });
                   setErrorMsg('');
                   setSuccessMsg('Payment details updated successfully!');
            } catch (error) {
                setErrorMsg('Failed to update payment mode. Please try again.');
                setSuccessMsg('');
            }

        }
    };
    const getVendorCurrency = () => {
        return localStorage.getItem("VendorCurrency");
    };
    function formatDate(dateString) {
        const date = new Date(dateString);
        const month = date.toLocaleDateString('en-US', { month: 'short' }); // "Nov"
        const day = date.getDate(); // 1
        const time = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        }).toLowerCase(); // "11:01 pm"
    
        return `${month} ${day}, ${time.replace(':', '.')}`; // "Nov 01 11.01pm"
    }
    // Filter quotations based on the search term
    const filteredResults = OrderItems.filter((product) => {
        const search = searchTerm.toLowerCase();
        return (
            (product.order.cust_name && product.order.cust_name.toLowerCase().includes(search)) ||
            (product.order.total_amount && product.order.total_amount.toLowerCase().includes(search))
        );
    });

    const handleWithdraw = async () => {
        if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
          setMessage0('Please enter a valid amount.');
          return;
        }
    
        const confirm = window.confirm(`Withdraw KES ${amount}? This includes a KES 30 transaction fee.`);
        if (!confirm) return;
    
        try {
          setLoadingWithdraw(true);
          const formData = new FormData();
          formData.append('amount', amount);
          formData.append('password', pass);
    
          const response = await axios.post(baseUrl+'/withdraw/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
    
          setMessage(response.data.msg);
        } catch (err) {
          setMessage0('An error occurred. Please try again.');
        } finally {
          setLoadingWithdraw(false);
        }
    };
    setTimeout(() => {
        setErrorMsg('');
        setSuccessMsg('');
    }, 5000);
    return (
        <section className="container mtopcon dashb">         
            <div className="row">
                
                    <div className="col-12 col-lg-2 dash-min mb-3">
                        <div className="nav-left-sidebar sidebar-dark pc">
                            <div className="slimScrollDiv" ><div className="menu-list" >
                                <nav className="navbar navbar-expand-lg navbar-light nav-l-con">
                                    <a className="d-xl-none d-lg-none" href="#"></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <Sidebar/>
                                    </div>
                                </nav>
                            </div><div className="slimScrollBar"></div><div className="slimScrollRail"></div></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10 dash-max">
                        <div className="row mb-3 ph-header-con">
                            <div className="col-7 pt-2"><h6 className="pageheader-title mb-3">Manage Finances </h6></div>
                        </div>
                        
                        <div className="row mb-3">
                            <div className="col-12 col-lg-4">
                            <div className="card shadow-sm border border-secondary border-opacity-10">
                                        <div className="card-body pymtdiv"> 
                                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-coll2" aria-expanded="false" aria-controls="flush-coll2">                                                            
                                                            <label htmlFor="lnm" className="form-label mb-0 font9"><b>Payment Method:</b> {selectedPaymentMode === '0' && <>Manual</>}{selectedPaymentMode === '1' && <>Automated</> }</label>
                                                        </button>
                                                        </h2>
                                                        <div id="flush-coll2" className="accordion-collapse collapse paymethod" data-bs-parent="#accordionFlushExample">
                                                            <div className="accordion-body">
                                                                {successMsg && <p className="text-success mt-4 text-center"><b>{successMsg}</b></p> } 
                                                                {errorMsg && <p className="text-danger mt-4 text-center"><b>{errorMsg}</b></p> }   
                                                            <div className="row pt-4">
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="card border-top-secondary btp1">
                                                                        <div className="card-body">
                                                                            <h5>Manual Payment</h5>
                                                                                <p className="font9">Your customers manually pay directly to your Mpesa/Bank Account.</p>
                                                                            <div className="metric-value d-inline-block">
                                                                                <input
                                                                                    type="radio"
                                                                                    value="0"
                                                                                    checked={selectedPaymentMode === '0'}
                                                                                    onChange={handleRadioChange}
                                                                                />
                                                                            </div>
                                                                            <div className="metric-label d-inline-block float-right text-success font-weight-bold">
                                                                                <span className="icon-circle-small icon-box-xs text-success bg-success-light"></span><span className="ml-1">0%</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="card border-top-secondary btp2">
                                                                        <div className="card-body">
                                                                            <h5>Automated Payment</h5>
                                                                                <p className="font9">Your customers get prompted automatically then you withdraw directly from your Kadhr Account.</p>
                                                                            <div className="metric-value d-inline-block">
                                                                                <input
                                                                                    type="radio"
                                                                                    value="1"
                                                                                    checked={selectedPaymentMode === '1'}
                                                                                    onChange={handleRadioChange}
                                                                                />
                                                                            </div>
                                                                            <div className="metric-label d-inline-block float-right text-success font-weight-bold">
                                                                                <span className="icon-circle-small icon-box-xs text-success bg-success-light"></span><span className="ml-1">4.8%</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="card shadow-sm border border-secondary border-opacity-10">
                                    {pymtFormData0.acc_no != '0' && <>
                                        <div className="card-header font9">
                                            <div className='row p-0 m-0'>
                                                <p className='pl-3 m-0 col-12'><b>Send Money </b></p>
                                            </div>
                                        </div> 
                                        <div className="card-body pt-1"> 
                                                <div className="row ordbgrw pb-1">
                                                    <div className="col-9 ord-sz1 pt-2"><font className="grey-text">Available Balance</font><br/> <h3>{getVendorCurrency()}. {strbal}</h3></div>
                                                    <div className="col-3 ord-sz1 pt-2"><font className="grey-text">Growth</font><br/>                                                         
                                                        {strdev >= '0' && <><font className='text-success'>{strdev}%</font></>}
                                                        {strdev < '0' && <><font className='text-danger'>{strdev}%</font></>}
                                                    </div>
                                                </div> 
                                                <div className="mb-3">      
                                                        <label htmlFor="lnm" className="form-label mb-0"><b>Payout to</b></label>
                                                        <div className="grey-text font9 mb-2">Select preferred payment account</div>
                                                        <select className="form-control font9 ht40" >
                                                            <option value={pymtFormData0.acc_no}>{pymtFormData0.pay_mode_desc} - {pymtFormData0.acc_no}</option>
                                                        </select>
                                                        <div className="input-group mt-3">
                                                            <span className="input-group-text font9">{getVendorCurrency()}</span>
                                                            <input type="number" className="form-control font9 ht40" placeholder="Amount" value={amount} onChange={(e) => setAmount(e.target.value)}/>
                                                        </div>
                                                        <button type="button" className="col-12 btn btn-primary mt-4"  data-bs-toggle="modal" data-bs-target="#withdrw">Proceed</button>
                                                    
                                                    <button type="button" className="col-12 btn btn-light mt-4 addpymt" data-bs-toggle="modal" data-bs-target="#addaccount"><Plus /> Update Payment Details</button>
                                                </div>              

                                                <div className="accordion accordion-flush mt-40 bg-white br-10" id="accordionFlushExample1">
                                                    <div className="accordion-item">
                                                        <h6 className="accordion-header">
                                                        <button className="accordion-button collapsed grey-text p-0 font9" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                                                            View transaction fees
                                                        </button>
                                                        </h6>
                                                        <div id="flush-collapseOne1" className="accordion-collapse collapse mt-3" data-bs-parent="#accordionFlushExample1">
                                                            <div className="accordion-body font-9 p-0 mt-2 wthdrwtbl">
                                                                <table className="table table-bordered rounded-3 m-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Min</th>
                                                                            <th scope="col">Max</th>
                                                                            <th scope="col">Charge ({getVendorCurrency()})</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th scope="row">1</th>
                                                                            <td>0</td>
                                                                            <td>100</td>
                                                                            <td>0</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row">2</th>
                                                                            <td>101</td>
                                                                            <td>1500</td>
                                                                            <td>7</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row">3</th>
                                                                            <td>1501</td>
                                                                            <td>5000</td>
                                                                            <td>11</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row">4</th>
                                                                            <td>5001</td>
                                                                            <td>150,000</td>
                                                                            <td>20</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div></>}
                                        {pymtFormData0.acc_no == '0' && <>
                                            <button type="button" className="col-12 btn btn-success addpymt" data-bs-toggle="modal" data-bs-target="#addaccount"><Plus /> Update Payment Details</button>
                                        </>}
                                    </div>
                            </div>
                            <div className="col-12 col-lg-8 orderscontainer">                                
                                    <hr className="hddsktp0"></hr>
                                    <div className="mb-4">
                                        <h6 className="pageheader-title mb-3">Transactions History </h6>
                                        <div className="row mb-3">
                                            <div className="col-12"><input
                                                className="filter2 border me-2 w-100"
                                                type="search"
                                                placeholder="Search Transactions"
                                                aria-label="Search"
                                                onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on keyup
                                            /></div>
                                        </div>
                                                                               

                                        {filteredResults.length === 0 ? (
                                            <p>No orders found.</p>
                                        ) : (
                                            <>
                                                {// Sort OrderItems by order_time in descending order (newest first)
                                            filteredResults.sort((a, b) => new Date(b.order.order_time) - new Date(a.order.order_time))
                                            .map((item, index) => { 
                                                return (
                                                    <Link to={`/seller/order/${item.order.order_id}`} className='text-dark' key={index}>
                                                        <div className="row ord-bg bg-white p-2 pointer hvr bx-shadow">
                                                            <div className="w-auto me-3 rownumsn p-0"><div className="pt-2 pl-2">{index+1}</div></div>
                                                            <div className="col-10 col-lg-11 pl-0 pr-0">
                                                                <div className="row">
                                                                    <div className="col-7 col-lg-3 ord-sz">{item.order.cust_name}</div>
                                                                    <div className="col-5 col-lg-3 ord-sz text-primary">
                                                                        {item.order.order_status === false && (
                                                                            <span className="badge rounded-pill text-bg-warning">{item.order.payment_status}</span>
                                                                        )}
                                                                        {item.order.order_status === true && (
                                                                            <span className="badge rounded-pill text-bg-success">Complete</span>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-7 col-lg-4 ord-sz grey-text">{getVendorCurrency()} {item.order.total_amount}</div>
                                                                    <div className="col-5 col-lg-2 ord-sz grey-text">{formatDate(item.order.order_time)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                );
                                            })}
                                            </>
                                        )}  
                                        {
                                            
                                        }


                                    </div>
                            </div>
                        </div>

                        


                </div>
            </div>

            <div className="modal fade" id="withdrw" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mt70">
                    <div className="modal-content">
                        <div className="modal-body pb-4">                                  
                                <div className="row">
                                    <div className="col-12"><p>Enter password to complete</p></div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><input type="password" name="passver1" placeholder="Password" className="form-control form-control" value={pass} onChange={(e) => setPass(e.target.value)}/></div>
                                    <div className="col-12 mt-3">
                                        <button onClick={handleWithdraw} disabled={loading} className="btn btn-primary col-12">
                                            {loading ? 'Processing...' : 'Withdraw'}
                                        </button>
                                    </div>  
                                    {message0 && <div className="mt-3 text-danger font-bold font9">{message0}</div>}                                  
                                    {message && <div className="mt-3 text-success font-bold font9">{message}</div>}
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="addaccount" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mt70">
                    <div className="modal-content">
                        <div className="modal-body pb-4">                                  
                            <h6><b>Update Payment Details</b></h6>
                            <div className="mb-4">
                                <label htmlFor="lnm" className="form-label mb-0 font9">Account Type</label>
                                <div className="grey-text font9 mb-2">Select account type & account number</div>
                                <select className="form-control font9 ht40" onChange={inputHandler} name="pay_mode_desc">
                                    <option value="">Select</option>
                                    <option value="Mpesa">Mpesa</option>
                                    <option value="Bank">Bank</option>
                                </select>                                
                                {PayDiv1 && <p className='text-danger font9 p-0 m-0'>*Confirm this value</p>}
                                {pymtFormData.pay_mode_desc == "Bank" && <>
                                    <div className="grey-text font9 mt-3">Bank Paybill</div>
                                    <div className="input-group">
                                        <input type="number" className="form-control font9 ht40" name="pay_mode_desc_paybill" onChange={inputHandler} placeholder=""/>
                                    </div>     
                                    {PayDiv2 && <p className='text-danger font9 p-0 m-0'>*Confirm Paybill</p>}                           
                                 </>
                                }                                
                                <div className="grey-text font9 mt-3">{pymtFormData.pay_mode_desc == "Bank" && <>Account Number</>}{pymtFormData.pay_mode_desc == "Mpesa" && <>Mpesa No. 254 xxx xxx xxx</>}</div>
                                <div className="input-group">
                                    <input type="number" className="form-control font9 ht40" name="acc_no" onChange={inputHandler} placeholder=""/>
                                </div>
                                    {PayDiv3 && <p className='text-danger font9 p-0 m-0'>*Confirm this value</p>}
                                
                                <button type="button" className="col-12 btn btn-primary mt-4" onClick={submitHandler}>Complete</button>     
                                {PayDiv4 && <p className='text-success mt-4 text-center'><b>Account updated successfully</b></p>}
                            </div>
                            {pymtFormData0.acc_no != '0' && <>
                                <hr/>
                                <h6>Existing Payment Details</h6>
                                <div className="row ord-bg bg-white hvr bx-shadow font9 acc-con">      
                                    {pymtFormData0.pay_mode_desc == "Mpesa" && <>                                                 
                                        <div className="col-1">1</div> 
                                        <div className="col-4 p-0">{pymtFormData0.pay_mode_desc}</div>                                            
                                        <div className="col-5 p-0">{pymtFormData0.acc_no}</div>                                          
                                        <div className="col-2"><CheckCircle className="pointer text-success"/></div>                               
                                    </>
                                    }  
                                    {pymtFormData0.pay_mode_desc == "Bank" && <>                                                 
                                        <div className="col-1">1</div> 
                                        <div className="col-3 p-0">{pymtFormData0.pay_mode_desc}</div>                                            
                                        <div className="col-3 p-0">{pymtFormData0.pay_mode_desc_paybill}</div>                                             
                                        <div className="col-3 p-0">{pymtFormData0.acc_no}</div>                                          
                                        <div className="col-2"><CheckCircle className="pointer text-success"/></div>                               
                                    </>
                                    }                                      
                                    
                                </div>
                                </>
                            } 
                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default SellerWithdraw;